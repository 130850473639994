import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { ApolloError } from "apollo-client/errors/ApolloError";
import { Icon, DataGrid, Loading } from "@foris/avocado-ui";
import cx from "classnames";
import SearchTableSkeleton from "./SearchTable.skeleton";
import EmptyResults from "../../../common/components/EmptyResults/EmptyResults";
import { DimensionNameEs } from "../../../models/IDimension";
import { PageInfo } from "@models/ISchema";
import css from "./searchTable.module.scss";

export interface ISearchTable {
  search: string;
  pageCount: number;
  setPage: (value: number) => void;
  total: number;
  page: number;
  loading: boolean;
  error: ApolloError;
}

interface ISearchTableProps {
  title: string;
  dimension: DimensionNameEs;
  advanceLink: string;
  columns: any[];
  data: any;
  loading?: boolean;
  search?: string;
  error?: any;
  pageInfo?: PageInfo;
  setPage?: (value: number) => void;
}

const SearchTable = (props: ISearchTableProps) => {
  const {
    data,
    columns,
    title,
    advanceLink,
    setPage,
    loading,
    search,
    error,
    dimension,
    pageInfo,
  } = props;

  const pagination = {
    onChange: !loading ? setPage : () => null,
    total: pageInfo?.total,
    page: pageInfo?.page,
    size: 20,
  };

  // first load of the table
  if (!data && loading && !error)
    return <SearchTableSkeleton loading={loading} columns={Array(columns.length)} />;

  // empty data
  if (!data || data?.length === 0 || error)
    return <EmptyResults dimension={dimension} search={search} />;

  const nonSortableColumns = useMemo(() => {
    const columnsSet = new Set<string>();

    columns.forEach(({ header }) => {
      columnsSet.add(header);
    });

    return columnsSet;
  }, [columns]);

  return (
    <section className={cx(css.cntResults)}>
      {loading && <Loading />}

      <header className={cx(css.header)}>
        <h1 className={cx(css.header_title)}>{title}</h1>
        <Link className={cx(css.header_link)} to={advanceLink}>
          <Icon icon="external-link" /> Ir a búsqueda avanzada
        </Link>
      </header>

      <DataGrid
        columns={columns}
        batch={data}
        nonSortableColumns={nonSortableColumns}
        onHeaderClick={() => null}
        pagination={pagination}
      />
    </section>
  );
};

export default SearchTable;
