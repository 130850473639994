import * as React from "react";
import Accordion from "./Accordion";

interface IAccordion {
  id: number;
  active: boolean;
  onChange?: (id: number) => void;
}

class AccordionContainer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      accordionChildren: [],
      active: { id: null, active: false },
    };
  }

  componentDidMount = () => {
    this.childrenAccordion();
  };

  cloneElement = (newProps: IAccordion, oldComponent: any, key: number) => {
    let newComponent = null;
    const newPropsClone = {
      key,
      ...oldComponent.props,
      accordion: {
        id: newProps.id,
        active: newProps.active,
        onChange: (id: any) => {
          this.childrenChange(id);
        },
      },
    };
    if (oldComponent.type === Accordion) {
      newComponent = React.cloneElement(oldComponent, newPropsClone);
    }
    return newComponent;
  };

  childrenChange = (id: any) => {
    const { accordionChildren } = this.state;
    const accordionChange = [];
    if (Array.isArray(accordionChildren) && accordionChildren.length > 0) {
      accordionChildren.forEach((item, index) => {
        let activeValue = id === index;
        if (activeValue && item.props.accordion.active) {
          activeValue = false;
        }
        const newProps = {
          ...item.props,
          key: index,
          accordion: {
            ...item.props.accordion,
            active: activeValue,
          },
        };
        accordionChange.push(React.cloneElement(item, newProps));
      });
    }
    this.setState({ accordionChildren: accordionChange });
  };

  childrenAccordion = () => {
    const { children } = this.props;
    const accordionChildren = [];
    const newProps: IAccordion = {
      id: 0,
      active: false,
    };
    if (Array.isArray(children) && children.length > 0) {
      children.forEach((item, index) => {
        newProps.id = index;
        const newComponent = this.cloneElement({ ...newProps }, item, index);
        if (newComponent) {
          accordionChildren.push(newComponent);
        }
      });
    } else {
      const newComponent = this.cloneElement(newProps, children, 1);
      if (newComponent) {
        accordionChildren.push(newComponent);
      }
    }
    this.setState({ accordionChildren });
  };

  render() {
    const { accordionChildren } = this.state;
    return accordionChildren;
  }
}

export default AccordionContainer;
