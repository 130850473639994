import React from "react";
import { Skeleton, Table } from "@foris/avocado-ui";
import cx from "classnames";
import css from "./searchTable.module.scss";

interface ISearchTableSkeletonProps {
  columns: any;
  loading: boolean;
}

const SearchTableSkeleton = (props: ISearchTableSkeletonProps) => {
  const { columns, loading } = props;
  return (
    <section className={cx(css.cntResultsSkeleton)}>
      <Skeleton.Text className={css.cntResultsSkeleton_title} size={28} dark />
      <Skeleton.Text className={css.cntResultsSkeleton_link} size={14} />
      <Table.Container
        className={css.cntResultsSkeleton_table}
        columns={columns}
        data={[]}
        pagerBottom={true}
        pagerTop={true}
        loading={loading}
      />
    </section>
  );
};

export default SearchTableSkeleton;
