import { InvalidInput, ValidationErrorReason } from "../ISchema";
import ErrorWarning, { IErrorWarning } from "./ErrorWarning";

class InstructorBundle extends ErrorWarning {
  private error: InvalidInput;
  constructor(error: InvalidInput) {
    super();
    this.error = error;
  }

  private getMessageInvalidInput = (): IErrorWarning => {
    let invalidInput = null;
    if (this.error.__typename === "InvalidInput") {
      switch (this.error.code) {
        case ValidationErrorReason.InvalidTeachingHoursPercentage:
          const teachingHours = `La suma de los porcentajes de responsabilidad de los docentes deben sumar 100%.`;
          invalidInput = this.setError(teachingHours);
          break;
        case ValidationErrorReason.InvalidPaymentPercentage:
          const payment = `La suma de los porcentajes de carga de los docentes deben sumar 100%.`;
          invalidInput = this.setError(payment);
          break;
        case ValidationErrorReason.MustSetPrimaryInstructor:
          const primary = `Uno de los docentes de la liga debe estar marcado como principal.`;
          invalidInput = this.setError(primary);
          break;
        case ValidationErrorReason.MoreThanOnePrimaryInstructor:
          const morePrimary = `La liga debe tener solo un docente marcado como principal.`;
          invalidInput = this.setError(morePrimary);
          break;
        default:
          invalidInput = null;
          break;
      }
    }
    return invalidInput;
  };

  public getMessage = () => {
    switch (this.error && this.error.__typename) {
      case "InvalidInput":
        return this.getMessageInvalidInput();
      default:
        return null;
    }
  };
}

export default InstructorBundle;
