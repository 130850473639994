import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  always,
  any,
  append,
  ifElse,
  isEmpty,
  join,
  keys,
  lensPath,
  map,
  omit,
  pipe,
  reduce,
  toPairs,
  uniq,
  view,
} from "ramda";
import { useParams } from "react-router-dom";
import { Button } from "@foris/avocado-ui";
import Modal from "../Modal/Modal";
import { Context } from "../../context/GroupsManagerContext";
import { Types as EditionTypes } from "../../context/editions.reducer";
import { IParams } from "@models/IParams";
import {
  AdaptedGroup,
  AdaptedValidationErrorReason,
  ErrorsByGroupId,
  GroupEditionErrors,
} from "../../models";
import BottomErrors from "../BottomErrors/BottomErrors";
import BottomWarnings from "../BottomWarnings/BottomWarnings";
import savingAllowed from "../../utils/savingAllowed";
import splitGroupErrors from "../../utils/splitGroupErrors";
import allEditionsWithHardErrors from "../../utils/allEditionsWithHardErrors";
import BottomInfo from "../BottomInfo/BottomInfo";
import css from "./bottomDetail.module.scss";

interface Props {
  onSave: () => Promise<void>;
  parentGroupHasError?: boolean;
  isLoading?: boolean;
  externalErrors?: GroupEditionErrors;
}

const BottomDetail: React.FC<Props> = ({
  onSave,
  parentGroupHasError,
  isLoading,
  externalErrors = {},
}) => {
  const { state, dispatch } = useContext(Context);
  const { origin, scenario, workspace }: IParams = useParams();
  const [editedCapacitiesByCrn, setEditedCapacitiesByCrn] = useState<string[]>([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [editedVisibilitiesByCrn, setEditedVisibilitiesByCrn] = useState<string[]>([]);
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const onModalClick = {
    primary: () => {
      setDisplayModal(false);
      dispatch({ type: EditionTypes.Clean });
      onSave();
    },
    secondary: () => setDisplayModal(false),
  };

  const campuses = pipe(
    keys,
    reduce((acc, groupId) => append(state?.data?.groupsById[groupId as string], acc), []),
    map(view(lensPath(["campus", "code"]))),
    uniq,
    join(", "),
  );

  /**
   * Split the `errorsByGroupId` by "hardness".
   */
  const [hardErrorsByGroupId, rawSoftErrorsByGroupId]: [
    ErrorsByGroupId,
    ErrorsByGroupId,
  ] = useMemo(() => {
    const errorsByGroupId = state?.editions?.errorsByGroupId ?? {};

    if (Object.keys(externalErrors) !== undefined) {
      Object.keys(externalErrors).forEach(key => {
        errorsByGroupId[key] = externalErrors[key];
      });
    }

    return splitGroupErrors(structuredClone(errorsByGroupId));
  }, [state?.editions?.errorsByGroupId, externalErrors]);

  const softErrorsByGroupId = useMemo(
    () => omit(keys(hardErrorsByGroupId), rawSoftErrorsByGroupId),
    [rawSoftErrorsByGroupId],
  );

  const someHardErrorExists = !isEmpty(hardErrorsByGroupId) || Boolean(parentGroupHasError);
  const someSoftErrorExists = !isEmpty(softErrorsByGroupId);
  const somePendingChangeExists = useMemo(() => {
    return any(
      (group: AdaptedGroup) =>
        !group?.isEditable?.allowed &&
        group?.isEditable?.reason === AdaptedValidationErrorReason.PendingChangeRequest,
      state?.data?.groups ?? [],
    );
  }, [state?.data?.groups]);

  /**
   * After groups are edited, update the list of groups with edited
   * `capacities` and the list of groups with edited `visibilities`.
   */
  useEffect(() => {
    const { capacitiesById, visibilitiesById } = reduce(
      ({ capacitiesById, visibilitiesById }, [groupId, edition]) => {
        const crn = state?.data?.groupsById[groupId]?.code;
        if (edition?.capacity !== undefined) capacitiesById.add(crn);
        if (edition?.visibleForEnrollment !== undefined) visibilitiesById.add(crn);
        return { capacitiesById, visibilitiesById };
      },
      { capacitiesById: new Set(), visibilitiesById: new Set() },
      toPairs(state?.editions?.byGroupId),
    );
    setEditedCapacitiesByCrn(Array.from(capacitiesById) as string[]);
    setEditedVisibilitiesByCrn(Array.from(visibilitiesById) as string[]);
  }, [state?.editions?.byGroupId]);

  return (
    <section className={css.bottom}>
      <Modal
        typeState="confirm"
        title="Cambios con errores no se guardan"
        show={displayModal}
        textButtonPrincipal={"Guardar cambios"}
        textButtonSecondary={"Volver a editar"}
        onClickPrincipal={onModalClick?.primary}
        onClickSecondary={onModalClick?.secondary}
        onClose={onModalClick?.secondary}
      >
        Algunos de tus cambios tienen errores. Si continuas, solo se guardarán los cambios válidos.
      </Modal>
      {!isEmpty(state?.editions?.byGroupId) && (
        <h3 className={css.bottom_label}>Cambios pendientes</h3>
      )}
      {editedCapacitiesByCrn?.length > 0 && (
        <p className={css.bottom_text}>
          Grupos con cupos editados: {join(" | ", editedCapacitiesByCrn)}
        </p>
      )}
      {editedVisibilitiesByCrn?.length > 0 && (
        <p className={css.bottom_text}>
          Grupos con visibilidad editada: {join(" | ", editedVisibilitiesByCrn)}
        </p>
      )}
      {!isEmpty(state?.editions?.byGroupId) && (
        <p className={css.bottom_text}>Sedes: {campuses(state?.editions?.byGroupId)}</p>
      )}

      {somePendingChangeExists && <BottomInfo contextUrl={contextUrl} />}
      {(someHardErrorExists || someSoftErrorExists) && (
        <>
          {someHardErrorExists && (
            <BottomErrors
              errorsByGroupId={hardErrorsByGroupId}
              capacityError={{
                display: Boolean(parentGroupHasError),
                group: state?.data?.group,
              }}
              groupsById={state?.data?.groupsById}
            />
          )}
          {someSoftErrorExists && (
            <BottomWarnings
              errorsByGroupId={softErrorsByGroupId}
              groupsById={state?.data?.groupsById}
            />
          )}
        </>
      )}

      <div className={css.bottom_buttons}>
        <Button
          disabled={isEmpty(state?.editions?.byGroupId) || isLoading}
          color="primary"
          variant="outline"
          onClick={() => dispatch({ type: EditionTypes.Clean })}
        >
          Cancelar
        </Button>
        <Button
          disabled={
            parentGroupHasError ||
            allEditionsWithHardErrors(state?.editions?.errorsByGroupId) ||
            isEmpty(state?.editions?.byGroupId) ||
            isLoading
          }
          onClick={() => {
            const someEditionExists = !isEmpty(state?.editions?.byGroupId);
            const someEditionIsntSavable = !savingAllowed(state?.editions?.errorsByGroupId);
            return ifElse(
              always(someEditionExists && someEditionIsntSavable),
              () => setDisplayModal(true),
              onSave,
            )();
          }}
        >
          Guardar cambios
        </Button>
      </div>
    </section>
  );
};

export default BottomDetail;
