import gql from "graphql-tag";

export const linkSearchMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $filter: LinkFilterInput) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createLinkFilter(filter: $filter) {
        id
        links {
          items {
            id
            vacancies
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
        }
      }
    }
  }
`;

export const linkSearchQuery = gql`
  query filter($scenarioId: ID!, $filterId: ID, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      links(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          vacancies
          course {
            id
            name
            code
            courseComponents {
              id
              component {
                id
                code
                name
              }
            }
          }
          sectionsCount
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
