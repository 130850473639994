import { has, flip } from "ramda";

/**
 * Return true if the given `keyof GroupEditionErrors` is a "hard" error, meaning,
 * users can't skip it to save an edition.
 *
 * @param error keyof GroupEditionErrors
 *
 * @return boolean
 */
const isHardError = flip(has)({
  CapacityAugmentedForInactive: true,
  CapacityGTSmallerClassroom: true,
  CapacityLTEnrollments: true,
});

export default isHardError;
