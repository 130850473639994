import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { always, head, lensPath, lensProp, map, pipe, prepend, set, when } from "ramda";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { IParams } from "@models/IParams";
import { AcademicUnit, AcademicUnitFilterInput } from "@models/ISchema";
import { Context } from "../../context/GroupsManagerContext";
import { Types } from "../../context/filters.reducer";
import { schoolSearch } from "../../graphql/schoolSearch.query";
import { SelectableOption } from "../../models";

interface SchoolSelectorProps {
  onLoading?: (isLoading: boolean) => void;
}

const SchoolSelector: React.FC<SchoolSelectorProps> = ({ onLoading }) => {
  const { state, dispatch } = useContext(Context);
  const client = useApolloClient();
  const allSchoolsOption = { id: "*", value: "*", label: "Buscar o seleccionar", self: null };
  const [selectedSchool, setSelectedSchool] = useState<SelectableOption<AcademicUnit>>(
    Boolean(state?.filters?.school)
      ? {
          id: state?.filters?.school?.id,
          value: state?.filters?.school?.id,
          label: state?.filters?.school?.name ?? "",
          self: state?.filters?.school,
        }
      : allSchoolsOption,
  );
  const [page, setPage] = useState(0);
  const [, setSearchTerm] = useState("");
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const { origin, scenario }: IParams = useParams();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [isLoadingSelectableSchools, setIsLoadingSelectableSchools] = useState(false);

  const variableFields: AcademicUnitFilterInput = useMemo(() => {
    const subjectId = state?.filters?.subject?.id;
    const campusId = state?.filters?.campus?.id;

    if (!subjectId && !campusId) return undefined;

    return pipe(
      when(always(Boolean(subjectId)), set(lensPath(["subject", "eq"]), subjectId)),
      when(always(Boolean(campusId)), set(lensPath(["campus", "eq"]), campusId)),
    )({});
  }, [state?.filters?.subject, state?.filters?.campus]);

  useEffect(() => {
    dispatch({ type: Types.SetSchool, payload: selectedSchool?.self });

    if (!state?.filters?.subject) {
      dispatch({ type: Types.SetDepartment, payload: null });
    }
  }, [selectedSchool]);

  useEffect(() => {
    onLoading?.(isLoadingSelectableSchools);
  }, [isLoadingSelectableSchools]);

  /**
   * Clean the selection if the filters are updated from outside this component
   * (in particular, with the context's action `CleanFilters`).
   */
  useEffect(() => {
    if (!state?.filters?.school) setSelectedSchool(allSchoolsOption);
  }, [state?.filters?.school]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const variables = {
          query: schoolSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              pagination: { page, size, searchTerm },
              fields: variableFields,
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery = data?.data;
        const pageInfo = dataQuery?.groupsManagerSchools?.pageInfo;
        const schools = dataQuery?.groupsManagerSchools?.items;

        const options = map(
          (school: AcademicUnit) => ({
            id: school?.id,
            value: school?.id,
            label: school?.name ?? "",
            self: school,
          }),
          schools ?? [],
        );

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, variableFields],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: newSearchPage === 1 ? prepend(allSchoolsOption, options) : options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  /**
   * If a subject or campus is selected, request the selectable schools and
   * auto-select it if there's only one option.
   */
  useEffect(() => {
    const doRequest = async () => {
      setIsLoadingSelectableSchools(true);

      const { options } = await requestItems("", 1);
      if (options?.length === 1) {
        setSelectedSchool(head(options));
      } else if (options?.length === 0) {
        setSelectedSchool(set(lensProp("label"), "Escuela no definida", allSchoolsOption));
      }

      setIsLoadingSelectableSchools(false);
    };

    if (state?.filters?.subject || state?.filters?.campus) {
      doRequest();
    } else if (!state?.filters?.subject && !state?.filters?.campus) {
      setSelectedSchool(allSchoolsOption);
    }

    return () => {
      // stop any request when the component unmounts
      client.stop();
      setIsLoadingSelectableSchools(false);
    };
  }, [state?.filters?.subject, state?.filters?.campus]);

  return (
    <SelectPagination
      label="Escuela"
      isDisabled={false}
      value={selectedSchool}
      onInputChange={setSearchTerm}
      onChange={setSelectedSchool}
      loadOptions={loadOptions}
    />
  );
};

export default SchoolSelector;
