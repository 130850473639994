import gql from "graphql-tag";
import { FRAGMENT_SECTIONS } from "../../graphql/section.fragment";

export const populationSection = gql`
  query populationSection(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on PopulationInfo {
            population {
              id
              origin {
                id
              }
              code
              campus {
                id
                code
              }
              program {
                id
                code
                name
              }
              modality {
                id
                code
              }
              shift {
                id
                code
              }
              curriculum
              level
              size
              packageCount
              term {
                code
              }
            }
          }
        }
        ...CalendarSections
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
        termParts {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
    }
  }
  ${FRAGMENT_SECTIONS}
`;
