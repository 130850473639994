import React, { useState, useEffect, useContext, useCallback } from "react";
import { has, map } from "ramda";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { AcademicUnit } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { Context } from "../../context/GroupsManagerContext";
import { Types } from "../../context/filters.reducer";
import { courseTypeSearch } from "../../graphql/courseTypeSearch.query";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { SelectableOption } from "../../models";

const CourseTypeSelector: React.FC = () => {
  const { state, dispatch } = useContext(Context);
  const client = useApolloClient();
  const allCourseTypesOption = { id: "*", value: "*", label: "Buscar o seleccionar", self: null };
  const [selectedCourseType, setSelectedCourseType] = useState<SelectableOption<AcademicUnit>>(
    allCourseTypesOption,
  );
  const { origin, scenario }: IParams = useParams();
  const [, setSearchTerm] = useState("");
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const params = queryString.parse(location.search);

  const excludeAlreadySelectedCourseType = (option: FilterOptionOption<unknown>) => {
    const courseType = option as FilterOptionOption<AcademicUnit>;
    return !has(courseType?.value, state?.filters?.courseTypesById ?? {});
  };

  useEffect(() => {
    if (selectedCourseType?.id !== "*") {
      dispatch({ type: Types.AddCourseTypeById, payload: selectedCourseType?.self });
    }
  }, [selectedCourseType]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const variables = {
          query: courseTypeSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              pagination: { page, size, searchTerm },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery = data?.data;
        const pageInfo = dataQuery?.groupsManagerCourseTypes?.pageInfo;
        const courseTypes = dataQuery?.groupsManagerCourseTypes?.items;

        const options = map((courseType: AcademicUnit) => ({
          id: courseType?.id,
          value: courseType?.id,
          label: courseType?.name ?? "",
          self: courseType,
        }))(courseTypes ?? []);

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, page],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  return (
    <SelectPagination
      label=""
      isDisabled={false}
      value={allCourseTypesOption}
      onInputChange={setSearchTerm}
      onChange={setSelectedCourseType}
      loadOptions={loadOptions}
      filterOption={excludeAlreadySelectedCourseType}
      {...{
        loadOptionsOnMenuOpen: true,
      }}
    />
  );
};

export default CourseTypeSelector;
