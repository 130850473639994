import { SectionValidationErrorUnion, ValidationErrorReason } from "../ISchema";
import ErrorWarning, { IErrorWarning } from "./ErrorWarning";

export const sectionEditStrings = {
  SectionCapacityExceedsCourseComponentMaxSize:
    "Vacantes de sección exceden estándar académico definido para el componente.",
  InvalidSection: "Sección no existe.",
  InvalidCourseComponent: "Componente seleccionado no existe.",
};

class SectionEdit extends ErrorWarning {
  private section: SectionValidationErrorUnion;
  constructor(error: SectionValidationErrorUnion) {
    super();
    this.section = error;
  }

  private getMessageInvalidInput = (): IErrorWarning => {
    let invalidInput = null;
    if (this.section.__typename === "InvalidInput") {
      switch (this.section.code) {
        case ValidationErrorReason.InvalidSection:
          invalidInput = this.setError(sectionEditStrings.InvalidSection);
          break;
        case ValidationErrorReason.InvalidCourseComponent:
          invalidInput = this.setError(sectionEditStrings.InvalidCourseComponent);
          break;
        default:
          invalidInput = null;
          break;
      }
    }
    return invalidInput;
  };

  private getMessageComponentSize = (): IErrorWarning => {
    let invalidComponent = null;
    if (this.section.__typename === "InvalidComponentSize") {
      switch (this.section.reason) {
        case ValidationErrorReason.SectionCapacityExceedsCourseComponentMaxSize:
          const message = sectionEditStrings.SectionCapacityExceedsCourseComponentMaxSize;
          invalidComponent = this.setWarning(message);
          break;
        default:
          invalidComponent = null;
      }
    }
    return invalidComponent;
  };

  public getMessage = () => {
    switch (this.section && this.section.__typename) {
      case "InvalidInput":
        return this.getMessageInvalidInput();
      case "InvalidComponentSize":
        return this.getMessageComponentSize();
      default:
        return null;
    }
  };
}

export default SectionEdit;
