import React from "react";
import { useQuery } from "react-apollo";
import Select from "react-select";
import { GET_ORIGINS } from "./context.queries";
import Skeleton from "react-loading-skeleton";

interface ISelectProps {
  scenarioValue: any;
  originValue: any;
  onChange: (value: any) => void;
}

const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

const OriginsSelect: React.FC<ISelectProps> = (props: ISelectProps) => {
  const { scenarioValue, onChange, originValue } = props;

  const { loading, error, data } = useQuery<any, any>(GET_ORIGINS, {
    variables: {
      scenarioId: scenarioValue ? scenarioValue.value : 0,
      originFilter: {
        type: "ASSIGNATION",
        isVisible: true,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={"100%"} />
      </div>
    );
  if (error) return <Select options={[]} placeholder="Origin" />;

  const options = data.data.origins.map((value: any) => {
    return { label: `${value.id} - ${value.label}`, value: value.id };
  });

  return (
    <div style={{ padding: "10px 0" }}>
      <Select
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#e5e8ec",
            primary: "#6993c9",
            neutral80: "#575d62",
            neutral90: "#575d62",
          },
        })}
        maxMenuHeight={100}
        options={options}
        placeholder="Seleccionar"
        value={originValue}
        onChange={(value: any): void => onChange(value)}
      />
    </div>
  );
};
export default OriginsSelect;
