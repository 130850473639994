import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { baseQuery } from "../graphql/base.query";
import { ContextEDH } from "@context/ContextEDH";
import { Types } from "@context/base.reducer";
import { BaseQuery } from "@models/ISchema";

type Response = { data: BaseQuery; error: boolean; loading: boolean };

const useGetDataBase = (): [Response, (id: string) => void] => {
  const client = useApolloClient();
  const { dispatch } = useContext(ContextEDH);
  const [response, setResponse] = useState<Response>(null);

  const getDataBase = async (id: string) => {
    setResponse({ data: null, error: false, loading: true });
    try {
      const { data } = await client.query({
        query: baseQuery,
        variables: {
          id: parseInt(id),
        },
      });
      const base: BaseQuery = data?.base;
      dispatch({ type: Types.SetBase, payload: base });
      const res = { data: base, error: false, loading: false };
      setResponse(res);
      return [res, getDataBase];
    } catch (error) {
      setResponse({ data: null, error: true, loading: false });
      dispatch({ type: Types.SetBase, payload: null });
      console.error("HOOK-getDataBase", error);
    }
  };

  return [response, getDataBase];
};

export default useGetDataBase;
