import React, { useEffect, useContext, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "react-apollo";
import { Button, Loading } from "@foris/avocado-ui";
import cx from "classnames";
import { ClassroomBookingValidationErrorUnion } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/request.reducer";
import { Types as TypesSearch } from "../../context/search.reducer";
import useGetEventTypes from "../../hooks/useGetEventTypes";
import useGetIntervals from "../../hooks/useIntervals";
import { createClassroomBookingMutation } from "../../graphql/createClassroomBooking.mutation";
import Header from "../../components/RequestHeader/RequestHeader";
import Form from "../../components/RequestForm/RequestForm";
import Days from "../../components/RequestDays/RequestDays";
import Errors from "../../components/RequestErrors/RequestErrors";
import Layout from "../../../../common/layout/Layout";
import css from "./request.module.scss";

const Request = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(BookingContext);
  const [createClassroomBookingAction] = useMutation(createClassroomBookingMutation);
  const { origin, scenario, workspace }: IParams = useParams();
  const [loading, setLoading] = useState(false);
  const [eventTypes, getEventTypes] = useGetEventTypes();
  const [intervals, getIntervals] = useGetIntervals();
  const urlContext = `${workspace}/${scenario}/${origin}`;

  useEffect(() => {
    if (!state?.search?.classroom) {
      history.push(`/booking/search/${urlContext}`);
    }

    if (!state?.request?.eventTypesOptions && !eventTypes?.data && !eventTypes?.loading) {
      getEventTypes();
    }

    if (!state?.search?.intervals?.length && !intervals?.data) {
      getIntervals(scenario);
    }
  }, []);

  const calculateSessions = () => {
    const formatDate = (date: Date, time: string) => {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hasDefaultTime = time?.split(":")?.length <= 2;
      const dateTime = hasDefaultTime ? `${time}:00` : time;

      return `${year}-${month}-${day} ${dateTime}`;
    };

    const isSameDay = (date: Date, dayId: string) => {
      const dateDayId = date.getDay().toString();
      return dateDayId === "0" ? dayId === "7" : dateDayId === dayId;
    };

    const selectedWeeks = state?.search?.intervals?.filter(week => week.selected);
    const dates = state?.search?.dateTime;

    const sessions = [];

    // recurrent booking
    if (selectedWeeks && selectedWeeks.length) {
      selectedWeeks?.forEach(week => {
        const start = new Date(week.startingDate);
        const end = new Date(week.endingDate);

        dates.forEach(date => {
          const dayId = date.day.value;
          const currDate = new Date(start.getTime());

          // move forward one day at a time until we reach the target day
          while (!isSameDay(currDate, dayId)) {
            currDate.setDate(currDate.getDate() + 1);
          }

          // keep adding one session per week for the defined day/start/end
          // until the `end` date is reached
          while (currDate <= end) {
            sessions.push({
              start: formatDate(currDate, date.start.value.value),
              end: formatDate(currDate, date.end.value.value),
            });

            // move a week forward
            currDate.setDate(currDate.getDate() + 7);
          }
        });
      });
    }
    // unique booking
    else {
      dates.forEach(date => {
        const [year, month, mintues] = date?.day?.value?.split("-");
        sessions.push({
          start: formatDate(
            new Date(parseInt(year), parseInt(month) - 1, parseInt(mintues)),
            date.start.value.value,
          ),
          end: formatDate(
            new Date(parseInt(year), parseInt(month) - 1, parseInt(mintues)),
            date.end.value.value,
          ),
        });
      });
    }

    return sessions;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const title = state?.request?.title?.value;
    const eventType = state?.request?.eventType?.value;
    const responsible = state?.request?.userResponsible?.value;
    let isSubmit = true;

    if (!title || title === "") {
      dispatch({
        type: Types.SetTitle,
        payload: { value: title, error: true },
      });
      isSubmit = false;
      setLoading(false);
    }
    if (!eventType || eventType === "") {
      dispatch({
        type: Types.SetEventType,
        payload: { value: state?.request?.eventType?.value, error: true },
      });
      isSubmit = false;
      setLoading(false);
    }
    if (!responsible || responsible === "") {
      dispatch({
        type: Types.SetUserResponsible,
        payload: { value: state?.request?.userResponsible?.value, error: true },
      });
      isSubmit = false;
      setLoading(false);
    }

    if (isSubmit) {
      const capacity = state?.search?.requirements?.people?.count;
      const description = state?.request?.description?.value;
      const isRecurrent = state?.search?.recurrence;
      const programId = state?.search?.preferences?.program?.value;
      const classroomId = state?.search?.classroom?.id;
      const sessions = calculateSessions();
      const user = state?.request?.userResponsible?.value;

      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun: false,
          skipValidations: false,
          responsibleId: user,
          sessions,
          capacity,
          classroomId,
          isRecurrent,
          title,
          programId,
          description,
          classroomBookingTypeId: eventType,
        },
      };
      const response = await createClassroomBookingAction({
        variables,
      });

      const errors: ClassroomBookingValidationErrorUnion[] =
        response?.data?.cube?.createClassroomBooking?.validationErrors;

      if (errors && errors.length > 0) {
        dispatch({ type: Types.SetErrors, payload: errors });
        setLoading(false);
      } else {
        //sucess
        setLoading(false);
        dispatch({ type: Types.ClearRequest, payload: null });
        dispatch({ type: TypesSearch.ClearSearch, payload: null });
        history.push(`/booking/list/${urlContext}`);
      }
    }
  };

  return (
    <Layout contextSearch={false}>
      <section className={cx(css.cntRequest)}>
        {loading && <Loading />}
        <Header workspace={workspace} scenario={scenario} origin={origin} />
        <section className={cx(css.detailRequest, "container-row")}>
          <Days />
          <Form />
          <Errors />
          <footer className={cx(css.footerRequest, "container-row", "row_sm--between")}>
            <Button
              className={cx(css.footerRequest_btn, "col_sm", "col_xs_12")}
              typeButton="transparent"
              onClick={() => {
                dispatch({ type: Types.ClearRequest, payload: null });
                dispatch({ type: TypesSearch.ClearSearch, payload: null });
                history.push(`/booking/list/${urlContext}`);
              }}
            >
              Cancelar
            </Button>
            <Button
              className={cx(css.footerRequest_btn, "col_sm", "col_xs_12")}
              onClick={handleSubmit}
            >
              Enviar solicitud
            </Button>
          </footer>
        </section>
      </section>
    </Layout>
  );
};

export default Request;
