import { createContext, useCallback, useState } from "react";
import { User, Module, OnOffFeatures } from "../../models/ISchema";
export interface IContextApp {
  lockEdit: boolean;
  setLockEdit: (value: boolean) => void;
  user: User;
  setUser: (value: User) => void;
  modules: Module[];
  setModules: (value: Module[]) => void;
  openContext: boolean;
  setOpenContext: (value: boolean) => void;
  permits: OnOffFeatures;
  setPermits: (value: OnOffFeatures) => void;
}

// context default value
const defaultContext: IContextApp = {
  lockEdit: false,
  setLockEdit: null,
  user: null,
  setUser: null,
  modules: null,
  setModules: null,
  openContext: false,
  setOpenContext: null,
  permits: null,
  setPermits: null,
};

// context object
export const ContextApp = createContext<IContextApp>(defaultContext);

// custom Hook
export const useContextApp = (): IContextApp => {
  const [lockEdit, setLockEditState] = useState(defaultContext.lockEdit);
  const [user, setUserState] = useState(defaultContext.user);
  const [modules, setModulesState] = useState(defaultContext.modules);
  const [openContext, setOpenContextState] = useState(defaultContext.openContext);
  const [permits, setPermitsState] = useState(defaultContext.permits);
  /**
   * lockEdit
   */
  const setLockEdit = useCallback((value: boolean) => {
    if (value) setLockEditState(value);
  }, []);

  /**
   * set User context
   */
  const setUser = useCallback((value: User) => {
    if (value) setUserState(value);
  }, []);

  /**
   * set modules context
   */
  const setModules = useCallback((value: Module[]) => {
    if (value) setModulesState(value);
  }, []);

  /**
   * set open modal context
   */
  const setOpenContext = useCallback((value: boolean) => {
    if (value) setOpenContextState(value);
  }, []);

  const setPermits = useCallback((value: OnOffFeatures) => {
    if (value) setPermitsState(value);
  }, []);

  return {
    lockEdit,
    setLockEdit,
    user,
    setUser,
    modules,
    setModules,
    openContext,
    setOpenContext,
    permits,
    setPermits,
  };
};
