import gql from "graphql-tag";

export const coursesMultiKey = gql`
  query course($scenarioId: ID!, $filter: CourseFilterInput) {
    data(scenarioId: $scenarioId) {
      courses(filter: $filter) {
        items {
          id
          name
          code
          level
          curriculum {
            id
            code
            name
            program {
              id
              code
              name
              shift {
                id
                code
                name
              }
              modality {
                id
                code
                name
              }
              campus {
                id
                code
                name
              }
              department {
                id
                code
                name
              }
            }
          }
        }
        pageInfo {
          page
          size
          total
          hasNextPage
        }
      }
    }
  }
`;
