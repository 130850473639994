import gql from "graphql-tag";

export const COMPONENT_LIST = gql`
  query($scenarioId: ID!, $filter: CourseComponentFilterInput!) {
    data(scenarioId: $scenarioId) {
      courseComponents(filter: $filter) {
        items {
          id
          component {
            id
            code
            name
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
