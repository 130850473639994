import * as R from "ramda";
import { Session } from "@models/ISchema";
import { Week } from "@modules/sections/ISections";
import { Week as linkDataWeek } from "../context/linkData.reducer";

const intervalAdapter = R.curry(
  (sessionsWeeksById: { [key: string]: boolean }, interval: Week): Week =>
    R.pipe(
      R.assoc("checked", interval.id in sessionsWeeksById),
      R.assoc("highlight", false),
    )(interval),
);

const intervalsAdapter = (linkWeeks: Week[], session: Session): linkDataWeek[] => {
  const sessionWeeksById: { [key: string]: boolean } = R.reduce(
    (acc, week) => R.assoc(week.id, true, acc),
    {},
    session?.assignment?.intervals ?? [],
  );
  return R.map(intervalAdapter(sessionWeeksById), linkWeeks ?? []);
};

export default intervalsAdapter;
