import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import Select, { IOption } from "../../../../../common/components/Select/Select";
import { SECTION_OPTIONS } from "../graphql/sections.queries";
import { CubeQuery } from "../../../../../models/ISchema";
import { IParams } from "../../../../../models/IParams";

export interface ISelectSection {
  courseId: number;
  courseComponentId: string;
  className?: string;
  onChange: (value: IOption) => void;
}
const SelectSection: React.FC<ISelectSection> = ({
  courseId,
  courseComponentId,
  className,
  ...props
}: ISelectSection) => {
  const client = useApolloClient();
  const { origin, scenario }: IParams = useParams();
  const [sectionOptions, setSectionOptions] = useState(null);

  const getSectionOptions = useCallback(async () => {
    try {
      const variables = {
        originId: origin,
        scenarioId: scenario,
        filter: {
          fields: {
            and: [
              {
                courseId: {
                  eq: courseId,
                },
              },
              {
                courseComponentId: {
                  eq: courseComponentId,
                },
              },
            ],
          },
        },
      };
      const { data } = await client.query({ query: SECTION_OPTIONS, variables });
      const sections: CubeQuery = data.cube;
      const options: IOption[] =
        sections &&
        sections.sectionsSearch &&
        sections.sectionsSearch.items &&
        sections.sectionsSearch.items.map(item => {
          const sectionItem: IOption = {
            label: `${item.code} - ${item.course.name}`,
            value: item.id,
          };
          return sectionItem;
        });
      setSectionOptions(options);
    } catch (e) {
      console.error(e);
    }
  }, [client, courseComponentId, courseId, origin, scenario]);

  useEffect(() => {
    if (sectionOptions === null) getSectionOptions();
  }, [getSectionOptions, sectionOptions]);

  if (sectionOptions === null) return null;
  return (
    <Select
      className={className}
      placeholder="Buscar sección"
      options={sectionOptions}
      isClearable
      {...props}
    />
  );
};

export default SelectSection;
