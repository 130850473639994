import React, { useContext, useState } from "react";
import * as R from "ramda";
import cx from "classnames";
import { useMutation } from "react-apollo";
import { useParams } from "react-router-dom";
import { Button, Loading } from "@foris/avocado-ui";
import { ContextEdit, IContextEdit, IInstructor, IClassRoom } from "../ContextEdit/ContextEdit";
import { enums } from "../../../utils";
import { UPDATE_EVENT_MUTATION, CREATE_EVENT_MUTATION } from "../formEdit.queries";
import { IWeek } from "../../../ISections";
import { IParams } from "../../../../../models/IParams";
import TableIncidences from "./TableIncidences";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import css from "./validation.module.scss";

interface IValidationProps {
  event: any;
  selected: IWeek;
  onClose: (value: any) => void;
  createSession?: boolean;
  sectionId?: any;
  vacancies?: number;
  currentWeeks?: any;
}

const ValidationReport: React.FC<IValidationProps> = (props: IValidationProps) => {
  const { event, selected, onClose, createSession, sectionId, vacancies, currentWeeks } = props;
  const context: IContextEdit = useContext(ContextEdit);
  const contextApp: IContextApp = useContext(ContextApp);
  const { scenario, origin }: IParams = useParams();
  const [updateSession] = useMutation(UPDATE_EVENT_MUTATION, {});
  const [createNewSession] = useMutation(CREATE_EVENT_MUTATION, {});
  const [activeLoading, setActiveLoading] = useState(false);
  const userCanEdit =
    contextApp?.user?.permissions?.update && contextApp?.user?.abilities?.can_edit_assignment;
  const userCanCreate =
    contextApp?.user?.permissions?.create && contextApp?.user?.abilities?.can_edit_assignment;

  // Same Data
  const eventSection = event.sectionId ? event.sectionId : sectionId;
  const eventVacancies =
    event.resource && event.resource.info ? event.resource.info.section.vacancies : vacancies;

  // Original Data
  const originalDay =
    event.resource && event.resource.blockRange && event.resource.blockRange.start
      ? enums.DAY_NAMES_ES[event.resource.blockRange.start.day]
      : "";
  const originalStart =
    event.resource && event.resource.blockRange && event.resource.blockRange.start
      ? event.resource.blockRange.start.startingTime.replace(":00", "")
      : "";
  const originalEnd =
    event.resource && event.resource.blockRange && event.resource.blockRange.end
      ? event.resource.blockRange.end.endingTime.replace(":00", "")
      : "";
  const originalDate = `${originalDay} ${originalStart} - ${originalEnd}`;
  const originalInstructors =
    event.resource && event.resource.instructors ? event.resource.instructors : [];
  const originalClassRooms =
    event.resource && event.resource.classrooms ? event.resource.classrooms : [];
  const originalBuilding =
    event.resource && event.resource.classroom ? event.resource.classroom.building.name : "";
  const originalBuildings =
    event.resource && event.resource.classrooms
      ? event.resource.classrooms.map((building: any) => building.building.name)
      : [];
  const intervalsSessionWeek =
    event.resource && event.resource.intervals ? event.resource.intervals : null;

  // New Data
  const newDay = context.currentData.blockRanges.day;
  const newStartTime = context.currentData.blockRanges.startTime;
  const newEndTime = context.currentData.blockRanges.endTime;
  const newInstructors = context.instructors;
  const newClassRooms = context.classrooms;
  const newBuildings =
    context.classrooms && context.classrooms.map((building: any) => building.building.name)
      ? context.classrooms.map((building: any) => building.building.name)
      : [];
  const newDate =
    newDay.label !== "" && newStartTime.label !== "" && newEndTime.label !== ""
      ? `${newDay ? newDay.label : ""} ${newStartTime ? newStartTime.label : ""} - ${
          newEndTime ? newEndTime.label : ""
        }`
      : "";
  const blocksCount = context.currentData.noScheduleEventsCount
    ? context.currentData.noScheduleEventsCount
    : null;
  const idInstructors = newInstructors.map((valueinstructor: IInstructor) =>
    parseInt(valueinstructor.id),
  );
  const idClassrooms = newClassRooms.map((valueClassroom: IClassRoom) =>
    parseInt(valueClassroom.id),
  );

  const callUpdateHook = (variables: any, e: React.MouseEvent) => {
    setActiveLoading(true);
    if (createSession) {
      createNewSession({
        variables,
      })
        .then(() => {
          onClose(e);
          window.location.reload();
          setActiveLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      updateSession({
        variables,
      })
        .then(() => {
          onClose(e);
          window.location.reload();
          setActiveLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const repeatObj = () => {
    let objRepeat = {};

    switch (context.currentData.repeat) {
      case "onlySession":
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: [parseInt(selected.id)],
            uniqueInterval: true,
          },
        };
        break;
      case "allWeeks":
        const allWeeksFromSession = R.map(
          R.pipe(R.prop<"id", string>("id"), parseInt),
          intervalsSessionWeek,
        );
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: allWeeksFromSession,
            uniqueInterval: false,
          },
        };
        break;
      case "weekSelect":
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: R.pipe(
              R.view(R.lensPath(["currentData", "weeksRepeat"])),
              R.filter(R.propOr(false, "checked")),
              R.map(R.pipe(R.prop<"id", string>("id"), parseInt)),
            )(context),
            uniqueInterval: false,
            forceFork: true,
          },
        };
        break;
      case "withWeeksGrouper":
        const checkedWeeks = R.pipe(
          R.view(R.lensPath(["currentData", "weeksRepeat"])),
          R.filter(R.propOr(false, "checked")),
          R.map(R.pipe(R.prop<"id", string>("id"), parseInt)),
        )(context);
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: checkedWeeks,
            uniqueInterval: false,
            forceFork: false,
          },
        };
        break;
    }
    return objRepeat;
  };

  const showBlockRange = () => {
    let objBlock = {};
    if (context.currentData.optionHour === "hourRequiere") {
      objBlock = {
        changeBlocks: {
          op: "CHANGE",
          day: newDay ? newDay.value : null,
          startTime: newStartTime ? newStartTime.value : null,
          endTime: newEndTime ? newEndTime.value : null,
        },
      };
    }
    return objBlock;
  };

  const showBlocks = () => {
    let objBlockCount = {};
    if (context.currentData.optionHour === "blockRequiere" && blocksCount !== null) {
      objBlockCount = {
        noScheduleEventsCount: blocksCount,
      };
    }
    return objBlockCount;
  };

  const mutationValidate = (e: React.MouseEvent) => {
    const addRepeat = repeatObj();
    const blockRange = showBlockRange();
    const addBlocksCount = showBlocks();
    let sectionId = {};
    let sessionId = {};
    if (createSession)
      sectionId = {
        sectionId: eventSection,
      };
    if (!createSession)
      sessionId = {
        sessionId: parseInt(event.sessionId),
      };
    const variables = {
      originId: origin,
      scenarioId: scenario,
      input: {
        ...sectionId,
        ...sessionId,
        dryRun: false,
        skipValidations: true,
        clientMutationId: "mutation",
        changeset: {
          changeClassroom: {
            op: "CHANGE",
            classroomIds: idClassrooms ? idClassrooms : [],
          },
          changeInstructor: {
            op: "CHANGE",
            instructorIds: idInstructors ? idInstructors : [],
          },
          ...addBlocksCount,
          ...addRepeat,
          ...blockRange,
        },
      },
    };

    callUpdateHook(variables, e);
  };

  const userCanMutateSession = () => {
    const { validationErrors } = context;

    if (!validationErrors.length) return true;

    const abilitiesValidations = {
      InstructorAssignmentNotAllowed: true,
      BlocksAssignmentNotAllowed: true,
      ClassroomsAssignmentNotAllowed: true,
      IntervalsAssignmentNotAllowed: true,
    };
    for (let i = 0; i < validationErrors.length; i++) {
      const validation = validationErrors[i];
      if (validation.__typename && validation.__typename in abilitiesValidations) {
        return false;
      }
    }
    return true;
  };

  return (
    <React.Fragment>
      {activeLoading && <Loading className="container-row" />}
      {!activeLoading && (
        <section className={css.cntValidationReport}>
          <h3 className={css.cntValidationReport_title}>Validación de cambios</h3>
          {/*  ORIGINAL DATA  */}
          {!createSession && (
            <section className={cx(css.cntCard, "container-row")}>
              <h4 className={cx(css.cntCard_title, "col_12")}>Sesión origen</h4>
              <div className={cx(css.item, "col_1 ")}>
                <label className={css.item_label}>Sección</label>
                <p className={css.item_info}>{eventSection}</p>
              </div>
              <div className={cx(css.item, "col_2")}>
                <label className={css.item_label}>Horario</label>
                <p className={css.item_info}>{originalDate}</p>
              </div>
              <div className={cx(css.item, "col_3")}>
                <label className={css.item_label}>Docente</label>
                {originalInstructors
                  ? originalInstructors.map((instructor: any, indexIns: number) => {
                      return (
                        <p key={indexIns} className={css.item_info}>
                          {`${instructor.code || ""} - ${instructor.name || ""}`}{" "}
                        </p>
                      );
                    })
                  : ""}
              </div>
              <div className={cx(css.item, "col_3")}>
                <label className={css.item_label}>Sala</label>
                {originalClassRooms
                  ? originalClassRooms.map((classRoom: any, indexCls: number) => {
                      return (
                        <p key={indexCls} className={css.item_info}>
                          {`${classRoom.code || ""} - ${classRoom.name || ""}`}{" "}
                        </p>
                      );
                    })
                  : ""}
              </div>
              <div className={cx(css.item, "col_2")}>
                <label className={css.item_label}>Edificio</label>
                <p className={css.item_info}>{originalBuilding}</p>
              </div>
              <div className={cx(css.item, "col_1")}>
                <label className={css.item_label}>Vacantes</label>
                <p className={css.item_info}>{eventVacancies}</p>
              </div>
            </section>
          )}

          {/*  NEW DATA  */}
          <section className={cx(css.cntCard, "container-row")}>
            <h4 className={cx(css.cntCard_title, "col_12")}>Cambios</h4>
            <div className={cx(css.item, css.item__same, "col_1 ")}>
              <label className={css.item_label}>Sección</label>
              <p className={css.item_info}>{eventSection}</p>
            </div>
            <div className={cx(css.item, newDate === originalDate && css.item__same, "col_2")}>
              <label className={css.item_label}>Horario</label>
              <p className={css.item_info}>{newDate}</p>
            </div>
            <div
              className={cx(
                css.item,
                newInstructors && newInstructors === originalInstructors && css.item__same,
                "col_3",
              )}
            >
              <label className={css.item_label}>Docente</label>
              {newInstructors
                ? newInstructors.map((instructorNew: any, indexIns: number) => {
                    return (
                      <p key={indexIns} className={css.item_info}>
                        {`${instructorNew.code || ""} - ${instructorNew.name || ""}`}{" "}
                      </p>
                    );
                  })
                : ""}
            </div>
            <div
              className={cx(
                css.item,
                newClassRooms && newClassRooms === originalClassRooms && css.item__same,
                "col_3",
              )}
            >
              <label className={css.item_label}>Sala</label>
              {newClassRooms
                ? newClassRooms.map((classRoomNew: any, indexCls: number) => {
                    return (
                      <p key={indexCls} className={css.item_info}>
                        {`${classRoomNew.code || ""} - ${classRoomNew.name || ""}`}{" "}
                      </p>
                    );
                  })
                : ""}
            </div>
            <div
              className={cx(
                css.item,
                newBuildings && newBuildings === originalBuildings && css.item__same,
                "col_2",
              )}
            >
              <label className={css.item_label}>Edificio</label>
              <p className={css.item_info}>
                {newBuildings ? newBuildings.map((building: string) => building) : ""}
              </p>
            </div>
            <div className={cx(css.item, css.item__same, "col_1")}>
              <label className={css.item_label}>Vacantes</label>
              <p className={css.item_info}>{eventVacancies}</p>
            </div>
          </section>

          <section className={cx(css.cntTable)}>
            {context.customValidations.length > 0 ||
            context.intervalValidationErrors.length > 0 ||
            context.validationErrors.length > 0 ? (
              <TableIncidences sessionId={event.sessionId} currentWeeks={currentWeeks} />
            ) : (
              <h4 className={css.cntTable_title}>No se encontraron incidencias</h4>
            )}
          </section>

          <div className={cx(css.cntBtn, "container-row")}>
            <Button
              typeButton="transparent"
              className={cx(css.cntBtn_item, "col_1")}
              onClick={() => context.setCurrentView("form")}
            >
              Cancelar
            </Button>
            {!createSession && userCanEdit && (
              <Button
                disabled={!context.editUserCanSkip || !userCanMutateSession()}
                className={cx(css.cntBtn_item, "col_2")}
                onClick={(e: React.MouseEvent) => {
                  mutationValidate(e);
                }}
              >
                Realizar modificación
              </Button>
            )}
            {createSession && userCanCreate && (
              <Button
                disabled={!context.editUserCanSkip || !userCanMutateSession()}
                className={cx(css.cntBtn_item, "col_2")}
                onClick={(e: React.MouseEvent) => {
                  mutationValidate(e);
                }}
              >
                Crear sesión
              </Button>
            )}
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default ValidationReport;
