import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Icon } from "@foris/avocado-ui";
import cx from "classnames";
import { ClassroomBookingStatusEnum } from "../../../../models/ISchema";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { IParams } from "../../../../models/IParams";
import { enums } from "../../utils";
import icons from "../../../../utils/icons";
import Button from "../../../../common/components/Button/Button";
import css from "./item_info.module.scss";

const ItemBooking = (props: any) => {
  const { event } = props;
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const context: IContextApp = useContext(ContextApp);
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const accessToBookingAdmin = context.user?.permissions?.create;
  const accessToBookingUser =
    context?.modules?.find(item => item.code === "bookings").accesible || false;

  let classroom = null;
  let building = null;
  let day = "";
  let startDate = "";
  let endDate = "";
  let description = "";
  let responsible = "";
  let status = "";
  let bookingId = null;

  if (event && event.resource) {
    const resource = event.resource;
    if (resource.blockRange) {
      if (resource.blockRange.start) {
        day =
          (resource.blockRange.start.day && enums.DAY_NAMES_ES[resource.blockRange.start.day]) ||
          "";
        startDate = resource.blockRange.start.startingTime.replace(":00", "") || "";
      }
      endDate = resource.blockRange.end
        ? resource.blockRange.end.endingTime.replace(":00", "")
        : "";
    }
  }

  if (event && event.bookings) {
    const bookingsInfo = event.bookings;
    classroom = bookingsInfo.classroom ? bookingsInfo.classroom : { classroomType: {} };
    building = classroom.name ? (classroom.building ? classroom.building.name : "") : "-";
    description = bookingsInfo.description;
    bookingId = bookingsInfo.id;
    responsible = `${bookingsInfo.responsible.name} ${bookingsInfo.responsible.lastName}`;
    status = bookingsInfo.status;
  }

  return (
    <div className={css.section_info}>
      <section className={css.section_row}>
        <div className={css.section_items}>
          <div>
            <span>Horario</span>
            <p className={css.dataItems}>{`${day ? `${day},` : ""} ${startDate}-${endDate}`}</p>
          </div>
        </div>

        <div className={css.section_items}>
          <div>
            <span>Sala</span>
            <p className={css.dataItems}>
              {classroom && classroom.name && classroom.classroomType
                ? `${classroom.name} - ${classroom.classroomType.name}`
                : "Sin sala asignada"}
            </p>
          </div>
          <div>
            <span>Edificio</span>
            <p className={css.dataItems}>{building}</p>
          </div>
        </div>

        <div className={cx(css.section_items, "container-row")}>
          <span>Estado</span>
          <p className={cx(css.dataItems, "col_12")}>
            <Icon
              icon={icons.statusIcon(status)}
              size={10}
              className={cx(
                css.iconStatusBooking,
                status === ClassroomBookingStatusEnum.Confirmed && css.iconCheck,

                status === ClassroomBookingStatusEnum.Rejected && css.iconClose,
              )}
            />
            {icons.statusIconText(status)}
          </p>
        </div>

        <div className={cx(css.section_items, "container-row")}>
          <span>Responsable</span>
          <p className={cx(css.dataItems, "col_12")}>{responsible}</p>
        </div>

        <div className={cx(css.section_items, "container-row")}>
          <span>Descripción</span>
          <p className={cx(css.dataItems, "col_12")}>{description}</p>
        </div>
      </section>
      {accessToBookingAdmin && accessToBookingUser && (
        <footer>
          <Button
            className={css.btn_footer_primary}
            onClick={() => history.push(`/booking/detail/${contextUrl}/${bookingId}`)}
          >
            Gestionar
          </Button>
        </footer>
      )}
    </div>
  );
};

export default ItemBooking;
