import { createContext, useCallback, useState } from "react";
import { Label } from "../../../../models/ISchema";

export interface ILabel extends Label {
  isNew: boolean;
}

interface IOption {
  value: ILabel;
  label: string;
}
export interface IContextEditLabel {
  labels: ILabel[];
  setLabels: (value: ILabel[]) => void;
  options: IOption[];
  setOptions: (value: IOption[]) => void;
}

// context default value
const defaultContext: IContextEditLabel = {
  labels: null,
  setLabels: () => null,
  options: null,
  setOptions: () => null,
};

export const ContextEditLabel = createContext<IContextEditLabel>(defaultContext);

// custom Hook
export const useContextEditLabel = (): IContextEditLabel => {
  const [labels, setLabelsState] = useState(defaultContext.labels);
  const [options, setOptionsState] = useState(defaultContext.options);

  const setLabels = useCallback((value: ILabel[]) => {
    if (value) setLabelsState(value);
  }, []);

  const setOptions = useCallback((value: IOption[]) => {
    if (value) setOptionsState(value);
  }, []);

  return {
    labels,
    setLabels,
    options,
    setOptions,
  };
};
