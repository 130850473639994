import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Classroom } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, setPage, loading, error } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "sala");
  const route = `/scheduler/editor/classroom/${workspace}/${scenario}/${origin}`;

  const columns = [
    {
      header: getHeadCell("Código"),
      renderer: ({ id, code }: Classroom) => getLinkCell(route, id, code),
    },
    {
      header: getHeadCell("Nombre"),
      renderer: ({ id, name }: Classroom) => getLinkCell(route, id, name),
    },
    {
      header: getHeadCell("Edificio"),
      renderer: ({ building }: Classroom) => getColumnCell(building?.name),
    },
    {
      header: getHeadCell("Sede"),
      renderer: ({ building }: Classroom) => getColumnCell(building?.campus?.name),
    },
    {
      header: getHeadCell("Piso"),
      renderer: ({ floor }: Classroom) => getColumnCell(floor),
      styles: {
        desktop: {
          maxWidth: 90,
        },
      },
    },
    {
      header: getHeadCell("Tipo"),
      renderer: ({ classroomType }: Classroom) => getColumnCell(classroomType?.name),
    },
    {
      header: getHeadCell("Capacidad"),
      renderer: ({ capacity }: Classroom) => getColumnCell(capacity),
    },
    {
      header: getHeadCell("Utilización (%)"),
      renderer: ({ assignment }: Classroom) => getColumnCell(assignment?.usagePercentage),
    },
    {
      header: getHeadCell("Ocupación (%)"),
      renderer: ({ assignment }: Classroom) =>
        getColumnCell(assignment?.chairsOccupationPercentage),
    },
  ];

  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension="sala"
      advanceLink={`/editor/advanceSearch/classroom/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
