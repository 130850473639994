import { ClassroomBooking, ClassroomBookingValidationErrorUnion } from "@models/ISchema";
import { RequestTypes, Option, DataError, SnackbarType, UserResponsible } from "./types";

/**
 * Set the given `errors` as the `RequestTypes`'s errors.
 *
 * @param errors ClassroomBookingValidationErrorUnion
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setErrors = (errors: ClassroomBookingValidationErrorUnion[], state: RequestTypes) => {
  return {
    ...state,
    errors,
  };
};

/**
 * Set the given `snackbar` as the `RequestTypes`'s snackbar.
 *
 * @param snackbar SnackbarType
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setSnackbar = (snackbar: SnackbarType, state: RequestTypes) => {
  return {
    ...state,
    snackbar,
  };
};

/**
 * Set the given `editedBooking` as the `RequestTypes`'s editedBooking.
 *
 * @param editedBooking ClassroomBooking
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setEditedBooking = (editedBooking: ClassroomBooking, state: RequestTypes) => {
  return {
    ...state,
    editedBooking,
  };
};

/**
 * Set the given `eventTypesOptions` as the `RequestTypes`'s eventTypesOptions.
 *
 * @param errors Option[]
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setEventTypesOptions = (eventTypesOptions: Option[], state: RequestTypes) => {
  return {
    ...state,
    eventTypesOptions,
  };
};

/**
 * Set the given `eventType` as the `RequestTypes`'s eventType.
 *
 * @param eventType DataError
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setEventType = (eventType: DataError, state: RequestTypes) => {
  return {
    ...state,
    eventType,
  };
};

/**
 * Set the given `description` as the `RequestTypes`'s description.
 *
 * @param description DataError
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setDescription = (description: DataError, state: RequestTypes) => {
  return {
    ...state,
    description,
  };
};

/**
 * Set the given `title` as the `RequestTypes`'s title.
 *
 * @param title DataError
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setTitle = (title: DataError, state: RequestTypes) => {
  return {
    ...state,
    title,
  };
};

/**
 * Clear the RequestTypes` context.
 *
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const clearRequest = (state: RequestTypes) => {
  return {
    ...state,
    errors: null,
    title: null,
    description: null,
    eventType: null,
    snackbar: { display: false, message: "" },
  };
};

/**
 * Set the given `usersOptions` as the `RequestTypes`'s usersOptions.
 *
 * @param usersOptions Option[]
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setUsersOptions = (usersOptions: Option[], state: RequestTypes) => {
  return {
    ...state,
    usersOptions,
  };
};

/**
 * Set the given `userResponsible` as the `RequestTypes`'s userResponsible.
 *
 * @param userResponsible DataError
 * @param state RequestTypes
 *
 * @return RequestTypes
 */
export const setUserResponsible = (userResponsible: UserResponsible, state: RequestTypes) => {
  return {
    ...state,
    userResponsible,
  };
};
