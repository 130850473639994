import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import {
  ContextEdit,
  IContextEdit,
  ICurrentData,
  IInstructor,
} from "../../ContextEdit/ContextEdit";
import { instructorSearch } from "../graphql/instructor.queries";
import { IParams } from "../../../../../../models/IParams";
import Select from "../../../../../../common/components/Select/Select";
import css from "../../itemEdit.module.scss";

const FIRST_SEARCH = 20;

const InstructorSearch: React.FC = () => {
  const client = useApolloClient();
  const { scenario, origin }: IParams = useParams();
  const context: IContextEdit = useContext(ContextEdit);
  const [valueSearch, setValueSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [selectValue, setSelectValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const serializeOptions = (isntructors: any) => {
    const instructorOptions = [];

    if (isntructors.length === 0) return instructorOptions;

    isntructors.forEach((inst: any) => {
      instructorOptions.push({
        label: `${inst.code} - ${inst.name}`,
        value: inst.id,
        code: inst.code,
        name: inst.name,
      });
    });
    return instructorOptions;
  };

  useEffect(() => {
    const queryOptions = (value: string) => {
      return {
        query: instructorSearch,
        variables: {
          originId: origin,
          scenarioId: scenario,
          filter: {
            page: 1,
            size: FIRST_SEARCH,
            searchTerm: value,
          },
        },
      };
    };

    const getDataOptions = async (value: any) => {
      try {
        setLoading(true);
        if (value !== "") {
          const query = queryOptions(value);
          const { data } = await client.query(query);
          const insOptions = serializeOptions(data.cube.instructors.items);
          setOptions(insOptions);
          setLoading(false);
        }
      } catch (error) {
        setOptions([]);
        setLoading(false);
      }
    };
    const getCacheDataOptions = async (value: any) => {
      try {
        setLoading(true);
        const query = queryOptions(value);
        const data = await client.readQuery(query);
        const insOptions = serializeOptions(data.cube.instructors.items);
        setOptions(insOptions);
        setLoading(false);
      } catch (error) {
        getDataOptions(value);
      }
    };
    if (valueSearch !== "") getCacheDataOptions(valueSearch);
    else setOptions([]);
  }, [valueSearch, client, origin, scenario]);

  useEffect(() => {
    const oldData: ICurrentData = context.currentData;
    if (selectValue === null) {
      setOptions([]);
      const newData: ICurrentData = { ...oldData, instructor: null };
      context.setCurrentData(newData);
    } else {
      const instructor: IInstructor = {
        id: selectValue.value,
        name: selectValue.name,
        code: selectValue.code,
      };
      const newData: ICurrentData = { ...oldData, instructor };
      context.setCurrentData(newData);
    }
  }, [selectValue]);

  /* const disabled = selectValue
    ? context.instructors.find((item: any) => item.id === selectValue.value)
    : true; */

  return (
    <React.Fragment>
      <Select
        isClearable
        isLoading={loading}
        className={css.item_select}
        placeholder="Docente"
        onInputChange={(value: any) => setValueSearch(value)}
        options={options}
        value={selectValue}
        onChange={(value: any) => {
          const disabled = value
            ? context.instructors.find((item: any) => item.id === value.value)
            : true;
          if (!disabled) {
            const instructorsList = [...context.instructors];
            const instructor: IInstructor = {
              id: value.value,
              name: value.name,
              code: value.code,
            };
            instructorsList.push(instructor);
            context.setInstructors(instructorsList);
          }
          setSelectValue(null);
        }}
      />
    </React.Fragment>
  );
};

export default InstructorSearch;
