import React from "react";
import { reject } from "lodash";
import { Table, Pager, CardState } from "@foris/avocado-ui";
import { Classroom } from "@models/ISchema";
import css from "./advanceTable.module.scss";

interface AdvanceTableProps {
  loading: boolean;
  data: Classroom[];
  size: number;
  total: number;
  page: number;
  selected: Classroom[];
  setSelected: (value: Classroom[]) => void;
  onPageChange: (page: number) => void;
}

const AdvanceTable: React.FC<AdvanceTableProps> = (props: AdvanceTableProps) => {
  const { loading, data, size, total, page, onPageChange, setSelected, selected } = props;

  const classRoomCell = (row: any) => {
    return (
      <p className={css.cell}>
        {row?.row?.original?.code}
        <span className={css.cell_bold}>{` ${row?.row?.original?.name} | `}</span>
        {row?.row?.original?.building?.name}
      </p>
    );
  };

  const percentCell = (param: any) => {
    return <p className={css.cell}>{`${param.value}%`}</p>;
  };

  const renderCheck = (table: any) => {
    const findSelected = selected?.find(value => value.id === table?.row?.original?.id);
    const onChange = () => {
      let cloneSelected = selected ? [...selected] : [];
      if (!findSelected) cloneSelected.push(table?.row?.original);
      if (findSelected)
        cloneSelected = reject(cloneSelected, value => value.id === table?.row?.original?.id);
      setSelected(cloneSelected);
    };

    return (
      <Table.Checkbox
        {...table.row.getToggleRowSelectedProps()}
        checked={findSelected}
        onChange={onChange}
      />
    );
  };

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 40,
      maxWidth: 40,
      Cell: (row: any) => renderCheck(row),
    },
    {
      Header: "Sala",
      accessor: "classroom",
      Cell: (row: any) => classRoomCell(row),
    },
    {
      Header: "Piso",
      accessor: "floor",
      maxWidth: 90,
    },
    {
      Header: "Tipo",
      accessor: "classroomType.name",
      maxWidth: 220,
    },
    {
      Header: "Capacidad",
      accessor: "capacity",
      maxWidth: 200,
    },
    {
      Header: "Utilización",
      accessor: "assignment.usagePercentage",
      maxWidth: 110,
      Cell: (row: any) => percentCell(row),
    },
    {
      Header: "Ocupación",
      accessor: "assignment.chairsOccupationPercentage",
      maxWidth: 110,
      Cell: (row: any) => percentCell(row),
    },
  ];

  if (!data && loading)
    return (
      <Table.Container className={css.advanceTable} columns={columns} data={[]} loading={loading} />
    );

  if (total === 0)
    return (
      <section className={css.cardState}>
        <CardState typeCard="informative" title="Sin resultados">
          Amplíe su búsqueda para encontrar salas.
        </CardState>
      </section>
    );

  if (data)
    return (
      <Table.Container
        className={css.advanceTable}
        columns={columns}
        data={data}
        pagerBottom={true}
        pagerTop={true}
        loading={loading}
      >
        <Pager size={size} total={total} page={page} onPageChange={onPageChange} />
      </Table.Container>
    );

  return null;
};

export default AdvanceTable;
