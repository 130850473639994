import gql from "graphql-tag";

export const GET_CONTEXT = gql`
  query contextualizador(
    $scenarioFilter: ScenariosInput
    $scenarioId: ID!
    $originFilter: OriginInput
  ) {
    base {
      workspaces {
        id
        name
      }
      scenarios(filter: $scenarioFilter) {
        id
        name
        description
      }
    }
    data(scenarioId: $scenarioId) {
      origins(filter: $originFilter) {
        id
        label
      }
    }
  }
`;
