import { BlockRanges } from "../context/formData.reducer";
import { selectedDayEn } from "../../../../utils/days";
import { equals, filter, join, map, not, pipe, take } from "ramda";

class Block {
  day: string;
  days: string[];
  endTime: string;
  selected: string;
  startTime: string;
  blocks: number;

  constructor(block: BlockRanges) {
    this.day = block?.day || "";
    this.days = block?.days || [];
    this.startTime = block?.startTime || "";
    this.endTime = block?.endTime || "";
    this.selected = block?.selected || "";
    this.blocks = block?.blocks || 0;
  }

  // Blocks with a single `day` and no `days` should be supported until we
  // implement the `BlockRange` component with multiple days on the single
  // session edit view.
  get formattedDays() {
    return !this.days.length && this.day
      ? this.formatDay(this.day)
      : pipe(map(this.formatDay), filter(pipe(equals(""), not)), join(","))(this.days);
  }

  get formattedStartTime() {
    return take(5, this.startTime);
  }

  get formattedEndTime() {
    return take(5, this.endTime);
  }

  get formattedBlockTimes() {
    return `${this.formattedStartTime} - ${this.formattedEndTime}`;
  }

  get blockLabel() {
    return `${this.formattedDays} ${this.formattedBlockTimes}`;
  }

  formatDay(day: string) {
    const label = selectedDayEn(day).label;
    return label ? take(3, label) : "";
  }
}

export default Block;
