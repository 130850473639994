import * as R from "ramda";
import { Session } from "@models/ISchema";
import { EditedSession } from "../context/formData.reducer";

const intervals = R.view(R.lensPath(["assignment", "intervals"]));

const sessionHasIntervals = (session: Session | EditedSession): boolean => {
  const [hasAssignments, hasIntervals] = R.ap(
    [R.has("assignment"), R.pipe(intervals, R.isEmpty, R.not)],
    [session],
  );
  return R.and(hasAssignments, hasIntervals);
};

/**
 * Return a copy of the given `Sessions | EditedSessions` ordered by the ids of
 * it's assigned weeks.
 *
 * @param sessions (Session | EditedSession)[]
 * @return (Session | EditedSession)[]
 */
const orderSessionsByWeeks = R.sort<Session | EditedSession>((s1, s2) => {
  const [s1HasIntervals, s2HasIntervals] = R.ap([sessionHasIntervals], [s1, s2]);

  if (R.all(R.equals(true), [!s1HasIntervals, !s2HasIntervals])) return 0;
  else if (!s1HasIntervals) return -1;
  else if (!s2HasIntervals) return 1;

  const [fstWeekId, scdWeekId] = R.ap([R.pipe(intervals, R.head, R.propOr("-", "id"))], [s1, s2]);

  return R.lt(fstWeekId as string, scdWeekId as string)
    ? -1
    : R.equals(fstWeekId, scdWeekId)
    ? 0
    : 1;
});

export default orderSessionsByWeeks;
