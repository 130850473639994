import React from "react";
import { Bundle } from "../../models/ISchema";
import { Header } from "../../common/components";
interface IHeaderVacancies {
  data: Bundle;
}

const HeaderVacancies: React.FC<IHeaderVacancies> = (props: IHeaderVacancies) => {
  return <Header topTitle="Bundle" title={`${props.data?.code} ${props.data?.name}`} />;
};

export default HeaderVacancies;
