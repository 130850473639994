import React from "react";
import { Donut as AvocadosDonut, DonutLabels } from "@foris/avocado-ui";
import { AdaptedGroup } from "../../models";
import donut, { DonutRow } from "../../utils/donut";
import css from "./donut.module.scss";

interface IProps {
  group: Partial<AdaptedGroup>;
  padding?: string;
  rows?: DonutRow[];
}

const Donut = (props: IProps) => {
  const { group, padding, rows = [] } = props;
  const donutRows = rows?.length ? rows : donut(group);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: Boolean(padding) ? padding : "0",
      }}
    >
      <AvocadosDonut className={css.dataCircle} width="48px" height="48px" data={donutRows} />
      <DonutLabels className={css.dataLabels} data={donutRows} />
    </div>
  );
};

export default Donut;
