import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import moment from "moment";
import { ContextEdit, IContextEdit, IBlockRange, ICurrentData } from "../ContextEdit/ContextEdit";
import Select from "../../../../../common/components/Select/Select";
import Checkbox from "../../../../../common/components/Checkbox/Checkbox";
import Input from "../../../../../common/components/Input/Input";
import RadioButton from "../../../../../common/components/RadioButton/RadioButton";
import { daysOptions } from "../../../../../utils/days";
import { GET_BLOCKS_RANGES } from "./blockRanges.queries";
import { IParams } from "../../../../../models/IParams";
import css from "../itemEdit.module.scss";

interface IBlockRangeEdit {
  notRequireSchedule: boolean;
  blocksCount: any;
}

const BlockRanges: React.FC<any> = ({ blocksCount, notRequireSchedule }: IBlockRangeEdit) => {
  const client = useApolloClient();
  const { scenario }: IParams = useParams();
  const context: IContextEdit = useContext(ContextEdit);
  const [selectDay, setSelectDay] = useState(context.currentData.blockRanges.day);
  const [selectStartTime, setStartTime] = useState(context.currentData.blockRanges.startTime);
  const [selectEndTime, setEndTime] = useState(context.currentData.blockRanges.endTime);
  const [check, setCheck] = useState(null);
  const [hoursStartOptions, setHoursStartOptions] = useState(null);
  const [hoursEndOptions, setHoursEndOptions] = useState(null);
  const [disabledEnd, setDisabledEnd] = useState(false);
  const [valueBlock, setValueBlock] = useState(null);
  const [hourRequiere, setHourRequiere] = useState(true);
  const [blockRequiere, setBlockRequiere] = useState(false);
  const [warningActive, setWarningActive] = useState(false);
  const [warningText, setWarningText] = useState("");
  const variablesBlockRanges = {
    query: GET_BLOCKS_RANGES,
    variables: {
      scenarioId: scenario,
    },
  };

  const formatOptionsRanges = async (dataBlocks: any) => {
    const newListOptionsStart = [];
    const newListOptionsEnd = [];
    if (dataBlocks && dataBlocks.allBlocks) {
      const timesStart = dataBlocks.allBlocks.map((item: any) => [item.startingTime]);

      const timesEnd = dataBlocks.allBlocks.map((item: any) => [item.endingTime]);
      const uniqueDatesStart = timesStart
        .flat()
        .filter((item: any, pos: any) => timesStart.flat().indexOf(item) === pos);

      uniqueDatesStart.map((hour: any) =>
        newListOptionsStart.push({ label: hour.replace(":00", ""), value: hour }),
      );
      const uniqueDatesEnd = timesEnd
        .flat()
        .filter((item: any, pos: any) => timesEnd.flat().indexOf(item) === pos);

      uniqueDatesEnd.map((hour: any) =>
        newListOptionsEnd.push({ label: hour.replace(":00", ""), value: hour }),
      );
    }

    newListOptionsStart.sort(function(a, b) {
      return a.value.localeCompare(b.value);
    });
    newListOptionsEnd.sort(function(a, b) {
      return a.value.localeCompare(b.value);
    });

    setHoursStartOptions(newListOptionsStart);
    setHoursEndOptions(newListOptionsEnd);
  };

  const getCacheBlocksOptions = useCallback(async () => {
    const getBlocksOptions = async () => {
      try {
        const data = await client.query(variablesBlockRanges);
        const dataBlocks = data && data.data && data.data.data;
        await formatOptionsRanges(dataBlocks);
      } catch (error) {
        setHoursStartOptions(false);
      }
    };
    try {
      const data = await client.readQuery(variablesBlockRanges);
      const dataBlocks = data && data.data;
      await formatOptionsRanges(dataBlocks);
    } catch (error) {
      getBlocksOptions();
    }
  }, [variablesBlockRanges, client]);

  const setEndHour = useCallback(() => {
    setDisabledEnd(true);
    let startValue = selectStartTime;
    const optionsHoursStart = hoursStartOptions === null ? [] : hoursStartOptions;
    const optionsStartHour = check
      ? optionsHoursStart.slice(0, optionsHoursStart.length - blocksCount + 1)
      : optionsHoursStart;
    const findStart = optionsStartHour.map((e: any) => e.value).indexOf(startValue.value);
    const hourSelect = startValue;
    if (findStart === -1) {
      const format = "hh:mm:ss";
      const time = moment(startValue.value, format);
      const countOptions = optionsStartHour.length;
      const afteValues = time.isAfter(moment(optionsStartHour[countOptions - 1].value, format));
      if (!afteValues) {
        for (let i = 0; i < countOptions - 1; i++) {
          const beforeTime = moment(optionsStartHour[i].value, format);
          const afterTime = moment(optionsStartHour[i + 1].value, format);
          if (time.isBetween(beforeTime, afterTime)) {
            startValue = optionsStartHour[i + 1];
            setWarningActive(true);
            setWarningText(
              `Hora de inicio seleccionada no existe (${hourSelect.label}). Se sugiere el próximo bloque configurado (${startValue.label}).`,
            );
          }
        }
      } else {
        startValue = optionsStartHour[countOptions - 1];
      }
    }

    const indexStart = optionsHoursStart.findIndex(item => item.value === selectStartTime.value);
    setStartTime(startValue);
    setEndTime(hoursEndOptions[indexStart + blocksCount - 1]);
  }, [blocksCount, check, hoursEndOptions, hoursStartOptions, selectStartTime]);

  useEffect(() => {
    check ? setEndHour() : setDisabledEnd(false);
  }, [check, selectStartTime, setEndHour]);

  useEffect(() => {
    if (hoursEndOptions === null) getCacheBlocksOptions();
  }, [hoursEndOptions, getCacheBlocksOptions]);

  useEffect(() => {
    if (hoursStartOptions === null) getCacheBlocksOptions();
  }, [hoursStartOptions, getCacheBlocksOptions]);

  useEffect(() => {
    const blockRanges: IBlockRange = {
      day: selectDay,
      startTime: selectStartTime,
      endTime: selectEndTime,
    };
    const noScheduleEventsCount = valueBlock;
    const optionHour = hourRequiere ? "hourRequiere" : "blockRequiere";
    const oldData: ICurrentData = context.currentData;
    const newData: ICurrentData = { ...oldData, blockRanges, noScheduleEventsCount, optionHour };
    context.setCurrentData(newData);
  }, [selectDay, selectStartTime, selectEndTime, valueBlock, hourRequiere, blockRequiere]);

  useEffect(() => {
    if (check === null && hoursStartOptions && hoursEndOptions) {
      if (notRequireSchedule) {
        setCheck(false);
      } else {
        setCheck(true);
      }
    }
  }, [hoursStartOptions, hoursEndOptions, check, notRequireSchedule]);

  const optionsHoursEnd = hoursEndOptions === null ? [] : hoursEndOptions;
  const optionsHoursStart = hoursStartOptions === null ? [] : hoursStartOptions;

  const optionsStartHour = check
    ? optionsHoursStart.slice(0, optionsHoursStart.length - blocksCount + 1)
    : optionsHoursStart;

  const validateHour =
    selectDay.value === "" ||
    (selectStartTime && selectStartTime.label && selectStartTime.label === "") ||
    (selectEndTime && selectEndTime.label && selectEndTime.label === "");

  const validateNotRequire = valueBlock === "" || valueBlock < 1;

  useEffect(() => {
    if (blocksCount) {
      setValueBlock(blocksCount);
    } else {
      setValueBlock(null);
    }
  }, [blocksCount]);

  if (check === null) return null;

  return (
    <div className={cx(css.item, "container-row row_align--center")}>
      <label className={cx(css.item_label, "col_12")}>Programación</label>
      <RadioButton
        name="isRequiere"
        className={css.item_radio}
        label="Con horario"
        check={hourRequiere}
        onClick={() => {
          setHourRequiere(true);
          setBlockRequiere(false);
        }}
      />

      <RadioButton
        name="isRequiere"
        className={cx(css.item_radio, css.item_radio__block)}
        label="No requiere horario"
        check={blockRequiere}
        onClick={() => {
          setHourRequiere(false);
          setBlockRequiere(true);
        }}
      />

      {blockRequiere && (
        <React.Fragment>
          <label className={cx(css.item_label, "col_12")}>Bloques</label>
          <div className={cx("col_1")}>
            <Input
              type="number"
              value={valueBlock}
              onChange={value => {
                setValueBlock(value);
              }}
            />
          </div>
          {validateNotRequire && (
            <p className={cx(css.textError, "col_12")}>
              Campo obligatorio y valor de bloques debe ser mayor a 1
            </p>
          )}
        </React.Fragment>
      )}
      {hourRequiere && (
        <React.Fragment>
          <label className={cx(css.item_label, "col_12")}>Horario</label>

          {!notRequireSchedule && (
            <div className="container-row row_align--center">
              <label className={cx(css.item_checkLabel)}>Mantener duración de la sesión</label>
              <Checkbox
                className={cx(css.item_check)}
                check={check}
                onClick={() => setCheck(!check)}
              />
            </div>
          )}
          <Select
            className={cx(css.item_select, css.item_select__day)}
            placeholder="Día"
            value={selectDay}
            options={daysOptions}
            onChange={(value: any) => setSelectDay(value)}
          />
          <Select
            className={cx(css.item_select, css.item_select__hour)}
            placeholder="Hora"
            value={selectStartTime}
            options={optionsStartHour}
            onChange={(value: any) => setStartTime(value)}
            disabled={selectDay.value === "notRequiere"}
          />
          <span className={css.item_text}>-</span>
          <Select
            className={cx(css.item_select, css.item_select__hour)}
            placeholder="Hora"
            value={selectEndTime}
            options={optionsHoursEnd}
            onChange={(value: any) => setEndTime(value)}
            disabled={disabledEnd || selectDay.value === "notRequiere"}
          />
          {warningActive && <p className={cx(css.textError, "col_12")}>{warningText}</p>}

          {validateHour && <p className={cx(css.textError, "col_12")}>Campos obligatorios </p>}
        </React.Fragment>
      )}
    </div>
  );
};

export default BlockRanges;
