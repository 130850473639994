import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { Route } from "react-router-dom";
import { IContextApp, ContextApp } from "../Context/contextApp";
import { ContextEDH } from "@context/ContextEDH";
import { authMutation } from "@modules/login/graphql/auth.mutation";
import useGetSettings from "@modules/login/hooks/useGetSettings";
import useGetDataBase from "@modules/login/hooks/useGetDataBase";
import { User, Module, BaseQuery } from "@models/ISchema";
const DARWINED_URL = process.env.REACT_APP_DARWINED_URL;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const context: IContextApp = useContext(ContextApp);
  const { state } = useContext(ContextEDH);
  const [settings, getSettings] = useGetSettings();
  const [authUserMutation] = useMutation(authMutation, {});
  const [loading, setLoading] = useState(null);
  const [dataBase, getDataBase] = useGetDataBase();
  const [userID, setUserID] = useState(null);
  const token = localStorage.getItem("token");
  const notPrtctd = token;

  const getAuthUserMutation = async () => {
    setLoading(true);
    try {
      if (token) {
        const variables = {
          token: token,
        };
        const { data } = await authUserMutation({
          variables,
        });
        if (data && data.auth && data.auth.id) {
          localStorage.setItem("token", token);
          setUserID(data.auth.id);
        } else {
          localStorage.clear();
        }
      } else {
        window.location.href = DARWINED_URL;
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error("[auth]", e);
      localStorage.clear();
    }
  };

  const getDataContext = async () => {
    try {
      const response = await getDataBase(userID);
      const data = response[0];
      const base: BaseQuery = data?.data;
      const currentUser: User = base.user;
      const currentModules: Module[] = base.modules;
      const lockUserEdit = !currentUser.permissions.update;
      currentUser && context.setUser(currentUser);
      currentModules && context.setModules(currentModules);
      context.setLockEdit(lockUserEdit);
      context.setPermits(base?.onOff);
    } catch (error) {
      console.error("getDataContext", error);
    }
  };

  // get EDH settings with new context
  useEffect(() => {
    if (
      state?.base?.base === null &&
      !dataBase?.data &&
      !dataBase?.loading &&
      !dataBase?.error &&
      userID
    ) {
      getDataContext();
    }
  }, [userID]);

  useEffect(() => {
    if (!context.user && !loading) getAuthUserMutation();
    if (state?.base?.settings === null && !settings.data && !settings?.loading) getSettings();
  }, []);

  if (!loading && context.user) {
    return (
      <Route
        {...rest}
        render={(props: any) =>
          notPrtctd ? (
            <Component {...props} />
          ) : (
            <Route
              component={() => {
                window.location.href = DARWINED_URL;
                return null;
              }}
            />
          )
        }
      />
    );
  }

  return null;
};

export default PrivateRoute;
