import { useHistory } from "react-router-dom";

interface UseGoBackProps {
  workspace?: string;
  scenario?: string;
  origin?: string;
  defaultPath?: string;
}

const useGoBack = ({ workspace, scenario, origin, defaultPath }: UseGoBackProps) => {
  const history = useHistory();
  const homePath =
    workspace && scenario && origin ? `/home/${workspace}/${scenario}/${origin}` : "/";

  const handleGoBack = () => {
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(defaultPath || homePath);
    }
  };

  return {
    handleGoBack,
  };
};

export default useGoBack;
