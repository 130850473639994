import { objectEmpty } from "../../../../utils/validations";
const buildingDependency = (values: any, objBuilding: any, objCampus: any) => {
  const cloneBuilding = { ...objBuilding };
  let newBuilding = JSON.parse(JSON.stringify(cloneBuilding));
  const cloneCampus = { ...objCampus };
  let newCampus = JSON.parse(JSON.stringify(cloneCampus));
  if (values.campus) {
    newCampus = {
      ...newCampus,
      ...{
        building: {
          campusId: {
            eq: values.campus,
          },
        },
      },
    };
  } else {
    if (newCampus.hasOwnProperty("building")) {
      delete newCampus.building;
    }
  }

  if (values.building) {
    newBuilding = {
      ...newBuilding,
      ...{
        buildingId: {
          eq: values.building,
        },
      },
    };
  } else {
    if (newBuilding.hasOwnProperty("buildingId")) {
      delete newBuilding.buildingId;
    }
  }

  return { objBuilding: newBuilding, objCampus: newCampus, error: false };
};

const classRoomType = (values: any, objClassroomType: any) => {
  const clone = { ...objClassroomType };
  let newValues = JSON.parse(JSON.stringify(clone));

  if (values.classroomType) {
    newValues = {
      ...newValues,
      ...{
        classroomTypeId: {
          eq: values.classroomType,
        },
      },
    };
  } else {
    if (newValues.hasOwnProperty("classroomTypeId")) {
      delete newValues.classroomTypeId;
    }
  }
  return { objClassroomType: newValues };
};

const capacity = (values: any, objCapacity: any) => {
  const clone = { ...objCapacity };
  let newFilter = JSON.parse(JSON.stringify(clone));
  const _capacity = { capacity: { allowedOverflow: null, value: {} } };
  // allowedOverflow property
  if (values.overflow) {
    _capacity.capacity = {
      ..._capacity.capacity,
      ...{ allowedOverflow: values.overflow },
    };
  } else {
    if (_capacity.capacity.hasOwnProperty("allowedOverflow")) {
      delete _capacity.capacity.allowedOverflow;
    }
  }
  // capacity values property
  const _capacityValues = { value: { gte: null, lte: null } };
  // value.gte property
  if (values.minCapacity && values.minCapacity !== "") {
    _capacityValues.value = {
      ..._capacityValues.value,
      ...{ gte: values.minCapacity },
    };
  }

  // value.lte property
  if (values.maxCapacity && values.maxCapacity !== "") {
    _capacityValues.value = {
      ..._capacityValues.value,
      ...{ lte: values.maxCapacity },
    };
  }
  const min = _capacityValues.value.gte;
  const max = _capacityValues.value.lte;
  if (!min && !max) {
    delete _capacity.capacity.value;
  } else {
    _capacity.capacity = {
      ..._capacity.capacity,
      ..._capacityValues,
    };
  }

  if (objectEmpty(_capacity.capacity)) {
    delete newFilter.capacity;
  } else {
    newFilter = { ...newFilter, ..._capacity };
  }
  return { objCapacity: newFilter, error: false };
};

const validations = {
  classRoomType,
  buildingDependency,
  capacity,
};

export { validations };
