import gql from "graphql-tag";

export const megaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      coursesWithSections(filter: $filter) {
        items {
          id
          code
          name
          sectionsCount
          demand {
            id
            vacancies
            crosslist
          }
          curriculum {
            id
            name
            program {
              id
              code
              campus {
                id
                code
                name
                __typename
              }
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
    }
  }
`;
