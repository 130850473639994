import * as R from "ramda";
import { Session } from "@models/ISchema";
import { EditedSession } from "../context/formData.reducer";
import { Week } from "@modules/sections/ISections";
import blockAdapter from "./block";
import intervalsAdapter from "./interval";

/**
 * Adapt a `Session` to an `EditedSession`.
 *
 * Complexity: O(n)
 * where `n` is the number of weeks in the `linkWeeks` array
 *
 * @param session Session | EditedSession
 * @param link LinkPageType
 */
export const sessionAdapter = R.curry(
  (linkWeeks: Week[], session: Session): EditedSession => ({
    ...session,
    instructors: session?.instructors,
    classrooms: session?.classrooms,
    section: session?.section,
    blocks: blockAdapter(session),
    intervals: intervalsAdapter(linkWeeks, session),
    isNew: false,
    isCloned: false,
    session: session,
  }),
);

export default sessionAdapter;
