import gql from "graphql-tag";

export const bookingDeleteMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $input: ClassroomBookingChangeStatusInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classroomBookingChangeStatus(input: $input) {
        skippedValidations
        commited
      }
    }
  }
`;
