import { useState } from "react";
import { keys, map } from "ramda";
import { useMutation } from "react-apollo";
import { Campus, GroupsManagerCrudResult } from "@models/ISchema";
import { groupsManagerCrud } from "../graphql/groupsManagerCrud.mutation";
import { AdaptedGroup } from "../models";

interface Props {
  origin: string;
  scenario: string;
}

type ReturnType = [
  { result: any; error: boolean; isLoading: boolean },
  (
    parentGroup: AdaptedGroup,
    campusIds: Record<Campus["id"], any>,
    dryRun?: boolean,
    skipValidations?: boolean,
  ) => Promise<void>,
];

export const useCreateSubgroups = ({ scenario, origin }: Props): ReturnType => {
  const [error, setError] = useState(false);
  const [result, setResult] = useState<GroupsManagerCrudResult>({});
  const [isLoading, setIsLoading] = useState(false);
  const [groupsManagerCrudMutation] = useMutation(groupsManagerCrud, {});

  const buildChangeset = ({ code }: AdaptedGroup, campusIds: Record<Campus["id"], any>) => {
    const creates = map(
      (campusId: Campus["id"]) => ({ clientCode: code, campusId }),
      keys(campusIds),
    );
    return { creates };
  };

  const submitCreations = async (
    parentGroup: AdaptedGroup,
    campusIds: Record<Campus["id"], any>,
    dryRun = false,
    skipValidations = true,
  ) => {
    try {
      setIsLoading(true);

      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun,
          skipValidations,
          changesets: buildChangeset(parentGroup, campusIds),
        },
      };
      const { data: responseData } = await groupsManagerCrudMutation({ variables });
      setResult(responseData?.cube?.groupsManagerCrud);
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ result, error, isLoading }, submitCreations];
};
