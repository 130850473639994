import gql from "graphql-tag";

const disableSessionMutation = gql`
  mutation Editar($scenarioId: ID!, $originId: ID!, $sessionIds: [ID]!, $intervalIds: [ID]!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      disableSessions(sessionIds: $sessionIds, intervalIds: $intervalIds) {
        commited
        validationErrors {
          ... on ExternalGroupValidation {
            allowed
            rejected_groups
            __typename
          }
        }
      }
      __typename
    }
  }
`;

export { disableSessionMutation };
