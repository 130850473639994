import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { departmentQuery } from "../graphql/department.queries";
import { Department, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorDepartment, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const DepartmentSelect: React.FC<ISelectorDepartment> = (props: ISelectorDepartment) => {
  const client = useApolloClient();
  const { onCallback, campus, label, className } = props;
  const { scenario }: IParams = useParams();
  const [optionsDepartment, setOptionsDepartment] = useState([]);
  const [valueDepartment, setValueDepartment] = useState(null);
  const [prevCampus, setPrevCampus] = useState(campus);
  const [disabled, setDisabled] = useState(false);

  const variablesDepartment = {
    query: departmentQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setDepartmentOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optDepartments = [];
      if (dataQuery.departments.items.length > 0) {
        dataQuery.departments.items.forEach((department: Department) => {
          optDepartments.push({
            label: `${department.code} - ${department.name.replace(/-/g, "")}`,
            value: department.id,
          });
        });
      }
      setOptionsDepartment(optDepartments);
    }
  };

  const getDepartmentOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesDepartment);
      await setDepartmentOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [variablesDepartment, client]);

  const getCacheDepartmentOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesDepartment);
      await setDepartmentOptions(data);
    } catch (error) {
      getDepartmentOptions();
    }
  }, [variablesDepartment, client, getDepartmentOptions]);

  useEffect(() => {
    if (campus && prevCampus !== campus && disabled) {
      getCacheDepartmentOptions();
      setPrevCampus(campus);
      setDisabled(false);
    }
    if (!campus) {
      setPrevCampus(null);
      setValueDepartment("");
      setDisabled(true);
    }
  }, [disabled, campus, prevCampus, getCacheDepartmentOptions]);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Escuela</label>}
      <Select
        options={optionsDepartment}
        value={valueDepartment}
        placeholder={label ? "Seleccionar" : "Escuela"}
        isClearable
        isDisabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValueDepartment(value);
            onCallback(value);
          } else {
            setValueDepartment("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default DepartmentSelect;
