import React, { useState } from "react";
import cx from "classnames";
import Checkbox from "../../../common/components/Checkbox/Checkbox";
import css from "./checks.module.scss";

export interface IAssignment {
  blocks: boolean;
  instructor: boolean;
  classroom: boolean;
}

export interface IAssignmentProps {
  onCallback: (value: IAssignment) => void;
}

const AssignmentCheckbox: React.FC<IAssignmentProps> = (props: IAssignmentProps) => {
  const { onCallback } = props;
  const [blockCheck, setBlockCheck] = useState(false);
  const [instructorCheck, setInstructorCheck] = useState(false);
  const [classroomCheck, setClassroomCheck] = useState(false);
  return (
    <div className={cx(css.shared_check, css.shared_check_item_row, "col_12")}>
      <div className={cx(css.shared_check_check, "col_12")}>
        <Checkbox
          className={css.shared_check_title}
          label="Solamente secciones sin horario"
          onClick={value => {
            setBlockCheck(value);
            onCallback({
              blocks: value,
              instructor: instructorCheck,
              classroom: classroomCheck,
            });
          }}
        />
      </div>
      <div className={cx(css.shared_check_check, "col_12")}>
        <Checkbox
          className={css.shared_check_title}
          label="Solamente secciones sin docente"
          onClick={value => {
            setInstructorCheck(value);
            onCallback({
              blocks: blockCheck,
              instructor: value,
              classroom: classroomCheck,
            });
          }}
        />
      </div>
      <div className={cx(css.shared_check_check, "col_12")}>
        <Checkbox
          className={css.shared_check_title}
          label="Solamente secciones sin sala"
          onClick={value => {
            setClassroomCheck(value);
            onCallback({
              blocks: blockCheck,
              instructor: instructorCheck,
              classroom: value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default AssignmentCheckbox;
