import * as React from "react";
import { Link, useParams } from "react-router-dom";
import cx from "classnames";
import { IParams } from "../../../models/IParams";
import css from "./empty-results.module.scss";

interface IPropsEmpty {
  dimension?: string;
  search?: string;
}

const EmptyResults: React.FC<IPropsEmpty> = (props: IPropsEmpty) => {
  const { dimension, search } = props;
  const { origin, scenario, workspace }: IParams = useParams();

  return (
    <div className={css.cntEmpty}>
      <h2 className={css.cntEmpty_title}>
        No hemos encontrado resultados{dimension && ` en ${dimension}`}
        {search && ` para "${search}"`}
      </h2>
      <p className={css.cntEmpty_text}>
        Amplíe su búsqueda para encontrar resultados o vuelva al home.
      </p>
      <div className={css.cntBtn}>
        <Link to={"/editor/"}>
          <button className={cx(css.cntBtn_item, css.cntBtn_item__home)}>Ir al Home</button>
        </Link>
        {origin && scenario && workspace && (
          <Link to={`/editor/advanceSearch/${workspace}/${scenario}/${origin}`}>
            <button className={cx(css.cntBtn_item, css.cntBtn_item__search)}>
              Búsqueda avanzada
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EmptyResults;
