import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import PackagesAndGroupsReport from "@modules/reports/pages/PackagesAndGroupsReport";

const base = "/report";
const context = ":workspace/:scenario/:origin";

export const moduleRoutes = [
  {
    path: `${base}/packages-and-groups/${context}`,
    component: PackagesAndGroupsReport,
    name: "PackagesAndGroupsReport",
  },
];

const RouteDimensions: React.FC = (props: any) => (
  <Switch>
    {moduleRoutes.map((route, index) => (
      <ProtectedRoute
        key={index + props.location.key}
        path={route.path}
        component={route.component}
        exact
      />
    ))}
  </Switch>
);

export default RouteDimensions;
