import React, { useState, useEffect } from "react";
import { Checkbox, Icon, Table } from "@foris/avocado-ui";
import {
  ClassroomBooking,
  OrderByDirection,
  ClassroomBookingOrderByFields,
} from "../../../../models/ISchema";
import { useHistory, useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import css from "./bookingList.module.scss";

interface IOrderByItem {
  field: ClassroomBookingOrderByFields;
  direction?: OrderByDirection;
}

type OrderDirectionsByFieldType = { [key in ClassroomBookingOrderByFields]: OrderByDirection };

interface IBookingTableProps {
  bookings: Array<ClassroomBooking>;
  bookingsSelectedIds: Array<string>;
  setBookingSelectedIds: (ids: Array<string>) => void;
  getBookings: (orderBy?: IOrderByItem[]) => void;
  loading: boolean;
}

const formattedHour = (time: string) => {
  const [hour, minutes] = time.split(":");
  return `${hour}:${minutes}`;
};

const formattedDate = (dateTime: string) => {
  const [date] = dateTime.split(" ");
  const [year, month, day] = date.split("-");
  return `${day}.${month}.${year}`;
};

const datesCell = (data: ClassroomBooking) => (
  <ul>
    {data.sessions.map((session, index) =>
      session.scheduleEvent.map((event, index2) => {
        const [date, start] = event.start.split(" ");
        const end = event.end.split(" ")[1];
        return (
          <li key={index + index2}>{`${formattedDate(date)} | ${formattedHour(
            start,
          )} - ${formattedHour(end)}`}</li>
        );
      }),
    )}
  </ul>
);

const statusCell = (status: string) => {
  switch (status) {
    case "pending":
      return <Icon icon="clock" size={14} />;
    case "confirmed":
      return <Icon icon="check" size={14} className={css.iconCheck} />;
    case "rejected":
      return <Icon icon="close" size={14} className={css.iconClose} />;
  }
};

const BookingTable: React.FC<IBookingTableProps> = (props: IBookingTableProps) => {
  const { setBookingSelectedIds, bookingsSelectedIds, bookings, getBookings, loading } = props;
  const [orderDirectionsByField, setOrderDirectionsByField] = useState<OrderDirectionsByFieldType>(
    {} as OrderDirectionsByFieldType,
  );

  const history = useHistory();
  const { origin: originId, scenario: scenarioId, workspace: workspaceId }: IParams = useParams();
  const contextUrl = `${workspaceId}/${scenarioId}/${originId}`;

  const addBookingSelect = (bookingId: string) => {
    const newBookingSelectedIds = [...bookingsSelectedIds, bookingId];
    setBookingSelectedIds(newBookingSelectedIds);
  };

  const removeBookingSelect = (bookingId: string) => {
    const newBookingSelectedIds = [...bookingsSelectedIds];
    const index = newBookingSelectedIds.indexOf(bookingId);
    newBookingSelectedIds.splice(index, 1);
    setBookingSelectedIds(newBookingSelectedIds);
  };

  const renderCheck = (data: ClassroomBooking, currentChecked: boolean) => {
    const handleChange = (event: any) => {
      const isChecked = event.currentTarget.checked;
      if (isChecked) {
        addBookingSelect(data.id);
      } else {
        removeBookingSelect(data.id);
      }
    };
    return <Checkbox onChange={handleChange} checked={currentChecked} />;
  };

  const renderTitle = (props: any) => {
    const classroomId = props.row.original.id;
    return (
      <div
        className={css.linkDetail}
        onClick={() => history.push(`/booking/detail/${contextUrl}/${classroomId}`)}
      >
        {props.value}
      </div>
    );
  };

  const headerCheck = () => {
    const handleChange = (event: any) => {
      const isChecked = event.currentTarget.checked;
      if (isChecked) {
        const newBookingSelectedIds = [...bookings.map(booking => booking.id)];
        setBookingSelectedIds(newBookingSelectedIds);
      } else {
        setBookingSelectedIds([]);
      }
    };
    return <Checkbox onChange={handleChange} />;
  };

  const updateOrderDirectionsByField = (field: ClassroomBookingOrderByFields) => () => {
    const orderDirectionsByFieldCopy = { ...orderDirectionsByField };
    if (!orderDirectionsByFieldCopy[field]) {
      orderDirectionsByFieldCopy[field] = OrderByDirection.Desc;
    } else if (orderDirectionsByFieldCopy[field] === OrderByDirection.Desc) {
      orderDirectionsByFieldCopy[field] = OrderByDirection.Asc;
    } else {
      delete orderDirectionsByFieldCopy[field];
    }
    setOrderDirectionsByField(orderDirectionsByFieldCopy);
  };

  // Update the `bookings` if a orderable column header was clicked
  useEffect(() => {
    const orderBy = Object.entries(orderDirectionsByField).map(([field, direction]) => ({
      field,
      direction,
    }));
    getBookings(orderBy as IOrderByItem[]);
  }, [orderDirectionsByField]);

  const columns = [
    {
      Header: headerCheck(),
      accessor: "id",
      maxWidth: 50,
      Cell: (data: any) =>
        renderCheck(
          data.row.original,
          bookingsSelectedIds.some(item => data.row.original.id === item),
        ),
    },

    {
      Header: "Nombre",
      accessor: "title",
      minWidth: 80,
      Cell: (props: any) => renderTitle(props),
      isSortable: true,
      callbackForIsSortable: updateOrderDirectionsByField(ClassroomBookingOrderByFields.Title),
    },
    {
      Header: "Fecha",
      accessor: "data",
      minWidth: 80,
      Cell: (data: any) => datesCell(data.row.original),
      isSortable: true,
      callbackForIsSortable: updateOrderDirectionsByField(ClassroomBookingOrderByFields.Date),
    },
    {
      Header: "Sala",
      accessor: "classroom.name",
      maxWidth: 90,
      isSortable: true,
      callbackForIsSortable: updateOrderDirectionsByField(ClassroomBookingOrderByFields.Classroom),
    },
    {
      Header: "Edificio",
      accessor: "classroom.building.name",
      minWidth: 140,
      isSortable: true,
      callbackForIsSortable: updateOrderDirectionsByField(ClassroomBookingOrderByFields.Building),
    },
    {
      Header: "Pers.",
      accessor: "capacity",
      maxWidth: 90,
    },
    {
      Header: "Responsable",
      accessor: "responsible.name",
      maxWidth: 100,
      isSortable: true,
      callbackForIsSortable: updateOrderDirectionsByField(
        ClassroomBookingOrderByFields.Responsible,
      ),
    },
    {
      Header: "Creado",
      accessor: "createdAt",
      maxWidth: 100,
      Cell: (data: any) => formattedDate(data.value),
    },
    {
      Header: "Est.",
      accessor: "status",
      maxWidth: 100,
      Cell: (data: any) => statusCell(data.value),
      isSortable: true,
      callbackForIsSortable: updateOrderDirectionsByField(ClassroomBookingOrderByFields.Status),
    },
  ];

  if (!bookings) return null;

  return <Table.Container loading={loading} columns={columns} data={bookings} />;
};

export default BookingTable;
