import gql from "graphql-tag";

export const NEW_LINK_CODE = gql`
  mutation linkCode($scenarioId: ID!, $originId: ID!, $courseId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      inferLinkCode(courseId: $courseId, index: 1)
    }
  }
`;

export const NEW_LINK_SECTION = gql`
  mutation linkCode($scenarioId: ID!, $originId: ID!, $input: CreateLinkInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      createLink(input: $input) {
        skippedValidations
        commited
        validationErrors {
          ... on InvalidInput {
            code
          }
          ... on InvalidSectionChanges {
            validationErrors {
              ... on InvalidInput {
                code
              }
            }
          }
        }
        link {
          id
          code
          bundle {
            id
          }
        }
      }
    }
  }
`;
