import gql from "graphql-tag";

export const GET_WORKSPACES = gql`
  query contextualizador {
    base {
      workspaces {
        id
        name
      }
    }
  }
`;

export const GET_SCENARIOS = gql`
  query scenariosQuery($scenarioFilter: ScenariosInput) {
    base {
      scenarios(filter: $scenarioFilter) {
        id
        name
        description
      }
    }
  }
`;

export const GET_ORIGINS = gql`
  query originsQuery($scenarioId: ID!, $originFilter: OriginInput) {
    data(scenarioId: $scenarioId) {
      origins(filter: $originFilter) {
        id
        label
      }
    }
  }
`;
