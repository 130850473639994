import React, { useEffect, useContext, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import { ContextEDH } from "@context/ContextEDH";
import { User, Module, BaseQuery } from "../../models/ISchema";
import { IParams } from "../../models/IParams";
import { authMutation } from "./graphql/auth.mutation";
import useGetDataBase from "@modules/login/hooks/useGetDataBase";
import useGetSettings from "./hooks/useGetSettings";

const darwinLogin = process.env.REACT_APP_DARWINED_URL;

const EditorAuth: React.FC = () => {
  const context: IContextApp = useContext(ContextApp);
  const { state } = useContext(ContextEDH);
  const { token }: IParams = useParams();
  const localToken = localStorage.getItem("token");
  const [invalidToken, setInvalidToken] = useState(null);
  const [invalidCatch, setInvalidCatch] = useState(null);
  const [renderApp, setRenderApp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authUserMutation] = useMutation(authMutation, {});
  const [settings, getSettings] = useGetSettings();
  const [dataBase, getDataBase] = useGetDataBase();

  const getDataContext = async (uID: string) => {
    try {
      const response = await getDataBase(uID);
      const data = response[0];
      const base: BaseQuery = data?.data;
      const currentUser: User = base.user;
      const currentModules: Module[] = base.modules;
      const lockUserEdit = !currentUser.permissions.update;
      currentUser && context.setUser(currentUser);
      currentModules && context.setModules(currentModules);
      context.setLockEdit(lockUserEdit);
      context.setPermits(base?.onOff);
    } catch (error) {
      console.error("getDataContext", error);
    }
  };

  const handleDataSettingsRequests = (userId: string) => {
    if (state?.base?.settings === null && !settings?.data && !settings?.loading) {
      getSettings();
    }

    if (!state?.base?.base && !dataBase?.data && !dataBase?.loading) {
      getDataContext(userId);
    }
  };

  const getAuthUserMutation = async () => {
    try {
      setLoading(true);

      const variables = { token };
      const { data } = await authUserMutation({ variables });
      const userId = data?.auth?.id;
      const hasUserId = !!userId;

      if (hasUserId) {
        localStorage.setItem("token", token);
        handleDataSettingsRequests(userId);
      } else {
        localStorage.clear();
      }

      setRenderApp(hasUserId);
      setInvalidToken(!hasUserId);
    } catch (e) {
      console.error(e);
      localStorage.clear();
      setInvalidCatch(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const hasToken = !!(token || localToken);

    if (!hasToken) {
      window.location.href = darwinLogin;
    }

    if (hasToken && !loading && !invalidToken && !invalidCatch) {
      getAuthUserMutation();
    }
  }, []);

  if ((invalidToken || invalidCatch) && !renderApp && !loading) {
    return (
      <Route
        component={() => {
          window.location.href = darwinLogin;
          return null;
        }}
      />
    );
  }

  if (renderApp && context?.user?.context && !loading) {
    const { workspaceId, scenarioId, originId } = context.user.context;
    if (workspaceId && scenarioId) {
      const homePath = `/home/${workspaceId}/${scenarioId}/${originId}`;
      return <Redirect to={homePath} />;
    }
  }

  return null;
};

export default EditorAuth;
