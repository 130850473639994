import { DAY_NUMBER } from "@modules/sections/utils/enums";
import { Week } from "../context/linkData.reducer";
import { BlockRanges } from "../context/formData.reducer";
import dayjs from "dayjs";

export const isOutsideThreshold = (threshold: string, week: Week, blocks: BlockRanges) => {
  if (!threshold || !week?.id) return false;

  const [thresholdDate, thresholdTime] = (threshold ?? "").split(" ");

  if (blocks?.selected === "hours") {
    const [hour, minutes] = thresholdTime?.split(":");
    const time = `${hour}:${minutes}:00`;
    const dayNum = blocks?.selected === "hours" ? DAY_NUMBER?.[blocks?.day] : 0;
    const sessionDate = dayjs(week?.startingDate)
      .add(dayNum, "day")
      .format("YYYY-MM-DD");

    return (
      sessionDate <= thresholdDate || (sessionDate === thresholdDate && time <= blocks?.startTime)
    );
  }

  return week?.startingDate <= thresholdDate || week?.endingDate <= thresholdDate;
};
