import gql from "graphql-tag";

export const classroomBooking = gql`
  query classroomBooking($scenarioId: ID!, $originId: ID!, $bookingId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classroomBooking(id: $bookingId) {
        id
        title
        status
        program
        relatedProgram {
          id
          name
          code
          campus {
            id
            name
            __typename
          }
          department {
            id
            name
            __typename
          }
          modality {
            id
            name
            __typename
          }
          shift {
            id
            name
            __typename
          }
          __typename
        }
        description
        capacity
        isRecurrent
        responsible {
          id
          name
          lastName
        }
        type {
          id
          name
        }
        sessions {
          scheduleEvent {
            id
            start
            end
            __typename
          }
          events {
            interval {
              id
              startingDate
              endingDate
              name
            }
            block {
              day
            }
          }
          __typename
        }
        classroom {
          id
          code
          name
          capacity
          building {
            id
            code
            name
            campus {
              id
              code
              name
              __typename
            }
            __typename
          }
          classroomType {
            id
            code
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
