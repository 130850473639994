import React, { useContext, useState } from "react";
import { add, lensProp, map, over, pipe, prop, reduce, set } from "ramda";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { DataGrid, Icon, IconType, Button } from "@foris/avocado-ui";
import { Context } from "../../context/GroupsManagerContext";
import { Types as TableFiltersTypes } from "../../context/tableFilters.reducer";
import * as mouseflow from "@utils/mouseflow";
import { GroupChange, OrderByDirection, PageInfo } from "@models/ISchema";
import { GroupsChangesTableColumn } from "../../models";
import { ContextEDH } from "@context/ContextEDH";
import { PagerProps } from "@foris/avocado-ui/lib/types/components/Pager/Pager";
import { DataGridProps } from "@foris/avocado-ui/lib/types/components/DataGrid/DataGrid";
import TableFilter from "../TableFilter/TableFilter";
import { Column, SortInfo } from "@foris/avocado-ui/lib/types/components/DataGrid/types";
import { TableFiltersReducerType } from "../../context/types";
import groupsChangesTableHeaderToOrderByObj from "../../utils/groupsChangesTableHeaderToOrderByObj";
import css from "./groupsHistoryTable.module.scss";

interface GroupsHistoryTableProps {
  contextUrl: string;
  groupChanges: GroupChange[];
  pageInfo: PageInfo;
  requestGroupCapacityChangeDetails: (
    page: number,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => void;
}

const ROWS_PER_PAGE = 25;

const GroupsHistoryTable: React.FC<GroupsHistoryTableProps> = ({
  contextUrl,
  groupChanges,
  pageInfo,
  requestGroupCapacityChangeDetails,
}) => {
  const { state, dispatch } = useContext(Context);
  const { state: outerState } = useContext(ContextEDH);
  const [columnsToSearchBy, setColumnsToSearchBy] = useState<
    Partial<Record<GroupsChangesTableColumn, boolean>>
  >({
    Fecha: true,
    // Estado: true, -- not supported
    CRN: true,
    Sede: true,
    Materia: true,
    // "Tipo de cambio": true, -- not supported
    Responsable: true,
  });
  const history = useHistory();

  const pagination = pipe(
    set(lensProp<PagerProps>("onChange"), (page: number) =>
      requestGroupCapacityChangeDetails(
        page,
        state?.tableFilters?.orderBy,
        state?.tableFilters?.searchBy,
      ),
    ),
    set(lensProp<PagerProps>("total"), pageInfo?.total),
    set(lensProp<PagerProps>("page"), pageInfo?.page),
    set(lensProp<PagerProps>("size"), ROWS_PER_PAGE),
  )((pageInfo ?? {}) as PagerProps);

  const columns: DataGridProps<GroupChange>["columns"] = [
    {
      header: "Fecha",
      accessor: "createdAt",
      styles: {
        width: "15%",
      },
    },
    {
      header: "Estado",
      renderer: (groupChange: GroupChange) => {
        const clock = <Icon className={css.stateColumn_icon__clock} icon={"clock"} />;
        const error = <Icon className={css.stateColumn_icon__error} icon={"circle-full-error"} />;
        const check = <Icon className={css.stateColumn_icon__check} icon={"circle-full-check"} />;

        const item = {
          SUCCESS: { translation: "Guardado", icon: check },
          FAILED: { translation: "Fallado", icon: error },
          PENDING: { translation: "Pendiente", icon: clock },
        }[groupChange?.status];

        return (
          <span className={css.stateColumn_container}>
            {item?.icon}
            {item?.translation}
          </span>
        );
      },
      styles: {
        maxWidth: "10%",
      },
    },
    {
      header: "CRN",
      renderer: (groupChange: GroupChange) => {
        const pathname = `/editor/groups-manager/${contextUrl}/${groupChange?.group?.id}`;
        return (
          <Link
            to={{ pathname }}
            onClick={(e: React.MouseEvent) => {
              e?.preventDefault();
              mouseflow.actionTag(
                "action_groups_manager_enrollments",
                outerState?.base?.isMouseflowEnabled,
              );
              history.push(pathname);
            }}
          >
            <p>{groupChange?.group?.code ?? "--"}</p>
          </Link>
        );
      },
      styles: {
        maxWidth: "7%",
      },
    },
    {
      header: "Sede",
      accessor: "group.campus.code",
      styles: {
        maxWidth: "7%",
      },
    },
    {
      header: "Materia",
      renderer: (groupChange: GroupChange) => {
        return (
          <span>
            {groupChange?.group?.course?.code} {groupChange?.group?.course?.name}
          </span>
        );
      },
      styles: {
        maxWidth: "20%",
        textAlign: "left",
      },
    },
    {
      header: "Tipo de cambio",
      renderer: (groupChange: GroupChange) => {
        const prop = groupChange?.propertyUpdated === "capacity" ? "Cupos" : "Visibilidad";
        return <span>{prop}</span>;
      },
      styles: {
        maxWidth: "10%",
        textAlign: "left",
      },
    },
    {
      header: "Detalle",
      renderer: (groupChange: GroupChange) => {
        const getCapacityIcon = (before: string, after: string): IconType => {
          if (before < after) return "chevron-up";
          if (before === after) return "minus";
          else return "chevron-down";
        };

        const current = groupChange?.current;
        const expected = groupChange?.expected;

        if (groupChange?.propertyUpdated === "capacity") {
          return (
            <span className={css.state}>
              {current} | {expected}
              <Icon icon={getCapacityIcon(current, expected)} />
            </span>
          );
        }

        return (
          <span className={css.state}>
            <Icon icon={current === "0" ? "closed-eye" : "open-eye"} />
            |
            <Icon icon={expected === "0" ? "closed-eye" : "open-eye"} />
          </span>
        );
      },
      styles: {
        width: "10%",
      },
    },
    {
      header: "Responsable",
      renderer: (groupChange: GroupChange) => {
        return <span>{groupChange?.username ?? ""}</span>;
      },
      styles: {
        width: "25%",
        textAlign: "left",
      },
    },
  ];

  const leftHeaderComponent = () => {
    const now = moment().format("DD-MM-YYYY HH:mm");
    const { count: numberOfGroups } = reduce(
      (acc, groupChange) => {
        if (groupChange?.group?.id in acc?.groupIds) return acc;
        acc?.groupIds.add(groupChange?.group?.id);
        return over(lensProp<any>("count"), add(1))(acc);
      },
      { count: 0, groupIds: new Set() },
      groupChanges ?? [],
    );

    return (
      <div className={css.leftHeader}>
        <span className={css.leftHeader_numberOfGroups}>{numberOfGroups} Grupos</span>
        <span className={css.leftHeader_lastUpdate}>
          {" "}
          | <strong>Última actualización</strong>: {now}
        </span>
      </div>
    );
  };

  const middleHeaderComponent = () => {
    return (
      <section className={css.middleHeader}>
        <Button
          color="primary"
          variant="outline"
          onClick={() =>
            requestGroupCapacityChangeDetails(
              pageInfo?.page,
              state?.tableFilters?.orderBy,
              state?.tableFilters?.searchBy,
            )
          }
        >
          <Icon icon="repeat" size={14} />
          Actualizar vista
        </Button>
      </section>
    );
  };

  const onHeaderClick = (column: Column<GroupChange>) => {
    const currentOrderBy = state?.tableFilters?.orderBy;
    if (column?.header === currentOrderBy?.header) {
      const newDirection =
        currentOrderBy?.direction === OrderByDirection.Asc
          ? OrderByDirection.Desc
          : OrderByDirection.Asc;
      const newOrderBy = set(lensProp("direction"), newDirection, state?.tableFilters?.orderBy);
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestGroupCapacityChangeDetails(pageInfo?.page, newOrderBy, state?.tableFilters?.searchBy);
    } else {
      const newOrderBy = groupsChangesTableHeaderToOrderByObj(
        column?.header as GroupsChangesTableColumn,
      );
      dispatch({
        type: TableFiltersTypes.SetOrderBy,
        payload: newOrderBy,
      });
      requestGroupCapacityChangeDetails(pageInfo?.page, newOrderBy, state?.tableFilters?.searchBy);
    }
  };

  return (
    <section className={css.table}>
      <DataGrid
        columns={columns ?? []}
        nonSortableColumns={new Set(["Tipo de cambio"])}
        batch={groupChanges ?? []}
        columnsToHide={state?.tableFilters?.columnsToHide}
        onHeaderClick={onHeaderClick}
        sortInfo={{
          header: state?.tableFilters?.orderBy?.header ?? "",
          direction:
            (state?.tableFilters?.orderBy?.direction?.toLowerCase() as SortInfo["direction"]) ??
            "desc",
        }}
        pagination={pagination}
        header={{
          className: css.header,
          left: leftHeaderComponent(),
          middle: middleHeaderComponent(),
          right: (
            <TableFilter
              columnsToSearchBy={columnsToSearchBy}
              nonSortableColumns={new Set(["Tipo de cambio"])}
              setColumnsToSearchBy={setColumnsToSearchBy}
              headerToOrderByObjCallback={groupsChangesTableHeaderToOrderByObj}
              selectableColumns={map(prop("header"), columns) as GroupsChangesTableColumn[]}
              request={(
                newOrderBy: TableFiltersReducerType["orderBy"],
                newSearchBy: TableFiltersReducerType["searchBy"],
              ) => {
                requestGroupCapacityChangeDetails(1, newOrderBy, newSearchBy);
              }}
            />
          ),
        }}
      />
    </section>
  );
};

export default GroupsHistoryTable;
