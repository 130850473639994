import React, { createContext, useReducer, Dispatch } from "react";
import dayjs from "dayjs";
import { searchReducer, SearchActions } from "./search.reducer";
import { requestReducer, RequestActions } from "./request.reducer";
import { RequestTypes, SavedRequestTypes, SearchTypes } from "./types";
import { SavedRequestActions, savedRequestReducer } from "./savedRequest.reducer";

export type InitialStateType = {
  search: SearchTypes;
  request: RequestTypes;
  savedRequest: SavedRequestTypes;
};

export const initialState: InitialStateType = {
  search: {
    view: "search",
    preferences: null,
    dateTimeFormatted: [],
    dateTime: [
      {
        index: 1,
        recurrence: false,
        day: {
          value: dayjs(new Date()).format("YYYY-MM-DD"),
          error: false,
        },
        start: {
          value: null,
          error: false,
        },
        end: {
          value: null,
          error: false,
        },
      },
    ],
    requirements: {
      people: {
        count: null,
        error: false,
      },
    },
    blockOptions: {
      start: [],
      end: [],
    },
    recurrence: false,
    buildingOptions: [],
    classroomTypesOptions: [],
    intervals: null,
    currentBooking: null,
  },
  savedRequest: {
    editedBooking: null,
    bookingCapacity: null,
    recurrence: null,
    bookingPreferences: null,
  },
  request: {
    errors: null,
    snackbar: { display: false, error: false, message: "" },
    editedBooking: null,
    eventTypesOptions: null,
    title: null,
    description: null,
    eventType: null,
    usersOptions: null,
    userResponsible: null,
  },
};

const BookingContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<SearchActions | RequestActions | SavedRequestActions | void>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ search, request, savedRequest }: InitialStateType, action: any) => {
  return {
    search: searchReducer(search, action),
    request: requestReducer(request, action),
    savedRequest: savedRequestReducer(savedRequest, action),
  };
};

const BookingContextProvider: React.FC<{ children?: React.ReactNode | undefined }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <BookingContext.Provider value={{ state, dispatch }}>{children}</BookingContext.Provider>;
};

export { BookingContextProvider, BookingContext };
