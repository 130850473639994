import { IParams } from "@models/IParams";
import { IEvent, IWeek } from "@modules/sections/ISections";
import { useHistory, useParams } from "react-router-dom";

interface NavigationHandlerProps {
  sessionId: string;
  currentSelectedWeek: IWeek;
  event?: IEvent;
}

const useNavigateToMultipleEdit = () => {
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();

  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const handleNavigateToMultipleEdit = ({
    sessionId,
    currentSelectedWeek,
    event,
  }: NavigationHandlerProps) => {
    history.push(`/editor/form-edit/${contextUrl}/${event?.links[0]?.id}`, {
      sessionId,
      currentSelectedWeek,
      event,
    });
  };

  return {
    handleNavigateToMultipleEdit,
  };
};

export default useNavigateToMultipleEdit;
