import React from "react";
import { useMutation } from "react-apollo";
import { UPDATE_EVENT_MUTATION } from "./sections.queries";
import { ISectionsProps } from "./ISections";
import { formatTime } from "../../utils/time";
import GridSections from "./GridSections/GridSections";
import useNavigateToMultipleEdit from "@dimensions/section/hooks/useNavigateToMultipleEdit";

const Sections: React.FC<ISectionsProps> = (props: ISectionsProps) => {
  const [updateEvents] = useMutation(UPDATE_EVENT_MUTATION, {});
  const startBlock = localStorage.getItem("range-start-time");
  const endBlock = localStorage.getItem("range-end-time");
  const blockRanges = formatTime(startBlock, endBlock);
  const callUpdateHook = (variables: any, callback: (value: any) => void) => {
    updateEvents({ variables })
      .then(data => callback({ status: "OK", content: data }))
      .catch(err => callback({ status: "ERROR", content: err }));
  };

  const { handleNavigateToMultipleEdit } = useNavigateToMultipleEdit();

  return (
    <div className="container-fluid" style={{ padding: "0px !important" }}>
      <div className="row">
        <GridSections
          {...props}
          onOpenEdit={handleNavigateToMultipleEdit}
          calendarRanges={blockRanges}
          updateEvent={(variables: any, callback: (value: any) => void) =>
            callUpdateHook(variables, callback)
          }
        />
      </div>
    </div>
  );
};

export default Sections;
