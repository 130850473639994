import React, { useEffect, useContext, useState, useMemo } from "react";
import { has, keys, head, pipe } from "ramda";
import cx from "classnames";
import { Input, Icon } from "@foris/avocado-ui";
import { Types as EditionTypes } from "../../context/editions.reducer";
import { Context } from "../../context/GroupsManagerContext";
import nonEditableReasonText from "../../utils/nonEditableReasonText";
import splitGroupErrors from "../../utils/splitGroupErrors";
import messageByGroupEditionError from "../../utils/messageByGroupEditionError";
import { AdaptedGroup, HardGroupEditionErrors, SoftGroupEditionErrors } from "../../models";
import css from "./capacityInput.module.scss";

interface Props {
  group: AdaptedGroup;
  isSubgroup: boolean;
  disabled?: boolean;
  inputClassname?: string;
  disableErrorIcons?: boolean;
}

const CapacityInput: React.FC<Props> = props => {
  const { group, isSubgroup, disabled, inputClassname, disableErrorIcons = false } = props;
  const { state, dispatch } = useContext(Context);
  const [inputValue, setInputValue] = useState<string | number>(group?.capacity);
  const [editing, setEditing] = useState(false);

  const onChange = (e: any) => setInputValue(e?.target?.value);

  const [hardErrorsByGroupId, softErrorsByGroupId] = useMemo(
    () =>
      pipe(splitGroupErrors, ([softErrorsByGroupId, hardErrorsByGroupId]) => [
        keys(softErrorsByGroupId[group?.id]),
        keys(hardErrorsByGroupId[group?.id]),
      ])(state?.editions?.errorsByGroupId ?? {}),
    [state?.editions?.errorsByGroupId[group?.id]],
  );

  const onBlur = () => {
    setEditing(false);
    const value = inputValue?.toString();
    const shouldReset = value === "" || parseInt(value) < 0;

    if (shouldReset) setInputValue(group?.capacity);

    dispatch({
      type: EditionTypes.UpdateGroupCapacity,
      payload: {
        groupId: group?.id,
        value: shouldReset ? group?.capacity?.toString() : value,
        originalValue: group?.capacity,
      },
    });

    dispatch({ type: EditionTypes.SetGroupsToEdit, payload: {} });
  };

  const capacity = () => {
    // 1. If the user is currently editing the capacity, return its current edition.
    if (editing) {
      return inputValue;
    }

    // 2. If a change capacity was requested, return the one previously setted by the user.
    if (has(group?.id, state?.editions?.pendingCapacitiesByGroupId)) {
      return state?.editions?.pendingCapacitiesByGroupId[group?.id];
    }

    // 3. If an edition was made, return the edited capacity instead of the original one.
    if (
      state?.editions?.byGroupId[group?.id]?.capacity === 0 ||
      Boolean(state?.editions?.byGroupId[group?.id]?.capacity)
    ) {
      return state?.editions?.byGroupId[group?.id]?.capacity;
    }

    // 4. otherwise, return the original group capacity
    return group?.capacity;
  };

  useEffect(() => {
    setInputValue(group?.capacity);
  }, [group]);

  useEffect(() => {
    dispatch({
      type: EditionTypes.UpdateErrorsByGroupId,
      payload: { group, editions: state?.editions?.byGroupId[group?.id], isSubgroup },
    });
  }, [state?.editions?.byGroupId[group?.id]?.capacity]);

  const rightInfo = () => {
    if (hardErrorsByGroupId?.length && !disableErrorIcons) {
      const fstError = head(hardErrorsByGroupId ?? []);
      return (
        <>
          <p className={css.tooltip}>
            {
              messageByGroupEditionError((fstError ?? "") as keyof HardGroupEditionErrors)
                .abbreviated
            }
          </p>
          <Icon
            className={cx(css.tooltip__icon, css["tooltip__icon--error"])}
            icon="circle-full-error"
          />
        </>
      );
    } else if (softErrorsByGroupId?.length && !disableErrorIcons) {
      const fstError = head(softErrorsByGroupId ?? []);
      return (
        <>
          <p className={css.tooltip}>
            {
              messageByGroupEditionError((fstError ?? "") as keyof SoftGroupEditionErrors)
                .abbreviated
            }
          </p>
          <Icon
            className={cx(css.tooltip__icon, css["tooltip__icon--warning"])}
            icon="alert-triangle"
          />
        </>
      );
    } else if (group?.id in state?.editions?.byGroupId) {
      return (
        <>
          <p className={css.tooltip}>{"Cambio pendiente de guardar"}</p>
          <Icon className={cx(css.tooltip__icon, css["tooltip__icon--pending"])} icon="repeat" />
        </>
      );
    } else if (disabled) {
      return (
        <>
          <p className={css.tooltip}>{nonEditableReasonText(group?.isEditable)}</p>
          <Icon
            className={cx(css.tooltip__icon, css["tooltip__icon--alert"])}
            icon="circle-alert"
          />
        </>
      );
    }

    return <></>;
  };

  const errorsClassNames = () => {
    if (disableErrorIcons || (!hardErrorsByGroupId?.length && !softErrorsByGroupId?.length)) {
      return "";
    }

    return hardErrorsByGroupId?.length ? css["input--error"] : css["input--alert"];
  };

  return (
    <div className={css.container}>
      <Input
        classname={{
          global: cx(css.input, errorsClassNames()),
          input: inputClassname,
        }}
        sm
        value={capacity()}
        disabled={disabled}
        onChange={onChange}
        type={"number"}
        onBlur={onBlur}
        onFocus={() => {
          setEditing(true);
          setInputValue("");
        }}
      />
      {rightInfo()}
    </div>
  );
};

export default CapacityInput;
