import * as React from "react";
import "./checkbox.scss";

interface ICheckbox {
  className?: string;
  label?: string;
  disabled?: boolean;
  check?: boolean;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
}

class Checkbox extends React.Component<ICheckbox, any> {
  render() {
    const { className, label, disabled, check, onClick, onChange } = this.props;

    return (
      <section
        className={`component_check ${className} ${disabled && "component_check--disabled"}`}
      >
        <label className="cnt_option">
          <span className="cnt_option-label">{label}</span>
          <input
            className="cnt_option-input"
            type="checkbox"
            checked={check}
            disabled={disabled}
            onClick={(e: React.MouseEvent<HTMLInputElement>) =>
              onClick && onClick(e.currentTarget.checked)
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange && onChange(e.currentTarget.checked)
            }
          />
          <span className="cnt_option-check"></span>
        </label>
      </section>
    );
  }
}

export default Checkbox;
