import gql from "graphql-tag";

export const editPackageLabelMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $input: EditPackageLabelsInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editPackageLabels(input: $input) {
        commited
        package {
          id
          code
          labels {
            id
            code
            name
            category {
              id
              code
              name
            }
          }
        }
      }
    }
  }
`;
