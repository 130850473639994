import React from "react";
import * as R from "ramda";
import { Link as LinkRouter, useParams } from "react-router-dom";
import { Icon } from "@foris/avocado-ui";
import { Link } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { Header } from "@components/index";
import css from "@components/Header/header.module.scss";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Dimension } from "@models/IDimension";
import cx from "classnames";

interface ILinkHeader {
  setHeight?: any;
  link: Link;
}

const LinkHeader = (props: ILinkHeader) => {
  const { setHeight, link } = props;
  const { origin, scenario, workspace }: IParams = useParams();
  const info = link;

  const [dimension] = useGetDimension(Dimension.league);

  if (!info) return null;

  const program = info?.course?.curriculum?.program;
  const courseCode = info?.course?.code || "";
  const courseName = info?.course?.name || "";
  let typeGroup = "";
  info?.groups?.forEach(value => {
    const groupsSection = typeGroup && typeGroup.split(",").find(R.equals(value.type));
    if (typeGroup === "") {
      typeGroup = value.type;
    } else {
      if (!groupsSection) typeGroup = `${typeGroup}, ${value.type}`;
    }
  });

  const subTitle = () => {
    const idBundle = info?.bundle?.id;
    const termCode = info?.bundle?.term?.code;
    const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${idBundle}`;

    return (
      !!(idBundle || termCode) && (
        <div className={cx(css.infoItem_subTitle, css.infoItem_subTitleWrapper)}>
          <p className={css.headerSubtitle_label}>
            Período: <span className={css.isRegular}>{termCode}</span>
          </p>
          |
          <LinkRouter to={routeBundle} className={css.link}>
            <span className={css.link_text}>{`Bundle: ${info?.bundle?.code}`}</span>
            <Icon icon="external-link" size={14} className={css.link_icon} />
          </LinkRouter>
        </div>
      )
    );
  };

  const details = [
    { title: "Sede", content: program?.campus?.code || "" },
    { title: "Escuela", content: program?.department?.code || "" },
    { title: "Modalidad", content: program?.modality?.code || "" },
    { title: "Jornada", content: program?.shift?.code || "" },
    { title: "Carrera", content: program?.code || "" },
    { title: "Currículo", content: info?.course?.curriculum?.code || "" },
    { title: "Nivel", content: info?.course?.level?.toString() || "" },
    { title: "Vacantes", content: info?.vacancies?.toString() || "" },
    { title: "Bloques totales liga", content: info?.totalBlocks?.toString() || "" },
    { title: "Creditos", content: info?.course?.credits?.toString() || "" },
  ];

  if (typeGroup) details.push({ title: "Tipos de grupo", content: typeGroup });
  const moreDetails = [details];

  const headerInfo = {
    title: courseCode && courseName ? `${courseCode}, ${courseName}` : "",
    topTitle: info?.code || "",
    subTitle: subTitle(),
    moreDetails,
  };
  return <Header dimension={dimension.label} {...headerInfo} setHeight={setHeight} />;
};

export default LinkHeader;
