import gql from "graphql-tag";

export const classroomBookingChangeStatusMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $input: ClassroomBookingChangeStatusInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classroomBookingChangeStatus(input: $input) {
        skippedValidations
        commited
        bookings {
          id
          title
          description
        }
      }
    }
  }
`;
