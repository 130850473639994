import { useState } from "react";
import * as R from "ramda";
import { FormPageType } from "../context/formData.reducer";

export type WarningType = {
  [key: string]: {
    message: string;
    active: boolean;
    predicate: (form: FormPageType) => boolean;
  };
};

/**
 * Given a WarningType object, set warnings as active/inactive evaluating the
 * `predicate` warning's param.
 *
 * @param warningsDefinition Warnings
 * @param clearIf (form: FormPageType): boolean
 * @return [string[], (form: FormPageType) => void];
 */
export const useWarnings = (
  warningsDefinition: WarningType,
  clearIf: (form: FormPageType) => boolean,
): [string[], (form: FormPageType) => void] => {
  const [warnings, setWarnings] = useState<WarningType>(warningsDefinition);
  const [warningsMessages, setWarningsMessages] = useState<string[]>([]);

  /*
   * Return the `active` warnings' `messages`.
   *
   * @param WarningType
   * @return string[]
   */
  const getWarningsMessages = R.pipe(
    R.filter(R.propOr(false, "active")),
    R.values,
    R.map(R.propOr("", "message")),
  );

  /**
   * If the `clearIf` predicate is true, set all warnings as false.
   * Otherwise, set the warning's `active` param to the result of each warning's
   * `predicate` evaluation.
   *
   * @param form FormPageType
   * @return void
   */
  const evaluate = (form: FormPageType) => {
    const asses = (warning: string) => (clearIf(form) ? false : warnings[warning].predicate(form));
    const newWarnings = R.reduce(
      (acc, warning: string) => R.set(R.lensPath([warning, "active"]), asses(warning), acc),
      warnings,
      R.keys(warningsDefinition) as string[],
    );
    setWarnings(newWarnings as WarningType);
    setWarningsMessages(getWarningsMessages(newWarnings) as string[]);
  };

  return [warningsMessages, evaluate];
};
