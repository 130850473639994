import React, { useState, useContext, useEffect } from "react";
import cx from "classnames";
import * as R from "ramda";
import { Button } from "@foris/avocado-ui";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import AccordionTag from "./AccordionTag";
import AccordionHeader from "./AccordionHeader";
import AssignmentLabeler from "../../models/AssignmentLabeler";
import { AppContext } from "../../context/EditSessionsContext";
import { Section, Session } from "@models/ISchema";
import { Types, EditedSession } from "../../context/formData.reducer";
import css from "./accordion.module.scss";

interface IAccordionSessionsBySectionProps {
  sections: Section[];
  setAllSessionsChecked: (value: boolean) => void;
  onCompareBySession: (value: Session) => void;
  onCompareBySection: (
    sessions: Session[],
    section: Section,
    checked: boolean,
    newSessions: EditedSession[],
  ) => boolean;
  userCanCreate: boolean;
}

const AccordionSessionsBySection = ({
  sections,
  setAllSessionsChecked,
  onCompareBySection,
  onCompareBySession,
  userCanCreate,
}: IAccordionSessionsBySectionProps) => {
  const { state, dispatch } = useContext(AppContext);
  const [newSessionsBySection, setNewSessionsBySection] = useState<{
    [key: string]: EditedSession[];
  }>({});
  const [sessionsBySection, setSessionsBySection] = useState<{ [key: string]: EditedSession[] }>(
    {},
  );
  const sessionsToDelete = state?.form?.sessionsToDelete ?? {};

  const formatSessions = R.curry(
    (savedSessionsById: { [key: string]: EditedSession }, sessions: Session[]) =>
      R.map(
        session =>
          R.has(session?.id, savedSessionsById)
            ? savedSessionsById[session?.id]
            : R.pipe(
                R.set(R.lensProp<EditedSession>("id"), session?.id),
                R.set(R.lensProp<EditedSession>("session"), session),
              )(session),
        sessions,
      ),
  );

  const getMockSession = (section: Section) => ({
    id: section.id + (section?.sessions?.length || 0),
    blocks: null,
    instructors: null,
    classrooms: null,
    intervals: null,
    session: null,
    isNew: true,
    section: section,
  });

  const displaySession = (
    session: EditedSession,
    savedSessionsById: { [key: string]: string },
    selectedSessionsById: { [key: string]: string },
    section: Section,
    index: number,
  ) => (
    <AccordionTag
      key={session.id}
      deleted={session.id in sessionsToDelete}
      color={index + 1}
      session={session?.session}
      weeks={AssignmentLabeler.fromWeeks(null, session?.intervals, state?.link?.weeks)}
      date={AssignmentLabeler.fromDate(null, session?.blocks)}
      edited={session.id in savedSessionsById}
      selected={session.id in selectedSessionsById}
      onClick={value => {
        setAllSessionsChecked(false);
        return onCompareBySession(value);
      }}
      icon={session.id in sessionsToDelete ? "trash" : "repeat"}
      section={section}
    />
  );

  const displaySavedNewSession = (
    session: EditedSession,
    selectedSessionsById: { [key: string]: string },
    section: Section,
    index: number,
  ) => (
    <AccordionTag
      key={`${session.id}_${index}`}
      deleted={session.id in sessionsToDelete}
      color={11}
      editedSession={session}
      weeks={AssignmentLabeler.fromWeeks(null, session?.intervals, state?.link?.weeks)}
      date={AssignmentLabeler.fromDate(null, session?.blocks)}
      edited={true}
      selected={session.id in selectedSessionsById}
      section={section}
      onClick={() => {
        setAllSessionsChecked(false);
        return onCompareBySession(session as Session);
      }}
      icon="circle-plus"
    />
  );

  const displayClonedSession = (
    session: EditedSession,
    selectedSessionsById: { [key: string]: string },
    section: Section,
  ) => (
    <AccordionTag
      key={`cloned_${session?.id}`}
      color={11}
      editedSession={session}
      weeks={AssignmentLabeler.fromWeeks(null, session?.intervals, state?.link?.weeks)}
      date={AssignmentLabeler.fromDate(null, session?.blocks)}
      edited={true}
      selected={session.id in selectedSessionsById}
      section={section}
      onClick={() => {
        setAllSessionsChecked(false);
        return onCompareBySession(session as Session);
      }}
      icon="circle-plus"
    />
  );

  useEffect(() => {
    if (!state?.form?.savedSessions?.length) return;
    setNewSessionsBySection(
      R.reduce(
        (acc, session) => {
          if (!(session?.section?.id in acc)) acc[session?.section?.id] = [];
          if (session?.isNew) acc[session?.section.id].push(session);
          return acc;
        },
        {},
        state?.form?.savedSessions,
      ),
    );
  }, [state?.form?.savedSessions]);

  useEffect(() => {
    if (!state?.form?.sessionsToCreate?.length && !sessionsBySection?.length) {
      setSessionsBySection({});
      return;
    }

    setSessionsBySection(
      R.reduce(
        (acc, session: EditedSession) => {
          const sectionId = R.view(R.lensPath(["section", "id"]), session);
          if (R.not(R.has(sectionId, acc))) acc[sectionId] = [];
          return R.over(R.lensProp<any>(sectionId), R.append(session), acc);
        },
        {},
        state?.form?.sessionsToCreate ?? [],
      ),
    );
  }, [state?.form?.sessionsToCreate]);

  return (
    <>
      {sections.map((section: Section, index: number) => {
        const [savedSessionsById, selectedSessionsById] = R.ap(
          [R.reduce((table, session) => R.assoc(session.id, session, table), {})],
          [state?.form?.savedSessions ?? [], state?.form?.selectedSessions ?? []],
        );
        const sessions = formatSessions(
          savedSessionsById,
          R.concat(section?.sessions ?? [], section?.unasignedSessions ?? []),
        );

        const newSessions = newSessionsBySection[section.id] ?? [];
        const sessionsOfSection = sessionsBySection[section.id] || [];

        return (
          <AccordionItem
            uuid={index.toString()}
            key={section?.id}
            className={cx(css.accordionItem)}
          >
            <AccordionItemState>
              {({ expanded }) => (
                <AccordionItemHeading className={cx(css.accordionHeader)}>
                  <AccordionItemButton
                    className={cx(
                      css.accordionButton,
                      "container-row",
                      "row--between",
                      "row_align--start",
                    )}
                  >
                    <AccordionHeader
                      section={section}
                      chevron={expanded ? "chevron-up" : "chevron-down"}
                      updated={false}
                      onCheck={(...args) => {
                        setAllSessionsChecked(false);
                        return onCompareBySection(...args);
                      }}
                    />
                  </AccordionItemButton>
                </AccordionItemHeading>
              )}
            </AccordionItemState>
            <AccordionItemPanel className={cx(css.accordionPanel)}>
              {(sessions as any).map((session: EditedSession) =>
                displaySession(session, savedSessionsById, selectedSessionsById, section, index),
              )}
              {newSessions.map(session =>
                displaySavedNewSession(session, selectedSessionsById, section, index),
              )}
              {sessionsOfSection.map((session: EditedSession) =>
                displayClonedSession(session, selectedSessionsById, section),
              )}
              {state?.form?.selectedCreateSession &&
                state?.form?.selectedSection?.id === section.id && (
                  <AccordionTag
                    color={11}
                    editedSession={getMockSession(section)}
                    weeks=""
                    date=""
                    edited={true}
                    selected={null}
                    icon="circle-plus"
                    section={section}
                  />
                )}
              {userCanCreate && (
                <Button
                  typeButton="transparent"
                  className={cx(css.accordionPanel_button)}
                  disabled={state?.form?.selectedCreateSession}
                  onClick={() => {
                    dispatch({ type: Types.SelectedCreateSession, payload: section });
                  }}
                >
                  + Agregar sesión
                </Button>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        );
      })}
    </>
  );
};

export default AccordionSessionsBySection;
