import { ActionMap } from "@models/IContext";
import { ActivePageType, PageReducerType } from "./types";
import { setActivePage, setLoading } from "./page.actions";

export enum Types {
  SetActivePage = "SET_ACTIVE_PAGE",
  SetLoading = "SET_LOADING",
}

type PagePayload = {
  [Types.SetActivePage]: ActivePageType;
  [Types.SetLoading]: boolean;
};

export type PageActions = ActionMap<PagePayload>[keyof ActionMap<PagePayload>];

export const pageReducer = (state: PageReducerType, action: PageActions) => {
  switch (action?.type) {
    case Types.SetActivePage:
      return setActivePage(action?.payload, state);
    case Types.SetLoading:
      return setLoading(action?.payload, state);
    default:
      return state;
  }
};
