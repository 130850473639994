import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as R from "ramda";
import cx from "classnames";
import { CardState } from "@foris/avocado-ui";
import { IParams } from "@models/IParams";
import Modal from "@components/modal/ModalState/ModalState";
import AccordionSections from "../../components/Accordion/AccordionSaved";
import ResultsHeader from "../../components/ResultEdit/ResultHeader";
import Compare from "../../components/Compare/Compare";
import IncidenceTable from "../../components/Incidence/IncidenceTable";
import { AppContext } from "../../context/EditSessionsContext";
import { Types } from "../../context/switchPage.reducer";
import { Types as TypeForm } from "../../context/formData.reducer";
import { useGetLink } from "../../hooks/useGetLinks";
import useSessionsCrudMutation from "../../hooks/useSessionsCrudMutation";
import css from "./resultEdit.module.scss";

interface IResultsEdit {
  heightHeader: number;
}
const ResultsEdit: React.FC<IResultsEdit> = (props: IResultsEdit) => {
  const { heightHeader } = props;
  const history = useHistory();
  const { id, origin, scenario, workspace }: IParams = useParams();
  const { state, dispatch } = useContext(AppContext);
  const [dataLinks, getDataLinks] = useGetLink();
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [resultSessionsCrud, submitSessionsCrud] = useSessionsCrudMutation({
    dryRun: false,
    skipValidations: true,
  });

  const savedSessions = state?.form?.savedSessions ?? [];
  const sessionsToDelete = R.values(state?.form?.sessionsToDelete) ?? [];

  const numberOfSavedSessions = savedSessions?.length ?? 0;
  const numberOfSessionsToCreate = state?.form?.sessionsToCreate?.length ?? 0;
  const numberOfSessionsToDelete = sessionsToDelete?.length ?? 0;
  const numberOfEditedSessions = R.length(R.reject(R.propOr(false, "isNew"), savedSessions));
  const numberOfNewSessions = R.length(R.filter(R.propOr(false, "isNew"), savedSessions));
  const someEditionMade = Boolean(
    numberOfSessionsToDelete + numberOfSavedSessions + numberOfSessionsToCreate,
  );

  const selectedEdit = state?.result?.selectSession;
  const selectedCreate = state?.result?.selectCreateSession;
  const loading = state?.page?.loading;

  const submitForm = () => {
    if (R.and(R.not(loading), someEditionMade)) {
      dispatch({ type: Types.SetLoading, payload: true });
      submitSessionsCrud();
    }
  };

  useEffect(() => {
    const responseData = resultSessionsCrud?.data;
    if (someEditionMade) {
      const validateEdited = numberOfEditedSessions > 0;
      const validateCreate = numberOfNewSessions + numberOfSessionsToCreate > 0;
      const validateDeleted = numberOfSessionsToDelete > 0;

      const goToResultView =
        (validateEdited && !validateCreate && responseData?.commited) ||
        (!validateEdited && validateCreate && responseData) ||
        (validateEdited && validateCreate && responseData) ||
        validateDeleted;

      if (goToResultView && R.not(dataLinks) && Boolean(responseData)) {
        getDataLinks();
      }
    }
    if (responseData?.error) setError(true);
  }, [resultSessionsCrud]);

  useEffect(() => {
    if (Boolean(dataLinks) && Boolean(state?.link?.info) && R.not(confirm)) {
      setConfirm(true);
      dispatch({ type: Types.SetLoading, payload: false });
    }
  }, [dataLinks]);

  if (loading) return null;

  return (
    <>
      {confirm && (
        <Modal
          typeState="confirm"
          title="Edición realizada"
          show={confirm}
          textButtonPrincipal="Ir a liga"
          onClickPrincipal={() => {
            dispatch({
              type: TypeForm.CleanForm,
              payload: true,
            });
            const context = `${workspace}/${scenario}/${origin}`;
            history.push(`/scheduler/editor/link/${context}/${id}`, {
              currentSelectedWeek: state.link.selectedWeek,
            });
          }}
          textButtonSecondary="Seguir editando"
          onClickSecondary={() => {
            dispatch({ type: TypeForm.CleanForm, payload: true });
            dispatch({ type: Types.FormPage, payload: true });
          }}
        >
          Las modificaciones fueron procesadas y guardadas correctamente. Selecciona una opción para
          continuar.
        </Modal>
      )}
      {error && (
        <Modal
          typeState="error"
          title="Error"
          show={error}
          textButtonPrincipal="Confirmar"
          onClickPrincipal={() => window.location.reload()}
        >
          Se ha encontrado un error en la validación
        </Modal>
      )}
      {!confirm && (
        <section className={cx(css.content, css[`content__height${heightHeader}`])}>
          <aside className={cx(css.aside)}>
            <AccordionSections />
          </aside>
          <section className={cx(css.results)}>
            {selectedEdit || selectedCreate || sessionsToDelete ? (
              <>
                <ResultsHeader onSubmit={submitForm} />
                <section className={css.results_content}>
                  <Compare />
                  <IncidenceTable />
                </section>
              </>
            ) : (
              <CardState
                typeCard="informative"
                title="Validaciones de cambios"
                className={css.initCard}
              >
                Selecciona una sesión del listado de la izquierda para revisar el resultado de sus
                validaciones.
              </CardState>
            )}
          </section>
        </section>
      )}
    </>
  );
};

export default ResultsEdit;
