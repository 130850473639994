import { TableFiltersReducerType } from "../context/types";
import { GroupsManagerGroupChangesOrderByFieldsEnum, OrderByDirection } from "@models/ISchema";
import { GroupsChangesTableColumn } from "../models";

const columnToField = {
  Fecha: GroupsManagerGroupChangesOrderByFieldsEnum.Date,
  Estado: GroupsManagerGroupChangesOrderByFieldsEnum.Status,
  CRN: GroupsManagerGroupChangesOrderByFieldsEnum.GroupCode,
  Sede: GroupsManagerGroupChangesOrderByFieldsEnum.Campus,
  Materia: GroupsManagerGroupChangesOrderByFieldsEnum.CourseName,
  "Tipo de cambio": GroupsManagerGroupChangesOrderByFieldsEnum.ChangeType,
  Detalle: GroupsManagerGroupChangesOrderByFieldsEnum.Detail,
  Responsable: GroupsManagerGroupChangesOrderByFieldsEnum.Responsible,
};

/**
 * Given a GroupsManagerTable's column header, return the corresponding
 * GroupsManagerGroupChangesOrderByFieldsEnum.
 *
 * @param header The GroupsManagerTable's column header.
 *
 * @returns TableFiltersReducerType["orderBy"]
 */
const groupsChangesTableHeaderToOrderByObj = (
  header: GroupsChangesTableColumn,
): TableFiltersReducerType["orderBy"] => {
  if (header == null || !(header in columnToField)) {
    return {
      header: "Fecha",
      field: columnToField["Fecha"],
      direction: OrderByDirection.Desc,
    };
  }
  return {
    header,
    field: columnToField[header],
    direction: OrderByDirection.Desc,
  };
};

export default groupsChangesTableHeaderToOrderByObj;
