export const getFields = ({ filterBooking, page, sizePage: size, orderBy = [] }) => {
  return {
    fields: {
      fullText: filterBooking.length > 3 ? filterBooking : "",
    },
    pagination: {
      page,
      size,
    },
    orderBy,
  };
};

export const getChangeStatusFields = ({ status, bookingIds, comment }) => {
  return {
    dryRun: false,
    skipValidations: false,
    bookingIds,
    status,
    comment,
  };
};
