import { curry, set, lensProp, reject, ap, and, over, equals } from "ramda";
import {
  DataReducerType,
  LinkPopulationPair,
  LinkPackagePair,
  LinkPopulationObj,
  LinkPackageObj,
} from "./types";
import { key } from "../utils";

export const addAssignments = (payload: LinkPackagePair[] = [], state: DataReducerType) => {
  const neaAssignments = {
    ...state?.assignments,
  };

  payload.forEach(({ package: pkg, linkId }) => {
    const objKey = key(pkg?.id, linkId);

    neaAssignments[objKey] = {
      linkId,
      package: pkg,
    };
  });

  return {
    ...state,
    assignments: neaAssignments,
  };
};

export const addAssignment = curry(
  ({ package: pack, linkId }: LinkPackagePair, state: DataReducerType) => {
    const assignments = set(
      lensProp(key(pack?.id, linkId)),
      { package: pack, linkId },
      state?.assignments,
    );
    return set(lensProp("assignments"), assignments, state);
  },
);

export const addCreation = curry(
  ({ population, linkId, termId, packageName }: LinkPopulationPair, state: DataReducerType) => {
    const creations = set(
      lensProp(!linkId ? population?.code : key(population?.code, linkId)),
      { population, linkId, termId, packageName },
      state?.creations,
    );
    return set(lensProp("creations"), creations, state);
  },
);

export const addDeletion = curry(
  ({ package: pack, linkId }: LinkPackagePair, state: DataReducerType) => {
    const deletions = set(
      lensProp(key(pack?.id, linkId)),
      { package: pack, linkId },
      state?.deletions,
    );
    return set(lensProp("deletions"), deletions, state);
  },
);

export const cleanAssignments = set<DataReducerType, LinkPackageObj>(lensProp("assignments"), {});

export const cleanCreations = set<DataReducerType, LinkPopulationObj>(lensProp("creations"), {});

export const cleanDeletions = set<DataReducerType, LinkPackageObj>(lensProp("deletions"), {});

export const deleteAssignment = curry(
  ({ package: { id: packageId }, linkId }: LinkPackagePair, state: DataReducerType) => {
    const [samePackage, sameLink] = ap([equals], [packageId, linkId]);
    const remove = reject(({ package: { id }, linkId: itemLinkId }: LinkPackagePair) =>
      and(samePackage(id), sameLink(itemLinkId)),
    );
    return over(lensProp("assignments"), remove, state);
  },
);

export const deleteAssignments = (payload: LinkPackageObj, state: DataReducerType) => {
  return {
    ...state,
    assignments: payload,
  };
};

export const deleteCreation = curry(
  ({ population: { code }, linkId }: LinkPopulationPair, state: DataReducerType) => {
    const [samePopulation, sameLink] = ap([equals], [code, linkId]);
    const remove = reject(({ population: { code }, linkId: itemLinkId }: LinkPopulationPair) =>
      and(samePopulation(code), sameLink(itemLinkId)),
    );
    return over(lensProp("creations"), remove, state);
  },
);

export const setLink = set(lensProp<DataReducerType, "link">("link"));

export const setPopulations = set(lensProp<DataReducerType, "populations">("populations"));
