import gql from "graphql-tag";

export const groupsManagerCrud = gql`
  mutation($originId: ID!, $scenarioId: ID!, $input: GroupsManagerCrudInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      groupsManagerCrud(input: $input) {
        userCanSkipValidations
        payload {
          creates {
            commited
            group {
              id
            }
            validationErrors {
              ... on GroupsManagerValidation {
                allowed
                reason
                __typename
              }
            }
            __typename
          }
          updates {
            commited
            group {
              id
            }
            validationErrors {
              ... on GroupsManagerValidation {
                allowed
                reason
                __typename
              }
            }
            __typename
          }
        }
        __typename
      }
    }
  }
`;
