import React, { useState, useEffect } from "react";
import { Loading } from "@foris/avocado-ui";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { EmptyResults } from "@components/index";
import SectionsContainer from "@modules/sections/Sections";
import { ISectionData } from "@modules/sections/ISections";
import { functionsData } from "@modules/sections/utils";
import Layout from "../../../../../common/layout/Layout";
import { useLinkSection } from "../../hooks/useLinkSection";
import HeaderLink from "../../components/HeaderLink";

const GridSectionLink: React.FC = () => {
  const [data, getLinkInfo] = useLinkSection();
  const { origin, scenario, workspace }: IParams = useParams();
  const [calendar, setCalendar] = useState<ISectionData>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (data.data && loading) {
      setLoading(false);
    }

    if (data.error && !loading) {
      setError(true);
    }
  }, [data]);

  useEffect(() => {
    if (!data.data && !loading) {
      setLoading(true);
      getLinkInfo();
    }
  }, []);

  let sections: ISectionData = null;

  if (data?.data) {
    sections = functionsData.serializeSections(data.data);
  }

  const dataCalendar = calendar ? calendar : sections;

  return (
    <Layout contextSearch>
      {data?.link && <HeaderLink link={data?.link} />}
      {loading && <Loading />}
      {error && <EmptyResults dimension="Liga" />}
      {dataCalendar && (
        <SectionsContainer
          config={{
            scenarioId: parseInt(scenario),
            originId: parseInt(origin),
            workspaceId: parseInt(workspace),
          }}
          defaultTerm={data?.link?.bundle?.term}
          data={dataCalendar}
          setDataGrid={setCalendar}
        />
      )}
    </Layout>
  );
};

export default GridSectionLink;
