import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Icon, Button, Select } from "@foris/avocado-ui";
import cx from "classnames";
import { enums, messages } from "../../utils";
import * as mouseflow from "@utils/mouseflow";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { ContextEDH } from "@context/ContextEDH";
import { IParams } from "../../../../models/IParams";
import css from "./item_info.module.scss";
import { Classroom } from "@models/ISchema";

const ItemInfo = (props: any) => {
  const { event, error, currentSelectedWeek } = props;
  const { origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const context: IContextApp = useContext(ContextApp);
  const { state } = useContext(ContextEDH);
  const [linkValue, setLinkValue] = useState(null);

  let instructors = null;
  let classrooms = null;
  let campus = null;
  let day = "";
  let startDate = "";
  let endDate = "";
  let nameComponent = "";
  let sectionId = "";
  let vacancies = "";
  let enrollments = "";
  let waitList = "";
  let demand = "";
  let groups = [];
  const optionsLinks = [];
  const userCanEdit =
    context?.user?.permissions?.update && context?.user?.abilities?.can_edit_assignment;

  if (event && event.resource) {
    const resource = event?.resource;
    instructors = resource?.instructors ?? [];
    classrooms = resource?.classrooms ?? [];
    campus = resource?.info?.course?.curriculum?.program?.campus?.name || "-";

    if (resource?.blockRange) {
      const block = resource?.blockRange;
      if (block?.start) {
        day = (block?.start?.day && enums.DAY_NAMES_ES[block?.start?.day]) || "";
        startDate = block?.start?.startingTime?.replace(":00", "") || "";
      }
      endDate = block?.end ? block?.end?.endingTime?.replace(":00", "") : "";
    }
    if (resource?.info?.courseComponent) {
      nameComponent = resource?.info?.courseComponent?.component?.code ?? "";
    }
    if (resource?.info?.section) {
      const infoSection = resource?.info?.section;
      sectionId = infoSection?.id ?? "";
      vacancies = infoSection?.vacancies ?? "";
      groups = infoSection?.groups ?? [];
      enrollments = infoSection?.usedCapacity ?? "";
      waitList = infoSection?.waitingLists ?? "";
    }
    demand = resource?.info?.course?.demand?.crosslist
      ? `(${resource?.info?.course?.demand?.crosslist})`
      : "";
  }

  const errors = error && event.error.details;
  const errorList = error && errors.map((item: any) => item.__typename);
  const uniqueList =
    error && errorList.filter((item: any, pos: any) => errorList.indexOf(item) === pos);

  const typeGroup = [];
  if (groups) groups.forEach(group => group.type && typeGroup.push(group.type));

  const contextUrl = `${workspace}/${scenario}/${origin}`;

  useEffect(() => {
    event?.links?.forEach((link: any) => {
      const options = { label: `Liga: ${link?.id}`, value: link?.id };
      optionsLinks.push(options);
    });
  }, []);

  const displayNoResource = (mandatoriness: boolean, resource: string) => {
    const classname = mandatoriness
      ? css.absent_resource__required
      : css.absent_resource__not_required;
    const text = mandatoriness ? `Requiere ${resource}` : `No requiere ${resource}`;
    return <p className={classname}> {text} </p>;
  };

  return (
    <div className={css.section_info}>
      <section className={css.sectionRow}>
        <div className={cx(css.sectionRow_item, "container-row")}>
          <div className={cx(css.sectionRow_column, css.icon)}>
            <Icon icon="clock" size={18} />
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Horario</label>
            <p>{`${day ? `${day},` : ""} ${startDate}-${endDate}`}</p>
          </div>
        </div>

        <div className={cx(css.sectionRow_item, "container-row")}>
          <div className={cx(css.sectionRow_column, css.icon)}>
            <Icon icon="user" size={18} />
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Docente</label>
            {instructors?.length > 0
              ? instructors.map((dataInstructor: any, index: number) => {
                  const codeInstructor = dataInstructor?.code ?? "";
                  const nameInstructor = dataInstructor?.name ?? "Sin docente asignado";
                  if (dataInstructor?.id) {
                    return (
                      <p
                        key={index}
                        className={css.sectionRow_link}
                        onClick={() =>
                          history.push(
                            `/scheduler/editor/instructor/${contextUrl}/${dataInstructor.id}`,
                          )
                        }
                      >{`${codeInstructor} - ${nameInstructor}`}</p>
                    );
                  } else {
                    return <p key={index}>{`${codeInstructor} - ${nameInstructor}`}</p>;
                  }
                })
              : displayNoResource(
                  event?.resource?.info?.courseComponent?.requiresInstructor,
                  "docente",
                )}
          </div>
        </div>

        <div className={cx(css.sectionRow_item, "container-row")}>
          <div className={cx(css.sectionRow_column, css.icon)}>
            <Icon icon="building" size={18} />
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Sala</label>
            {classrooms?.length > 0
              ? classrooms.map((classroom: any, index: number) => {
                  const typeClassroom = classroom?.classroomType?.name ?? "";
                  const nameClassroom = classroom?.name ?? "Sin sala asignada";
                  if (classroom?.id) {
                    return (
                      <Link
                        key={classroom.id}
                        to={`/scheduler/editor/classroom/${contextUrl}/${classroom.id}`}
                      >
                        <p
                          key={index}
                          className={css.sectionRow_link}
                        >{`${nameClassroom} - ${typeClassroom}`}</p>
                      </Link>
                    );
                  } else {
                    return <p key={index}>{`${nameClassroom} - ${typeClassroom}`}</p>;
                  }
                })
              : displayNoResource(
                  event?.resource?.info?.courseComponent?.requiresClassroom,
                  "sala",
                )}
          </div>
          {classrooms?.length > 0 && (
            <>
              <div className={css.sectionRow_column}>
                <label className={css.sectionRow_title}>Edificio</label>
                {classrooms?.map((classroomData: Classroom, index) => (
                  <p key={index} className={css.dataItems}>
                    {classroomData?.building?.name || "-"}
                  </p>
                ))}
              </div>
              <div className={css.sectionRow_column}>
                <label className={css.sectionRow_title}>Sede</label>
                {classrooms?.map((classroomData: Classroom, index) => (
                  <p key={index} className={css.dataItems}>
                    {classroomData?.building?.campus?.name || "-"}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>

        <div className={cx(css.sectionRow_item, "container-row")}>
          <div className={css.sectionRow_column}>
            {event?.bundle?.id ? (
              <Link
                to={{
                  pathname: `/editor/vacancies/${contextUrl}/${event?.bundle?.id}`,
                }}
              >
                <label className={css.sectionRow_title}>Bundle</label>
                <p className={css.sectionRow_link}>{event?.bundle?.code ?? ""}</p>
              </Link>
            ) : (
              <>
                <label className={css.sectionRow_title}>Vacantes</label>
                <p>{vacancies}</p>
              </>
            )}
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Sección</label>
            {sectionId ? (
              <Link to={`/scheduler/editor/section/${contextUrl}/${sectionId}`}>
                <p className={css.sectionRow_link}>{sectionId}</p>
              </Link>
            ) : (
              <p>{sectionId}</p>
            )}
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Componente</label>
            <p>{nameComponent}</p>
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Vacantes</label>
            <p>{vacancies}</p>
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Inscritos</label>
            <p>{enrollments}</p>
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Lista de espera</label>
            <p>{waitList}</p>
          </div>
          <div className={css.sectionRow_column}>
            <label className={css.sectionRow_title}>Sede</label>
            <p>{campus}</p>
          </div>
        </div>

        {typeGroup?.length > 0 && (
          <div className={cx(css.sectionRow_item, "container-row")}>
            <div className="container-column">
              <label className={css.sectionRow_title}>Tipo de grupo</label>
              <p>{typeGroup.join(", ")}</p>
            </div>
          </div>
        )}

        <div className={cx(css.sectionRow_item, "container-row")}>
          <div className="container-column">
            <label className={css.sectionRow_title}>{`Grupos ${demand}`}</label>
            <p>
              <>
                {groups?.map((group: any, index) => {
                  const codeGroup =
                    index + 1 === groups.length ? group.code && group.code : `${group.code}, `;
                  if (group.id) {
                    return (
                      <label
                        key={index}
                        className={css.sectionRow_link}
                        onClick={() => {
                          history.push(`/scheduler/editor/group/${contextUrl}/${group.id}`);
                        }}
                      >
                        {codeGroup}
                      </label>
                    );
                  } else {
                    return { codeGroup };
                  }
                })}
              </>
            </p>
          </div>
        </div>

        {event?.links?.length > 1 ? (
          <div className={cx(css.sectionRow_item)}>
            <label className={cx(css.sectionRow_title, "col_12")}>Liga</label>
            <Select
              value={linkValue}
              placeholder="Selecciona una opción"
              options={optionsLinks}
              onChange={(value: any) => {
                setLinkValue(value);
              }}
              className={cx(css.select, "col_6")}
              {...{ activeMaxHeight: true }}
            />
          </div>
        ) : null}

        {error && (
          <div className={css.section_error}>
            <div className={css.list_error}>
              <span>{`${uniqueList.length} Error${uniqueList.length > 1 ? "es" : ""}`}</span>
              <ul>
                {uniqueList.map((item: any, index: number) => (
                  <li key={index}> {messages.errors[item]}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </section>
      <footer className={cx(css.sectionFooter, "container-row")}>
        {userCanEdit && event.canEditCourse && event?.links?.length === 1 && (
          <Button
            className={css.sectionFooter_btn}
            onClick={() => {
              mouseflow.actionTag("action_edit_multiple", state?.base?.isMouseflowEnabled);
              history.push(`/editor/form-edit/${contextUrl}/${event?.links[0]?.id}`, {
                sessionId: event?.sessionId,
                currentSelectedWeek,
              });
            }}
          >
            Edición múltiple
          </Button>
        )}
        {userCanEdit && event.canEditCourse && event?.links?.length > 1 && (
          <Button
            className={css.sectionFooter_btn}
            onClick={() => {
              mouseflow.actionTag("action_edit_multiple", state?.base?.isMouseflowEnabled);
              history.push(`/editor/form-edit/${contextUrl}/${linkValue?.value}`, {
                sessionId: event?.sessionId,
                currentSelectedWeek,
              });
            }}
            disabled={!linkValue}
          >
            Edición múltiple
          </Button>
        )}
      </footer>
    </div>
  );
};

export default ItemInfo;
