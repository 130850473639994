import React from "react";
import css from "./cardSession.module.scss";
import { Card } from "@foris/avocado-ui";
import cx from "classnames";

type SessionType = string | boolean | InstructorCard[] | ClassroomCard[] | IntervalCard[];

export interface InstructorCard {
  code: string;
  name: string;
}

export interface ClassroomCard {
  code: string;
  name: string;
  building: string;
}

export interface IntervalCard {
  values: string[];
  type: "added" | "deleted";
}

export interface SessionValue {
  value: SessionType;
  isSame?: boolean;
}

export interface CardSessionProps {
  title: string;
  blockRange: SessionValue;
  vacancies: SessionValue;
  instructors: SessionValue;
  classrooms: SessionValue;
  intervals?: SessionValue;
  className?: string;
  isDeleted?: boolean;
}

const CardSession: React.FC<CardSessionProps> = (props: CardSessionProps) => {
  const {
    title,
    blockRange,
    vacancies,
    instructors,
    classrooms,
    intervals,
    className,
    isDeleted = false,
  } = props;

  return (
    <Card.Simple className={cx(css.card, !!className ? className : "")}>
      <Card.Content className={cx("container-row")}>
        <h2 className={cx(css.card_title, "col_12")}>{title}</h2>
        <div className={cx(css.item, "col_4", blockRange.isSame && css.item__same)}>
          <p className={css.item_label}>Horario</p>
          <p className={css.item_info}>{blockRange.value as string}</p>
        </div>
        {vacancies.value && (
          <div className={cx(css.item, "col_4", vacancies.isSame && css.item__same)}>
            <p className={css.item_label}>Vacantes</p>
            <p className={css.item_info}>{vacancies.value as string}</p>
          </div>
        )}
        <div className={cx(css.item, "col_12", instructors.isSame && css.item__same)}>
          <p className={css.item_label}>Docente</p>
          {Array.isArray(instructors.value) && instructors.value.length > 0 ? (
            <>
              {instructors.value.map((instructor: any) => {
                return (
                  <p className={css.item_info} key={instructor.code}>
                    {instructor.code || "-"}
                    <span className={css.bold}>{` ${instructor.name || "-"}`}</span>
                  </p>
                );
              })}
            </>
          ) : (
            <p className={css.item_info}>Sin asignación</p>
          )}
        </div>
        <div className={cx(css.item, "col_12", classrooms.isSame && css.item__same)}>
          <p className={css.item_label}>Sala</p>
          {Array.isArray(classrooms.value) && classrooms.value.length > 0 ? (
            <>
              {classrooms.value.map((classroom: any) => (
                <p className={css.item_info} key={classroom?.code}>
                  {classroom?.code || "-"}
                  <span className={css.bold}>{` ${classroom?.name || "-"}
                  ${classroom?.building ? `| ` : ""} `}</span>
                  {classroom?.building || ""}
                </p>
              ))}
            </>
          ) : (
            <p className={css.item_info}>Sin asignación</p>
          )}
        </div>
        {intervals && !intervals?.isSame && (
          <>
            {Array.isArray(intervals.value) && (
              <>
                {intervals.value.map((interval, index: number) => (
                  <React.Fragment key={index}>
                    {interval.type === "added" && interval?.values?.length > 0 && (
                      <div key={index} className={cx(css.item, "col_12")}>
                        <p className={css.item_label}>
                          {isDeleted ? `Semanas` : `Semanas agregadas`}
                        </p>
                        <p className={css.item_info}>{interval.values.join(" - ")}</p>
                      </div>
                    )}
                    {interval.type === "deleted" && interval?.values?.length > 0 && (
                      <div key={index} className={cx(css.item, "col_12")}>
                        <p className={css.item_label}>Semanas eliminadas</p>
                        <p className={css.item_info}>
                          {interval.values
                            .sort((a: string, b: string) => Number(a) - Number(b))
                            .join(" - ")}
                        </p>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </>
        )}
      </Card.Content>
    </Card.Simple>
  );
};

export default CardSession;
