import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Input, RadioButton } from "@foris/avocado-ui";
import cx from "classnames";
import { InstructorLink, ValidationErrorReason } from "@models/ISchema";
import { ContextVacancies, IContextVacancies } from "../contextVacancies/ContextVacancies";
import { IParams } from "../../../models/IParams";
import css from "./instructor.module.scss";

export interface IInstructorListProps {
  linkId: string;
  activeEdit: boolean;
  instructor: InstructorLink;
  index: number;
  isPrimary: boolean;
  totalBlocks: number;
  paymentPercent: number;
  blockPercent: number;
  onChangeIsPrimary?: (e: any) => void;
  onChange?: (e: any) => void;
  onChangePayment?: (e: any) => void;
}

const InstructorList: React.FC<IInstructorListProps> = (props: IInstructorListProps) => {
  const {
    linkId,
    activeEdit,
    instructor,
    index,
    isPrimary,
    totalBlocks,
    paymentPercent,
    blockPercent,
    onChangeIsPrimary,
    onChange,
    onChangePayment,
  } = props;
  const context: IContextVacancies = useContext(ContextVacancies);
  const { origin, scenario, workspace }: IParams = useParams();
  const [paymentError, setPaymentError] = useState(false);
  const [responsabilityError, setResponsabilityError] = useState(false);
  const components = instructor.components ? instructor.components.join(", ") : "";
  const isUnassigment = instructor.instructor.id === "unassigment";

  useEffect(() => {
    setPaymentError(false);
    setResponsabilityError(false);
    if (context?.errorsInstructors && context?.errorsInstructors.length > 0) {
      context?.errorsInstructors.forEach(item => {
        if (item.link?.linkId === linkId) {
          if (item?.item?.code === ValidationErrorReason.InvalidPaymentPercentage) {
            setPaymentError(true);
          }
          if (item?.item?.code === ValidationErrorReason.InvalidTeachingHoursPercentage) {
            setResponsabilityError(true);
          }
        }
      });
    } else {
      setResponsabilityError(false);
      setPaymentError(false);
    }
  }, [context?.errorsInstructors, paymentError, responsabilityError]);

  const handleChangeResponsability = (value: string) => {
    onChange?.(value);
    onChangePayment?.(value);
  };

  return (
    <ul className={css.infoList} key={index}>
      <li className={css.infoList_item}>
        <RadioButton
          name={`instructorBundle-${linkId}`}
          checked={isPrimary}
          onChange={() => onChangeIsPrimary(true)}
          disabled={!activeEdit || isUnassigment}
        />
      </li>
      <li className={cx(css.infoList_item, isUnassigment && css.infoList_item__unassigment)}>
        {!isUnassigment ? (
          <Link
            className={css.link}
            to={`/scheduler/editor/instructor/${workspace}/${scenario}/${origin}/${instructor.id}`}
          >
            <span className={css.link_regular}>{instructor.instructor.code} </span>{" "}
            {instructor.instructor.name}
          </Link>
        ) : (
          `${instructor.instructor.name}`
        )}
      </li>
      <li className={cx(css.infoList_item, isUnassigment && css.infoList_item__unassigment)}>
        {`${instructor.totalBlocks} ${blockPercent ? `(${blockPercent}%)` : ""} `}
      </li>
      <li
        className={cx(
          css.infoList_item,
          css.infoList_item__input,
          isUnassigment && css.infoList_item__unassigment,
        )}
      >
        {isUnassigment ? (
          isUnassigment
        ) : (
          <>
            {activeEdit ? (
              <Input
                classNameInput={cx(css.inputItem)}
                value={totalBlocks}
                onChange={e => handleChangeResponsability(e.target.value)}
                disabled={!activeEdit}
                error={responsabilityError}
              />
            ) : null}

            <span className={css.suffix}>{!activeEdit ? totalBlocks : ""}%</span>
            {responsabilityError && (
              <Icon icon="circle-full-error" size={16} className={css.icon} />
            )}
          </>
        )}
      </li>
      <li
        className={cx(
          css.infoList_item,
          css.infoList_item__input,
          isUnassigment && css.infoList_item__unassigment,
        )}
      >
        {isUnassigment ? (
          isUnassigment
        ) : (
          <>
            {activeEdit ? (
              <Input
                classNameInput={cx(css.inputItem)}
                value={paymentPercent}
                onChange={e => onChangePayment(e.target.value)}
                disabled={!activeEdit}
                error={paymentError}
              />
            ) : null}
            <span className={css.suffix}>{!activeEdit ? paymentPercent : ""}%</span>
            {paymentError && <Icon icon="circle-full-error" size={16} className={css.icon} />}
          </>
        )}
      </li>
      <li className={cx(css.infoList_item, isUnassigment && css.infoList_item__unassigment)}>
        {components}
      </li>
    </ul>
  );
};

export default InstructorList;
