import React, { useContext } from "react";
import { Card, Input, Select, Textarea } from "@foris/avocado-ui";
import cx from "classnames";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/request.reducer";
import UserSelect from "../AsyncSelectors/UserAsyncSelect";
import css from "./requestForm.module.scss";

const Form = () => {
  const { state, dispatch } = useContext(BookingContext);

  return (
    <Card.Simple
      className={cx(css.cardRequest, "container-row", "row--block", "col_8", "col_md_12")}
    >
      <p className={cx(css.cardRequest_text)}>¿Para qué te gustaría reservar?</p>
      <UserSelect className={css.responsable_selector} />
      <Input
        placeholder="Placeholder"
        label="Título de evento"
        value={state?.request?.title?.value || ""}
        maxLength={25}
        error={state?.request?.title?.error ? "El título del evento es requerido" : null}
        className={cx(css.cardRequest_item, "col_4", "col_sm_12")}
        onChange={e =>
          dispatch({ type: Types.SetTitle, payload: { value: e?.target?.value, error: false } })
        }
      />
      <Select
        label="Tipo de evento"
        placeholder="Placeholder"
        options={state?.request?.eventTypesOptions || []}
        error={state?.request?.eventType?.error ? "El tipo de evento es requerido" : null}
        className={cx(css.cardRequest_item, "col_4", "col_md_12")}
        onChange={e =>
          dispatch({ type: Types.SetEventType, payload: { value: e?.value, error: false } })
        }
      />
      <Textarea
        label="Descripción (opcional)"
        max={250}
        characteresAvailable={true}
        className={cx(css.cardRequest_item, "col_12")}
        onChange={e =>
          dispatch({
            type: Types.SetDescription,
            payload: { value: e?.target?.value, error: false },
          })
        }
      />
    </Card.Simple>
  );
};

export default Form;
