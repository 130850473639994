import { ActionMap } from "@models/IContext";
import { EditionsReducerType } from "./types";
import {
  addGroupToEdit,
  addPendingCapacity,
  clean,
  editGroupsToEditCapacities,
  editGroupsToEditVisibilities,
  removeGroupToEdit,
  removePendingCapacity,
  restoreGroupsToEditCapacities,
  sanitizePendingEditions,
  setGroupsToEdit,
  updateErrorsByGroupId,
  updateGroupCapacity,
  updateGroupVisibility,
} from "./editions.actions";
import { AdaptedGroup, GroupEdition } from "../models";

export enum Types {
  AddGroupToEdit = "ADD_GROUP_TO_EDIT",
  AddPendingCapacity = "ADD_PENDING_CAPACITY",
  Clean = "Clean",
  EditGroupsToEditCapacities = "EDIT_GROUPS_TO_EDIT_CAPACITIES",
  EditGroupsToEditVisibilities = "EDIT_GROUPS_TO_EDIT_VISIBILITIES",
  RemoveGroupToEdit = "REMOVE_GROUP_TO_EDIT",
  RemovePendingCapacity = "REMOVE_PENDING_CAPACITY",
  RestoreGroupsToEditCapacities = "RESTORE_GROUPS_TO_EDIT_CAPACITIES",
  SanitizePendingEditions = "SANITIZE_PENDING_EDITIONS",
  SetGroupsToEdit = "SET_GROUPS_TO_EDIT",
  UpdateErrorsByGroupId = "UPDATE_ERRORS_BY_GROUP_ID",
  UpdateGroupCapacity = "UPDATE_GROUP_CAPACITY",
  UpdateGroupVisibility = "UPDATE_GROUP_VISIBILITY",
}

type EditionsPayload = {
  [Types.AddGroupToEdit]: AdaptedGroup;
  [Types.AddPendingCapacity]: { groupId: string; capacity: number };
  [Types.Clean]: undefined;
  [Types.EditGroupsToEditCapacities]: {
    capacity: number;
    groupsById: Record<AdaptedGroup["id"], AdaptedGroup>;
    groupIdsToExclude: Set<string>;
  };
  [Types.EditGroupsToEditVisibilities]: Record<string, AdaptedGroup>;
  [Types.RemoveGroupToEdit]: AdaptedGroup;
  [Types.RemovePendingCapacity]: string;
  [Types.RestoreGroupsToEditCapacities]: undefined;
  [Types.SanitizePendingEditions]: AdaptedGroup[];
  [Types.SetGroupsToEdit]: Record<AdaptedGroup["id"], AdaptedGroup>;
  [Types.UpdateErrorsByGroupId]: {
    group: AdaptedGroup;
    editions: GroupEdition;
    isSubgroup: boolean;
  };
  [Types.UpdateGroupCapacity]: {
    groupId: string;
    value: string;
    originalValue: number;
  };
  [Types.UpdateGroupVisibility]: {
    groupId: string;
    value: boolean;
    originalValue: boolean;
  };
};

export type EditionsActions = ActionMap<EditionsPayload>[keyof ActionMap<EditionsPayload>];

export const editionsReducer = (state: EditionsReducerType, action: EditionsActions) => {
  switch (action?.type) {
    case Types.AddGroupToEdit:
      return addGroupToEdit(action?.payload, state);
    case Types.AddPendingCapacity:
      return addPendingCapacity(action?.payload?.groupId, action?.payload?.capacity, state);
    case Types.Clean:
      return clean(state);
    case Types.EditGroupsToEditCapacities:
      const { capacity, groupsById, groupIdsToExclude } = action?.payload;
      return editGroupsToEditCapacities(capacity, groupsById, groupIdsToExclude, state);
    case Types.EditGroupsToEditVisibilities:
      return editGroupsToEditVisibilities(action?.payload, state);
    case Types.RemoveGroupToEdit:
      return removeGroupToEdit(action?.payload, state);
    case Types.RemovePendingCapacity:
      return removePendingCapacity(action?.payload, state);
    case Types.RestoreGroupsToEditCapacities:
      return restoreGroupsToEditCapacities(state);
    case Types.SanitizePendingEditions:
      return sanitizePendingEditions(action?.payload, state);
    case Types.SetGroupsToEdit:
      return setGroupsToEdit(action?.payload, state);
    case Types.UpdateErrorsByGroupId:
      return updateErrorsByGroupId(action?.payload, state);
    case Types.UpdateGroupCapacity:
      return updateGroupCapacity(action?.payload, state);
    case Types.UpdateGroupVisibility:
      return updateGroupVisibility(action?.payload, state);
    default:
      return state;
  }
};
