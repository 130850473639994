import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { curriculumQuery } from "../graphql/curriculum.queries";
import { Curriculum, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorCurriculum, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const CurriculumSelect: React.FC<ISelectorCurriculum> = (props: ISelectorCurriculum) => {
  const client = useApolloClient();
  const { onCallback, program, label, className, activeCode } = props;
  const { scenario }: IParams = useParams();
  const [optionsCurriculum, setOptionsCurriculum] = useState([]);
  const [valueCurriculum, setValueCurriculum] = useState(null);
  const [prevProgram, setPrevProgram] = useState(program);
  const [disabled, setDisabled] = useState(false);

  const variablesCurriculum = {
    query: curriculumQuery,
    variables: {
      scenarioId: scenario,
      filter: {
        fields: {
          and: [
            {
              program_id: {
                eq: program,
              },
            },
          ],
        },
      },
    },
  };

  const setCurriculumOptions = useCallback(async (data: any) => {
    if (data && data.data) {
      console.table("data", data);
      const dataQuery: DataQuery = data.data.data;
      const optCurriculums = [];
      if (dataQuery.curriculums.items.length > 0) {
        dataQuery.curriculums.items.forEach((curriculum: Curriculum) => {
          optCurriculums.push({
            label: curriculum.code,
            value: activeCode ? curriculum.code : curriculum.id,
          });
        });
      }
      setOptionsCurriculum(optCurriculums);
    }
  }, []);

  const getCurriculumgOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesCurriculum);
      await setCurriculumOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [variablesCurriculum, client, setCurriculumOptions]);

  const getCacheCurriculumOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesCurriculum);
      await setCurriculumOptions(data);
    } catch (error) {
      getCurriculumgOptions();
    }
  }, [variablesCurriculum, client, setCurriculumOptions, getCurriculumgOptions]);

  useEffect(() => {
    if (program && prevProgram !== program && disabled) {
      getCacheCurriculumOptions();
      setPrevProgram(program);
      setDisabled(false);
    }

    if (!program) {
      setPrevProgram(null);
      setValueCurriculum("");
      setDisabled(true);
    }
  }, [disabled, program, prevProgram, getCacheCurriculumOptions]);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Currículum</label>}
      <Select
        options={optionsCurriculum}
        value={valueCurriculum}
        placeholder={label ? "Seleccionar" : "Currículum"}
        isClearable
        isDisabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValueCurriculum(value);
            onCallback(value);
          } else {
            setValueCurriculum("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default CurriculumSelect;
