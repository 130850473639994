import React, { useContext, useMemo } from "react";
import { ContextApp } from "@config/Context/contextApp";
import Menu, { MenuItem } from "./Menu/Menu";

export interface IMenuProps {
  hasSessions?: boolean;
  openEdit?: (value: any) => void;
  openDisabledSession?: (value: any) => void;
  openChangeComponent?: (value: any) => void;
  onDisabledSessionError?: () => void;
}

const SectionMenu: React.FC<IMenuProps> = (props: IMenuProps) => {
  const {
    openEdit,
    openDisabledSession,
    openChangeComponent,
    onDisabledSessionError,
    hasSessions,
  } = props;
  const { user } = useContext(ContextApp);

  const userCanCreateOrEditSessions =
    user?.permissions?.create &&
    user?.abilities?.can_edit_links_and_sections &&
    user?.abilities?.can_edit_or_delete_sessions;

  const handleDeleteSession = (val: any) => {
    if (!user?.abilities?.can_edit_or_delete_sessions && hasSessions) {
      onDisabledSessionError?.();
      return;
    }

    openDisabledSession?.(val);
  };

  const menuItems = useMemo(() => {
    const items: MenuItem[] = [
      {
        label: "Cambiar Componente",
        icon: "edit",
        onClick: openChangeComponent,
      },
    ];

    if (user?.abilities?.can_delete_sections) {
      items.push({
        label: "Eliminar",
        icon: "trash",
        onClick: handleDeleteSession,
      });
    }

    if (userCanCreateOrEditSessions) {
      items.unshift({
        label: "Agregar Sesión",
        icon: "plus",
        onClick: openEdit,
      });
    }

    return items;
  }, [userCanCreateOrEditSessions]);

  return <Menu items={menuItems} />;
};

export default SectionMenu;
