import React from "react";
import { Route, Switch } from "react-router-dom";
import Shell from "../../config/routes/shell";
import GetAuth from "./GetApp";

const EditorAuth: React.FC = () => {
  return (
    <Switch>
      <Route path="/auth/:token?" component={GetAuth} />
      <Route path="/" component={Shell} />
    </Switch>
  );
};

export default EditorAuth;
