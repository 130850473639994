import { useState } from "react";
import { view, lensPath, pipe } from "ramda";
import { useApolloClient } from "react-apollo";
import { groupsSearch as groupQuery } from "../graphql/group.query";
import { groupAdapter } from "../adapters/group";
import { AdaptedGroup } from "../models";

type sponse = [
  AdaptedGroup,
  (scenarioId: string, originId: string, groupId: string) => void,
  boolean,
];

export const useGetGroup = (): sponse => {
  const client = useApolloClient();
  const [response, setResponse] = useState<AdaptedGroup>(null);
  const viewGroup = view(lensPath(["cube", "groupsManagerSearch", "items", 0]));
  const [loading, setLoading] = useState<boolean>(false);

  const getGroup = async (scenarioId: string, originId: string, groupId: string) => {
    setLoading(true);
    try {
      const { data, errors } = await client.query({
        query: groupQuery,
        variables: {
          scenarioId,
          originId,
          filter: {
            fields: {
              isReferent: { is: false },
              id: { eq: groupId },
            },
          },
        },
        fetchPolicy: "no-cache",
      });

      if (errors) {
        console.error("[Group error]: ", errors);
        setLoading(false);
        return null;
      }

      pipe(viewGroup, groupAdapter, setResponse)(data);
      setLoading(false);
    } catch (error) {
      console.error("[Group error]: ", error);
      setLoading(false);
    }
  };

  return [response, getGroup, loading];
};
