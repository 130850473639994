import * as React from "react";
import { Loader } from "../Loader/Loader";
import css from "./autocomplete.module.scss";

export interface IOption {
  label: string | JSX.Element;
  value: string;
}

interface IOptions {
  options: Array<IOption>;
  onSelect?: (value: IOption) => void;
  loading?: boolean;
}

const Options: React.FC<IOptions> = (props: IOptions) => {
  const { options, onSelect, loading } = props;

  return (
    <ul className={css.listSuggest}>
      {loading ? (
        <Loader size="tiny" />
      ) : (
        <>
          {options.length > 0 &&
            options.map((value: IOption) => (
              <li
                key={value.value}
                className={css.listSuggest_item}
                onClick={() => onSelect(value)}
              >
                {typeof value.label === "string" ? (
                  <p className={css.textSuggest}>{value.label}</p>
                ) : (
                  value.label
                )}
              </li>
            ))}
        </>
      )}
    </ul>
  );
};

export default Options;
