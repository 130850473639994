import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { intervalsQuery } from "../graphql//intervals.query";
import { BookingContext } from "../context/BookingContext";
import { Types } from "../context/search.reducer";
import { DataQuery } from "@models/ISchema";
import useSelectedWeekIntervals from "./useSelectedWeekIntervals";

const useGetIntervals = (): [
  { data: boolean; error: boolean; loading: boolean },
  (scenario: string) => void,
] => {
  const client = useApolloClient();
  const { dispatch, state } = useContext(BookingContext);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedWeekIntervals] = useSelectedWeekIntervals(state?.search?.currentBooking?.sessions);

  const getIntervals = async (scenario: string) => {
    try {
      const variablesClassRoom = {
        query: intervalsQuery,
        variables: {
          scenarioId: scenario,
        },
      };
      const { data } = await client.query(variablesClassRoom);
      const dataQuery: DataQuery = data?.data;
      const intervals = [...dataQuery?.intervals];
      const intervalWeeks = intervals.map((week, index) => {
        return {
          ...week,
          selected: week?.id in selectedWeekIntervals,
          value: (index + 1).toString(),
        };
      });
      dispatch({ type: Types.SetIntervals, payload: intervalWeeks });
    } catch (error) {
      console.error("[getIntervals]", error);
      dispatch({ type: Types.SetIntervals, payload: [] });
      setResult(true);
      setError(true);
      setLoading(false);
    }
  };

  const getCacheIntervals = async (scenario: string) => {
    try {
      const variablesClassRoom = {
        query: intervalsQuery,
        variables: {
          scenarioId: scenario,
        },
      };
      const { data } = await client.readQuery(variablesClassRoom);
      const dataQuery: DataQuery = data?.data;
      const intervals = [...dataQuery?.intervals];
      const intervalWeeks = intervals.map((week, index) => {
        return {
          ...week,
          selected: week?.id in selectedWeekIntervals,
          value: (index + 1).toString(),
        };
      });
      dispatch({ type: Types.SetIntervals, payload: intervalWeeks });
    } catch (error) {
      getIntervals(scenario);
    }
  };

  return [{ data: result, error, loading }, getCacheIntervals];
};

export default useGetIntervals;
