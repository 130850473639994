import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { customBlocksQuery } from "../graphql/customBlocksQuery.query";
import { BookingContext } from "../context/BookingContext";
import { Types } from "../context/search.reducer";
import { DataQuery } from "@models/ISchema";

const useGetOptions = (): [
  { data: boolean; error: boolean; loading: boolean },
  (scenario: string) => void,
] => {
  const client = useApolloClient();
  const { dispatch } = useContext(BookingContext);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setCustomBlocksOptions = async (data: any) => {
    if (data) {
      const newOptionsCustomBlocks = { start: [], end: [] };

      newOptionsCustomBlocks.start = (data?.start || []).map((blocks: string) => ({
        label: blocks,
        value: blocks,
      }));

      newOptionsCustomBlocks.end = (data?.end || []).map((blocks: string) => ({
        label: blocks,
        value: blocks,
      }));

      dispatch({ type: Types.SetBlockOptions, payload: newOptionsCustomBlocks });
    }
  };

  const getBlocksOptions = async (scenario: string) => {
    setLoading(true);
    try {
      const variablesCustomBlocks = {
        query: customBlocksQuery,
        variables: {
          scenarioId: scenario,
        },
      };
      const { data } = await client.query(variablesCustomBlocks);
      const dataQuery: DataQuery = data?.data;
      await setCustomBlocksOptions(dataQuery?.customBlocks);
      setLoading(false);
      setError(false);
      setResult(true);
    } catch (e) {
      console.error("[user]", e);
      setLoading(false);
      setError(true);
      setResult(true);
      return null;
    }
  };

  return [{ data: result, error, loading }, getBlocksOptions];
};

export default useGetOptions;
