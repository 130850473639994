import gql from "graphql-tag";

export const populationsQuery = gql`
  query megaSearch(
    $scenarioId: ID!
    $filter: PopulationFilterInput!
    $originId: ID!
    $termId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      complementedPopulations(filter: $filter, termId: $termId) {
        items {
          id
          level
          code
          curriculum
          packageIndex
          size
          program {
            id
            code
            name
            department {
              id
              code
              name
            }
          }
          campus {
            id
            code
            name
          }
          modality {
            id
            code
            name
          }
          shift {
            id
            code
            name
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
