import gql from "graphql-tag";

export const BLOCK_RANGES_QUERY = gql`
  query institutionBlockRange($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      institutionCalendarRange {
        startTime
        endTime
      }
    }
  }
`;

export const HOLIDAYS_QUERY = gql`
  query($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      holidays {
        id
        code
        name
        date
      }
    }
  }
`;
