import React, { useContext } from "react";
import { Icon, Table } from "@foris/avocado-ui";
import { AppContext } from "../../context/EditSessionsContext";
import { Types } from "../../context/formData.reducer";
import cx from "classnames";
import css from "./../FormEdit/formItem.module.scss";

interface InstructorTableProps {
  disabled?: boolean;
}

const SimpleTableClassroom: React.FC<InstructorTableProps> = ({ disabled }) => {
  const { state, dispatch } = useContext(AppContext);

  const codeColumn = (row: any) => <span>{row?.row?.original?.code}</span>;

  const nameColumn = (row: any) => <span>{row?.row?.original?.name}</span>;

  const deleteColumn = (row: any) => (
    <button
      disabled={disabled}
      className={cx(css.btnTrash, disabled ? css.btnTrash__disabled : "")}
      onClick={() => {
        const deleteRow = row?.row?.original?.id;
        const classroomList = [...state?.form?.editedSessions?.classrooms];
        const indexInstructor = classroomList.findIndex(item => item.id === deleteRow);
        classroomList.splice(indexInstructor, 1);
        dispatch({
          type: Types.ClassroomEditedSessions,
          payload: {
            classrooms: classroomList,
          },
        });
      }}
    >
      <Icon icon="trash" size={18} />
    </button>
  );

  const columns = [
    {
      id: "code",
      maxWidth: 100,
      Cell: (row: any) => codeColumn(row),
    },
    {
      id: "name",
      minWidth: 200,
      Cell: (row: any) => nameColumn(row),
    },
    {
      id: "delete",
      maxWidth: 80,
      Cell: (row: any) => deleteColumn(row),
    },
  ];

  return (
    <>
      {state?.form?.editedSessions?.classrooms?.length > 0 ? (
        <Table.Container
          data={state?.form?.editedSessions?.classrooms || []}
          columns={columns}
          secondary
          hiddenHeader
        />
      ) : null}
    </>
  );
};

export default SimpleTableClassroom;
