import React from "react";
import { GroupProvider } from "../context/GroupContext";
import Layout from "../../../../common/layout/Layout";
import Group from "../GridSections/Group";

const EditSessions: React.FC = () => {
  return (
    <Layout contextSearch>
      <GroupProvider>
        <Group />
      </GroupProvider>
    </Layout>
  );
};

export default EditSessions;
