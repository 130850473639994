import { SectionValidationErrorUnion, ValidationErrorReason } from "../ISchema";
import ErrorWarning, { IErrorWarning } from "./ErrorWarning";

export const sectionDeleteStrings = {
  GroupHasEnrollments: "Esta sección tiene grupos con estudiantes inscritos.",
  InvalidSection: "Sección no existe o está deshabilitada.",
  ExternalBlock:
    "No se permite hacer cambios de horario debido a que el\
      grupo tiene alumnos inscritos, se encuentra con cupo > 0 en el \
      sistema de inscripciones, o bien, se está intentando cambiar a un salón de menor capacidad.",
};

class SectionDelete extends ErrorWarning {
  private section: SectionValidationErrorUnion;
  constructor(error: SectionValidationErrorUnion) {
    super();
    this.section = error;
  }

  private getMessageExternalValidation = (): IErrorWarning => {
    let invalidInput = null;
    if (this.section.__typename === "ExternalGroupValidation") {
      invalidInput = this.setError(sectionDeleteStrings.ExternalBlock);
    }

    return invalidInput;
  };

  private getMessageInvalidInput = (): IErrorWarning => {
    let invalidInput = null;
    if (this.section.__typename === "InvalidInput") {
      switch (this.section.code) {
        case ValidationErrorReason.InvalidSection:
          invalidInput = this.setError(sectionDeleteStrings.InvalidSection);
          break;
        default:
          invalidInput = null;
          break;
      }
    }
    return invalidInput;
  };

  private getMessageGroupEnrollment = (): IErrorWarning => {
    let invalidComponent = null;
    if (this.section.__typename === "InvalidGroupEnrollment") {
      switch (this.section.reason) {
        case ValidationErrorReason.GroupHasEnrollments:
          const message = sectionDeleteStrings.GroupHasEnrollments;
          invalidComponent = this.setError(message);
          break;
        default:
          invalidComponent = null;
      }
    }
    return invalidComponent;
  };

  public getMessage = () => {
    switch (this.section && this.section.__typename) {
      case "InvalidInput":
        return this.getMessageInvalidInput();
      case "InvalidGroupEnrollment":
        return this.getMessageGroupEnrollment();
      case "ExternalGroupValidation":
        return this.getMessageExternalValidation();
      default:
        return null;
    }
  };
}

export default SectionDelete;
