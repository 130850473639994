import gql from "graphql-tag";

export const levelQuery = gql`
  query($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      levels {
        id
        level
      }
    }
  }
`;
