import React from "react";
import Modal from "@modules/sections/GroupsManager/components/Modal/Modal";

interface DeletePackageModalProps {
  isOpen?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const DeletePackageModal = ({ isOpen, onCancel, onConfirm }: DeletePackageModalProps) => {
  return (
    <Modal
      typeState="confirm"
      show={isOpen}
      title="Confirma que deseas eliminar el paquete"
      subtitle="Esta acción es irreversible."
      onClose={() => onCancel?.()}
      textButtonPrincipal="Eliminar paquete"
      colorButtonPrincipal="primary"
      textButtonSecondary="Cancelar"
      onClickPrincipal={() => onConfirm?.()}
      onClickSecondary={() => onCancel?.()}
    >
      La programación (Ligas) dentro del paquete no se verá afectada. Si es necesario, puedes crear
      un paquete similar y vincularlo con esta programación.
      <br />
      <br />
      Tras el completado de esta acción, serás redirigido a la pantalla de inicio del Editor de
      Horarios.
    </Modal>
  );
};

export default DeletePackageModal;
