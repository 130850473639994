import gql from "graphql-tag";

export const LINK_CRUD_MUTATION = gql`
  mutation updateLink($originId: ID!, $scenarioId: ID!, $input: LinkCrudInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      linksCrud(input: $input) {
        skippedValidations
        commited
        payload {
          updates {
            link {
              id
              code
              bundle {
                id
                __typename
              }
              __typename
            }
            validationErrors {
              ... on GenericInvalidValidation {
                __typename
                reason
              }
              ... on InvalidLabelLink {
                link {
                  id
                  code
                  label
                  __typename
                }
                allowed
                reason
                __typename
              }
            }
            __typename
          }
          __typename
        }
        userCanSkipValidations
        __typename
      }
      __typename
    }
  }
`;
