import { omit, set, lensPath, pipe } from "ramda";
import { AcademicUnit, Subject } from "@models/ISchema";
import { AdaptedSubject } from "../models";

export const subjectAdapter = (subject: Subject): AdaptedSubject => {
  const departments = subject?.academicUnits?.filter(
    (au: AcademicUnit) => au?.academicUnitType?.code === "DEP_UF",
  );
  const schools = subject?.academicUnits?.filter(
    (au: AcademicUnit) => au?.academicUnitType?.code === "SCH_UF",
  );

  return pipe(
    omit(["academicUnits"]),
    set(
      lensPath<any>(["academicUnits", "departments"]),
      departments,
    ),
    set(
      lensPath<AdaptedSubject>(["academicUnits", "schools"]),
      schools,
    ),
  )(subject);
};
