import { createContext, useCallback, useState } from "react";
import { Interval, TermPart, TermPartCategory } from "@models/ISchema";
import {
  IntervalValidationErrors,
  EditorValidationError,
  CustomValidation,
} from "./../../../../../models/ISchema";

export type Views = "form" | "searchClassRoom" | "serachInstructor" | "validations";
export type Repeat = "onlySession" | "allWeeks" | "weekSelect" | "withWeeksGrouper";
export type OptionHour = "hourRequiere" | "blockRequiere";

// TODO: use the one in the ISections.ts file
export type TermPartsByCategory = {
  category: TermPartCategory;
  termParts: TermPart[];
};

export interface Week extends Interval {
  value?: number;
  status?: "on" | "off";
  title?: string;
  highlight?: boolean;
}

export interface IBlockRange {
  day: any;
  startTime: any;
  endTime: any;
}

export interface IClassRoom {
  code: string;
  id: string;
  name: string;
  building?: string;
}

export interface IInstructor {
  code: string;
  id: string;
  name: string;
}

export interface ICustomValidationItem {
  key: string;
  value: string;
}

export interface IGloblaErrors {
  __typename: string;
  code: string;
  items: Array<ICustomValidationItem>;
}

export interface WeekRepeat extends Week {
  checked: boolean;
}

export interface ICurrentData {
  blockRanges: IBlockRange;
  instructor: IClassRoom;
  classroom: IClassRoom;
  repeat: Repeat;
  weeksRepeat: WeekRepeat[];
  noScheduleEventsCount: number;
  optionHour?: OptionHour;
}

export interface IContextEdit {
  currentView: Views;
  setCurrentView: (value: Views) => void;
  currentData: ICurrentData;
  setCurrentData: (value: ICurrentData) => void;
  dataValidation: Array<IntervalValidationErrors>;
  setDataValidation: (value: Array<IntervalValidationErrors>) => void;
  instructors: Array<IInstructor>;
  termPartsByCategory: TermPartsByCategory[];
  setTermPartsByCategory: (value: Array<TermPartsByCategory>) => void;
  setInstructors: (value: Array<IInstructor>) => void;
  classrooms: Array<IClassRoom>;
  setClassrooms: (value: Array<IClassRoom>) => void;
  globalErrors: Array<IGloblaErrors>;
  setGlobalErrors: (value: Array<IGloblaErrors>) => void;
  validationErrors: Array<EditorValidationError>;
  setValidationErrors: (value: Array<EditorValidationError>) => void;
  customValidations: Array<CustomValidation>;
  setCustomValidations: (value: Array<CustomValidation>) => void;
  intervalValidationErrors: Array<IntervalValidationErrors>;
  setIntervalValidationErrors: (value: Array<IntervalValidationErrors>) => void;
  editUserCanSkip: boolean;
  setEditUserCanSkip: (value: boolean) => void;
}

const defaultContext: IContextEdit = {
  currentView: "form",
  setCurrentView: null,
  currentData: {
    blockRanges: null,
    instructor: null,
    classroom: null,
    repeat: "allWeeks",
    weeksRepeat: [] as WeekRepeat[],
    noScheduleEventsCount: null,
    optionHour: "hourRequiere",
  },
  setCurrentData: null,
  dataValidation: null,
  setDataValidation: null,
  instructors: [],
  termPartsByCategory: [],
  setTermPartsByCategory: null,
  setInstructors: null,
  classrooms: [],
  setClassrooms: null,
  globalErrors: null,
  setGlobalErrors: null,
  validationErrors: [],
  setValidationErrors: null,
  customValidations: [],
  setCustomValidations: null,
  intervalValidationErrors: [],
  setIntervalValidationErrors: null,
  editUserCanSkip: false,
  setEditUserCanSkip: null,
};

export const ContextEdit = createContext<IContextEdit>(defaultContext);

export const useContextEdit = (): IContextEdit => {
  const [currentView, setViewState] = useState(defaultContext.currentView);
  const [currentData, setDataState] = useState(defaultContext.currentData);
  const [dataValidation, setDataValidationState] = useState(defaultContext.dataValidation);
  const [instructors, setInstructorsState] = useState(defaultContext.instructors);
  const [termPartsByCategory, setTermPartsByCategoryState] = useState(
    defaultContext.termPartsByCategory,
  );
  const [classrooms, setClassroomssState] = useState(defaultContext.classrooms);
  const [globalErrors, setGlobalErrorsState] = useState(defaultContext.globalErrors);
  const [validationErrors, setValidationErrorState] = useState(defaultContext.validationErrors);
  const [customValidations, setCustomValidationState] = useState(defaultContext.customValidations);
  const [editUserCanSkip, setEditUserCanSkipState] = useState(defaultContext.editUserCanSkip);
  const [intervalValidationErrors, setIntervalValidationErrorState] = useState(
    defaultContext.intervalValidationErrors,
  );

  const setCurrentView = useCallback((value: Views) => {
    if (value) setViewState(value);
  }, []);

  const setCurrentData = useCallback((value: ICurrentData) => {
    if (value) setDataState(value);
  }, []);

  const setTermPartsByCategory = useCallback((value: TermPartsByCategory[]) => {
    if (value) setTermPartsByCategoryState(value);
  }, []);

  const setDataValidation = useCallback((value: Array<IntervalValidationErrors>) => {
    if (value) setDataValidationState(value);
  }, []);

  const setInstructors = useCallback((value: Array<IInstructor>) => {
    if (value) setInstructorsState(value);
  }, []);

  const setClassrooms = useCallback((value: Array<IClassRoom>) => {
    if (value) setClassroomssState(value);
  }, []);

  const setGlobalErrors = useCallback((value: Array<IGloblaErrors>) => {
    if (value) setGlobalErrorsState(value);
  }, []);

  const setValidationErrors = useCallback((value: Array<EditorValidationError>) => {
    if (value) setValidationErrorState(value);
  }, []);

  const setCustomValidations = useCallback((value: Array<CustomValidation>) => {
    if (value) setCustomValidationState(value);
  }, []);

  const setIntervalValidationErrors = useCallback((value: Array<IntervalValidationErrors>) => {
    if (value) setIntervalValidationErrorState(value);
  }, []);

  const setEditUserCanSkip = useCallback((value: boolean) => {
    if (value) setEditUserCanSkipState(value);
  }, []);

  return {
    currentView,
    setCurrentView,
    currentData,
    setCurrentData,
    dataValidation,
    setDataValidation,
    instructors,
    termPartsByCategory,
    setTermPartsByCategory,
    setInstructors,
    classrooms,
    setClassrooms,
    globalErrors,
    setGlobalErrors,
    validationErrors,
    setValidationErrors,
    customValidations,
    setCustomValidations,
    intervalValidationErrors,
    setIntervalValidationErrors,
    setEditUserCanSkip,
    editUserCanSkip,
  };
};
