import React, { useContext, useEffect } from "react";
import { isEmpty, last } from "ramda";
import { Link, useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import { Icon, Loading } from "@foris/avocado-ui";
import Layout from "@common/layout/Layout";
import { IParams } from "@models/IParams";
import { Context } from "../../context/GroupsManagerContext";
import { Types as NavigationTypes } from "../../context/navigation.reducer";
import { useGetGroupsChanges } from "../../hooks/useGetGroupChanges";
import ChangeRequestsTable from "../../components/ChangeRequestsTable/ChangeRequestsTable";
import css from "./groupChangeHistory.module.scss";

const GroupChangeHistory = () => {
  const { origin, scenario, workspace, id }: IParams = useParams();
  const [groupChanges, loading, pageInfo, getGroupChanges] = useGetGroupsChanges(scenario, origin);
  const { state, dispatch } = useContext(Context);
  const history = useHistory();
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const requestGroupChanges = (page: number) => {
    getGroupChanges(page, false, id, state?.tableFilters?.orderBy, state?.tableFilters?.searchBy);
  };

  useEffect(() => {
    requestGroupChanges(1);
  }, []);

  const navigateBackwards = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch({ type: NavigationTypes.SetForceFetching, payload: true });

    if (isEmpty(state?.navigation?.locationStack)) {
      history.push(`/editor/groups-manager/${contextUrl}`);
    } else {
      dispatch({ type: NavigationTypes.PopFromLocationStack });
      const url = last(state?.navigation?.locationStack ?? [])?.view;
      history.push(url);
    }
  };

  return (
    <Layout contextSearch>
      {loading && <Loading />}
      {!loading && (
        <div className={cx(css.main)}>
          <header className={css.header}>
            <Link
              to={{
                pathname: last(state?.navigation?.locationStack ?? [])?.view,
              }}
              onClick={navigateBackwards}
            >
              <Icon className={css.header__icon} icon="chevron-left" size={25} />
              <strong className={css.header__text}>Historial de grupo {id}</strong>
            </Link>
          </header>
          <ChangeRequestsTable
            groupId={id}
            scenarioId={scenario}
            origin={origin}
            groupChanges={groupChanges}
            pageInfo={pageInfo}
            requestGroupCapacityChangeDetails={requestGroupChanges}
          />
        </div>
      )}
    </Layout>
  );
};

export default GroupChangeHistory;
