import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Instructor } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error, setPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "docente");
  const route = `/scheduler/editor/instructor/${workspace}/${scenario}/${origin}`;

  const columns = [
    {
      header: getHeadCell("Código"),
      renderer: ({ code, id }: Instructor) => getLinkCell(route, id, code),
    },
    {
      header: getHeadCell("Nombre"),
      renderer: ({ name, id }: Instructor) => getLinkCell(route, id, name),
      styles: {
        desktop: {
          minWidth: 260,
        },
      },
    },
    {
      header: getHeadCell("Prioridad"),
      renderer: ({ priority }: Instructor) => getColumnCell(priority),
    },
    {
      header: getHeadCell("Bloques Asignados (% semanal)"),
      renderer: ({ assignment }: Instructor) => getColumnCell(assignment?.averagePerIntervalBlocks),
      styles: {
        desktop: {
          minWidth: 180,
        },
      },
    },
    {
      header: getHeadCell("Total Bloques Asignados"),
      renderer: ({ assignment }: Instructor) => getColumnCell(assignment?.totalAssignedBlocks),
    },
    {
      header: getHeadCell("Ocupación (%)"),
      renderer: ({ assignment }: Instructor) => getColumnCell(assignment?.usagePercentage),
    },
  ];

  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension="docente"
      advanceLink={`/editor/advanceSearch/instructor/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
