import gql from "graphql-tag";

export const authMutation = gql`
  mutation authUser($token: String!) {
    auth(token: $token) {
      id
      name
      context {
        workspaceId
        scenarioId
        originId
      }
    }
  }
`;
