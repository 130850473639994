import React from "react";
import moment from "moment";
import {
  IntervalValidationErrors,
  EditorValidationError,
  CustomValidation,
} from "./../../../../../models/ISchema";
import css from "./validation.module.scss";

interface IWeekColumnProps {
  currentWeeks?: any;
  column: EditorValidationError | CustomValidation | IntervalValidationErrors;
}

const WeekColumn: React.FC<IWeekColumnProps> = (props: IWeekColumnProps) => {
  const { column, currentWeeks } = props;

  let interval = 0;
  let numberWeek = "1";
  let rangeWeek = "";

  if (column?.__typename !== "IntervalValidationErrors") {
    return <span className={css.numberWeek}>Todas las semanas</span>;
  } else {
    let matchWeek = { value: "" };
    if (currentWeeks) {
      matchWeek = currentWeeks.find((item: any) => item.id === column.interval.id);
    }
    if (column.interval.id) {
      const dateStart = column.interval.startingDate;
      const dateEnd = column.interval.endingDate;
      interval = column.interval.id ? column.interval.id.length : 0;
      numberWeek = interval === 1 ? "0" + column.interval.id : column.interval.id;
      rangeWeek = `(${moment(dateStart).format("DD/MM/YYYY")} - ${moment(dateEnd).format(
        "DD/MM/YYYY",
      )})`;
    }
    return (
      <div>
        <span className={css.numberWeek}>{`Semana ${
          matchWeek && matchWeek.value ? matchWeek.value : numberWeek
        } `}</span>
        <span className="rangeWeek">{rangeWeek}</span>
      </div>
    );
  }
};

export default WeekColumn;
