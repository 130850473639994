export type MouseflowTags =
  | "action_add_group"
  | "action_edit_multiple"
  | "action_edit_single"
  | "action_groups_manager"
  | "action_groups_manager_change_history_report"
  | "action_groups_manager_enrollments"
  | "action_groups_manager_enrollments_report"
  | "action_groups_manager_groups_report"
  | "action_groups_manager_main_change_history"
  | "action_groups_manager_subgroups"
  | "action_groups_manager_subgroups_report"
  | "action_open_header";

export const actionTag = (tag: MouseflowTags, isMouseflowEnabled: boolean) => {
  if (isMouseflowEnabled) {
    window["_mfq"] = window["_mfq"] || [];
    window["_mfq"].push(["tag", tag]);
  }
};
