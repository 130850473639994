import {
  all,
  append,
  clone,
  equals,
  filter,
  has,
  isEmpty,
  keys,
  lensPath,
  lensProp,
  map,
  not,
  omit,
  over,
  pipe,
  prop,
  reduce,
  set,
  view,
} from "ramda";
import { AdaptedGroup, GroupEdition } from "../models";
import validateGroupEdition from "../utils/validateGroupEdition";
import { EditionsReducerType } from "./types";

/**
 * (private) Remove the empty `byGroupId`s.
 *
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const _removeEmptyGroupEditions = (state: EditionsReducerType): EditionsReducerType => {
  const groupIds = keys(state?.byGroupId);
  const groupIdsToOmit = reduce(
    (acc, groupId) => {
      return isEmpty(state?.byGroupId[groupId]) ? append(groupId, acc) : acc;
    },
    [],
    groupIds,
  );
  return over(lensProp("byGroupId"), omit(groupIdsToOmit), state);
};

/**
 * Add the given `groupId` in the groupToEditIds
 *
 * @param group AdaptedGroup
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const addGroupToEdit = (
  group: AdaptedGroup,
  state: EditionsReducerType,
): EditionsReducerType => set(lensPath(["groupsToEditById", group?.id]), group, state);

/**
 * Add the given `capacity` to the given `groupId` updating the context's
 * `pendingCapacitiesByGroupId`
 *
 * @param groupId string
 * @param capacity number
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const addPendingCapacity = (
  groupId: string,
  capacity: number,
  state: EditionsReducerType,
): EditionsReducerType =>
  set(
    lensPath<EditionsReducerType>(["pendingCapacitiesByGroupId", groupId]),
    capacity,
    state,
  );

/**
 * Clean any edition made.
 *
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const clean: (state: EditionsReducerType) => EditionsReducerType = pipe(
  set(lensProp<EditionsReducerType>("byGroupId"), {}),
  set(lensProp<EditionsReducerType>("errorsByGroupId"), {}),
  set(lensProp<EditionsReducerType>("groupsToEditById"), {}),
);

/**
 * Set the `capacity` of the `groupToEditIds` to the given `capacity`.
 *
 * The `byGroupId` should never have its `capacity` as equals to the
 * "original" group `capacity`. If this is the case, the `capacity` key will be
 * removed from the `byGroupId` object.
 *
 * @param capacity number
 * @param groupsById Record<string, AdaptedGroup>
 * @param groupIdsToExclude Set<string>
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const editGroupsToEditCapacities = (
  capacity: number,
  groupsById: Record<string, AdaptedGroup>,
  groupIdsToExclude: Set<string>,
  state: EditionsReducerType,
): EditionsReducerType => {
  let newState = clone(state);

  const groupIds = omit(Array.from(groupIdsToExclude), state?.groupsToEditById);
  for (const groupId of keys(groupIds)) {
    const group = groupsById[groupId];
    if (group?.capacity === capacity) {
      delete newState?.byGroupId[groupId]?.capacity;
    } else {
      newState = set(lensPath(["byGroupId", groupId, "capacity"]), capacity, newState);
    }
  }

  return _removeEmptyGroupEditions(newState);
};

/**
 * Edit the `visibleForEnrollment` of the `groupToEditIds`.
 *
 * If all the groups to edit have the same visibility, set the new visibility as the opposite.
 * Otherwise, set the new visibility as `true` (or "visible").
 *
 * The `byGroupId` should never have its `visibility` as equals to the
 * "original" group `visibility`. If this is the case, the `capacity` key will be
 * removed from the `byGroupId` object.
 *
 * @param groupsById Record<string, AdaptedGroup>
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const editGroupsToEditVisibilities = (
  groupsById: Record<string, AdaptedGroup>,
  state: EditionsReducerType,
) => {
  const groupsToEditById = keys(state?.groupsToEditById);
  const groupVisibility = (group: AdaptedGroup) =>
    state?.byGroupId[group?.id]?.visibleForEnrollment ?? group?.visibleForEnrollment;
  // `true` if all the given groups (considering the current editions)
  // have `visibleForEnrollment = true`, `false` otherwise
  const newVisibility = pipe(
    map((groupId: string) => groupsById[groupId]),
    pipe(map(groupVisibility), all(equals(true))),
    not,
  )(groupsToEditById);

  let newState = clone(state);

  for (const groupId of groupsToEditById) {
    const group = groupsById[groupId];
    if (group?.visibleForEnrollment === newVisibility) {
      delete newState?.byGroupId[groupId]?.visibleForEnrollment;
    } else {
      newState = set(
        lensPath(["byGroupId", groupId, "visibleForEnrollment"]),
        newVisibility,
        newState,
      );
    }
  }

  return _removeEmptyGroupEditions(newState);
};

/**
 * Remove the given `group` from the `groupToEditIds`
 *
 * @param group AdaptedGroup
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const removeGroupToEdit = (
  group: AdaptedGroup,
  state: EditionsReducerType,
): EditionsReducerType => over(lensProp("groupsToEditById"), omit([group?.id]), state);

/**
 * Remove the given `groupId` from the `pendingCapacitiesByGroupId`
 *
 * @param groupId string
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const removePendingCapacity = (
  groupId: string,
  state: EditionsReducerType,
): EditionsReducerType => {
  const newState = clone(state);
  delete newState?.pendingCapacitiesByGroupId[groupId];
  return newState;
};

/**
 * Restore the `capacity` of the `groupsToEditById`
 *
 * @param EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const restoreGroupsToEditCapacities = (state: EditionsReducerType): EditionsReducerType => {
  const newState = clone(state);

  for (const groupId of keys(state?.byGroupId)) {
    if (has(groupId, state?.groupsToEditById)) {
      delete newState?.byGroupId[groupId]?.capacity;
    }
  }

  return _removeEmptyGroupEditions(newState);
};

/**
 * Given a list of `AdaptedGroup`s, remove the groups in `pendingCapacitiesByGroupId`
 * that are editable, meaning, groups which have its column `isEditable.allowed` = true.
 *
 * @param subgroups - AdaptedGroup[]
 *
 * @return EditionsReducerType
 */
export const sanitizePendingEditions = (
  subgroups: AdaptedGroup[],
  state: EditionsReducerType,
): EditionsReducerType => {
  const editableGroupIds = pipe(
    filter(view(lensPath(["isEditable", "allowed"]))),
    map(prop("id")),
  )(subgroups ?? []);
  return over(lensProp("pendingCapacitiesByGroupId"), omit(editableGroupIds), state);
};

/**
 * Set the given set of groupIds as the new groups to edit
 *
 * @param Record<AdaptedGroup['id'], AdaptedGroup>
 * @param EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const setGroupsToEdit: (
  groupsToEditById: Record<AdaptedGroup["id"], AdaptedGroup>,
  state: EditionsReducerType,
) => EditionsReducerType = set(lensProp<EditionsReducerType>("groupsToEditById"));

/**
 * Set the validation errors for the given group
 *
 * @param payload { group: AdaptedGroup, editions: GroupEdition, isSubgroup: boolean }
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const updateErrorsByGroupId = (
  {
    group,
    editions,
    isSubgroup,
  }: { group: AdaptedGroup; editions: GroupEdition; isSubgroup: boolean },
  state: EditionsReducerType,
) => {
  const newState = set(
    lensPath<EditionsReducerType>(["errorsByGroupId", group?.id]),
    validateGroupEdition(group, editions, isSubgroup),
    state,
  );

  // if no error was triggered, clean the group from the errors
  if (isEmpty(newState?.errorsByGroupId[group?.id])) {
    delete newState?.errorsByGroupId[group?.id];
  }

  return newState;
};

/**
 * Update the `capacity` of a group.
 * If both the `capacity` and the `visibleForEnrollment` field are the same as
 * the current group on the DB, clean the group edition from the context.
 *
 * @param payload { groupId: AdaptedGroup["id"], value: number, originalValue: number }
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const updateGroupCapacity = (
  {
    groupId,
    value,
    originalValue,
  }: {
    groupId: AdaptedGroup["id"];
    value: string;
    originalValue: number;
  },
  state: EditionsReducerType,
) => {
  const newState = set(lensPath(["byGroupId", groupId, "capacity"]), parseInt(value), state);

  if (parseInt(value) === originalValue) {
    delete newState?.byGroupId[groupId]?.capacity;
  }

  return _removeEmptyGroupEditions(newState);
};

/**
 * Update the `visibleForEnrollment` of a group.
 * If both the `capacity` and the `visibleForEnrollment` field are the same as
 * the current group on the DB, clean the group edition from the context.
 *
 * @param payload { groupId: string, value: boolean, originalValue: boolean }
 * @param state EditionsReducerType
 *
 * @return EditionsReducerType
 */
export const updateGroupVisibility = (
  {
    groupId,
    value,
    originalValue,
  }: {
    groupId: string;
    value: boolean;
    originalValue: boolean;
  },
  state: EditionsReducerType,
) => {
  const newState = set(lensPath(["byGroupId", groupId, "visibleForEnrollment"]), value, state);

  if (value === originalValue) {
    delete newState?.byGroupId[groupId]?.visibleForEnrollment;
  }

  return _removeEmptyGroupEditions(newState);
};
