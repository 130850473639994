import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { courseQuery } from "../graphql/course.queries";
import { Course, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorCourse, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const CourseSelect: React.FC<ISelectorCourse> = (props: ISelectorCourse) => {
  const client = useApolloClient();
  const { onCallback, curriculum, level, program, label, className, filterBy, placeholder } = props;
  const { scenario }: IParams = useParams();
  const [optionsCourse, setOptionsCourses] = useState([]);
  const [valueCourse, setValueCourse] = useState(null);
  let filerCourse = null;
  switch (filterBy) {
    case "program":
      filerCourse = program;
      break;
    case "curriculum":
      filerCourse = curriculum;
      break;
    case "level":
      filerCourse = level;
      break;
  }
  const [prevFilter, setPrevFilter] = useState(filerCourse);
  const [disabled, setDisabled] = useState(false);

  // variables options
  const objCurriculum = curriculum
    ? {
        curriculumId: {
          eq: curriculum,
        },
      }
    : {};

  const objLevel = level ? { level: { eq: level } } : {};

  const objProgram = program
    ? {
        program: {
          id: {
            eq: program,
          },
        },
      }
    : {};

  const variables = {
    ...objProgram,
    ...objCurriculum,
    ...objLevel,
  };

  const variablesCourse = {
    query: courseQuery,
    variables: {
      scenarioId: scenario,
      filter: {
        fields: {
          ...variables,
        },
      },
    },
  };

  const setCourseOptions = useCallback(async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optCourses = [];
      if (dataQuery.courses.items.length > 0) {
        dataQuery.courses.items.forEach((course: Course) => {
          optCourses.push({ label: `${course.code} - ${course.name}`, value: course.id });
        });
      }
      setOptionsCourses(optCourses);
    }
  }, []);

  const getCourseOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesCourse);
      await setCourseOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [client, variablesCourse, setCourseOptions]);

  const getCacheCourseOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesCourse);
      await setCourseOptions(data);
    } catch (error) {
      getCourseOptions();
    }
  }, [client, variablesCourse, getCourseOptions, setCourseOptions]);

  const clearSelect = useCallback(() => {
    setPrevFilter(null);
    setValueCourse("");
    setDisabled(true);
  }, []);

  const getDataByFilter = useCallback(
    (filter: any) => {
      if (filter && filter !== prevFilter) {
        setPrevFilter(filter);
        getCacheCourseOptions();
        setDisabled(false);
      }
      if (!filter) clearSelect();
    },
    [getCacheCourseOptions, prevFilter, clearSelect],
  );

  useEffect(() => {
    switch (filterBy) {
      case "program":
        getDataByFilter(program);
        break;
      case "curriculum":
        getDataByFilter(curriculum);
        break;
      case "level":
        getDataByFilter(level);
        break;
      default:
        clearSelect();
    }
  }, [filterBy, level, curriculum, program, prevFilter, getDataByFilter, clearSelect]);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Asignatura</label>}
      <Select
        options={optionsCourse}
        value={valueCourse}
        placeholder={placeholder ? placeholder : label ? "Seleccionar" : "Asignatura"}
        isClearable
        isDisabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValueCourse(value);
            onCallback(value);
          } else {
            setValueCourse("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default CourseSelect;
