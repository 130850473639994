import gql from "graphql-tag";

export const classRoomSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: ClassroomFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createClassroomFilter(filter: $filter) {
        id
        classrooms(pagination: $pagination) {
          items {
            code
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
          }
        }
      }
    }
  }
`;

export const classRoomSearchQuery = gql`
  query filter($scenarioId: ID!, $filterId: ID, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      classrooms(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          name
          capacity
          allowedOverflow
          floor
          building {
            id
            name
            code
            __typename
            campus {
              id
              code
              name
              __typename
            }
          }
          classroomType {
            id
            code
            name
            __typename
          }
          assignment {
            usagePercentage
            chairsOccupationPercentage
          }
          __typename
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
