import React, { useState, useContext, useEffect, useMemo } from "react";
import * as R from "ramda";
import { Checkbox, Select, Input, RadioButton, CardState, Icon } from "@foris/avocado-ui";
import { useWarnings } from "../../hooks/useWarnings";
import cx from "classnames";
import { AppContext } from "../../context/EditSessionsContext";
import { EditedSession, Types, BlockRanges, FormPageType } from "../../context/formData.reducer";
import FormItem from "../FormEdit/FormItem";
import { Session } from "@models/ISchema";
import { Option } from "@modules/booking/context/types";
import css from "./blockRange.module.scss";

const getHourLabel = (date: string) => {
  const [hour, mins] = date.split(":");
  return `${hour}:${mins}`;
};

interface BlockRangeProps {
  disabled?: boolean;
}

const BlockRange = ({ disabled = false }: BlockRangeProps) => {
  const { state, dispatch } = useContext(AppContext);
  const [checkHours, setCheckHours] = useState(true);
  const [warnings, setWarnings] = useWarnings(
    {
      blockTypeSelection: {
        message: "Debes seleccionar al menos una opción",
        active: false,
        predicate: (form: FormPageType) => {
          return !form?.editedSessions?.blocks?.selected;
        },
      },
      mandatoryBlocks: {
        message: "Debes ingresar un valor de bloques numérico y mayor que cero.",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return selection === "blocks" && !blocks?.blocks;
        },
      },
      timeSelection: {
        message: "Debes seleccionar hora de inicio y hora de fin.",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return selection === "hours" && (!blocks?.endTime || !blocks?.startTime);
        },
      },
      timeConsistency: {
        message: "La hora de término debe ser mayor que la hora de inicio.",
        active: false,
        predicate: (form: FormPageType) => {
          const selection = form?.editedSessions?.blocks?.selected;
          const blocks = form?.editedSessions?.blocks;
          return (
            selection === "hours" &&
            !!blocks?.endTime &&
            !!blocks?.startTime &&
            blocks?.endTime <= blocks?.startTime
          );
        },
      },
    },
    form =>
      (!form?.savedSessionsToCreateIds && !form?.selectedSessions?.length) ||
      !form?.assignmentEdited?.blocks,
  );

  /**
   * Handle warnings evaluations
   */
  useEffect(() => {
    setWarnings(state?.form);
  }, [state?.form?.assignmentEdited?.blocks, state?.form?.editedSessions?.blocks]);

  const errors = state?.form?.errors;
  const editedSessions = state?.form?.editedSessions;
  const selectedSessions = state?.form?.selectedSessions;
  const blocksCount =
    selectedSessions.length !== 1
      ? null
      : (selectedSessions[0] as Session)?.blocksCount
      ? (selectedSessions[0] as Session)?.blocksCount
      : (selectedSessions[0] as EditedSession)?.blocks?.blocks;

  const someDeletedSessionIsSelected = R.pipe(
    R.map(R.propOr("-", "id")),
    R.any(R.flip(R.has)(state?.form?.sessionsToDelete)),
  )(selectedSessions);

  const optionStart = useMemo(() => {
    const selectedOption = (state?.link?.blockRanges?.start ?? [])?.find(
      option => option.value === editedSessions?.blocks?.startTime,
    );

    if (selectedOption?.value) return selectedOption;

    if (editedSessions?.blocks?.startTime) {
      return {
        label: getHourLabel(editedSessions?.blocks?.startTime),
        value: editedSessions?.blocks?.startTime,
      };
    }

    return null;
  }, [state?.link?.blockRanges?.start, editedSessions?.blocks?.startTime]);

  const optionEnd = useMemo(() => {
    const selectedOption = (state?.link?.blockRanges?.end ?? [])?.find(
      option => option.value === editedSessions?.blocks?.endTime,
    );

    if (selectedOption?.value) return selectedOption;

    if (editedSessions?.blocks?.endTime) {
      return {
        label: getHourLabel(editedSessions?.blocks?.endTime),
        value: editedSessions?.blocks?.endTime,
      };
    }

    return null;
  }, [state?.link?.blockRanges?.end, editedSessions?.blocks?.endTime]);

  const sortTimeOptions = (times: Option[] = []): Option[] =>
    R.sort((a, b) => {
      const [[hour1, mins1], [hour2, mins2]] = R.ap(
        [R.pipe(R.prop("label"), R.split(":"), R.map(parseInt))],
        [a, b],
      );

      if (hour1 < hour2 || (hour1 === hour2 && mins1 < mins2)) return -1;
      if (hour1 > hour2 || (hour1 === hour2 && mins1 > mins2)) return 1;
      return 0;
    }, times);

  const changeWithBlockRange = (start: string) => {
    const blocks = state?.link?.blockRanges;
    let indexStart = blocks?.end.findIndex(item => item.value === start);
    if (indexStart === -1) {
      indexStart =
        blocks?.end.findIndex(item => {
          const [hour1, mins1] = start?.split(":")?.map(v => parseInt(v));
          const [hour2, mins2] = item.value?.split(":")?.map(v => parseInt(v));
          return hour2 > hour1 || (hour2 === hour1 && mins2 > mins1);
        }) - 1;
    }
    const endValue = blocks?.end[indexStart + blocksCount]?.value;
    return endValue;
  };

  const clearBlockErrors = () => {
    dispatch({
      type: Types.FormError,
      payload: R.reject(R.propEq("type", "BlockRanges"), errors),
    });
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(() => {
    if (checkHours && editedSessions?.blocks?.startTime) {
      const startTime = editedSessions?.blocks?.startTime;
      const hourBlocks = checkHours && blocksCount ? changeWithBlockRange(startTime) : null;
      const endHour = hourBlocks || editedSessions?.blocks?.endTime;
      clearBlockErrors();
      dispatch({
        type: Types.BlocksEditedSessions,
        payload: {
          blocks: R.pipe(
            R.set(R.lensProp<BlockRanges>("startTime"), startTime),
            R.set(R.lensProp<BlockRanges>("endTime"), endHour),
          )(editedSessions?.blocks),
        },
      });
    }
  }, [checkHours]);

  useEffect(() => {
    if (R.not(R.isEmpty(errors ?? [])) && R.any(R.propEq("type", "BlockRanges"), errors)) {
      scrollToTop();
    }
  });

  return (
    <FormItem title="Horario" type="blocks">
      <section className={css.blockRange}>
        <div
          className={cx(css.options, "container-row", "row--noWrap", css.blockRange_bottomBorder)}
        >
          <RadioButton
            checked={editedSessions?.blocks?.selected === "hours"}
            disabled={someDeletedSessionIsSelected || disabled}
            labelRight="Con horario"
            name="isRequiere"
            className={cx(css.options_item, css.options_item__radioButton)}
            onChange={() => {
              dispatch({
                type: Types.BlocksEditedSessions,
                payload: {
                  blocks: R.set(R.lensProp("selected"), "hours", editedSessions?.blocks),
                },
              });
              clearBlockErrors();
            }}
          />
          <RadioButton
            checked={editedSessions?.blocks?.selected === "blocks"}
            disabled={someDeletedSessionIsSelected || disabled}
            labelRight="No requiere horario"
            name="isRequiere"
            className={cx(css.options_item, css.options_item__radioButton)}
            onChange={() => {
              dispatch({
                type: Types.BlocksEditedSessions,
                payload: {
                  blocks: R.set(R.lensProp("selected"), "blocks", editedSessions?.blocks),
                },
              });
              clearBlockErrors();
            }}
          />
        </div>
        {editedSessions?.blocks?.selected === "hours" && (
          <>
            {blocksCount && (
              <Checkbox
                disabled={someDeletedSessionIsSelected || disabled}
                checked={checkHours}
                labelRight={`Mantener duración de la sesión (${blocksCount} bloques)`}
                className={css.options}
                onChange={() => setCheckHours(!checkHours)}
              />
            )}
            <div className={cx(css.options, "container-row")}>
              <div className={cx(css.options, "container-row")}>
                <Select
                  isDisabled={someDeletedSessionIsSelected || disabled}
                  value={optionStart || null}
                  placeholder="hh:mm"
                  label="Hora inicio"
                  className={cx(css.options_item, "col_2")}
                  options={sortTimeOptions(state?.link?.blockRanges?.start)}
                  isClearable={false}
                  onChange={value => {
                    const hourBlocks =
                      checkHours && blocksCount ? changeWithBlockRange(value?.value) : null;
                    const endHour = hourBlocks || editedSessions?.blocks?.endTime;
                    clearBlockErrors();
                    dispatch({
                      type: Types.BlocksEditedSessions,
                      payload: {
                        blocks: R.pipe(
                          R.set(R.lensProp<BlockRanges>("startTime"), value?.value),
                          R.set(R.lensProp<BlockRanges>("endTime"), endHour),
                        )(editedSessions?.blocks),
                      },
                    });
                  }}
                />
                <Icon icon={"minus"} size={18} className={cx(css.blockRange_dashIcon)} />
                <Select
                  value={optionEnd || null}
                  placeholder="hh:mm"
                  label="Hora término"
                  className={cx(css.options_item, "col_2")}
                  options={R.pipe(
                    // filter times that are less than the startTime selected
                    R.reject(({ label }: Option) => {
                      if (!state?.form?.editedSessions?.blocks?.startTime) return false;
                      const [
                        startHour,
                        startMins,
                      ] = state?.form?.editedSessions?.blocks?.startTime
                        ?.split(":")
                        ?.map(val => parseInt(val));
                      const [endHour, endMins] = label.split(":")?.map(val => parseInt(val));
                      return R.or(
                        R.lte(endHour, startHour),
                        R.and(R.equals(startHour, endHour), R.lte(endMins, startMins)),
                      );
                    }),
                    sortTimeOptions,
                  )(state?.link?.blockRanges?.end)}
                  isClearable={false}
                  isDisabled={
                    someDeletedSessionIsSelected ||
                    (checkHours && blocksCount ? true : false) ||
                    disabled
                  }
                  onChange={value => {
                    clearBlockErrors();
                    dispatch({
                      type: Types.BlocksEditedSessions,
                      payload: {
                        blocks: R.set(R.lensProp("endTime"), value?.value, editedSessions?.blocks),
                      },
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
        {editedSessions?.blocks?.selected === "blocks" && (
          <Input
            className={css.blockRange_input}
            disabled={someDeletedSessionIsSelected || disabled}
            label="Bloques"
            type="number"
            value={editedSessions?.blocks?.blocks || ""}
            onChange={value => {
              dispatch({
                type: Types.BlocksEditedSessions,
                payload: {
                  blocks: R.set(
                    R.lensProp("blocks"),
                    parseInt(value?.target?.value),
                    editedSessions?.blocks,
                  ),
                },
              });
            }}
          />
        )}
        {warnings.map(warning => (
          <div key={warning}>
            <CardState typeCard="warning" key={warning} title="Error de validación">
              <p className={css.errorText}>{warning}</p>
            </CardState>
          </div>
        ))}
      </section>
    </FormItem>
  );
};

export default BlockRange;
