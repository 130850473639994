import React, { useContext, useState, useEffect } from "react";
import { isEmpty, uniq } from "ramda";
import { useMutation } from "react-apollo";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import cx from "classnames";
import { Icon, Loading, IWeeklyItem } from "@foris/avocado-ui";
import { ClassroomBooking, Session } from "@models/ISchema";
import { IParams } from "@models/IParams";
import RemoveBookingModal from "../RemoveBookingModal/RemoveBookingModal";
import { BookingContext } from "../../context/BookingContext";
import useGetIntervals from "../../hooks/useIntervals";
import { classroomBookingChangeStatusMutation } from "../../graphql/classroomBookingChangeStatus.mutation";
import * as utils from "../../utils";
import { Types } from "../../context/search.reducer";
import { Types as RequestTypes } from "../../context/request.reducer";
import { getChangeStatusFields } from "@modules/booking/pages/BookingList/bookingFilterVariables";
import Detail from "./Detail";
import Resources from "./Resources";
import State from "./State";
import css from "./cardDetail.module.scss";

interface CardDetailProps {
  booking: ClassroomBooking;
  campus: string;
}

interface ISession {
  day: string;
  time: string;
}

const CardDetail: React.FC<CardDetailProps> = (props: CardDetailProps) => {
  const { booking, campus } = props;
  const { scenario, workspace, origin, id }: IParams = useParams();
  const { state, dispatch } = useContext(BookingContext);
  const [intervals, getIntervals] = useGetIntervals();
  const [activeModal, setActiveModal] = useState(false);
  const [statusConfirmation, setStatusConfirmation] = useState<string>(null);
  const [editingState, setEditingState] = useState<boolean>(false);
  const [editingDetails, setEditingDetails] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [classroomBookingChangeStatus] = useMutation(classroomBookingChangeStatusMutation, {});

  const sessions: ISession[] = [];
  const weeks: string[] = [];
  const days: any[] = [];
  const IS_RECURRENT = booking?.isRecurrent;
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const pathBookings = `/booking/list/${contextUrl}?campus=${campus}`;

  const handleChangeStatus = async (status: string, comment: any) => {
    setLoading(true);
    try {
      await classroomBookingChangeStatus({
        variables: {
          originId: origin,
          scenarioId: scenario,
          input: getChangeStatusFields({ bookingIds: [id], status, comment }),
        },
      });

      window.location.reload();
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getDataWeek = (selectedWeeks: string[]) => {
    const dataIntervals: IWeeklyItem[] = state?.search?.intervals?.map(week => {
      const findId = selectedWeeks?.find(value => value === week?.id);
      return {
        id: week.id,
        label: week.value,
        disabled: false,
        state: findId ? "active" : null,
        tooltip: {
          label: `Sem ${week.value} ${dayjs(week.startingDate)?.format("DD.MM.YYYY")} - ${dayjs(
            week.endingDate,
          )?.format("DD.MM.YYYY")}`,
        },
        onClick: () => null,
      };
    });
    return dataIntervals;
  };

  const uniqueValues = (session: Session, type: "week" | "day") => {
    const sessionBlocks = session?.events.map(event => event.block);
    switch (type) {
      case "day":
        const uniqueDay = [...Array.from(new Set(sessionBlocks.map(item => item.day)))];
        days.push({
          day: uniqueDay[0],
          start: dayjs(session.scheduleEvent[0]?.start)?.format("HH:mm"),
          end: dayjs(session.scheduleEvent[0]?.end)?.format("HH:mm"),
        });
        break;
      case "week":
        const sessionIntervals = session?.events.map(event => event.interval);
        const unique = [...Array.from(new Set(sessionIntervals.map(item => item.id)))];
        weeks.push(unique[0]);
        break;
      default:
        return null;
    }
  };

  booking?.sessions &&
    booking?.sessions.forEach(session => {
      uniqueValues(session, "day");
      uniqueValues(session, "week");
      session.scheduleEvent.forEach(event => {
        const day = utils.format.day(event.start);
        const startTime = utils.format.time(event.start);
        const endTime = utils.format.time(event.end);
        sessions.push({ day, time: `${startTime} - ${endTime}` });
      });
    });

  useEffect(() => {
    if (!state?.search?.intervals?.length && !intervals?.data && IS_RECURRENT) {
      getIntervals(scenario);
    }
  });

  useEffect(() => {
    if (isEmpty(booking)) return;
    dispatch({ type: Types.SetCurrentBooking, payload: booking });
    dispatch({ type: RequestTypes.SetEditedBooking, payload: booking });
  }, [booking]);

  if (!booking?.sessions) return <div>No exite reserva</div>;
  if (!state?.search?.intervals?.length && IS_RECURRENT) return null;

  if (loading) {
    return <Loading />;
  }

  return (
    <section className={cx(css.cntBookingDetail, "container-row")}>
      <RemoveBookingModal
        campus={campus}
        open={activeModal}
        onClose={() => {
          setActiveModal(false);
        }}
      />

      <Link to={pathBookings} className={cx(css.cntBookingDetail_link, "col_12")}>
        <Icon icon="chevron-left" size={24} className={css.iconBack} />
        Evento
      </Link>

      <div className={cx("col_8", "col_sm_12")}>
        {/* Booking details */}
        <Detail
          booking={booking}
          editing={editingDetails}
          setEdit={setEditingDetails}
          campus={campus}
        />

        {/* Booking resources */}
        <Resources booking={booking} intervals={getDataWeek(uniq(weeks ?? [])) ?? []} />

        {/* Booking state */}
        <State
          booking={booking}
          editing={editingState}
          onClick={() => setEditingState(true)}
          onConfirmation={setStatusConfirmation}
          onNotification={setNotificationText}
          onCancel={() => setEditingState(false)}
          onSave={handleChangeStatus}
          statusConfirmation={statusConfirmation}
          notificationText={notificationText}
        />
      </div>
    </section>
  );
};

export default CardDetail;
