export const colors = [
  { id: 1, color: "#f69b70" },
  { id: 2, color: "#afcea7" },
  { id: 3, color: "#a5d3d8" },
  { id: 4, color: "#f9cf85" },
  { id: 5, color: "#cca89f" },
  { id: 6, color: "#9db2b4" },
  { id: 7, color: "#cdd89d" },
  { id: 8, color: "#d9c69e" },
  { id: 9, color: "#b79e72" },
  { id: 10, color: "#9dd8b9" },
  { id: 11, color: "#81b1b6" },
  { id: 12, color: "#8ca792" },
  { id: 13, color: "#e0ac52" },
  { id: 14, color: "#a9bdcd" },
  { id: 15, color: "#f7b07d" },
  { id: 16, color: "#b4c8b9" },
  { id: 17, color: "#a49473" },
  { id: 18, color: "#628387" },
  { id: 19, color: "#dd8e53" },
  { id: 20, color: "#a27e76" },
  { id: 21, color: "#6f9e85" },
  { id: 22, color: "#768c9e" },
  { id: 23, color: "#d17b55" },
  { id: 24, color: "#83ab78" },
  { id: 25, color: "#d0c2a7" },
  { id: 26, color: "#a3ac7c" },
];

export const colorsLeague = [
  { id: 0, color: "#f69b70", codeName: "L-A" },
  { id: 1, color: "#afcea7", codeName: "L-B" },
  { id: 2, color: "#a5d3d8", codeName: "L-C" },
  { id: 3, color: "#f9cf85", codeName: "L-D" },
  { id: 4, color: "#cca89f", codeName: "L-E" },
  { id: 5, color: "#9db2b4", codeName: "L-F" },
  { id: 6, color: "#cdd89d", codeName: "L-G" },
  { id: 7, color: "#d9c69e", codeName: "L-H" },
  { id: 8, color: "#b79e72", codeName: "L-I" },
  { id: 9, color: "#9dd8b9", codeName: "L-J" },
  { id: 10, color: "#81b1b6", codeName: "L-K" },
  { id: 11, color: "#8ca792", codeName: "L-L" },
  { id: 12, color: "#e0ac52", codeName: "L-M" },
  { id: 13, color: "#a9bdcd", codeName: "L-N" },
  { id: 14, color: "#f7b07d", codeName: "L-O" },
  { id: 15, color: "#b4c8b9", codeName: "L-P" },
  { id: 16, color: "#a49473", codeName: "L-Q" },
  { id: 17, color: "#628387", codeName: "L-R" },
  { id: 18, color: "#dd8e53", codeName: "L-S" },
  { id: 19, color: "#a27e76", codeName: "L-T" },
  { id: 20, color: "#6f9e85", codeName: "L-U" },
  { id: 21, color: "#768c9e", codeName: "L-V" },
  { id: 22, color: "#d17b55", codeName: "L-W" },
  { id: 23, color: "#83ab78", codeName: "L-X" },
  { id: 24, color: "#d0c2a7", codeName: "L-Y" },
  { id: 25, color: "#a3ac7c", codeName: "L-Z" },
];
