import gql from "graphql-tag";

export const responsabilityMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $input: InstructorResponsibilityInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      instructorResponsibilityUpsert(input: $input) {
        skippedValidations
        commited
        validations {
          ... on InvalidInput {
            code
            meta
          }
        }
      }
    }
  }
`;
