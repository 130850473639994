import gql from "graphql-tag";

export const groupSearchMutation = gql`
  mutation groups($scenarioId: ID!, $originId: ID!, $filter: GroupFilterInput) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createGroupFilter(filter: $filter) {
        id
        groups {
          items {
            id
            code
            label
            clientCode
            visibleForEnrollment
            course {
              code
              name
              curriculum {
                id
                name
                program {
                  id
                  code
                  campus {
                    id
                    code
                    name
                    __typename
                  }
                }
              }
            }
            links {
              id
              vacancies
              code
              bundle {
                id
                code
                label
                name
                capacity
              }
            }
            enrollmentStats {
              id
              enrollmentsCount
              waitingListsCount
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
        }
      }
    }
  }
`;

export const groupSearchQuery = gql`
  query filterGroup($scenarioId: ID!, $filterId: ID!, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      groups(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          label
          name
          capacity
          clientCode
          visibleForEnrollment
          course {
            code
            name
            curriculum {
              id
              name
              program {
                id
                code
                campus {
                  id
                  code
                  name
                  __typename
                }
              }
            }
          }
          links {
            id
            vacancies
            code
            bundle {
              id
              code
              label
              name
              capacity
            }
          }
          enrollmentStats {
            id
            enrollmentsCount
            waitingListsCount
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
