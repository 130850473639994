import gql from "graphql-tag";

export const groupChangesRequest = gql`
  query groupsManagerGroupChanges(
    $scenarioId: ID!
    $originId: ID!
    $filter: GroupsManagerGroupChangesFilterInput!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      groupsManagerGroupChanges(filter: $filter) {
        items {
          id
          source
          username
          group {
            id
            code
            course {
              id
              name
              code
            }
            campus {
              id
              name
              code
            }
          }
          propertyUpdated
          current
          expected
          status
          comment
          createdAt
          updatedAt
          __typename
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
        __typename
      }
      __typename
    }
  }
`;
