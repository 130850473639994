import React from "react";
import { IGroupValue } from "../contextVacancies/ContextVacancies";
import css from "./instructor.module.scss";
import { getTotalBlocksPercent } from "../utils";

export interface ITotalListProps {
  countResponsibility: IGroupValue[];
  indexList: number;
  totalBlocks?: number;
}

const TotalList: React.FC<ITotalListProps> = (props: ITotalListProps) => {
  const { indexList, countResponsibility, totalBlocks } = props;

  const responsibilityTotal = (typeTotal: string) => {
    let total = 0;
    let totalPercent = 0;

    if (countResponsibility) {
      countResponsibility.forEach((group: IGroupValue) => {
        if (typeTotal === "hours") {
          total = total + group.teachingHoursPercentage;
        } else if (typeTotal === "payment") {
          total = total + group.paymentPercentage;
        } else if (typeTotal === "blocks") {
          total += group?.instructor?.totalBlocks;
          totalPercent += getTotalBlocksPercent(group?.instructor?.totalBlocks, totalBlocks);
        }
      });

      if (typeTotal === "blocks") {
        return `${total} (${Math.round(totalPercent) || 0}%)`;
      }

      return total;
    }
  };

  return (
    <ul className={css.totalList} key={`total-${indexList}`}>
      <li className={css.totalList_item}>Total</li>
      <li className={css.totalList_item}>
        <span className={css.totalBlocks}>{responsibilityTotal("blocks")}</span>
      </li>
      <li className={css.totalList_item}>
        <span className={css.totalInput}>{`${responsibilityTotal("hours")}%`}</span>
      </li>
      <li className={css.totalList_item}>
        <span className={css.totalInput}>{`${responsibilityTotal("payment")}%`}</span>
      </li>
    </ul>
  );
};

export default TotalList;
