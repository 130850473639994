import React, { createContext, useReducer, Dispatch } from "react";
import { PageActions, pageReducer } from "./page.reducer";
import { DataActions, dataReducer } from "./data.reducer";
import { DataReducerType, PageReducerType } from "./types";
import { Population } from "@models/ISchema";

export type InitialStateType = {
  data: DataReducerType;
  page: PageReducerType;
};

export const initialState: InitialStateType = {
  data: {
    link: null,
    assignments: {},
    creations: {},
    deletions: {},
    populations: [] as Population[],
  },
  page: {
    active: "EDITION",
    loading: true,
  },
};

const Context = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<DataActions | PageActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = ({ data, page }: InitialStateType, action: any) => {
  return {
    data: dataReducer(data, action),
    page: pageReducer(page, action),
  };
};

const ContextProvider: React.FC<{ children?: React.ReactNode | undefined }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export { ContextProvider, Context };
