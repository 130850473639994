import React from "react";
import { Link, useParams } from "react-router-dom";
import cx from "classnames";
import { ILink, ISection, ILeague } from "../contextVacancies/ContextVacancies";
import { IParams } from "../../../models/IParams";
import css from "./links.module.scss";

export interface ILinkStructureProps {
  item: ILeague;
  index: number;
}
const LinkStructure: React.FC<ILinkStructureProps> = (props: ILinkStructureProps) => {
  const { item, index } = props;
  const { origin, scenario, workspace }: IParams = useParams();

  const compareLinks = (a: ILink, b: ILink) => {
    let comparison = 0;
    if (a.codeName > b.codeName) comparison = 1;
    else if (a.codeName < b.codeName) comparison = -1;
    return comparison;
  };

  const compareSections = (a: ISection, b: ISection) => {
    let comparison = 0;
    if (a.typeComponent > b.typeComponent) comparison = 1;
    else if (a.typeComponent < b.typeComponent) comparison = -1;
    return comparison;
  };

  return (
    <section className={cx(css.cntEdit)} key={index}>
      <ul className={cx(css.headerList, "container-row")} key={`header-list-${index}`}>
        <li className={cx(css.headerList_item)}>Componente</li>
        <li className={cx(css.headerList_item)}>Sección</li>
        <li className={cx(css.headerList_item)}>Vacantes</li>
        <li className={cx(css.headerList_item)}>Compartida</li>
      </ul>
      {item.sections.sort(compareSections).map((section: ISection, indexGroup: number) => {
        const orderLinks: Array<ILink> = section.link.sort(compareLinks);
        return (
          <ul className={cx(css.infoList, "container-row")} key={indexGroup}>
            <li className={cx(css.infoList_item)}>{section?.typeComponent}</li>
            <li className={cx(css.infoList_item)}>
              <Link
                to={`/scheduler/editor/section/${workspace}/${scenario}/${origin}/${section.sectionId}`}
                className={css.link}
              >
                {section.sectionId}
              </Link>
            </li>
            <li className={cx(css.infoList_item)}>{section.vacancies}</li>
            <li className={cx(css.infoList_item, css.infoList_item__share)}>
              {orderLinks.map((bundle: ILink) => (
                <div key={bundle.code} className={cx(css.shareCircle)}>
                  <div
                    className={cx(css.circleList)}
                    style={{
                      background: bundle.color,
                    }}
                  />
                  <span
                    style={{
                      color: bundle.color,
                    }}
                  >
                    {bundle.codeName}
                  </span>
                </div>
              ))}
            </li>
          </ul>
        );
      })}
    </section>
  );
};

export default LinkStructure;
