import { useState } from "react";
import { map, pipe, last, applySpec, view, lensPath, toPairs, prop } from "ramda";
import { useMutation } from "react-apollo";
import { PackageLinkAssignmentResult } from "@models/ISchema";
import { packageLinkAssignment } from "../graphql/packageLinkAssignment.mutation";
import { DataReducerType } from "../context/types";

interface Props {
  origin: string;
  scenario: string;
}

type ReturnType = [
  { result: any; error: boolean; isLoading: boolean },
  (data: DataReducerType, dryRun?: boolean, skipValidations?: boolean) => Promise<void>,
];

const usePackageLinkAssignment = (props: Props): ReturnType => {
  const { scenario, origin } = props;
  const [packageLinkAssignmentMutation] = useMutation(packageLinkAssignment, {});
  const [result, setResult] = useState<PackageLinkAssignmentResult>({});
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @param assignments - LinkPackageObj
   * @return LinkPackagePair
   */
  const buildAssignments = pipe(
    toPairs,
    map(
      pipe(
        last,
        applySpec({
          linkId: prop("linkId"),
          packageId: view(lensPath(["package", "id"])),
        }),
      ),
    ),
  );

  /**
   * @param deletions - LinkPackageObj
   * @return LinkPackagePair
   */
  const buildDeletions = pipe(
    toPairs,
    map(
      pipe(
        last,
        applySpec({
          linkId: prop("linkId"),
          packageId: view(lensPath(["package", "id"])),
        }),
      ),
    ),
  );

  const setInput = (
    { assignments, deletions }: DataReducerType,
    dryRun: boolean,
    skipValidations: boolean,
  ) => ({
    dryRun,
    skipValidations,
    changesets: {
      creates: buildAssignments(assignments),
      deletes: buildDeletions(deletions),
    },
  });

  const submitPackageLinkAssignment = async (
    data: DataReducerType,
    dryRun = true,
    skipValidations = true,
  ) => {
    try {
      setIsLoading(true);

      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: setInput(data, dryRun, skipValidations),
      };
      const { data: responseData } = await packageLinkAssignmentMutation({ variables });
      setResult(responseData?.cube?.packageLinkAssignment);
    } catch (e) {
      setError(true);
      console.error("[PackageLinkAssignment]: ", e);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ result, error, isLoading }, submitPackageLinkAssignment];
};

export default usePackageLinkAssignment;
