import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { Loading } from "@foris/avocado-ui";
import {
  CubeMutation,
  EditSectionPayload,
  SectionValidationErrorUnion,
} from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { IErrorWarning } from "../../../../models/Errors/ErrorWarning";
import SectionEdit from "../../../../models/Errors/SectionEdit";
import Button from "../../../../common/components/Button/Button";
import Validations from "../../../../common/components/Validations/Validations";
import HeaderEdit from "./../HeaderEdit/HeaderEdit";
import SearchComponent from "./SearchComponent";
import { MUTATION_EDIT_SECTION } from "./graphql/changeComponent.queries";
import css from "./changeComponent.module.scss";

interface IChangeComponent {
  event: any;
  createSession?: boolean;
  component?: any;
  courseId?: any;
  sectionId?: any;
  vacancies?: number;
  title?: string;
  onClose: (value: any) => void;
}

const ChangeComponent: React.FC<IChangeComponent> = (props: IChangeComponent) => {
  const { event, onClose, createSession, component, courseId, sectionId, vacancies, title } = props;
  const { scenario, origin }: IParams = useParams();
  const initSelect = { label: component.code, value: component.id };
  const [componentMutation] = useMutation(MUTATION_EDIT_SECTION, {});
  const [selectValue, setSelectValue] = useState(initSelect);
  const [valueCheck, setValueCheck] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [validationArray, setValidationArray] = useState<IErrorWarning[]>([]);

  const onSaveChangeComponent = async (commit: boolean) => {
    setActiveLoading(true);
    try {
      const objMutation = {
        scenarioId: scenario,
        originId: origin,
        input: {
          sectionId: sectionId,
          dryRun: !commit,
          skipValidations: commit,
          changeset: {
            changeCourseComponent: {
              op: "CHANGE",
              courseComponentId: selectValue && selectValue.value,
            },
          },
        },
      };
      if (!commit) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const error = await callMutationHook(objMutation);
        error && setActiveLoading(false);
      } else {
        const data = await componentMutation({ variables: objMutation });
        const dataMutation: EditSectionPayload = data.data.cube.editSection;
        if (dataMutation.commited) {
          window.location.reload();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const callMutationHook = async (variables: any) => {
    try {
      const data = await componentMutation({ variables });
      const dataMutation: CubeMutation = data.data.cube;
      const validationErrors: Array<SectionValidationErrorUnion> =
        dataMutation.editSection.validationErrors;
      const errorsValidations = [];
      if (validationErrors && validationErrors.length > 0) {
        validationErrors.forEach((error: SectionValidationErrorUnion) => {
          const errorsComponent = new SectionEdit(error);
          errorsValidations.push(errorsComponent.getMessage());
        });
        setValidationArray(errorsValidations);
        return true;
      } else {
        onSaveChangeComponent(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!selectValue) setValidationArray([]);
  }, [selectValue]);

  const warnings = validationArray
    ? validationArray.filter(warning => warning.type === "WARNING")
    : [];

  return (
    <>
      {activeLoading && <Loading className="container-row" />}
      <section className={cx(css.cntChangeComponent, "contaianer-row")}>
        <HeaderEdit
          event={event}
          createSession={createSession}
          component={component.code}
          sectionId={sectionId}
          vacancies={vacancies}
          title={title}
        />
        <section className={cx(css.content, "container-row")}>
          <h3 className={cx(css.content_title, "col_12")}>Cambiar componente</h3>
          <SearchComponent
            initValue={selectValue}
            courseId={courseId}
            sendValues={(value: any) => setSelectValue(value)}
          />

          <Validations
            validations={validationArray}
            onCallback={(valueCheck: boolean) => setValueCheck(valueCheck)}
          />
        </section>
        <footer className={cx(css.cntFooter, "container-row")}>
          <Button
            className={css.cntFooter_btn}
            color="transparent"
            onClick={(e: any) => onClose(e)}
          >
            Cancelar
          </Button>
          {validationArray && validationArray.length === 0 && (
            <Button
              disabled={!selectValue || activeLoading || selectValue === initSelect}
              className={css.cntFooter_btn}
              onClick={() => onSaveChangeComponent(false)}
            >
              Validar
            </Button>
          )}
          {warnings.length > 0 && (
            <Button
              disabled={!valueCheck || activeLoading}
              className={css.cntFooter_btn}
              onClick={() => onSaveChangeComponent(true)}
            >
              Confirmar cambio componente
            </Button>
          )}
        </footer>
      </section>
    </>
  );
};

export default ChangeComponent;
