import * as R from "ramda";
import { CourseComponent, Link } from "@models/ISchema";
import { LinkPageType } from "./linkData.reducer";

/**
 * @param BlockRanges
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const blockRanges = R.set(R.lensProp<LinkPageType, "blockRanges">("blockRanges"));

/**
 * @param Link
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const infoLink = R.set(R.lensProp<LinkPageType, "info">("info"));

/**
 * @param Block
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const rawBlocks = R.set(R.lensProp<LinkPageType, "rawBlocks">("rawBlocks"));

/**
 * @param Section[]
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const sectionLink = R.set(R.lensProp<LinkPageType, "sections">("sections"));

/**
 * @param link Link
 * @param state LinkPageType
 *
 * @return LinkPageType
 */
export const setAssignedWeeksByComponent = (link: Link, state: LinkPageType) => {
  const courseComponents = link?.course?.courseComponents ?? [];
  const assignedWeeksByComponent = R.reduce(
    (acc: any, cc: CourseComponent) => {
      const component = cc?.component?.code;
      const weekIds: string[] = R.map(R.prop("id"), cc?.impartationIntervals ?? ([] as Interval[]));

      if (!R.has(component, acc)) acc[component] = {};

      for (const weekId of weekIds) acc[component][weekId] = true;

      return acc;
    },
    {},
    courseComponents,
  );

  return R.set(R.lensProp("assignedWeeksByComponent"), assignedWeeksByComponent, state);
};

/**
 * @param RecommendationsBySessionId
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const setRecommendations = R.set(R.lensProp<LinkPageType>("recommendations"));

/**
 * @param IWeek
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const setSelectedWeek = R.set(R.lensProp<LinkPageType, "selectedWeek">("selectedWeek"));

/**
 * @param TermPartsByCategory[]
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const termPartsByCategory = R.set(
  R.lensProp<LinkPageType, "termPartsByCategory">("termPartsByCategory"),
);

/**
 * @param Week[]
 * @param LinkPageType
 *
 * @return LinkPageType
 */
export const weeksLink = R.set(R.lensProp<LinkPageType, "weeks">("weeks"));
