import React, { useContext } from "react";
import { Icon } from "@foris/avocado-ui";
import { Link } from "react-router-dom";
import cx from "classnames";
import { BookingContext } from "../../context/BookingContext";
import { Classroom } from "@models/ISchema";
import css from "./requestHeader.module.scss";

interface HeaderProps {
  origin: string;
  scenario: string;
  workspace: string;
}

const Header = (props: HeaderProps) => {
  const { scenario, origin, workspace } = props;
  const { state } = useContext(BookingContext);
  const searchPath = `/booking/search/${workspace}/${scenario}/${origin}/`;
  if (state?.search?.classroom) {
    const classroom: Classroom = state?.search?.classroom;
    return (
      <header className={cx(css.header, "container-row")}>
        <Link to={searchPath} className={cx(css.header_link, "col_12")}>
          <Icon icon="chevron-left" size={24} className={css.iconBack} />
          Reservar sala: {classroom?.code}
        </Link>
        <div className={cx("container-row", "col_12")}>
          <div className={cx(css.item, "col_2", "col_md_4")}>
            <p className={cx(css.item_label)}>Campus</p>
            <p className={cx(css.item_info)}>{classroom?.building?.campus?.name}</p>
          </div>
          <div className={cx(css.item, "col_2", "col_md_4")}>
            <p className={cx(css.item_label)}>Edificio</p>
            <p className={cx(css.item_info)}>{classroom?.building?.name}</p>
          </div>
          <div className={cx(css.item, "col_2", "col_md_4")}>
            <p className={cx(css.item_label)}>Tipo</p>
            <p className={cx(css.item_info)}>{classroom?.classroomType?.name}</p>
          </div>
        </div>
      </header>
    );
  }
  return null;
};

export default Header;
