import React, { useState } from "react";
import cx from "classnames";
import Checkbox from "../../../common/components/Checkbox/Checkbox";
import css from "./checks.module.scss";

export interface IFictionalProps {
  onCallback: (value: boolean) => void;
}

const FictionalCheckbox: React.FC<IFictionalProps> = (props: IFictionalProps) => {
  const [fictionalCheck, setFictionalCheck] = useState(false);
  return (
    <div className={cx(css.shared_check, "col_12")}>
      <div className={cx(css.shared_check_select, "col_12")}>
        <Checkbox
          className={css.shared_check_title}
          label="No considerar docentes ficticios"
          check={fictionalCheck}
          onClick={() => {
            setFictionalCheck(!fictionalCheck);
            props.onCallback(!fictionalCheck);
          }}
        />
      </div>
    </div>
  );
};

export default FictionalCheckbox;
