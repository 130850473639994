import { ActionMap } from "@models/IContext";
import { Session, SessionsMultiEditPayload, EditSessionPayload } from "@models/ISchema";
import { EditedSessions } from "./formData.reducer";

export type ResultType = {
  // TODO: change `selectedSession` to `sessionSelected`
  selectSession: Session;
  resultValidation: SessionsMultiEditPayload;
  deleteValidation: EditedSessions[];
  // TODO: change `selectCreateSession` to `sessionToCreateSelected`
  selectCreateSession: EditedSessions;
  sessionToDeleteSelected: EditedSessions;
  createValidation: SessionsMultiEditPayload;
};

export interface EditSessionPayloadId extends EditSessionPayload {
  id: string;
  sessionUuid: string;
}

export enum Types {
  CreateValidation = "SET_CREATE_VALIDATION",
  ResultValidation = "SET_RESULT_VALIDATION",
  DeleteValidation = "SET_DELETE_VALIDATION",
  SelectCreateSession = "SELECT_CREATE_SESSION",
  SelectDeletedSession = "SELECT_DELETED_SESSION",
  SelectSession = "SELECT_SESSION",
}

type ResultPayload = {
  [Types.CreateValidation]: SessionsMultiEditPayload;
  [Types.ResultValidation]: SessionsMultiEditPayload;
  [Types.DeleteValidation]: EditedSessions[];
  [Types.SelectCreateSession]: EditedSessions;
  [Types.SelectDeletedSession]: EditedSessions;
  [Types.SelectSession]: Session;
};

export type ResultActions = ActionMap<ResultPayload>[keyof ActionMap<ResultPayload>];

export const resultReducer = (state: ResultType, action: ResultActions) => {
  switch (action?.type) {
    case Types.CreateValidation:
      return { ...state, createValidation: action.payload };
    case Types.ResultValidation:
      return { ...state, resultValidation: action.payload };
    case Types.DeleteValidation:
      return { ...state, deleteValidation: action.payload };
    case Types.SelectCreateSession:
      return {
        ...state,
        selectCreateSession: action.payload,
        selectSession: null,
        sessionToDeleteSelected: null,
      };
    case Types.SelectSession:
      return {
        ...state,
        selectSession: action.payload,
        selectCreateSession: null,
        sessionToDeleteSelected: null,
      };
    case Types.SelectDeletedSession:
      return {
        ...state,
        selectSession: null,
        selectCreateSession: null,
        sessionToDeleteSelected: action.payload,
      };
    default:
      return state;
  }
};
