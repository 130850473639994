import gql from "graphql-tag";

export const megaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sections(filter: $filter) {
        items {
          id
          code
          vacancies
          course {
            id
            name
            curriculum {
              id
              code
            }
          }
          sessions {
            id
            assignment {
              classroom {
                id
                code
                name
              }
              instructor {
                id
                code
                name
              }
            }
          }
          schedule {
            resourceAssigment {
              schedule
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
