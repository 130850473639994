import gql from "graphql-tag";

export const programsQuery = gql`
  query programs($scenarioId: ID!, $filter: ProgramFilterInput) {
    data(scenarioId: $scenarioId) {
      programs(filter: $filter) {
        items {
          id
          code
          name
          campus {
            id
            name
            __typename
          }
          department {
            id
            name
            __typename
          }
          shift {
            id
            name
            __typename
          }
          modality {
            id
            name
            __typename
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
