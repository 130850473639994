export enum DAY_NAMES {
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
}

export enum DAY_NAMES_ES {
  MONDAY = "Lunes",
  TUESDAY = "Martes",
  WEDNESDAY = "Miércoles",
  THURSDAY = "Jueves",
  FRIDAY = "Viernes",
  SATURDAY = "Sábado",
  SUNDAY = "Domingo",
}

export enum DAY_NUMBER {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export enum EVENT_STATES {
  UNMODIFIED = "UNMODIFIED",
  MODIFIED = "MODIFIED",
  STAGED = "STAGED",
  UPDATED = "UPDATED",
}
