import { ActionMap } from "@models/IContext";
import { ClassroomBooking, ClassroomBookingValidationErrorUnion } from "@models/ISchema";
import { Option, DataError, RequestTypes, SnackbarType, UserResponsible } from "./types";
import {
  clearRequest,
  setDescription,
  setEditedBooking,
  setErrors,
  setEventType,
  setEventTypesOptions,
  setSnackbar,
  setTitle,
  setUserResponsible,
  setUsersOptions,
} from "./request.actions";

export enum Types {
  ClearRequest = "CLEAR_REQUEST",
  SetDescription = "SET_DESCRIPTION",
  SetEditedBooking = "SET_EDITED_BOOKING",
  SetErrors = "SET_ERRORS_REQUEST",
  SetEventType = "SET_EVENT_TYPE",
  SetEventTypesOptions = "SET_EVENT_TYPES_OPTIONS",
  SetTitle = "SET_TITLE",
  SetUserResponsible = "SET_USER_RESPONSIBLE",
  SetUsersOptions = "SET_USERS_OPTIONS",
  SetSnackbar = "SET_SNACKBAR",
}

type RequestPayload = {
  [Types.ClearRequest]: void;
  [Types.SetDescription]: DataError;
  [Types.SetSnackbar]: SnackbarType;
  [Types.SetEditedBooking]: ClassroomBooking;
  [Types.SetErrors]: ClassroomBookingValidationErrorUnion[];
  [Types.SetEventType]: DataError;
  [Types.SetEventTypesOptions]: Option[];
  [Types.SetTitle]: DataError;
  [Types.SetUserResponsible]: UserResponsible;
  [Types.SetUsersOptions]: Option[];
};

export type RequestActions = ActionMap<RequestPayload>[keyof ActionMap<RequestPayload>];

export const requestReducer = (state: RequestTypes, action: RequestActions) => {
  switch (action.type) {
    case Types.SetErrors:
      return setErrors(action?.payload, state);
    case Types.SetSnackbar:
      return setSnackbar(action?.payload, state);
    case Types.SetEventTypesOptions:
      return setEventTypesOptions(action?.payload, state);
    case Types.SetTitle:
      return setTitle(action?.payload, state);
    case Types.SetEditedBooking:
      return setEditedBooking(action?.payload, state);
    case Types.SetEventType:
      return setEventType(action?.payload, state);
    case Types.SetDescription:
      return setDescription(action?.payload, state);
    case Types.ClearRequest:
      return clearRequest(state);
    case Types.SetUsersOptions:
      return setUsersOptions(action?.payload, state);
    case Types.SetUserResponsible:
      return setUserResponsible(action?.payload, state);
    default:
      return state;
  }
};
