import gql from "graphql-tag";

export const megaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      populations(filter: $filter) {
        items {
          id
          term {
            id
            name
            code
          }
          campus {
            id
            code
            name
          }
          modality {
            id
            code
          }
          shift {
            id
            code
          }
          program {
            id
            code
            name
          }
          curriculum
          level
          size
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
    }
  }
`;
