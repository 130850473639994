import { optionsFilter } from "@common/layout/ContextSearch/contextOptions";
import { useMemo } from "react";

interface UseGetDimension {
  label?: string;
  value?: string;
}

const useGetDimension = (dimensionValue: string): [UseGetDimension] => {
  const currentDimension = useMemo(() => {
    const defaultValue = { label: "", value: "" };
    const dimension = optionsFilter.find(option => option.value === dimensionValue);

    return dimension || defaultValue;
  }, [dimensionValue]);

  return [currentDimension];
};

export default useGetDimension;
