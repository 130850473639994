import { assoc, isEmpty, keys, partition, pipe, reduce, toPairs } from "ramda";
import {
  ErrorsByGroupId,
  GroupEditionErrors,
  HardGroupEditionErrors,
  SoftGroupEditionErrors,
} from "../models";
import isHardError from "./isHardError";

const toObj = reduce(
  (acc: ErrorsByGroupId, error: keyof GroupEditionErrors) => assoc(error, true, acc),
  {} as ErrorsByGroupId,
);

/**
 * Return a list with two values where the 1st one contains the `hard` errors
 * and the 2nd the `soft` errors, both indexed by the `groupId`
 *
 * @param errorsByGroupId ErrorsByGroupId
 *
 * @return [ErrorsByGroupId, ErrorsByGroupId]
 */
const splitGroupErrors = (errorsByGroupId: ErrorsByGroupId): [ErrorsByGroupId, ErrorsByGroupId] =>
  reduce(
    ([hardErrorsByGroupId, softErrorsByGroupId], [groupId, errors]) => {
      const [hards, softs] = pipe(keys, partition(isHardError))(errors);

      if (!isEmpty(hards)) {
        hardErrorsByGroupId[groupId] = toObj(
          (hards as unknown) as (keyof HardGroupEditionErrors)[],
        );
      }
      if (!isEmpty(softs)) {
        softErrorsByGroupId[groupId] = toObj(
          (softs as unknown) as (keyof SoftGroupEditionErrors)[],
        );
      }

      return [hardErrorsByGroupId, softErrorsByGroupId];
    },
    [{}, {}],
    toPairs(errorsByGroupId),
  );

export default splitGroupErrors;
