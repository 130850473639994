import React from "react";
import cx from "classnames";
import { Button, Card, Textarea } from "@foris/avocado-ui";
import RadioButton from "@components/RadioButton/RadioButton";
import { ClassroomBooking, ClassroomBookingStatusEnum } from "@models/ISchema";
import * as utils from "../../utils";
import css from "./cardDetail.module.scss";

interface Props {
  editing: boolean;
  booking: ClassroomBooking;
  onClick: () => void;
  onConfirmation: (state: ClassroomBookingStatusEnum) => void;
  onNotification: (value: string) => void;
  onCancel: () => void;
  onSave: (status: string, comment: string) => void;
  statusConfirmation: string;
  notificationText: string;
}

const State: React.FC<Props> = ({
  booking,
  editing,
  onClick,
  onConfirmation,
  onNotification,
  onCancel,
  onSave,
  statusConfirmation,
  notificationText,
}) => {
  return (
    <Card.Simple className={css.cardDetail}>
      <Card.Content>
        <header className={cx(css.headerDetail, "container-row", "row--between")}>
          <p className={cx(css.headerDetail_subtitle, "col_12")}>Gestionar reserva</p>
        </header>

        {!editing ? (
          <>
            <p className={cx(css.cardDetail_text, "col_12")}>
              Estado: {utils.icons.statusIconText(booking?.status)}
            </p>
            <Button typeButton="transparent" className={css.cardDetail_btn} onClick={onClick}>
              Editar
            </Button>
          </>
        ) : (
          <>
            <div className="container-row">
              <RadioButton
                label="Confirmar"
                name="manage"
                className={css.cardDetail_radioBtn}
                onChange={() => onConfirmation(ClassroomBookingStatusEnum.Confirmed)}
              />
              <RadioButton
                label="Rechazar"
                name="manage"
                className={css.cardDetail_radioBtn}
                onChange={() => onConfirmation(ClassroomBookingStatusEnum.Rejected)}
              />
              <Textarea
                label="Notificación (opcional)"
                className={cx(css.cardDetail_textarea, "col_12")}
                onChange={e => onNotification(e.target.value)}
              />
            </div>
            <div className="container-row">
              <Button
                typeButton="transparent"
                className={css.cardDetail_btn}
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>
              <Button
                className={css.cardDetail_btn}
                disabled={statusConfirmation === null}
                onClick={() => onSave(statusConfirmation, notificationText)}
              >
                Guardar
              </Button>
            </div>
          </>
        )}
      </Card.Content>
    </Card.Simple>
  );
};

export default State;
