import React from "react";
import { reject } from "lodash";
import { Table, Pager } from "@foris/avocado-ui";
import { Instructor } from "@models/ISchema";
import css from "./advanceSearch.module.scss";

interface AdvanceTable {
  loading: boolean;
  data: Instructor[];
  size: number;
  total: number;
  page: number;
  selected: Instructor[];
  setSelected: (value: Instructor[]) => void;
  onPageChange: (page: number) => void;
}

const AdvanceSearch: React.FC<AdvanceTable> = (props: AdvanceTable) => {
  const { loading, data, size, total, page, onPageChange, setSelected, selected } = props;

  const instructorCell = (table: any) => {
    return (
      <p className={css.cell}>
        {table?.row?.original?.code}
        <span className={css.cell_bold}>{` ${table?.row?.original?.name}`}</span>
      </p>
    );
  };

  const percentCell = (param: any) => {
    return <p className={css.advanceCell}>{`${param.value}%`}</p>;
  };

  const renderCheck = (table: any) => {
    const findSelected = selected?.find(value => value.id === table?.row?.original?.id);
    const onChange = () => {
      let cloneSelected = selected ? [...selected] : [];
      if (!findSelected) cloneSelected.push(table?.row?.original);
      if (findSelected)
        cloneSelected = reject(cloneSelected, value => value.id === table?.row?.original?.id);
      setSelected(cloneSelected);
    };

    return (
      <Table.Checkbox
        {...table.row.getToggleRowSelectedProps()}
        checked={findSelected}
        onChange={onChange}
      />
    );
  };

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 40,
      maxWidth: 40,
      Cell: (row: any) => renderCheck(row),
    },
    {
      Header: "Docente",
      accessor: "instructor",
      Cell: (row: any) => instructorCell(row),
    },
    {
      Header: "Prioridad",
      accessor: "priority",
      maxWidth: 90,
    },
    {
      Header: "Bloques Asignados",
      accessor: "assignment.averagePerIntervalBlocks",
      maxWidth: 220,
      Cell: (row: any) => percentCell(row),
    },
    {
      Header: "Total Bloques Asignados",
      accessor: "assignment.blocks",
      maxWidth: 200,
    },
    {
      Header: "Ocupación",
      accessor: "assignment.usagePercentage",
      maxWidth: 110,
      Cell: (row: any) => percentCell(row),
    },
  ];

  if (!data && loading)
    return (
      <Table.Container className={css.advanceTable} columns={columns} data={[]} loading={loading} />
    );

  return (
    <Table.Container
      className={css.advanceTable}
      columns={columns}
      data={data}
      pagerBottom={true}
      pagerTop={true}
      loading={loading}
    >
      <Pager size={size} total={total} page={page} onPageChange={onPageChange} />
    </Table.Container>
  );
};

export default AdvanceSearch;
