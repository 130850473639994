import React from "react";
import { useParams } from "react-router-dom";
import { Icon } from "@foris/avocado-ui";
import { IParams } from "../../../../models/IParams";
import { Section } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error, setPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "sección");
  const route = `/scheduler/editor/section/${workspace}/${scenario}/${origin}`;

  const getInstructorName = ({ sessions }: Section) => {
    const session = sessions.find(({ assignment }) => assignment?.instructor?.name);
    return getColumnCell(session?.assignment?.instructor?.name || "");
  };

  const getClassrooms = ({ sessions }: Section) => {
    const classrooms: any = {};

    sessions.forEach(({ assignment }) => {
      const code = assignment?.classroom?.code;

      if (code && !(code in classrooms)) {
        classrooms[code] = code;
      }
    });

    const classroomsKeys = Object.keys(classrooms);

    return getColumnCell(classroomsKeys.length ? classroomsKeys.join(", ") : "");
  };

  const getScheduleStatus = (isCheck = false) => {
    const icon = isCheck ? "check" : "close";
    return <Icon icon={icon} size={16} />;
  };

  const columns = [
    {
      header: getHeadCell("Código"),
      renderer: ({ id, code }: Section) => getLinkCell(route, id, code),
    },
    {
      header: getHeadCell("Asignatura"),
      renderer: ({ course, id }: Section) => getLinkCell(route, id, course?.name),
      styles: {
        desktop: {
          width: 200,
        },
      },
    },
    {
      header: getHeadCell("Vacantes"),
      renderer: ({ vacancies }: Section) => getColumnCell(vacancies),
    },
    {
      header: getHeadCell("Currículo"),
      renderer: ({ course }: Section) => getColumnCell(course?.curriculum?.code),
    },
    {
      header: getHeadCell("Docente"),
      renderer: getInstructorName,
      styles: {
        desktop: {
          width: 200,
        },
      },
    },
    {
      header: getHeadCell("Sala"),
      renderer: getClassrooms,
    },
    {
      header: getHeadCell("Horario"),
      renderer: ({ schedule }: Section) => getScheduleStatus(schedule?.resourceAssigment?.schedule),
    },
  ];
  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension="sección"
      advanceLink={`/editor/advanceSearch/section/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
