import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { Card, Checkbox, Icon, Button } from "@foris/avocado-ui";
import { TermPart } from "@models/ISchema";
import { Week, TermPartsByCategory } from "../ISections";
import cx from "classnames";
import css from "./weeksMultiSelect.module.scss";

interface IWeeksMultiSelectProps {
  weeks: Week[];
  applySelectionOnContext: (weeksToCheck: { [key: number]: boolean }, checked?: boolean) => void;
  highlightSelectionOnContext: (weekIdsToHighlight: { [key: number]: boolean }) => void;
  onAllWeeksSelected: () => void;
  onOpenTermPartsSelector: () => void;
  termPartsByCategory: TermPartsByCategory[];
  disabled?: boolean;
}

const WeeksMultiSelect: React.FC<IWeeksMultiSelectProps> = ({
  weeks,
  applySelectionOnContext,
  highlightSelectionOnContext,
  onAllWeeksSelected,
  onOpenTermPartsSelector,
  termPartsByCategory,
  disabled,
}) => {
  const [displayTermPartSelector, setDisplayTermPartSelector] = useState<boolean>(false);
  const [numberOfHighlightedWeeks, setNumberOfHighlightedWeeks] = useState<number>(0);
  const [termPartsSelected, setTermPartsSelected] = useState<{ [key: string]: boolean }>(null);
  const [allWeeksSelected, setAllWeeksSelected] = useState<boolean>(false);

  const weeksByTermPartId = weeks?.reduce((acc, week) => {
    if (!week?.termParts) return acc;
    week.termParts.forEach(({ id }) => {
      if (id in acc) acc[id].push(week);
      else acc[id] = [week];
    });
    return acc;
  }, {});

  const initializeTermPartsChecks = () => {
    const checks: { [key: number]: boolean } = {};
    termPartsByCategory?.forEach(category => {
      category?.termParts?.forEach(({ id }) => (checks[id] = false));
    });
    setTermPartsSelected(checks);
    setNumberOfHighlightedWeeks(0);
  };

  const handleTermPartCheckboxClick = ({ id }: TermPart) => () => {
    if (!termPartsSelected) return;
    setTermPartsSelected(R.over(R.lensProp(id), R.not, termPartsSelected));
  };

  const applySelection = (checked: boolean) => {
    if (!termPartsSelected) return;

    // Determine weeks to check based on selection
    const weeksToCheck = allWeeksSelected
      ? // If all weeks are selected, filter active and highlighted weeks
        [...weeks]
          .filter(week => week.isInTerm && week.highlight)
          .reduce((acc, week) => {
            acc[week.id] = true;
            return acc;
          }, {})
      : // Otherwise, iterate over selected term parts and mark their weeks
        Object.entries(termPartsSelected).reduce((acc, [termPartId, checked]) => {
          if (checked) {
            weeksByTermPartId[termPartId].forEach(({ id }) => {
              acc[id] = true;
            });
          }
          return acc;
        }, {});

    initializeTermPartsChecks();
    setAllWeeksSelected(false);
    applySelectionOnContext(weeksToCheck, checked);
  };

  const highlightWeeksBySelectedTermParts = () => {
    if (termPartsSelected === null) return;
    const weekIdsToHighlight = R.pipe(
      R.toPairs,
      R.reduce<[string, boolean], string[]>(
        (acc, [termPartId, isChecked]) => R.when(R.always(isChecked), R.append(termPartId), acc),
        [],
      ),
      R.reduce(
        (acc, termPartId) =>
          R.pipe(
            R.propOr([], termPartId),
            R.forEach(({ id }) => (acc[id] = true)),
            R.always(acc),
          )(weeksByTermPartId),
        {},
      ),
    )(termPartsSelected);
    setNumberOfHighlightedWeeks(R.length(R.keys(weekIdsToHighlight)));
    highlightSelectionOnContext(weekIdsToHighlight);
  };

  useEffect(initializeTermPartsChecks, [termPartsByCategory]);

  useEffect(() => {
    if (allWeeksSelected === null) return;

    if (!allWeeksSelected) {
      highlightWeeksBySelectedTermParts();
    } else {
      setNumberOfHighlightedWeeks([...weeks]?.filter(week => week?.isInTerm).length);
      onAllWeeksSelected();
    }
  }, [allWeeksSelected]);

  useEffect(() => {
    if (!allWeeksSelected) highlightWeeksBySelectedTermParts();
  }, [termPartsSelected]);

  const termPartLabel = (termPart: TermPart, hasRelatedWeeks: boolean) => {
    const attrs = {};

    if (!hasRelatedWeeks) {
      attrs["data-tooltip"] = `Agrupación sin semanas relacionadas. Código ${termPart?.code}`;
    }

    return (
      <label
        className={`${css.multiSelect_termPartByCategory_termParts_item_label}
       ${!hasRelatedWeeks ? css.multiSelect_termPartByCategory_termParts_item_label_disabled : ""}`}
        {...attrs}
      >
        {termPart.name}
      </label>
    );
  };

  return (
    <Card.Simple className={css.multiSelect}>
      <Card.Header className={css.multiSelect_header}>
        <Button
          disabled={disabled}
          onClick={() => {
            setDisplayTermPartSelector(!displayTermPartSelector);
            if (displayTermPartSelector) {
              initializeTermPartsChecks();
              onOpenTermPartsSelector();
            }
          }}
          className={cx(css.multiSelect_unoutlined_button)}
          typeButton={"transparent"}
        >
          <div className={cx(css.multiSelect_unoutlined_button_chevron)}>
            <Icon size={14} icon={"filter"} /> Agrupador de semanas
          </div>
          <Icon size={16} icon={displayTermPartSelector ? "chevron-up" : "chevron-down"} />
        </Button>
      </Card.Header>
      {/*
        The `Card.Content` needs to be defined for the Card component to work
        properly, so if the `displayTermPartSelector` is equal to `false` we
        create a `Card.Content` with nothin in it.
      */}
      {displayTermPartSelector ? (
        <Card.Content>
          <section className={css.multiSelect_termPartByCategory}>
            <label>Otros</label>
            <div className={css.multiSelect_termPartByCategory_termParts}>
              <Checkbox
                disabled={disabled}
                className={css.multiSelect_termPartByCategory_termParts_item}
                labelRight={
                  <label className={css.multiSelect_termPartByCategory_termParts_item_label}>
                    Todas las semanas
                  </label>
                }
                checked={allWeeksSelected}
                onChange={() => setAllWeeksSelected(!allWeeksSelected)}
              />
            </div>
          </section>
          {termPartsByCategory?.map(item => (
            <section key={item.category.id} className={css.multiSelect_termPartByCategory}>
              <label>{item.category.name}</label>
              <div className={css.multiSelect_termPartByCategory_termParts}>
                {item.termParts.map(termPart => {
                  const hasRelatedWeeks = R.has(termPart.id, weeksByTermPartId);
                  return (
                    <Checkbox
                      className={css.multiSelect_termPartByCategory_termParts_item}
                      key={termPart.id}
                      disabled={disabled || !hasRelatedWeeks}
                      labelRight={termPartLabel(termPart, hasRelatedWeeks)}
                      checked={termPartsSelected ? termPartsSelected[termPart.id] : false}
                      onChange={handleTermPartCheckboxClick(termPart)}
                    />
                  );
                })}
              </div>
            </section>
          ))}
          <label className={css.multiSelect_count}>
            {numberOfHighlightedWeeks === 1
              ? `1 semana seleccionada`
              : `${numberOfHighlightedWeeks} semanas seleccionadas`}
          </label>
          <section className={css.multiSelect_buttonsSection}>
            <Button
              onClick={() => applySelection(false)}
              disabled={disabled || numberOfHighlightedWeeks === 0}
              className={cx(css.multiSelect_buttonsSection_button, css.multiSelect_discardButton)}
            >
              <label>Descartar Selección</label>
            </Button>
            <Button
              onClick={() => applySelection(true)}
              disabled={disabled || numberOfHighlightedWeeks === 0}
              className={css.multiSelect_buttonsSection_button}
            >
              <label>Aplicar selección</label>
            </Button>
          </section>
        </Card.Content>
      ) : (
        <Card.Content className={cx(css.multiSelect_hiddenContent)}>
          <></>
        </Card.Content>
      )}
    </Card.Simple>
  );
};

export default WeeksMultiSelect;
