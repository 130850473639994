import React, { useContext } from "react";
import { Card, DataTable, Icon, Pager } from "@foris/avocado-ui";
import { ContextEDH } from "@context/ContextEDH";
import * as mouseflow from "@utils/mouseflow";
import cx from "classnames";
import { IDataTableContentColumn } from "@foris/avocado-ui/lib/types/components/DataTable/components/DataTableContent/DataTableContent";
import { GroupEnrollment, PageInfo } from "@models/ISchema";
import css from "./enrollmentsTable.module.scss";

const ROWS_PER_PAGE = 20;

interface IProps {
  reportUrl: string;
  enrollments: GroupEnrollment[];
  enrollmentsPageInfo: PageInfo;
  requestEnrollments: (page?: number) => void;
}

const EnrollmentsTable = (props: IProps) => {
  const { state: outerState } = useContext(ContextEDH);
  const { reportUrl, enrollments, enrollmentsPageInfo, requestEnrollments } = props;

  const columns: IDataTableContentColumn[] = [
    {
      header: "Estudiante",
      accessor: "student",
      renderCell: (enrollment: GroupEnrollment) => {
        const code = enrollment?.student?.code;
        const name = enrollment?.student?.name;
        return (
          <span>
            {code} <strong>{name}</strong>
          </span>
        );
      },
      responsive: {
        allDevices: {
          styles: {
            maxWidth: "40%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Carrera",
      accessor: "program.code",
      renderCell: ({ program }: GroupEnrollment) => {
        const { code, name } = program;
        return <span>{`${code} | ${name}`}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            maxWidth: "20%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Campus",
      accessor: "campus.code",
      renderCell: ({ campus }: GroupEnrollment) => {
        const { code, name } = campus;
        return <span>{`${code} | ${name}`}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            maxWidth: "20%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Estado",
      accessor: "student.isWaitingList",
      renderCell: (enrollment: GroupEnrollment) => {
        return <span>{enrollment?.isWaitingList ? "En espera" : "Inscrito"}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            maxWidth: "8%",
            textAlign: "left",
          },
        },
      },
    },
    {
      header: "Fecha",
      accessor: "enrollmentDate",
      renderCell: (enrollment: GroupEnrollment) => {
        const showDate = !enrollment?.isWaitingList;
        return <span>{showDate ? enrollment?.enrollmentDate : ""}</span>;
      },
      responsive: {
        allDevices: {
          styles: {
            maxWidth: "12%",
            textAlign: "left",
          },
        },
      },
    },
  ];

  if (!enrollments?.length) {
    return (
      <section className={cx(css.noResult)}>
        <h3 className={css.noResult_title}>No se han encontrado matriculados para este grupo</h3>
      </section>
    );
  }

  return (
    <section className={cx(css.tableContent)}>
      <Card.Simple className={cx("container-row", css.tableCard)}>
        <Card.Content className="container-row">
          <header>
            <label className={css.tableCard__title}>
              {enrollments?.length ?? 0} Estudiantes Inscritos
            </label>
            <a
              className={css.tableCard__link}
              href={reportUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                mouseflow.actionTag(
                  "action_groups_manager_enrollments_report",
                  outerState?.base?.isMouseflowEnabled,
                );
              }}
              download
            >
              <Icon className={css.tableCard__icon} icon="download" />
              <span>Descargar reporte</span>
            </a>
          </header>

          <section className={cx("col_12", "row--center", css.tableCard_pager)}>
            <Pager
              page={enrollmentsPageInfo?.page ?? 0}
              size={ROWS_PER_PAGE}
              total={enrollmentsPageInfo?.total ?? 0}
              onChange={requestEnrollments}
            />
          </section>

          <div className={cx("col_12", css.tableCard_line)}></div>

          <section className="col_12">
            <DataTable.Content columns={columns} data={enrollments ?? []} />
          </section>

          <section className={cx("col_12", "row--center", css.tableCard_pager)}>
            <Pager
              page={enrollmentsPageInfo?.page ?? 0}
              size={ROWS_PER_PAGE}
              total={enrollmentsPageInfo?.total ?? 0}
              onChange={requestEnrollments}
            />
          </section>
        </Card.Content>
      </Card.Simple>
    </section>
  );
};

export default EnrollmentsTable;
