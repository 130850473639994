import React, { memo, useMemo } from "react";
import dayjs from "dayjs";
import { Link, useParams } from "react-router-dom";
import { ap, head, join, map, pipe, prop, reduce, split, uniq } from "ramda";
import { Button, Card, CardState, IWeeklyItem, Icon, Weekly } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { ClassroomBooking, ScheduleEvent, Session } from "@models/ISchema";
import * as utils from "../../utils";
import css from "./detail.module.scss";

interface Props {
  booking: ClassroomBooking;
  intervals: IWeeklyItem[];
}

interface Schedule {
  date: string;
  weekday: string;
  time: string;
}

const Resources: React.FC<Props> = ({ booking, intervals }) => {
  const { origin: originId, scenario: scenarioId, workspace: workspaceId }: IParams = useParams();
  const searchClassroomUrl = `${workspaceId}/${scenarioId}/${originId}/${booking?.id}`;

  const schedulesForUniqueBooking = pipe(
    reduce<any, any>((acc, session) => {
      const scheduleEvents: Schedule[] = session?.scheduleEvent?.map((event: ScheduleEvent) => {
        const date = utils.format.day(event?.start);
        const startTime = utils.format.time(event?.start);
        const endTime = utils.format.time(event?.end);
        return {
          weekday: utils.format.dateToWeekday(event?.start),
          date,
          time: `${startTime} - ${endTime}`,
        };
      });
      for (const event of (scheduleEvents as unknown) as Schedule[]) acc.push(event);
      return acc;
    }, []),
    uniq,
  );

  const stringifiedEvents = (sessions: Session[]) => {
    if (!sessions?.length) return [];
    const dateToWeekDay = (date: string) => {
      const dayIdToWeekday = {
        [0]: "Dom",
        [1]: "Lun",
        [2]: "Mar",
        [3]: "Mie",
        [4]: "Jue",
        [5]: "Vie",
        [6]: "Sab",
      };
      return dayIdToWeekday[dayjs(date).day()];
    };

    const formatTime = pipe(split(":"), ([hour, min]) => join(":", [hour, min]));

    const stringifyEvent = (event: ScheduleEvent) => {
      const [[date, startHour], [, endHour]] = ap([split(" ")], [event?.start, event?.end]);
      return `${dateToWeekDay(date)}, ${formatTime(startHour)} - ${formatTime(endHour)}`;
    };

    return pipe(
      map(prop("scheduleEvent")),
      map<ScheduleEvent[], ScheduleEvent>(head),
      map(stringifyEvent),
      uniq,
    )(sessions ?? []);
  };

  const showMediaLinks = useMemo(() => {
    return (
      ["NSGR", "AAUD", "BAUD", "BSGR"].includes(booking?.classroom?.code) &&
      ["rejected", "pending", "confirmed"].includes(booking?.status)
    );
  }, [booking?.status, booking?.classroom?.code]);

  return (
    <Card.Simple className={css.detail}>
      <header className={cx(css.header, "container-row", "row--between")}>
        <h3 className={cx(css.header__title)}>Sala y Horario</h3>
      </header>

      <>
        <section className={cx(css.info_container, "container-row")}>
          <div className={cx(css.info, "col_2", "col_sm_6")}>
            <p className={css.info__label}>Campus</p>
            <p className={css.info__text}>{booking?.classroom?.building?.campus?.name || "--"}</p>
          </div>
          <div className={cx(css.info, "col_2", "col_sm_6")}>
            <p className={css.info__label}>Sala</p>
            <p className={css.info__text}>{booking?.classroom?.name || "--"}</p>
          </div>
          <div className={cx(css.info, "col_2", "col_sm_6")}>
            <p className={css.info__label}>Edificio</p>
            <p className={css.info__text}>{booking?.classroom?.building?.name || "--"}</p>
          </div>
          <div className={cx(css.info, "col_2", "col_sm_6")}>
            <p className={css.info__label}>Tipo</p>
            <p className={css.info__text}>{booking?.classroom?.classroomType?.name || "--"}</p>
          </div>
          <div className={cx(css.info, "col_2", "col_sm_6")}>
            <p className={css.info__label}>Capacidad</p>
            <p className={css.info__text}>{booking?.capacity || "--"}</p>
          </div>
        </section>

        <section className={cx(css.info_container, css.days, "container-row")}>
          {stringifiedEvents(booking?.sessions)?.map((stringifiedEvent: string, idx: number) => (
            <div key={stringifiedEvent} className={cx(css.info, "col_2", "col_sm_6")}>
              <p className={css.info__label}>Día {idx + 1}</p>
              <p className={css.info__text}>{stringifiedEvent}</p>
            </div>
          ))}
        </section>

        <section className={cx(css.info_container, "container-row")}>
          <div className={cx(css.info, "col_2", "col_sm_6")}>
            <p className={css.info__label}>Tipo de reserva</p>
            <p className={css.info__text}>{booking?.isRecurrent ? "Recurrente" : "Evento único"}</p>
          </div>
        </section>

        {!booking?.isRecurrent ? (
          (schedulesForUniqueBooking(booking.sessions ?? []) as Schedule[]).map(
            ({ date, time, weekday }: Schedule) => (
              <section key={`${date}-${time}`} className={cx(css.info_container, "container-row")}>
                <div className={cx(css.info, "col_2", "col_sm_6")}>
                  <p className={css.info__label}>Día</p>
                  <p className={css.info__text}>{`${weekday} - ${date}`}</p>
                </div>
                <div className={cx(css.info, "col_2", "col_sm_6")}>
                  <p className={css.info__label}>Hora</p>
                  <p className={css.info__text}>{time}</p>
                </div>
              </section>
            ),
          )
        ) : (
          <section className={cx(css.info_container, "container-row")}>
            <Weekly className={css.cntSingleEvent_weekly} weeklyItems={intervals} />
          </section>
        )}

        {showMediaLinks && (
          <section className={cx(css.info_container, "container-row")}>
            <div className={cx(css.info)}>
              <p className={css.info__label}>Gestión de Medios Audiovisuales</p>

              <a
                className={css.info__link}
                href="https://bit.ly/3bOphsu"
                target="_blank"
                rel="noreferrer"
              >
                Solicitud de recursos técnicos audiovisuales <Icon icon="external-link" size={16} />
              </a>
              <a
                className={css.info__link}
                href="https://bit.ly/3EPfgaL"
                target="_blank"
                rel="noreferrer"
              >
                Solicitud de asistencia y/o notificación de incidencias{" "}
                <Icon icon="external-link" size={16} />
              </a>
            </div>

            {booking?.status === "rejected" && (
              <CardState
                title="Reserva de medios audiovisuales"
                typeCard="informative"
                className={cx(css.infoBox)}
              >
                <p className={cx(css.infoBox_text)}>
                  Por favor, recuerde liberar a través del formulario correspondiente los medios
                  audiovisuales que se puedan haber reservado para este evento en caso de no ser
                  necesarios.
                </p>
              </CardState>
            )}
          </section>
        )}

        <Link to={`/booking/search/${searchClassroomUrl}`} className={cx("col_12")}>
          <Button disabled={false} variant={"outline"}>
            Cambiar Sala / Horario
          </Button>
        </Link>
      </>

      {/* Classroom */}
    </Card.Simple>
  );
};

export default memo(Resources);
