import { useState } from "react";
import { view, lensPath } from "ramda";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { GroupEnrollment, PageInfo } from "@models/ISchema";
import { groupEnrollmentsByGroupId } from "../graphql/groupEnrollmentsByGroupId.query";
import { AdaptedGroup } from "../models";

interface IPagination {
  page: number;
  size: number;
}

const useGetGroupEnrollmentsByGroupId = (): [
  GroupEnrollment[],
  boolean,
  PageInfo,
  (group: AdaptedGroup, pagination: IPagination) => void,
] => {
  const { scenario, origin }: IParams = useParams();
  const client = useApolloClient();
  const [response, setResponse] = useState<GroupEnrollment[]>(null);
  const [pageInfo, setPageInfo] = useState<PageInfo>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const viewItems = view(lensPath(["cube", "groupEnrollments", "items"]));
  const viewPageInfo = view(lensPath(["cube", "groupEnrollments", "pageInfo"]));

  const variables = (group: AdaptedGroup, pagination: IPagination) => {
    return {
      scenarioId: scenario,
      originId: origin,
      groupId: group?.id,
      filter: {
        pagination: {
          page: pagination?.page ?? 1,
          size: pagination?.size ?? 1,
        },
      },
    };
  };

  const getGroupEnrollments = async (group: AdaptedGroup, pagination: IPagination) => {
    setLoading(true);
    try {
      const { data, errors } = await client.query({
        query: groupEnrollmentsByGroupId,
        variables: variables(group, pagination),
        fetchPolicy: "no-cache",
      });

      if (errors) {
        console.error("[GroupEnrollments error]", errors);
        setLoading(false);
        return null;
      }

      setResponse(viewItems(data));
      setPageInfo(viewPageInfo(data));
      setLoading(false);
    } catch (error) {
      console.error("[GroupEnrollments error]: ", error);
      setLoading(false);
    }
  };

  return [response, loading, pageInfo, getGroupEnrollments];
};

export default useGetGroupEnrollmentsByGroupId;
