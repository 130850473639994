import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error } = props;
  const title = generateTitle(pageInfo?.total, search, "asignatura");
  const route = `/scheduler/editor/course/${workspace}/${scenario}/${origin}`;

  const columns = [
    {
      header: getHeadCell("Código"),
      renderer: ({ id, code }) => getLinkCell(route, id, code),
    },
    {
      header: getHeadCell("Asignatura"),
      renderer: ({ id, name }) => getLinkCell(route, id, name),
      styles: {
        desktop: { minWidth: 300 },
      },
    },
    {
      header: getHeadCell("Sede"),
      renderer: ({ curriculum }) => getColumnCell(curriculum?.program?.campus?.name),
      styles: {
        desktop: { minWidth: 120 },
      },
    },
    {
      header: getHeadCell("Secciones"),
      renderer: ({ sectionsCount }) => getColumnCell(sectionsCount),
    },
    {
      header: getHeadCell("Demanda proyectada"),
      renderer: ({ demand }) => getColumnCell(demand?.vacancies),
    },
    {
      header: getHeadCell("Lista cruzada"),
      renderer: ({ demand }) => getColumnCell(demand?.crosslist),
    },
  ];

  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={props.setPage}
      loading={loading}
      error={error}
      dimension="asignatura"
      advanceLink={`/editor/advanceSearch/course/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
