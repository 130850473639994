import React from "react";
import { Link, useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Group } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import { Icon } from "@foris/avocado-ui";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import cx from "classnames";
import css from "./search.module.scss";
import utilsCss from "../../utils/tableUtils.module.scss";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, error, loading, setPage } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "grupo");
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const routeGroup = `/scheduler/editor/group/${contextUrl}`;
  const routeBundle = `/editor/vacancies/${contextUrl}`;
  const routeLink = `/scheduler/editor/link/${contextUrl}`;

  const linkGroupCell = (group: Group) => {
    return (
      <Link
        to={`${routeGroup}/${group.id}`}
        className={cx(css.linkCell, utilsCss.columnLink, css.linkCell_regular)}
      >
        <span className={utilsCss.codeText}>{group?.code}</span> {group.name}
      </Link>
    );
  };

  const linkBundleCell = (group: Group) => {
    const bundle = group?.links[0]?.bundle;

    return (
      <Link to={`${routeBundle}/${bundle?.id}`} className={cx(css?.linkCell, utilsCss.columnLink)}>
        {bundle?.code}
      </Link>
    );
  };

  const linkLinkCell = (group: Group) => {
    return (
      <Link
        to={`${routeLink}/${group?.links[0]?.id}`}
        className={cx(css?.linkCell, utilsCss.columnLink)}
      >
        {group?.links[0]?.code}
      </Link>
    );
  };

  const openGroup = ({ visibleForEnrollment }: Group) => {
    return visibleForEnrollment ? (
      <Icon icon="open-eye" size={20} className={css.linkCell} />
    ) : (
      <Icon icon="closed-eye" size={20} className={css.iconDisabledCell} />
    );
  };

  const capacityCell = (group: Group) => {
    const capacity = group?.capacity;
    const enrollmentsCount = group?.enrollmentStats?.enrollmentsCount
      ? group.enrollmentStats?.enrollmentsCount
      : "sin inscritos";
    const waitingListsCount = group?.enrollmentStats?.waitingListsCount
      ? group.enrollmentStats?.waitingListsCount
      : "";

    return getColumnCell(
      `${capacity} | ${enrollmentsCount}  ${waitingListsCount && `(+${waitingListsCount})`}`,
    );
  };

  const columns = [
    {
      header: getHeadCell("Grupo"),
      renderer: linkGroupCell,
      styles: {
        desktop: {
          minWidth: 250,
          width: 250,
        },
      },
    },
    {
      header: getHeadCell("Sede"),
      renderer: ({ course }: Group) => getColumnCell(course?.curriculum?.program?.campus?.name),
    },
    {
      header: getHeadCell("Periodo"),
      renderer: ({ term }: Group) => getColumnCell(term?.code),
    },
    {
      header: getHeadCell("Bundle"),
      renderer: linkBundleCell,
      styles: {
        desktop: {
          width: 200,
        },
      },
    },
    {
      header: getHeadCell("Liga"),
      renderer: linkLinkCell,
      styles: {
        desktop: {
          width: 200,
        },
      },
    },
    {
      header: getHeadCell("Vacantes e inscritos"),
      renderer: capacityCell,
    },
    {
      header: getHeadCell("Abierto"),
      renderer: openGroup,
      styles: {
        desktop: {
          maxWidth: 130,
        },
      },
    },
  ];

  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension="grupo"
      advanceLink={`/editor/advanceSearch/group/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
