import { pipe, set, lensProp, equals, reject, when, always, defaultTo, omit, propOr } from "ramda";
import { AdaptedGroup, GroupEdition, GroupEditionErrors } from "../models";

const orInf = defaultTo(Infinity);
const orMinInf = defaultTo(-Infinity);
const orZero = defaultTo(0);

/*
 *  *** - *** - *** - *** -
 * *** RULES DEFINITION ***
 *  *** - *** - *** - *** -
 *
 * Softs:
 *    - capacity should be >= 80% max efficiency (against `configMaxCapacity`)
 *    - capacity should be <= 100% max efficiency (against `effectiveMaxCapacity`)
 *    - capacity edited on CRN without assigned classrooms (against `effectiveMaxCapacity`)
 * Hards:
 *    - capacity should be >= number of enrolmments (against `enrollmentsCount`)
 *    - capacity should be >= smaller CRN's classroom (agains `effectiveMaxCapacity`)
 */

/**
 * Returns true if the `capacity` is greater than the `configMaxCapacity`.
 *
 * @param group AdaptedGroup
 * @param edition GroupEdition
 *
 * @return boolean
 */
export const validateCapacityGTMaxEfficiency = (
  group: AdaptedGroup,
  edition: GroupEdition,
): boolean => {
  if (edition?.capacity == null) return false;
  const capacity = orZero(edition?.capacity);
  const threshold = orInf(group?.groupCapacity?.configMaxCapacity);
  return capacity > threshold;
};

/**
 * Returns true if the `capacity` is greater than `groupCapacity.effectiveMaxCapacity`.
 *
 * @param group AdaptedGroup
 * @param edition GroupEdition
 *
 * @return boolean
 */
export const validateCapacityGTSmallerClassrroom = (
  group: AdaptedGroup,
  edition: GroupEdition,
): boolean => {
  if (edition?.capacity == null) return false;
  const capacity = orZero(edition?.capacity);
  const threshold = orInf(group?.groupCapacity?.effectiveMaxCapacity);
  return capacity > threshold;
};

/**
 * Returns true if the `capacity` was edited and the
 * `groupCapacity?.effectiveMaxCapacity` is `null`.
 *
 * @param group AdaptedGroup
 * @param edition GroupEdition
 *
 * @return boolean
 */
export const validateCapacityChangeOnCrnWithoutClassrooms = (
  group: AdaptedGroup,
  edition: GroupEdition,
): boolean => {
  return edition?.capacity != null && group?.groupCapacity?.effectiveMaxCapacity == null;
};

/**
 * Returns true if the `capacity` is less than the group's `groupCapacity.calculatedConfigMinCapacity`.
 *
 * @param group AdaptedGroup
 * @param edition GroupEdition
 *
 * @return boolean
 */
export const validateCapacityLT80MaxEfficiency = (
  group: AdaptedGroup,
  edition: GroupEdition,
): boolean => {
  if (edition?.capacity == null) return false;
  const capacity = orZero(edition?.capacity);
  const threshold = orMinInf(group?.groupCapacity?.calculatedConfigMinCapacity);
  return capacity < threshold;
};

/**
 * Returns true if the `capacity` is less than the min value between the
 * `externalStats?.enrollmentCount` and `enrollmentsCount,`.
 *
 * @param group AdaptedGroup
 * @param edition GroupEdition
 *
 * @return boolean
 */
export const validateCapacityLTEnrollments = (
  group: AdaptedGroup,
  edition: GroupEdition,
): boolean => {
  if (edition?.capacity == null) return false;
  const capacity = orZero(edition?.capacity);
  const threshold = orMinInf(group?.externalStats?.enrollmentCount || group?.enrollmentsCount);
  return capacity < threshold;
};

/**
 * Returns true if the group isn't `active` and the user is trying to augment
 * the group's capacity.
 *
 * @param group AdaptedGroup
 * @param edition GroupEdition
 *
 * @return boolean
 */
export const validateCapacityAugmentedForInactive = (
  group: AdaptedGroup,
  edition: GroupEdition,
) => {
  if (edition?.capacity == null || group?.isActive) return false;

  const capacity = orZero(edition?.capacity);
  const threshold = orInf(group?.capacity);
  return capacity > threshold;
};

/**
 * Validate the **editions** made on the given `group`
 * If the group is not editable the validations will not be performed and an
 * empty object will be returned immediately.
 * If no edition was made, no error will be triggered.
 *
 * @param group AdaptedGroup
 * @param edition GroupEdition
 *
 * @return GroupEditionErrors
 */
const validateGroupEdition = (
  group: AdaptedGroup,
  edition: GroupEdition,
  isSubgroup: boolean,
): GroupEditionErrors => {
  if (!group?.isEditable?.allowed) return {};

  const errorsToOmitOnSubgroups = ["CapacityGTMaxEfficiency", "CapacityLT80MaxEfficiency"];

  return pipe(
    pipe(
      set(
        lensProp<GroupEditionErrors>("CapacityGTMaxEfficiency"),
        validateCapacityGTMaxEfficiency(group, edition),
      ),
      set(
        lensProp<GroupEditionErrors>("CapacityLT80MaxEfficiency"),
        validateCapacityLT80MaxEfficiency(group, edition),
      ),
      set(
        lensProp<GroupEditionErrors>("CapacityGTSmallerClassroom"),
        validateCapacityGTSmallerClassrroom(group, edition),
      ),
      set(
        lensProp<GroupEditionErrors>("CapacityChangeOnCrnWithoutClassrooms"),
        validateCapacityChangeOnCrnWithoutClassrooms(group, edition),
      ),
      set(
        lensProp<GroupEditionErrors>("CapacityLTEnrollments"),
        validateCapacityLTEnrollments(group, edition),
      ),
      set(
        lensProp<GroupEditionErrors>("CapacityAugmentedForInactive"),
        validateCapacityAugmentedForInactive(group, edition),
      ),
    ),
    // since "CapacityChangeOnCrnWithoutClassrooms" and
    // "CapacityGTSmallerClassroom" are effectively the same error for the
    // user, we have to exclude the 2nd one if the 1st one was triggered
    when(
      propOr(false, "CapacityChangeOnCrnWithoutClassrooms"),
      omit(["CapacityGTSmallerClassroom"]),
    ),
    // omit the errors that belong to parent groups if we're dealing with a national subgroup
    when(always(isSubgroup && group?.referentType === "CAMPUS"), omit(errorsToOmitOnSubgroups)),
    // keep only the triggered errors
    reject<any>(equals(false)),
  )({} as GroupEditionErrors);
};

export default validateGroupEdition;
