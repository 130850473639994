import gql from "graphql-tag";

export const QUERY_SELECT_COMPONENT = gql`
  query($scenarioId: ID!, $filter: CourseComponentFilterInput!) {
    data(scenarioId: $scenarioId) {
      courseComponents(filter: $filter) {
        items {
          id
          component {
            id
            code
            name
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;

export const MUTATION_EDIT_SECTION = gql`
  mutation editSection($scenarioId: ID!, $originId: ID!, $input: EditSectionInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editSection(input: $input) {
        skippedValidations
        commited
        section {
          id
        }
        validationErrors {
          __typename
          ... on InvalidInput {
            code
          }
          ... on InvalidComponentSize {
            reason
            component {
              id
              component {
                id
                code
              }
            }
          }
        }
      }
    }
  }
`;
