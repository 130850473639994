import React, { useContext, useState } from "react";
import { Input, Icon } from "@foris/avocado-ui";
import cx from "classnames";
import { Types as EditionTypes } from "../../context/editions.reducer";
import { Context } from "../../context/GroupsManagerContext";
import css from "./multiCapacityInput.module.scss";

interface Props {
  disabled: boolean;
  getGroupIdsToExclude: Set<string>;
}

const MultiCapacityInput: React.FC<Props> = ({ disabled, getGroupIdsToExclude }) => {
  const { state, dispatch } = useContext(Context);
  const [inputValue, setInputValue] = useState<string | number>("");

  const onChange = (e: any) => {
    setInputValue(e?.target?.value);
  };

  const onBlur = () => {
    const value = inputValue?.toString();
    setInputValue(value);

    if (value === "") {
      dispatch({ type: EditionTypes.RestoreGroupsToEditCapacities });
    } else {
      setInputValue("");
      dispatch({
        type: EditionTypes.EditGroupsToEditCapacities,
        payload: {
          capacity: (value as unknown) as number,
          groupIdsToExclude: getGroupIdsToExclude,
          groupsById: state?.data?.groupsById,
        },
      });
    }

    dispatch({ type: EditionTypes.SetGroupsToEdit, payload: {} });
  };

  return (
    <div className={css.multyCapacityInput}>
      <span className={cx(css.label, disabled && css["label--disabled"])}>
        <Icon className={cx(css.icon, disabled && css["icon--disabled"])} icon="edit-pencil" />
        Editar cupos
      </span>
      <Input
        classname={{ global: cx(css.input) }}
        sm
        value={inputValue}
        disabled={disabled}
        onChange={onChange}
        type={"number"}
        onBlur={onBlur}
        onFocus={() => {
          setInputValue("");
        }}
      />
    </div>
  );
};

export default MultiCapacityInput;
