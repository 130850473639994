import gql from "graphql-tag";

export const UPDATE_GROUP_IS_ACTIVE = gql`
  mutation updateGroupIsActive(
    $scenarioId: ID!
    $originId: ID!
    $groupId: ID!
    $isActive: Boolean!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      updateGroupIsActive(groupId: $groupId, isActive: $isActive) {
        commited
        link {
          id
          code
        }
        group {
          id
          visibleForEnrollment
          isActive
        }
        validationErrors {
          ... on ExternalGroupValidation {
            allowed
            rejected_groups
            reason
            __typename
          }
          __typename
        }
      }
    }
  }
`;
