import { useState } from "react";
import { lensPath, view } from "ramda";
import { useMutation } from "react-apollo";
import { GenericInvalidValidation, Group } from "@models/ISchema";
import { UPDATE_GROUP_IS_ACTIVE } from "./../graphql/updateGroupIsActive.mutation";

type ReturnType = [
  { group: Group; commited: boolean; errors: GenericInvalidValidation[] },
  (groupId: string, isActive: boolean) => void,
];

const useUpdateGroupIsActive = (originId: string, scenarioId: string): ReturnType => {
  const [group, setGroup] = useState<Group>(null);
  const [errors, setErrors] = useState([]);
  const [commited, setCommited] = useState<boolean>(false);
  const [updateGroupIsActiveMutation] = useMutation(UPDATE_GROUP_IS_ACTIVE, {});

  const viewGroup = view(lensPath(["cube", "updateGroupIsActive", "group"]));
  const viewValidationErrors = view(lensPath(["cube", "updateGroupIsActive", "validationErrors"]));

  const updateGroupIsActive = async (groupId: string, isActive: boolean): Promise<boolean> => {
    try {
      const { data } = await updateGroupIsActiveMutation({
        variables: {
          originId,
          scenarioId,
          groupId,
          isActive,
        },
      });

      setGroup(viewGroup(data));
      setCommited(Boolean(data?.cube?.updateGroupIsActive?.commited));

      const validationErrors = viewValidationErrors(data);
      if (validationErrors?.length) {
        setErrors(validationErrors);
      }
    } catch (e) {
      setGroup(null);
      setCommited(false);
      console.error(e);
      return false;
    }
  };

  return [{ group, commited, errors }, updateGroupIsActive];
};

export default useUpdateGroupIsActive;
