import React from "react";
import { Link } from "react-router-dom";
import css from "./tableUtils.module.scss";

export const getLinkCell = (route: string, id: string, value: string) => {
  return (
    <Link className={css.columnLink} to={`${route}/${id}`}>
      {value}
    </Link>
  );
};

export const getColumnCell = (text: React.ReactNode) => {
  return <span className={css.columnCell}>{text}</span>;
};

export const getHeadCell = (text: string) => {
  return <span className={css.headText}>{text}</span>;
};
