import React, { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { Link } from "react-router-dom";
import { useParams, Redirect } from "react-router-dom";
import cx from "classnames";
import { IParams } from "../../models/IParams";
import { getCacheRangesByScenario } from "../../config/CalendarRanges/calendarRanges";
import Layout from "../../common/layout/Layout";
import ContextSearch from "../../common/layout/ContextSearch/ContextSearch";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import css from "./home.module.scss";

const EditorHome: React.FC = () => {
  const { origin, scenario, workspace }: IParams = useParams();
  const context: IContextApp = useContext(ContextApp);
  const [valueSelect, setValueSelect] = useState("");
  const client = useApolloClient();
  // if don't exist context-params
  if (!workspace && !scenario && !origin) {
    // if don't exist context-token
    if (context.user.context) {
      const { workspaceId, scenarioId, originId } = context.user.context;
      if (workspaceId && scenarioId) {
        const routeHome = `/home/${workspaceId}/${scenarioId}/${originId}`;
        return <Redirect to={routeHome} />;
      }
    }
  }

  const ranges = async () => {
    const newRanges = await getCacheRangesByScenario(scenario, client);
    if (newRanges) {
      localStorage.setItem("range-start-time", newRanges.start);
      localStorage.setItem("range-end-time", newRanges.end);
    }
  };

  const getDimension = (value: string) => {
    if (value === "league") {
      setValueSelect("link");
    } else {
      setValueSelect(value);
    }
  };

  ranges();
  const routeAdvSearch = valueSelect
    ? `/editor/advanceSearch/${valueSelect}/${workspace}/${scenario}/${origin}`
    : `/editor/advanceSearch/${workspace}/${scenario}/${origin}`;

  return (
    <Layout contextSearch={false}>
      <section className={cx(css.content, "container-row", "row_align--center")}>
        <div className={css.context}>
          <div className={css.advanceSearch}>
            <ContextSearch sendDimension={getDimension} className={css.advanceSearch_search} />
            <Link to={routeAdvSearch} className={css.advanceSearch_link}>
              Búsqueda avanzada
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default EditorHome;
