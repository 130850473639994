import { GroupValidationErrorUnion, ValidationErrorReason, Group } from "../ISchema";
import ErrorWarning, { IErrorWarning } from "./ErrorWarning";

class GroupCreate extends ErrorWarning {
  private error: GroupValidationErrorUnion;
  private group: Group;
  constructor(error: GroupValidationErrorUnion, group?: Group) {
    super();
    this.error = error;
    this.group = group;
  }

  private getMessageInvalidFieldValidation = (): IErrorWarning => {
    let invalidInput = null;
    if (this.error.__typename === "InvalidFieldValidation") {
      switch (this.error.reason) {
        case ValidationErrorReason.GroupCodeAlreadyTaken:
          const decodeJson = this.error.meta ? JSON.parse(this.error.meta) : null;
          const groupCode = this.group?.name ? ` ${this.group?.name}` : "";
          const errorExist = `La etiqueta de grupo${groupCode} ya existe`;
          let subjectCode = "",
            termCode = "";
          if (decodeJson) {
            subjectCode = decodeJson.subject_code
              ? ` para la asignatura ${decodeJson.subject_code}`
              : "";
            termCode = decodeJson.term_code ? ` y en el período ${decodeJson.term_code}` : "";
          }
          const message = `${errorExist}${subjectCode}${termCode}. Debe ingresar un código único.`;
          invalidInput = this.setError(message);
          break;
        default:
          invalidInput = null;
          break;
      }
    }
    return invalidInput;
  };

  public getMessage = () => {
    switch (this.error && this.error.__typename) {
      case "InvalidFieldValidation":
        return this.getMessageInvalidFieldValidation();
      default:
        return null;
    }
  };
}

export default GroupCreate;
