import * as React from "react";
import "./radioButton.scss";

interface IRadioButton {
  className?: string;
  label?: string;
  disabled?: boolean;
  check?: boolean;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
  name: string;
}

const RadioButton: React.FC<IRadioButton> = (props: IRadioButton) => {
  const { className, label, disabled, check, onClick, onChange, name } = props;

  return (
    <section className={`component_radio ${className} ${disabled && "component_radio--disabled"}`}>
      <label className="cnt_option">
        <span className="cnt_option-label">{label}</span>
        <input
          className="cnt_option-input"
          name={name}
          type="radio"
          checked={check}
          disabled={disabled}
          onClick={(e: React.MouseEvent<HTMLInputElement>) =>
            onClick && onClick(e.currentTarget.checked)
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange && onChange(e.currentTarget.checked)
          }
        />
        <span className="cnt_option-check"></span>
      </label>
    </section>
  );
};

export default RadioButton;
