import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Button } from "@foris/avocado-ui";
import cx from "classnames";
import { AppContext } from "../../../context/EditSessionsContext";
import { Types } from "../../../context/formData.reducer";
import { Types as TypeSwitch } from "../../../context/switchPage.reducer";
import {
  ClassroomFilterInput,
  ClassroomFilterFieldsInput,
  ClassroomPage,
  Session,
} from "@models/ISchema";
import { IParams } from "@models/IParams";
import AdvanceTable from "../../../components/Classroom/AdvanceTable";
import AdvanceForm from "../../../components/Classroom/AdvanceForm";
import { classroomsAvailabilities } from "../../../graphql/classroomAdvanceSearch.query";
import { daysEn } from "@utils/days";
import css from "./advanceSearch.module.scss";

const AdvanceSearch: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { scenario, origin }: IParams = useParams();
  const client = useApolloClient();
  const [data, setData] = useState<ClassroomPage>(null);
  const [loading, setLoading] = useState(false);
  const [building, setBuilding] = useState(null);
  const [classroomType, setClassroomType] = useState(null);
  const [minCapacity, setMinCapacity] = useState(null);
  const [maxCapacity, setMaxCapacity] = useState(null);
  const [hasEvents, setHasEvents] = useState(null);
  const [selected, setSelected] = useState(state?.form?.editedSessions?.classrooms);
  const [page, setPage] = useState(1);
  const size = 10;
  const selectedCreate = state?.form?.selectedCreateSession;
  const countSessions = selectedCreate ? 1 : state?.form?.selectedSessions?.length;
  const title =
    countSessions > 1
      ? `Buscar salas - ${countSessions} sesiones`
      : `Buscar salas - ${countSessions} sesión`;
  let codeSessions = [];
  if (!selectedCreate) {
    codeSessions = state?.form?.selectedSessions.map(value => value.id);
  } else {
    codeSessions = ["Nueva sesión"];
  }

  const getVariables = (
    building: string,
    classroomType: string,
    minCapacity: string,
    maxCapacity: string,
    hasEvents: boolean,
    _page = page,
  ) => {
    const intervalsCheck = state?.form?.editedSessions?.intervals?.filter(
      value => value.checked === true,
    );
    const availabilities = state?.form?.selectedSessions
      ?.map(_value => {
        const value = _value as Session;
        const intervals =
          intervalsCheck.length > 0
            ? intervalsCheck.map(value => parseInt(value.id))
            : value?.assignment?.intervals.map(value => parseInt(value.id));
        const dayEdited = state?.form?.editedSessions?.blocks?.day;
        const day = dayEdited ?? value?.assignment?.blockRange?.start?.day;
        if (day) {
          let dayNumber = daysEn.findIndex(value => value === day);
          dayNumber = dayNumber || dayNumber === 0 ? dayNumber + 1 : 0;
          const startEdited = state?.form?.editedSessions?.blocks?.startTime;
          const start = startEdited ?? value?.assignment?.blockRange?.start?.startingTime;
          const endEdited = state?.form?.editedSessions?.blocks?.endTime;
          const end = endEdited ?? value?.assignment?.blockRange?.end?.endingTime;
          return {
            dayId: [dayNumber?.toString()],
            blockRange: {
              start: start,
              end: end,
            },
            intervals,
          };
        }
        return null;
      })
      .filter(value => value !== null);
    const fieldsInputs: ClassroomFilterFieldsInput[] = [];
    if (classroomType)
      fieldsInputs.push({
        classroomTypeId: {
          eq: parseInt(classroomType),
        },
      });
    if (building)
      fieldsInputs.push({
        buildingId: {
          eq: parseInt(building),
        },
      });
    if (hasEvents)
      fieldsInputs.push({
        hasEvents,
      });

    if (minCapacity)
      fieldsInputs.push({
        capacity: {
          value: {
            gte: parseInt(minCapacity),
          },
        },
      });
    if (maxCapacity)
      fieldsInputs.push({
        capacity: {
          value: {
            lte: parseInt(maxCapacity),
          },
        },
      });
    const filter: ClassroomFilterInput = {
      fields: {
        and: [...fieldsInputs, { availabilities }],
      },
      pagination: {
        page: _page,
        size,
      },
    };
    const variables = {
      originId: origin,
      scenarioId: scenario,
      filter,
    };
    return variables;
  };

  const handlerSearch = async (
    _building: string,
    _classroomType: string,
    _minCapacity: string,
    _maxCapacity: string,
    _hasEvents: boolean,
  ) => {
    setData(null);
    setBuilding(_building);
    setClassroomType(_classroomType);
    setMinCapacity(_minCapacity);
    setMaxCapacity(_maxCapacity);
    setHasEvents(_hasEvents);
  };

  const classroomSearch = async (
    building: string,
    classroomType: string,
    minCapacity: string,
    maxCapacity: string,
    hasEvents: boolean,
    _page = page,
  ) => {
    try {
      setLoading(true);
      const variables = getVariables(
        building,
        classroomType,
        minCapacity,
        maxCapacity,
        hasEvents,
        _page,
      );
      const { data } = await client.query({ query: classroomsAvailabilities, variables });
      const dataQuery: ClassroomPage = data?.cube?.classroomsAvailabilities;
      setData(dataQuery);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <article className={css.advanceSearch}>
      <header className={css.header}>
        <h1 className={css.header_title}>{title}</h1>
        <h2 className={css.header_subTitle}>{`(${codeSessions.join(" | ")})`}</h2>
      </header>
      <AdvanceForm
        onCallback={(
          building: string,
          classroomType: string,
          minCapacity: string,
          maxCapacity: string,
          hasEvents: boolean,
        ) => {
          handlerSearch(building, classroomType, minCapacity, maxCapacity, hasEvents);
          classroomSearch(building, classroomType, minCapacity, maxCapacity, hasEvents);
        }}
        setPage={() => setPage(1)}
      />
      <section className={css.content}>
        <header className={cx(css.resume, "container-row")}>
          <p className={css.resume_count}>
            {data?.pageInfo?.total
              ? `${data?.pageInfo?.total} Sala${data?.pageInfo?.total > 1 ? "s" : ""}`
              : ""}

            <span className={css.selected}>
              {data?.pageInfo?.total && selected?.length
                ? ` | ${selected?.length} Sala${selected?.length > 1 ? "s" : ""} seleccionada${
                    selected?.length > 1 ? "s" : ""
                  }`
                : ""}
            </span>
          </p>
        </header>
        <AdvanceTable
          loading={loading}
          data={data?.items}
          size={data?.pageInfo?.size}
          total={data?.pageInfo?.total}
          page={page}
          selected={selected}
          setSelected={setSelected}
          onPageChange={value => {
            setPage(value);
            classroomSearch(building, classroomType, minCapacity, maxCapacity, hasEvents, value);
          }}
        />
      </section>
      {data?.pageInfo?.total > 0 && (
        <footer className={cx(css.footer, "container-row")}>
          <Button
            typeButton="transparent"
            className={css.footer_btn}
            onClick={() => {
              dispatch({ type: TypeSwitch.FormPage, payload: true });
            }}
          >
            Cancelar
          </Button>
          <Button
            className={css.footer_btn}
            onClick={() => {
              dispatch({
                type: Types.ClassroomEditedSessions,
                payload: {
                  classrooms: selected,
                },
              });
              dispatch({ type: Types.SavedSessions, payload: [state.form.editedSessions] });
              dispatch({ type: TypeSwitch.FormPage, payload: true });
            }}
          >
            Guardar
          </Button>
        </footer>
      )}
    </article>
  );
};

export default AdvanceSearch;
