import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { always, head, lensPath, lensProp, map, pipe, prepend, set, when } from "ramda";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { IParams } from "@models/IParams";
import { AcademicUnit, AcademicUnitFilterInput } from "@models/ISchema";
import { Context } from "../../context/GroupsManagerContext";
import { Types } from "../../context/filters.reducer";
import { departmentSearch } from "../../graphql/departmentSearch.query";
import { SelectableOption } from "../../models";

interface DepartmentSelectorProps {
  onLoading?: (isLoading: boolean) => void;
}

const DepartmentSelector: React.FC<DepartmentSelectorProps> = ({ onLoading }) => {
  const { state, dispatch } = useContext(Context);
  const client = useApolloClient();
  const allDepartmentsOption = { id: "*", value: "*", label: "Buscar o seleccionar", self: null };
  const [selectedDepartment, setSelectedDepartment] = useState<SelectableOption<AcademicUnit>>(
    Boolean(state?.filters?.department)
      ? {
          id: state?.filters?.department?.id,
          value: state?.filters?.department?.id,
          label: state?.filters?.department?.name ?? "",
          self: state?.filters?.department,
        }
      : allDepartmentsOption,
  );
  const [page, setPage] = useState(0);
  const [, setSearchTerm] = useState("");
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const { origin, scenario }: IParams = useParams();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [isLoadingSelectableDepartments, setIsLoadingSelectableDepartments] = useState(false);

  const variableFields: AcademicUnitFilterInput = useMemo(() => {
    const subjectId = state?.filters?.subject?.id;
    const campusId = state?.filters?.campus?.id;
    const schoolId = state?.filters?.school?.id;

    if (!subjectId && !campusId && !schoolId) return undefined;

    return pipe(
      when(always(Boolean(subjectId)), set(lensPath(["subject", "eq"]), subjectId)),
      when(always(Boolean(campusId)), set(lensPath(["campus", "eq"]), campusId)),
      when(always(Boolean(schoolId)), set(lensPath(["school", "eq"]), schoolId)),
    )({});
  }, [state?.filters?.subject, state?.filters?.campus, state?.filters?.school]);

  useEffect(() => {
    dispatch({ type: Types.SetDepartment, payload: selectedDepartment?.self });
  }, [selectedDepartment]);

  useEffect(() => {
    onLoading?.(isLoadingSelectableDepartments);
  }, [isLoadingSelectableDepartments]);

  /**
   * Clean the selection if the filters are updated from outside this component
   * (in particular, with the context's action `CleanFilters`).
   */
  useEffect(() => {
    if (!state?.filters?.department) setSelectedDepartment(allDepartmentsOption);
  }, [state?.filters?.department]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const variables = {
          query: departmentSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              pagination: { page, size, searchTerm },
              fields: variableFields,
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery = data?.data;
        const pageInfo = dataQuery?.groupsManagerDepartments?.pageInfo;
        const departments = dataQuery?.groupsManagerDepartments?.items;

        const options = map(
          (department: AcademicUnit) => ({
            id: department?.id,
            value: department?.id,
            label: department?.name ?? "",
            self: department,
          }),
          departments ?? [],
        );

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, variableFields],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: newSearchPage === 1 ? prepend(allDepartmentsOption, options) : options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  /**
   * If a subject or campus is selected, request the selectable departments and
   * auto-select it if there's only one option.
   */
  useEffect(() => {
    const doRequest = async () => {
      setIsLoadingSelectableDepartments(true);

      const { options } = await requestItems("", 1);
      if (options?.length === 1) {
        setSelectedDepartment(head(options));
      } else if (options?.length === 0) {
        setSelectedDepartment(
          set(lensProp("label"), "Departamento no definido", allDepartmentsOption),
        );
      }

      setIsLoadingSelectableDepartments(false);
    };

    if (state?.filters?.subject || state?.filters?.campus) {
      doRequest();
    } else if (!state?.filters?.subject && !state?.filters?.campus) {
      setSelectedDepartment(allDepartmentsOption);
    }

    return () => {
      // stop any request when the component unmounts
      client.stop();
      setIsLoadingSelectableDepartments(false);
    };
  }, [state?.filters?.subject, state?.filters?.campus]);

  return (
    <SelectPagination
      label="Departamento"
      isDisabled={false}
      value={selectedDepartment}
      onInputChange={setSearchTerm}
      onChange={setSelectedDepartment}
      loadOptions={loadOptions}
    />
  );
};

export default DepartmentSelector;
