import React, { useContext, useEffect, useState } from "react";
import * as R from "ramda";
import { Switch, Icon } from "@foris/avocado-ui";
import cx from "classnames";
import { AppContext } from "../../context/EditSessionsContext";
import { EditedSession, TypeAssignment, Types } from "../../context/formData.reducer";
import css from "./formItem.module.scss";
import { Session } from "@models/ISchema";
interface FormItemProps {
  title?: string;
  infoMessage?: string;
  type?: TypeAssignment;
  children: React.ReactNode;
}

const FormItem: React.FC<FormItemProps> = (props: FormItemProps) => {
  const { children, title, type, infoMessage } = props;
  const { state, dispatch } = useContext(AppContext);
  const {
    assignmentSame: assignment,
    assignmentEdited: edited,
    selectedSessions,
    selectedCreateSession,
    savedSessions,
  } = state?.form;
  const [switchIsDisabled, setSwitchIsDisabled] = useState(false);
  const [someDeletedSessionIsSelected, setSomeDeletedSessionIsSelected] = useState(false);
  const [displayContent, setDisplayContent] = useState(edited ? edited[type] : false);

  const assignmentSame = assignment ? assignment[type] : null;
  const count = selectedSessions?.length ?? 0;
  const showCheck = count === 1 || selectedCreateSession ? false : true;

  const setDisplay = (value: boolean) => {
    const displayEdit = { [type]: !someDeletedSessionIsSelected && value };
    dispatch({ type: Types.AssignmentEdited, payload: displayEdit });
    setDisplayContent(!someDeletedSessionIsSelected && value);
  };

  useEffect(() => {
    if (!selectedSessions || (!assignmentSame && displayContent)) setDisplay(false);
    if (assignmentSame && !displayContent) setDisplay(true);
  }, [assignmentSame]);

  useEffect(() => {
    if (!selectedSessions?.length) return;
    setSomeDeletedSessionIsSelected(
      R.pipe(
        R.map(R.propOr("-", "id")),
        R.any(R.flip(R.has)(state?.form?.sessionsToDelete)),
      )(selectedSessions),
    );
  }, [selectedSessions]);

  useEffect(() => {
    if (someDeletedSessionIsSelected) {
      dispatch({ type: Types.AssignmentEdited, payload: { [type]: false } });
      setDisplayContent(false);
    } else {
      setDisplayContent(edited ? edited[type] : false);
    }
  }, [someDeletedSessionIsSelected]);

  /**
   * Disable the `blocks` switch if there are selected sessions with schedules
   * and without schedules at the same time.
   */
  useEffect(() => {
    if (!selectedSessions.length) return;

    const allBlockTypesAreEq = (selectedSessions: (Session | EditedSession)[]) => {
      const blockType = R.ifElse(
        R.has("blocks"),
        R.pipe(R.propOr({}, "blocks"), R.propOr("", "selected")),
        R.pipe(
          R.pipe(R.propOr({}, "assignment"), R.propOr({}, "blockRange")),
          R.ifElse(R.isEmpty, R.always("blocks"), R.always("hours")),
        ),
      );

      const savedSessionsById = R.reduce(
        (sessionsById, session) => R.assoc(session.id, session, sessionsById),
        {},
        savedSessions,
      );
      return R.pipe(
        R.map((session: Session | EditedSession) =>
          session.id in savedSessionsById ? savedSessionsById[session.id] : session,
        ),
        R.map(blockType),
        R.pipe(
          R.reduce(
            ({ eq, prev }, curr) =>
              !prev || !eq ? { eq, prev: curr } : { eq: prev === curr, prev: curr },
            { eq: true, prev: null },
          ),
          R.prop("eq"),
        ),
      )(selectedSessions);
    };

    setSwitchIsDisabled(type === "blocks" && !allBlockTypesAreEq(selectedSessions));
  }, [selectedSessions, type]);

  return (
    <section className={css.formItem}>
      <header className={cx(css.header, "container-row", "row--between", "row_align--center")}>
        {!infoMessage && <h2 className={cx(css.header_title)}>{title}</h2>}
        {infoMessage && (
          <div className={cx(css.header_titleWithinfoMessage)}>
            <h2 className={cx(css.header_titleWithinfoMessage_title)}>{title}</h2>
            <p className={cx(css.header_titleWithinfoMessage_infoMessage)}>
              <Icon icon="circle-full-info" size={14} />
              {"  "}
              {infoMessage}
            </p>
          </div>
        )}
        {showCheck && (
          <Switch
            labelLeft="Editar"
            disabled={someDeletedSessionIsSelected || switchIsDisabled}
            checked={displayContent}
            className={cx(css.header_check)}
            onChange={e => setDisplay(e.target.checked)}
          />
        )}
      </header>
      {(displayContent || !showCheck) && children}
    </section>
  );
};

export default FormItem;
