import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "react-apollo";
import { Button } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { ClassroomBookingStatusEnum, ClassroomBookingStatusPayload } from "@models/ISchema";
import Modal from "@components/modal/Modal";
import { bookingDeleteMutation } from "../../graphql/bookingRemove.mutation";
import css from "./removeBookingModal.module.scss";

interface RemoveBookingProps {
  campus: string;
  open: boolean;
  onClose: () => void;
}

const RemoveBookingModal: React.FC<RemoveBookingProps> = (props: RemoveBookingProps) => {
  const { open, onClose, campus } = props;
  const { id, origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const [active, setActive] = useState(open);
  const [loading, setLoading] = useState(false);
  const [bookingDelete] = useMutation(bookingDeleteMutation, {});
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const onDeleteBooking = async () => {
    try {
      setLoading(true);
      const variables = {
        scenarioId: parseInt(scenario),
        originId: parseInt(origin),
        input: {
          dryRun: false,
          skipValidations: false,
          bookingIds: [parseInt(id)],
          status: ClassroomBookingStatusEnum.Cancelled,
          comment: ClassroomBookingStatusEnum.Cancelled,
        },
      };
      const { data } = await bookingDelete({ variables });
      const deleteBooking: ClassroomBookingStatusPayload = data.cube.classroomBookingChangeStatus;
      if (deleteBooking.commited) {
        const pathBookings = `/booking/list/${contextUrl}?campus=${campus}`;
        history.push(pathBookings);
      }
      setLoading(false);
    } catch (error) {
      console.error("[onDeleteBooking]", error);
    }
  };

  const headerModal = () => {
    if (!loading) {
      return (
        <p className={cx(css.modalBookingDetail_title, "col_12")}>
          Eliminar reserva: ¿estás seguro?
        </p>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (active !== open) setActive(open);
  }, [open]);

  return (
    <Modal
      className={css.modalBookingDetail}
      show={active}
      onClose={() => {
        setActive(false);
        onClose();
      }}
      header={() => headerModal()}
    >
      {!loading && (
        <section className={cx(css.bookingDetailInfo, "container-row")}>
          <p className={cx(css.bookingDetailInfo_text, "col_12")}>
            Si elimina la reserva pierde la sala reservada
          </p>
          <div className={cx("container-row")}>
            <Button
              className={css.bookingDetailInfo_btn}
              typeButton="transparent"
              onClick={() => {
                setActive(false);
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              className={css.bookingDetailInfo_btn}
              typeButton="error"
              onClick={() => onDeleteBooking()}
            >
              Eliminar
            </Button>
          </div>
        </section>
      )}
    </Modal>
  );
};

export default RemoveBookingModal;
