import { BLOCK_RANGES_QUERY } from "./calendar.queries";
import { DataQuery } from "../../models/ISchema";

const getRangesByScenario = async (scenario: any, client: any) => {
  try {
    const { data } = await client.query({
      query: BLOCK_RANGES_QUERY,
      variables: {
        scenarioId: scenario,
      },
    });
    if (data.data) {
      // Return values
      const start = data.data.institutionCalendarRange.startTime;
      const end = data.data.institutionCalendarRange.endTime;
      return { start, end };
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getCacheRangesByScenario = async (scenario: any, client: any) => {
  try {
    const { data } = await client.readQuery({
      query: BLOCK_RANGES_QUERY,
      variables: {
        scenarioId: scenario,
      },
    });
    const values: DataQuery = data;
    if (values.institutionCalendarRange) {
      // Return values
      const start = values.institutionCalendarRange.startTime;
      const end = values.institutionCalendarRange.endTime;
      return { start, end };
    }
  } catch (error) {
    const apiResult = await getRangesByScenario(scenario, client);
    return apiResult;
  }
};

export { getCacheRangesByScenario };
