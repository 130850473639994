import React from "react";
import { useQuery } from "react-apollo";
import Select from "react-select";
import { GET_WORKSPACES } from "./context.queries";
import Skeleton from "react-loading-skeleton";

interface ISelectProps {
  workspaceValue: any;
  onChange: (value: any) => void;
}

const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

const WorkspaceSelect: React.FC<ISelectProps> = (props: ISelectProps) => {
  const { workspaceValue, onChange } = props;
  const { loading, error, data } = useQuery<any, any>(GET_WORKSPACES, {
    variables: {},
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={"100%"} />
      </div>
    );
  if (error) return <Select options={[]} placeholder="Workspace" />;

  const options = data.base.workspaces.map((value: any) => {
    return { label: value.name, value: value.id };
  });

  return (
    <div style={{ padding: "10px 0" }}>
      <Select
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#e5e8ec",
            primary: "#6993c9",
            neutral80: "#575d62",
            neutral90: "#575d62",
          },
        })}
        maxMenuHeight={100}
        options={options}
        placeholder="Seleccionar"
        value={workspaceValue}
        onChange={(value: any): void => onChange(value)}
      />
    </div>
  );
};
export default WorkspaceSelect;
