import { GroupCapacity } from "@models/ISchema";
import { AdaptedGroupCapacityReason } from "../models";

const capacityCalculationReasonText = (groupCapacity: GroupCapacity) => {
  switch (groupCapacity?.calculatedCapacityReason) {
    case AdaptedGroupCapacityReason?.LinkCapacity:
      return "Capacidad desde la liga";
    case AdaptedGroupCapacityReason?.ClassroomCapacity:
      return "Capacidad desde la sala";
    case AdaptedGroupCapacityReason?.ZeroCapacity:
      return "Capacidad cero (Sin regla asociada)";
    default:
      return "Capacidad cero (Sin regla asociada)";
  }
};

export default capacityCalculationReasonText;
