const formatTime = (startTime: any, endTime: any) => {
  const start = new Date();
  const end = new Date();
  // Format start date
  if (startTime && endTime) {
    const timeStart = startTime.split(":");
    start.setHours(timeStart[0]);
    start.setMinutes(timeStart[1]);
    // Format end date
    const timeEnd = endTime.split(":");
    end.setHours(timeEnd[0]);
    end.setMinutes(timeEnd[1]);
  }

  return { start, end };
};

export { formatTime };
