import { lensPath, lensProp, set } from "ramda";
import {
  GroupsManagerEnrollmentsOrderByFieldsEnum,
  GroupsManagerEnrollmentsSearchableFieldsEnum,
  GroupsManagerGroupChangesOrderByFieldsEnum,
  GroupsManagerGroupChangesSearchableFieldsEnum,
  GroupsManagerSearchOrderByFields,
  GroupsManagerSearchSearchableFieldsEnum,
  OrderByDirection,
} from "@models/ISchema";
import { TableFiltersReducerType } from "./types";
import {
  EnrollmentsTableColumn,
  GroupsChangesTableColumn,
  GroupsManagerTableColumn,
  SubGroupsTableColumn,
} from "../models";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const setAll = (
  newState: TableFiltersReducerType,
  state: TableFiltersReducerType, // eslint-disable-next-line @typescript-eslint/no-unused-vars
): TableFiltersReducerType => {
  return newState;
};

/**
 * Set the columnsToHide.
 *
 * @param columnsToHide The columns to hide.
 *
 * @returns TableFiltersReducerType.
 */
export const setColumnsToHide = (
  columnsToHide: Set<GroupsManagerTableColumn>,
  state: TableFiltersReducerType,
): TableFiltersReducerType => {
  return set(lensProp("columnsToHide"), new Set(columnsToHide), state);
};

/**
 * Set the orderBy's `field` and `direction`.
 *
 * @param orderBy The orderBy object.
 * @param state The current state.
 *
 * @returns TableFiltersReducerType.
 */
export const setOrderBy = (
  orderBy: {
    header:
      | GroupsManagerTableColumn
      | GroupsChangesTableColumn
      | SubGroupsTableColumn
      | EnrollmentsTableColumn;
    field:
      | GroupsManagerSearchOrderByFields
      | GroupsManagerGroupChangesOrderByFieldsEnum
      | GroupsManagerEnrollmentsOrderByFieldsEnum;
    direction: OrderByDirection;
  },
  state: TableFiltersReducerType,
): TableFiltersReducerType => {
  return set(lensProp("orderBy"), orderBy, state);
};

/**
 * Set the searchBy's `fields`.
 *
 * @param fields The fields to search by.
 * @param state The current state.
 *
 * @returns TableFiltersReducerType.
 */
export const setSearchByFields = (
  fields:
    | GroupsManagerSearchSearchableFieldsEnum[]
    | GroupsManagerGroupChangesSearchableFieldsEnum[]
    | GroupsManagerEnrollmentsSearchableFieldsEnum[],
  state: TableFiltersReducerType,
): TableFiltersReducerType => {
  return set(lensPath(["searchBy", "fields"]), fields, state);
};

/**
 * Set the searchBy's `text`.
 *
 * @param text The text to search.
 * @param state The current state.
 *
 * @returns TableFiltersReducerType.
 */
export const setSearchByText = (
  text: string,
  state: TableFiltersReducerType,
): TableFiltersReducerType => {
  return set(lensPath(["searchBy", "text"]), text, state);
};
