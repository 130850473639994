import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// en folder
import enCommonJSON from "../locale/en/common.json";
// es folder
import esCommonJSON from "../locale/es/common.json";

const namespaces = ["common"];

i18n.use(initReactI18next).init({
  resources: {
    en: {
      common: enCommonJSON,
    },
    es: {
      common: esCommonJSON,
    },
  },
  lng: "es",
  ns: namespaces,
  defaultNS: "common",
});

export default i18n;
