export type ErrorTpe = "ERROR" | "WARNING";

export interface IErrorWarning {
  type: ErrorTpe;
  message: string;
}

class ErrorWarning {
  public setWarning = (message: string) => {
    const newWarning: IErrorWarning = {
      type: "WARNING",
      message: message,
    };
    return newWarning;
  };

  public setError = (message: string) => {
    const newError: IErrorWarning = {
      type: "ERROR",
      message: message,
    };
    return newError;
  };
}

export default ErrorWarning;
