import gql from "graphql-tag";

const sessionsQuery = gql`
  query sectionEditorView($scenarioId: ID!, $originId: ID!, $sectionId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      section(id: $sectionId) {
        id
        sessions {
          id
          vacancies
          assignment {
            blockRange {
              start {
                id
                day
                startingTime
              }
              end {
                id
                endingTime
              }
            }
            intervals {
              id
              termParts {
                id
                code
                name
              }
            }
            instructors {
              name
              code
              id
            }
            classrooms {
              name
              code
              id
            }
            day
          }
        }
      }
    }
  }
`;

export { sessionsQuery };
