import React, { useState, useEffect } from "react";
import cx from "classnames";
import { InstructorLink } from "../../../models/ISchema";
import { ILeague, IGroupValue } from "../contextVacancies/ContextVacancies";
import Header from "./Header";
import InstructorList from "./InstructorList";
import TotalList from "./TotalList";
import css from "./instructor.module.scss";
import { getTotalBlocksPercent } from "../utils";
interface IInstructorProps {
  linkId: string;
  item: ILeague;
  index: number;
  setValues: any;
  activeEdit: boolean;
}

const Instructor: React.FC<IInstructorProps> = (props: IInstructorProps) => {
  const { linkId, item, index, setValues, activeEdit } = props;
  const [countResponsibility, setCountResponsibility] = useState<Array<IGroupValue>>(null);
  const totalBlocks = [];
  const percentBlocks = [];

  const compareGroups = (a: InstructorLink, b: InstructorLink) => {
    let comparison = 0;
    if (a.instructor.name > b.instructor.name) comparison = 1;
    else if (a.instructor.name < b.instructor.name) comparison = -1;
    return comparison;
  };

  const orderInstructor: Array<InstructorLink> = item.instructors.sort(compareGroups);

  const onChangeInput = (value: any, indexInstructor: number, typeParam: any) => {
    let newValue = value ? parseInt(value) : 0;
    if (newValue > 100) newValue = 100;
    if (newValue <= 0) newValue = 0;
    if (typeParam === "responsability") {
      const findInstructor: IGroupValue = countResponsibility[indexInstructor];
      findInstructor.teachingHoursPercentage = newValue;
      const totalResponsibility = [...countResponsibility];
      setCountResponsibility(totalResponsibility);
      setValues(countResponsibility, index);
    } else if (typeParam === "payment") {
      const findInstructor: IGroupValue = countResponsibility[indexInstructor];
      findInstructor.paymentPercentage = newValue;
      const totalPayment = [...countResponsibility];
      setCountResponsibility(totalPayment);
      setValues(countResponsibility, index);
    }
  };

  const onChangeIsPrimary = (value: any, indexInstructor: number) => {
    const cloneData = [...countResponsibility];
    const findInstructor: IGroupValue = cloneData[indexInstructor];
    const newData: IGroupValue[] = [];
    if (findInstructor) {
      cloneData.forEach(link => {
        const newLink = { ...link };
        if (value && link?.instructor?.id === findInstructor?.instructor?.id) {
          newLink.isPrimary = true;
        } else {
          newLink.isPrimary = false;
        }
        newData.push(newLink);
      });
    }
    setCountResponsibility(newData);
    setValues(newData, index);
  };

  const getInitialState = () => {
    const countTotalBlocks: Array<IGroupValue> = [];
    item.instructors &&
      item.instructors.forEach((instructor: InstructorLink) => {
        const blockPercent = (instructor.totalBlocks * 100) / item.totalBlocks;
        const groupValue: IGroupValue = {
          linkId,
          instructor,
          blockPercent: blockPercent ? Math.round(blockPercent) : 0,
          teachingHoursPercentage: instructor.teachingHoursPercentage || 0,
          paymentPercentage: instructor.paymentPercentage || 0,
          isPrimary: instructor.isPrimary || false,
        };
        countTotalBlocks.push(groupValue);
      });
    setCountResponsibility(countTotalBlocks);
  };

  useEffect(() => {
    if (!countResponsibility) getInitialState();
  });

  let unassigment: InstructorLink = null;

  if (item.blocksWithoutInstructorAssigment > 0) {
    const blockPercent = (item.blocksWithoutInstructorAssigment * 100) / item.totalBlocks;
    unassigment = {
      ...item,
      id: "unassigment",
      instructor: {
        id: "unassigment",
        code: "",
        name: "Sin docente asignado",
        contract: null,
      },
      totalBlocks: item.blocksWithoutInstructorAssigment,
      link: null,
      blocksPercentage: Math.round(blockPercent) || 0,
    };
  }

  return (
    <section className={cx(css.cntEdit)}>
      <Header index={index} />
      {orderInstructor.map((instructor: InstructorLink, indexInst: number) => {
        totalBlocks.push(instructor.teachingHoursPercentage);
        percentBlocks.push(instructor.totalBlocks);
        let value: any = "";
        let valuePayment: any = "";
        let valueIsPrimary = false;

        if (countResponsibility) {
          value = countResponsibility[indexInst]
            ? countResponsibility[indexInst].teachingHoursPercentage
            : 0;
          valuePayment = countResponsibility[indexInst]
            ? countResponsibility[indexInst].paymentPercentage
            : 0;
          valueIsPrimary = countResponsibility[indexInst]
            ? countResponsibility[indexInst].isPrimary
            : false;
        }
        const blockPercent = getTotalBlocksPercent(instructor?.totalBlocks, item?.totalBlocks);

        return (
          <InstructorList
            linkId={linkId}
            key={instructor.id}
            instructor={instructor}
            activeEdit={activeEdit}
            blockPercent={Math.round(blockPercent) || 0}
            index={indexInst}
            isPrimary={valueIsPrimary}
            totalBlocks={value}
            paymentPercent={valuePayment}
            onChange={(value: any) => {
              onChangeInput(value, indexInst, "responsability");
            }}
            onChangePayment={(value: any) => {
              onChangeInput(value, indexInst, "payment");
            }}
            onChangeIsPrimary={(value: any) => {
              onChangeIsPrimary(value, indexInst);
            }}
          />
        );
      })}
      {unassigment && (
        <InstructorList
          linkId={linkId}
          key={unassigment.id}
          instructor={unassigment}
          activeEdit={false}
          isPrimary={unassigment.isPrimary}
          blockPercent={unassigment.blocksPercentage}
          paymentPercent={unassigment.paymentPercentage}
          index={0}
          totalBlocks={0}
        />
      )}

      <TotalList
        indexList={index}
        countResponsibility={countResponsibility}
        totalBlocks={item?.totalBlocks || 0}
      />
    </section>
  );
};

export default Instructor;
