import gql from "graphql-tag";

export const groupsSearch = gql`
  query groupsManagerData(
    $scenarioId: ID!
    $originId: ID!
    $filter: GroupsManagerSearchFilterInput!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      groupsManagerSearch(filter: $filter) {
        items {
          capacity
          clientCode
          code
          enrollmentsCount
          id
          isActive
          label
          utilization
          visibleForEnrollment
          isReferent
          referentType
          enrollmentStats {
            capacity
            usedCapacity
            enrollmentsCount
            waitingListsCount
          }
          links {
            id
            vacancies
            packages {
              id
              name
              code
            }
          }
          labels {
            id
            code
            name
            category {
              id
              code
              name
            }
          }
          subject {
            id
            code
            name
            credits
            academicUnits {
              id
              name
              academicUnitType {
                id
                code
                name
              }
            }
          }
          externalStats {
            capacity
            bookingCount
            enrollmentCount
            usedCapacity
            waitingListCount
            updatedAt
          }
          campus {
            code
          }
          course {
            code
            name
            tags
          }
          term {
            code
          }
          primaryInstructor {
            id
            code
            name
          }
          groupCapacity {
            id
            calculatedCapacity
            calculatedCapacityReason
            configMinCapacity
            configMaxCapacity
            effectiveMinCapacity
            effectiveMaxCapacity
            defaultCapacityPercentage
            calculatedConfigMinCapacity
            calculatedConfigMaxCapacity
          }
          isEditable {
            allowed
            reason
          }
          scheduleSummary {
            dateRange
            days {
              id
              name
              code
            }
            schedule
            classrooms {
              id
              code
              name
              capacity
              building {
                id
                code
                name
                campus {
                  id
                  code
                  name
                }
              }
            }
            instructors {
              id
              code
              name
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
        __typename
      }
      __typename
    }
  }
`;
