import React from "react";
import css from "./../autocomplete.module.scss";

export interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  options: Array<IOption>;
  onSelect: (value: IOption) => void;
}

const Options: React.FC<IOptions> = (props: IOptions) => {
  const { options, onSelect } = props;
  if (options.length === 0) {
    return null;
  }
  return (
    <ul className={css.listSelect}>
      {options.map((value: IOption) => (
        <li key={value.value} className={css.listSelect_item} onClick={() => onSelect(value)}>
          <p className={css.textSelect}>{value.label}</p>
        </li>
      ))}
    </ul>
  );
};

export default Options;
