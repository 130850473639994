import { objectEmpty } from "../../../utils/validations";
import { ProgramFilterFieldsInput, AssignmentFilterInput } from "../../../models/ISchema";
import {
  IShiftDependency,
  ILevelDependency,
  ICourseLevel,
  ILevelReturn,
  ICourseId,
  IContractTypeId,
  IBlocksDependency,
  IAssignedBlocks,
  IBlocksReturn,
  IDummy,
  IAvailability,
  IAvailabilityReturn,
  ITimeRangeDependency,
  IWeeksRangeDependency,
  IAssignment,
  IComponentTypeId,
  ISetLevelReturn,
  ICurriculumLevel,
} from "../IAdvanceSearch";

const shiftDependency = (value: IShiftDependency): ProgramFilterFieldsInput[] => {
  const shiftArray: ProgramFilterFieldsInput[] = [];
  if (value.campus)
    shiftArray.push({
      campusId: {
        eq: value.campus,
      },
    });
  if (value.department)
    shiftArray.push({
      departmentId: {
        eq: value.department,
      },
    });
  if (value.modality)
    shiftArray.push({
      modalityId: {
        eq: value.modality,
      },
    });
  if (value.shift)
    shiftArray.push({
      shiftId: {
        eq: value.shift,
      },
    });

  return shiftArray;
};

// instructor case
const levelDependency = (
  value: ILevelDependency,
  programArray: ProgramFilterFieldsInput[],
  objLevel: ICourseLevel,
): ILevelReturn => {
  const cloneLevel = { ...objLevel };
  let newLevel: ICourseLevel = JSON.parse(JSON.stringify(cloneLevel));
  const newDependencies = [...programArray];
  if (value.program && !value.curriculum && !value.level) {
    if (newDependencies.length === 5) newDependencies.pop();
    newDependencies.push({
      id: {
        eq: value.program,
      },
    });
  }
  if (value.level) {
    newLevel = {
      ...newLevel,
      ...{
        courseLevel: {
          eq: parseInt(value.level),
        },
      },
    };
  } else {
    if (newLevel.hasOwnProperty("courseLevel")) {
      delete newLevel.courseLevel;
    }
  }

  return {
    objLevel: newLevel,
    program: newDependencies,
  };
};

// section case
const setLevelDependency = (
  value: ILevelDependency,
  programArray: ProgramFilterFieldsInput[],
  objLevel: ICourseLevel,
  objCurriculum?: ICurriculumLevel,
): ISetLevelReturn => {
  const newDependencies = [...programArray];
  if (value.program && !value.curriculum && !value.level) {
    if (newDependencies.length === 5) newDependencies.pop();
    newDependencies.push({
      id: {
        eq: value.program,
      },
    });
    return { arrayProgram: newDependencies, objCurriculum: {}, objLevel: {}, objCourse: {} };
  }

  if (value.curriculum && !value.level) {
    const cloneCurriculum: ICurriculumLevel = { ...objCurriculum };
    let newCurriculum: ICurriculumLevel = JSON.parse(JSON.stringify(cloneCurriculum));
    newCurriculum = {
      curriculumId: {
        eq: value.curriculum,
      },
    };
    return { objCurriculum: newCurriculum, objLevel: {} };
  }

  if (value.level) {
    const cloneLevel: ICourseLevel = { ...objLevel };
    let newLevel: ICourseLevel = JSON.parse(JSON.stringify(cloneLevel));
    newLevel = {
      level: {
        eq: parseInt(value.level),
      },
    };
    return { objLevel: newLevel };
  }

  if (!value.program && !value.curriculum && !value.level) {
    if (newDependencies.length === 5) newDependencies.pop();
    return { arrayProgram: newDependencies, objCurriculum: {}, objLevel: {} };
  }
};

const courseDependencies = (value: string, objCourse: ICourseId): ICourseId => {
  const cloneCourse: ICourseId = { ...objCourse };
  let newCourse: ICourseId = JSON.parse(JSON.stringify(cloneCourse));
  if (value) {
    newCourse = {
      ...newCourse,
      ...{
        courseId: {
          eq: parseInt(value),
        },
      },
    };
  } else {
    if (newCourse.hasOwnProperty("courseId")) {
      delete newCourse.courseId;
    }
  }
  return newCourse;
};

const contractType = (value: string, objContractType: IContractTypeId): IContractTypeId => {
  const cloneContractType = { ...objContractType };
  let contractType: IContractTypeId = JSON.parse(JSON.stringify(cloneContractType));
  if (value) {
    contractType = {
      ...contractType,
      ...{
        contractTypeId: {
          eq: parseInt(value),
        },
      },
    };
  } else {
    if (contractType.hasOwnProperty("contractTypeId")) {
      delete contractType.contractTypeId;
    }
  }
  return contractType;
};

const blocks = (values: IBlocksDependency, objAssignedBlocks: IAssignedBlocks): IBlocksReturn => {
  const clone = { ...objAssignedBlocks };
  let newFilter: IAssignedBlocks = JSON.parse(JSON.stringify(clone));
  // block property
  const block = { assignedBlocks: { gte: null, lte: null } };
  // gte property
  if (values.minBlock && values.minBlock !== "") {
    block.assignedBlocks = {
      ...block.assignedBlocks,
      ...{ gte: values.minBlock },
    };
  }
  // lte property
  if (values.maxBlock && values.maxBlock !== "") {
    block.assignedBlocks = {
      ...block.assignedBlocks,
      ...{ lte: values.maxBlock },
    };
  }
  const min = block.assignedBlocks.gte;
  const max = block.assignedBlocks.lte;
  if (!min && !max) {
    delete block.assignedBlocks;
  } else {
    block.assignedBlocks = {
      ...block.assignedBlocks,
    };
  }
  if (objectEmpty(block)) {
    delete newFilter.assignedBlocks;
  } else {
    newFilter = { ...newFilter, ...block };
  }
  return { assignedBlocks: newFilter, error: false };
};

const dummy = (value: boolean, objDummy: IDummy): IDummy => {
  const cloneDummy = { ...objDummy };
  let newDummy = JSON.parse(JSON.stringify(cloneDummy));
  if (value) {
    newDummy = {
      ...newDummy,
      ...{
        dummy: {
          is: value,
        },
      },
    };
  } else {
    if (newDummy.hasOwnProperty("dummy")) {
      delete newDummy.dummy;
    }
  }
  return newDummy;
};

const variablesClone = (objAvailability: IAvailability) => {
  const clone = { ...objAvailability };
  return JSON.parse(JSON.stringify(clone));
};

const days = (value: number[], objAvailability: IAvailability): IAvailabilityReturn => {
  const newFilter: IAvailability = variablesClone(objAvailability);
  // set availability.dayId property
  if (value.length > 0) {
    newFilter.availability = {
      ...newFilter.availability,
      ...{ dayId: value.map(value => value.toString()) },
    };
  } else {
    if (newFilter.availability.hasOwnProperty("dayId")) delete newFilter.availability.dayId;
  }
  // validate empty availability property
  if (objectEmpty(newFilter.availability)) delete newFilter.availability;

  // Dependency of blockRange
  let timeRangeError = false;
  if (value.length > 0)
    if (newFilter.hasOwnProperty("availability"))
      if (!newFilter.availability.hasOwnProperty("blockRange")) {
        timeRangeError = true;
      } else {
        const start = !newFilter.availability.blockRange.hasOwnProperty("start");
        const end = !newFilter.availability.blockRange.hasOwnProperty("end");
        if (start || end) timeRangeError = true;
      }

  let daysError = false;
  if (value.length === 0)
    if (newFilter.hasOwnProperty("availability"))
      if (newFilter.availability.hasOwnProperty("blockRange")) daysError = true;

  return {
    availability: newFilter,
    error: timeRangeError || daysError,
    timeRangeError,
    daysError,
  };
};

const timeRange = (
  value: ITimeRangeDependency,
  objAvailability: IAvailability,
): IAvailabilityReturn => {
  const newFilter = variablesClone(objAvailability);
  const _block = { blockRange: {} };
  // blockRange.start property
  if (value.minTime && value.minTime !== "") {
    _block.blockRange = {
      ..._block.blockRange,
      ...{ start: value.minTime },
    };
  } else {
    if (_block.blockRange.hasOwnProperty("start")) {
      delete newFilter.availability.blockRange.start;
    }
  }
  // blockRange.end property
  if (value && value.maxTime && value.maxTime !== "") {
    _block.blockRange = {
      ..._block.blockRange,
      ...{ end: value.maxTime },
    };
  } else {
    if (_block.blockRange.hasOwnProperty("end")) {
      delete newFilter.availability.blockRange.end;
    }
  }

  // validate empty blockRange property
  if (_block.hasOwnProperty("blockRange")) {
    if (objectEmpty(_block.blockRange)) {
      if (newFilter.hasOwnProperty("availability")) delete newFilter.availability.blockRange;
    } else {
      newFilter.availability = {
        ...newFilter.availability,
        ..._block,
      };
    }
  }

  // validate empty availability property
  if (newFilter.hasOwnProperty("availability")) {
    if (objectEmpty(newFilter.availability)) delete newFilter.availability;
  }

  // Dependency of days
  let timeRangeError = false;
  if (value.minTime === "" || value.maxTime === "")
    if (newFilter.hasOwnProperty("availability"))
      if (newFilter.availability.hasOwnProperty("dayId")) timeRangeError = true;

  let daysError = false;
  if (value.minTime !== "" || value.maxTime !== "")
    if (newFilter.hasOwnProperty("availability") && !newFilter.availability.hasOwnProperty("dayId"))
      daysError = true;

  return { availability: newFilter, error: timeRangeError || daysError, timeRangeError, daysError };
};

const weeksRange = (
  value: IWeeksRangeDependency,
  objAvailability: IAvailability,
): IAvailabilityReturn => {
  const _availability = variablesClone(objAvailability);
  const _range = { intervalRange: {} };
  const _intervals = { intervals: [] };
  const _intervalRange = _availability.availability.intervalRange;
  // if value of weeks is with intervalRange
  // intervalRange.start property
  if (value.minWeek) {
    _range.intervalRange = {
      ..._range.intervalRange,
      ...{ start: value.minWeek },
    };
  } else if (_intervalRange.hasOwnProperty("start")) {
    delete _intervalRange.start;
  }
  // intervalRange.end property
  if (value.maxWeek) {
    _range.intervalRange = {
      ..._range.intervalRange,
      ...{ end: value.maxWeek },
    };
  } else if (_intervalRange.hasOwnProperty("end")) {
    delete _intervalRange.end;
  }

  // if value of weeks is with intervals
  if (value.weekRange) {
    _intervals.intervals = [...value.weekRange];
  } else if (_availability.availability.hasOwnProperty("intervals")) {
    delete _availability.availability.intervals;
  }

  const existItervalRange =
    _availability.hasOwnProperty("availability") &&
    _availability.availability.hasOwnProperty("intervalRange");

  // validate empty intervalRange property
  if (objectEmpty(_range.intervalRange) && existItervalRange) {
    delete _availability.availability.intervalRange;
  } else {
    _availability.availability = {
      ..._availability.availability,
      ..._range,
    };
  }

  // validate empty intervals property
  if (_intervals.intervals.length === 0 || existItervalRange) {
    if (
      _availability.hasOwnProperty("availability") &&
      _availability.availability.hasOwnProperty("intervals")
    )
      delete _availability.availability.intervals;
  } else {
    _availability.availability = {
      ..._availability.availability,
      ..._intervals,
    };
  }

  // validate empty availability property
  if (_availability.hasOwnProperty("availability"))
    if (objectEmpty(_availability.availability)) delete _availability.availability;

  return { availability: _availability };
};

const assignmentChecks = (
  value: IAssignment,
  objAssignment: AssignmentFilterInput,
): AssignmentFilterInput => {
  const cloneAssignment: AssignmentFilterInput = { ...objAssignment };
  let newAssignment: AssignmentFilterInput = JSON.parse(JSON.stringify(cloneAssignment));
  if (value.blocks) {
    newAssignment = {
      ...newAssignment,
      ...{
        hasBlocks: false,
      },
    };
  } else {
    if (newAssignment.hasOwnProperty("hasBlocks")) {
      delete newAssignment.hasBlocks;
    }
  }

  if (value.instructor) {
    newAssignment = {
      ...newAssignment,
      ...{
        hasInstructors: false,
      },
    };
  } else {
    if (newAssignment.hasOwnProperty("hasInstructors")) {
      delete newAssignment.hasInstructors;
    }
  }

  if (value.classroom) {
    newAssignment = {
      ...newAssignment,
      ...{
        hasClassrooms: false,
      },
    };
  } else {
    if (newAssignment.hasOwnProperty("hasClassrooms")) {
      delete newAssignment.hasClassrooms;
    }
  }

  return newAssignment;
};

const componentType = (component: string, objComponentType: IComponentTypeId): IComponentTypeId => {
  const cloneComponentType: IComponentTypeId = { ...objComponentType };
  let newComonentType: IComponentTypeId = JSON.parse(JSON.stringify(cloneComponentType));
  if (component) {
    newComonentType = {
      ...newComonentType,
      ...{
        componentId: {
          eq: parseInt(component),
        },
      },
    };
  } else {
    if (newComonentType.hasOwnProperty("componentId")) {
      delete newComonentType.componentId;
    }
  }
  return newComonentType;
};

export {
  shiftDependency,
  levelDependency,
  setLevelDependency,
  courseDependencies,
  contractType,
  blocks,
  dummy,
  days,
  timeRange,
  weeksRange,
  assignmentChecks,
  componentType,
};
