import * as R from "ramda";
import { filter, orderBy, property, flowRight, last, first } from "lodash";
import { SessionAssignment, Interval } from "@models/ISchema";
import { Week } from "../context/linkData.reducer";
import { BlockRanges } from "../context/formData.reducer";
import Block from "./Block";

class AssignmentLabeler {
  static fromWeeks(intervals: Interval[], savedWeeks: Week[], linkWeeks: Week[] = []) {
    if (!savedWeeks?.length && !linkWeeks?.length && !intervals?.length) {
      return "";
    }

    if (savedWeeks) {
      return AssignmentLabeler.labelByIntervals(filter(savedWeeks, { checked: true }));
    }

    const intervalIdsTable = R.reduce((acc, week) => R.assoc(week.id, true, acc), {}, linkWeeks);
    const weeksInCommon = R.filter(
      R.pipe(R.prop("id"), R.flip(R.has)(intervalIdsTable)),
      intervals ?? [],
    );

    return AssignmentLabeler.labelByIntervals(weeksInCommon);
  }

  static fromDate(assignment: SessionAssignment, blockRange: BlockRanges) {
    let assigmentSaved = "";

    if (blockRange) {
      const block = new Block(blockRange);

      if (block.day && block.startTime && block.endTime && block.selected === "hours") {
        assigmentSaved = block.blockLabel;
      }
      if (block.blocks && blockRange.selected === "blocks") {
        assigmentSaved = "No requiere horario";
      }
      if (assigmentSaved) return assigmentSaved;
    }

    return assignment?.blockRange
      ? "No requiere horario"
      : new Block(assignment?.blockRange as BlockRanges).blockLabel;
  }

  static labelByIntervals(intervals: Week[]) {
    if (!intervals?.length) return "";

    const sortedIntervals = orderBy(intervals, ["startingDate"], ["asc"]);
    const value = (edge: any) => flowRight(property("value"), edge)(sortedIntervals);
    const separation = this._weeksHaveGap(sortedIntervals) ? "---" : "-";

    return value(first) === value(last)
      ? `S${value(first)}`
      : `S${value(first)} ${separation} S${value(last)}`;
  }

  static _weeksHaveGap(weeks: Week[]) {
    const { gapFound } = weeks.reduce(
      ({ prevWeek, gapFound }, currWeek) => {
        if (!prevWeek) return { prevWeek: currWeek, gapFound: false };
        return {
          prevWeek: currWeek,
          gapFound: gapFound ? true : currWeek.value - prevWeek.value > 1,
        };
      },
      { prevWeek: null, gapFound: false },
    );
    return gapFound;
  }
}

export default AssignmentLabeler;
