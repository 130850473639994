import * as React from "react";
import Modal from "react-modal";
import cx from "classnames";
import css from "./modalState.module.scss";
import { Button, Icon, IconType } from "@foris/avocado-ui";

export type ModalStateType = "confirm" | "error" | "warning";

export interface IModalState {
  /** type modal */
  typeState: ModalStateType;
  /** title modal */
  title?: string;
  /** open modal */
  show: boolean;
  /** overwrite className */
  className?: string;
  /** content modal */
  children?: React.ReactNode | string;
  /** text button principal */
  textButtonPrincipal?: string;
  /** action button principal */
  onClickPrincipal?: () => void;
  /** text button secondary */
  textButtonSecondary?: string;
  /** action button secondary */
  onClickSecondary?: () => void;
  iconState?: IconType;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "0px solid rgb(204, 204, 204)",
    borderRadius: 8,
    overlfow: "auto",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 10,
  },
};

Modal.setAppElement("#root");

const ModalState: React.FC<IModalState> = (props: IModalState) => {
  const {
    typeState,
    title,
    show,
    className,
    children,
    textButtonPrincipal,
    onClickPrincipal,
    textButtonSecondary,
    onClickSecondary,
    iconState,
  } = props;
  const [iconType, setIconType] = React.useState<IconType>("check");

  React.useEffect(() => {
    switch (typeState) {
      case "confirm":
        setIconType("check");
        break;
      case "error":
        setIconType("close");
        break;
      case "warning":
        setIconType("alert");
        break;
    }
  }, [typeState]);

  return (
    <Modal isOpen={show} onRequestClose={(): void => null} style={customStyles}>
      <article
        className={cx(
          css.modalState,
          typeState && css[`modalState__${typeState}`],
          className && className,
        )}
      >
        <header className={css.header}>
          <div className={css.header_cntIcon}>
            {!iconState ? (
              <Icon icon={iconType} size={50} className={css.icon} />
            ) : (
              <Icon icon={iconState} size={50} className={css.icon} />
            )}
          </div>
        </header>
        <section className={cx(css.content, "container-row")}>
          {title && <h1 className={cx(css.content_title, "col_12")}>{title}</h1>}
          <section className={cx(css.content_children, "col_12")}>{children}</section>
          <footer className={cx(css.footer, "container-row", "row--between")}>
            {textButtonSecondary && (
              <Button
                typeButton="transparent"
                className={css.footer_btn}
                onClick={() => onClickSecondary && onClickSecondary()}
              >
                {textButtonSecondary}
              </Button>
            )}
            {textButtonPrincipal && (
              <Button
                typeButton={typeState}
                className={css.footer_btn}
                onClick={() => onClickPrincipal && onClickPrincipal()}
              >
                {textButtonPrincipal}
              </Button>
            )}
          </footer>
        </section>
      </article>
    </Modal>
  );
};

export default ModalState;
