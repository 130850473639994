import gql from "graphql-tag";

export const customBlocksQuery = gql`
  query customBlocks($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      customBlocks {
        start
        end
      }
    }
  }
`;
