import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import { megaSearch } from "../graphql/populationMegaSearch.query";
import { populationSearchQuery } from "../graphql/advanceSearch.queries";
import SearchResults from "./SearchResults";
import { IParams } from "../../../../models/IParams";
import { PopulationPage } from "../../../../models/ISchema";
import Layout from "../../../../common/layout/Layout";
import { variablesSearch } from "../../utils/searchDimension";

const SearchPopulation: React.FC = () => {
  const { origin, scenario }: IParams = useParams();
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [page, setPageCount] = useState(1);
  const variables = variablesSearch(scenario, origin, params, page);
  const querySearch = params?.advance ? populationSearchQuery : megaSearch;

  const { loading, error, data } = useQuery(querySearch, { variables });
  let populations: PopulationPage = data?.cube?.populations;
  if (params?.advance) populations = data?.filter?.populations;

  return (
    <Layout contextSearch>
      <SearchResults
        data={populations?.items}
        search={params?.search?.toString()}
        pageInfo={populations?.pageInfo}
        setPage={(value: number) => setPageCount(value)}
        loading={loading}
        error={error}
      />
    </Layout>
  );
};

export default SearchPopulation;
