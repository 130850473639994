import { ActionMap } from "@models/IContext";
import { NavigationReducerType } from "./types";
import { Location } from "../models";
import { addToLocationStack, popFromLocationStack, setForceFetching } from "./navigation.actions";

export enum Types {
  AddToLocationStack = "ADD_TO_LOCATION_STACK",
  PopFromLocationStack = "POP_FROM_LOCATION_STACK",
  SetForceFetching = "SET_FORCE_FETCHING",
}

type NavigationPayload = {
  [Types.AddToLocationStack]: Location;
  [Types.PopFromLocationStack]: undefined;
  [Types.SetForceFetching]: boolean;
};

export type NavigationActions = ActionMap<NavigationPayload>[keyof ActionMap<NavigationPayload>];

export const navigationReducer = (state: NavigationReducerType, action: NavigationActions) => {
  switch (action?.type) {
    case Types.AddToLocationStack:
      return addToLocationStack(action?.payload, state);
    case Types.PopFromLocationStack:
      return popFromLocationStack(state);
    case Types.SetForceFetching:
      return setForceFetching(action?.payload, state);
    default:
      return state;
  }
};
