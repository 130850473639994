import * as React from "react";
import Select from "react-select";

export interface IOption {
  label: string;
  value: string;
}

interface ISelect {
  options?: any;
  placeholder?: string;
  value?: string;
  onChange?: (e: any) => void;
  className?: string;
  isClearable?: boolean;
  disabled?: boolean;
  onInputChange?: (e: any) => void;
  isLoading?: boolean;
}

const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

const ReactSelect: React.FC<ISelect> = (props: ISelect) => {
  const {
    options,
    placeholder,
    onChange,
    value,
    isClearable,
    className,
    disabled,
    onInputChange,
    isLoading,
  } = props;
  return (
    <Select
      styles={customStyles}
      value={value && value}
      placeholder={placeholder}
      isDisabled={disabled && disabled}
      options={options}
      className={className}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#e5e8ec",
          primary: "#6993c9",
          neutral80: "#575d62",
          neutral90: "#575d62",
        },
      })}
      isClearable={isClearable}
      onChange={(value: any) => onChange && onChange(value)}
      onInputChange={(value: any) => onInputChange && onInputChange(value)}
      isLoading={isLoading && isLoading}
    />
  );
};

export default ReactSelect;
