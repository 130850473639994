import gql from "graphql-tag";
import { FRAGMENT_SECTIONS } from "../../graphql/section.fragment";

export const sectionEditorView = gql`
  query sectionEditorView(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
    $customField: ResourceType!
  ) {
    base {
      customFields(resourceType: $customField) {
        id
        code
        name
        label
      }
    }
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on SectionInfo {
            campus {
              id
              code
            }
            department {
              id
              code
              name
            }
            modality {
              id
              code
              name
            }
            shift {
              id
              code
              name
            }
            program {
              id
              code
              name
            }
            curriculum {
              id
              code
              name
            }
            course {
              id
              code
              name
              level
              canEdit
              customFields {
                id
                value
                code
              }
            }
            courseComponent {
              id
              maxBlocksPerSession
              component {
                id
                code
              }
              stripePriorities {
                id
                stripe {
                  id
                  code
                }
              }
              classroomPriorities {
                id
                classroomType {
                  id
                  code
                }
              }
              academicStandard
              modulation
            }
            section {
              id
              code
              vacancies
              groups {
                id
                type
              }
              bundle {
                id
                code
                label
                name
                capacity
              }
              links {
                id
              }
              totalBlocks
            }
            packages {
              id
              index
              code
            }
          }
        }
        ...CalendarSections
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
        termParts {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
    }
  }
  ${FRAGMENT_SECTIONS}
`;
