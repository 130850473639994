import { ActionMap } from "@models/IContext";
import { DataReducerType } from "./types";
import { setGroup, setGroups, setGroupsPageInfo, setGroupsById } from "./data.actions";
import { PageInfo } from "@models/ISchema";
import { AdaptedGroup } from "../models";

export enum Types {
  SetGroup = "SET_GROUP",
  SetGroups = "SET_GROUPS",
  SetGroupsById = "SET_GROUPS_BY_ID",
  SetGroupsPageInfo = "SET_GROUPS_PAGEINFO",
}

type DataPayload = {
  [Types.SetGroup]: AdaptedGroup;
  [Types.SetGroupsById]: AdaptedGroup[];
  [Types.SetGroupsPageInfo]: PageInfo;
  [Types.SetGroups]: AdaptedGroup[];
};

export type DataActions = ActionMap<DataPayload>[keyof ActionMap<DataPayload>];

export const dataReducer = (state: DataReducerType, action: DataActions) => {
  switch (action?.type) {
    case Types.SetGroup:
      return setGroup(action?.payload, state);
    case Types.SetGroupsById:
      return setGroupsById(action?.payload, state);
    case Types.SetGroups:
      return setGroups(action?.payload, state);
    case Types.SetGroupsPageInfo:
      return setGroupsPageInfo(action?.payload, state);
    default:
      return state;
  }
};
