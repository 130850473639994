import { AdaptedGroupIsEditable, AdaptedValidationErrorReason } from "../models";

const nonEditableReasonText = (isEditable: AdaptedGroupIsEditable) => {
  switch (isEditable?.reason) {
    case AdaptedValidationErrorReason?.ClassroomCapacityExceeded:
      return "Capacidad de sala exedida";
    case AdaptedValidationErrorReason?.ClassroomCapacityExceeded:
      return "Capacidad de sala exedida";
    case AdaptedValidationErrorReason?.DatabaseError:
      return "Error interno. Contacte a un administrador";
    case AdaptedValidationErrorReason?.EnrollmentsNotCovered:
      return "Inscritos no cubiertos";
    case AdaptedValidationErrorReason?.ExeedsMax:
      return "Excede máximo de eficiencia";
    case AdaptedValidationErrorReason?.GroupCodeAlreadyTaken:
      return "Código de grupo ya utilizado";
    case AdaptedValidationErrorReason?.GroupHasEnrollments:
      return "Grupo tiene inscritos";
    case AdaptedValidationErrorReason?.IntervalNotFound:
      return "Semana no encontrada";
    case AdaptedValidationErrorReason?.InvalidClassroom:
      return "Sala inválida";
    case AdaptedValidationErrorReason?.InvalidCourse:
      return "Curso inválido";
    case AdaptedValidationErrorReason?.InvalidCourseComponent:
      return "Asignatura inválida";
    case AdaptedValidationErrorReason?.InvalidGroup:
      return "Grupo inválido";
    case AdaptedValidationErrorReason?.InvalidLink:
      return "Liga inválido";
    case AdaptedValidationErrorReason?.InvalidLinkCourse:
      return "Curso de Liga inválido";
    case AdaptedValidationErrorReason?.InvalidLinkPackage:
      return "Paquete de Liga inválida";
    case AdaptedValidationErrorReason?.InvalidPaymentPercentage:
      return "Porcentaje de pago inválido";
    case AdaptedValidationErrorReason?.InvalidSection:
      return "Sección inválida";
    case AdaptedValidationErrorReason?.InvalidTeachingHoursPercentage:
      return "Porcentaje de horas de profesor inválido";
    case AdaptedValidationErrorReason?.LinkCodeAlreadyInUse:
      return "Código de Liga ya utilizado";
    case AdaptedValidationErrorReason?.LowerThanMin:
      return "Menor que el mínimo de eficiencia";
    case AdaptedValidationErrorReason?.MoreThanOnePrimaryInstructor:
      return "Más de un profesor primario";
    case AdaptedValidationErrorReason?.MustSetPrimaryInstructor:
      return "Setear profesor primario";
    case AdaptedValidationErrorReason?.RepeatedComponentForLink:
      return "Componente duplicado para Liga";
    case AdaptedValidationErrorReason?.SectionCapacityExceedsCourseComponentMaxSize:
      return "Capacidad de la sección excede el máximo permitido por la asignatura";
    case AdaptedValidationErrorReason?.SessionNotFound:
      return "Sesión no encontrada";
    case AdaptedValidationErrorReason?.SubjectNotFound:
      return "Subject no encontrado";
    case AdaptedValidationErrorReason?.WaitingListNotCovered:
      return "Lista de espera no cubierta";
    case AdaptedValidationErrorReason?.GroupVacanciesExternalValidation:
      return "Vacantes exceden validaciones externas";
    case AdaptedValidationErrorReason?.InvalidAccessCode:
      return "Código de acceso inválido";
    case AdaptedValidationErrorReason?.PendingChangeRequest:
      return "Solicitud de cambio pendiente";
    case AdaptedValidationErrorReason?.IsNotTheOwnerOfTheGroup:
      return "No es propietario del grupo";
    case AdaptedValidationErrorReason?.GroupCapacityChangeError:
      return "Error al cambiar capacidad";
    case AdaptedValidationErrorReason?.GroupVisibilityChangeError:
      return "Error al cambiar visibilidad";
    case AdaptedValidationErrorReason?.AccessDeniedToGroupManager:
      return "Acceso negado al Módulo de cupos";
    case AdaptedValidationErrorReason?.GroupNotYetMigrated:
      return "Grupo no migrado aun";
    default:
      return "Grupo no editable";
  }
};

export default nonEditableReasonText;
