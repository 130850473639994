import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContextEDH } from "@context/ContextEDH";
import { ContextProvider } from "@modules/sections/GroupsManager/context/GroupsManagerContext";
import ChangeHistory from "@modules/sections/GroupsManager/pages/ChangeHistory/ChangeHistory";
import GroupChangeHistory from "@modules/sections/GroupsManager/pages/GroupChangeHistory/GroupChangeHistory";
import GroupManagerApp from "@modules/sections/GroupsManager/pages/GroupManagerApp/GroupManagerApp";
import GroupsManagerApp from "@modules/sections/GroupsManager/pages/GroupsManagerApp/GroupsManagerApp";
import { Module, RouteType } from "./types";
import { requiredAbilitiesByModuleName } from "./settings";
import moduleIsAccessible from "./utils/moduleIsAccessible";

const base = "/editor/groups-manager";
const context = ":workspace/:scenario/:origin";

const groupsManagerModule: Module = {
  name: "groups_manager",
  routes: [
    {
      path: `${base}/subgroups/${context}/:id`,
      component: GroupManagerApp,
    },
    {
      path: `${base}/change_history/${context}/:id`,
      component: GroupChangeHistory,
    },
    {
      path: `${base}/change_history/${context}`,
      component: ChangeHistory,
    },
    {
      path: `${base}/${context}/:id`,
      component: GroupManagerApp,
    },
    {
      path: `${base}/${context}`,
      component: GroupsManagerApp,
    },
  ],
};

const RoutesEditorGroupsManager: React.FC = () => {
  const { state } = useContext(ContextEDH);
  const accessible = moduleIsAccessible(
    requiredAbilitiesByModuleName[groupsManagerModule?.name],
    state?.base?.base?.user?.abilities,
    state?.base?.base?.user?.permissions,
  );

  // If the module is not accesible, redirect the user to the home page
  if (!accessible) {
    return (
      <Switch>
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <ContextProvider>
      <Switch>
        {groupsManagerModule.routes?.map((route: RouteType) => (
          <Route key={route?.path} path={route?.path} component={route?.component} />
        ))}
      </Switch>
    </ContextProvider>
  );
};

export default RoutesEditorGroupsManager;
