import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import { Loading } from "@foris/avocado-ui";
import { ClassroomBooking } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { classroomBooking } from "../../graphql/bookingDetail.query";
import BookingCard from "../../components/CardDetail/CardDetail";
import Layout from "../../../../common/layout/Layout";

const BookingDetail = () => {
  const { id, origin, scenario }: IParams = useParams();

  const variables = {
    originId: origin,
    scenarioId: scenario,
    bookingId: id,
  };

  const { data, error, loading } = useQuery(classroomBooking, {
    variables,
    fetchPolicy: "network-only",
  });

  if (error && !loading)
    return (
      <Layout contextSearch={false}>
        <div>No existe reserva</div>
      </Layout>
    );

  if (loading)
    return (
      <Layout contextSearch={false}>
        <Loading />
      </Layout>
    );

  const classBooking: ClassroomBooking = data?.cube?.classroomBooking;

  return (
    <Layout contextSearch={false}>
      <BookingCard booking={classBooking} campus={scenario} />
    </Layout>
  );
};

export default BookingDetail;
