import gql from "graphql-tag";

export const classroomBookingsUpdate = gql`
  mutation classroomBookingsCrud(
    $scenarioId: ID!
    $originId: ID!
    $input: ClassroomBookingCrudInput!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classroomBookingsCrud(input: $input) {
        skippedValidations
        commited
        onlyDetails
        payload {
          updates {
            booking {
              id
              title
              status
              relatedProgram {
                id
                name
                code
                campus {
                  id
                  name
                  __typename
                }
                department {
                  id
                  name
                  __typename
                }
                modality {
                  id
                  name
                  __typename
                }
                shift {
                  id
                  name
                  __typename
                }
                __typename
              }
              description
              isRecurrent
              responsible {
                id
                name
                lastName
              }
              type {
                id
                name
              }
              sessions {
                scheduleEvent {
                  id
                  start
                  end
                  __typename
                }
                events {
                  interval {
                    id
                    startingDate
                    endingDate
                    name
                  }
                  block {
                    day
                  }
                }
                __typename
              }
              classroom {
                id
                code
                name
                capacity
                building {
                  id
                  code
                  name
                  campus {
                    id
                    code
                    name
                    __typename
                  }
                  __typename
                }
                classroomType {
                  id
                  code
                  name
                  __typename
                }
                __typename
              }
              __typename
            }
            validationErrors {
              __typename
              ... on InvalidInput {
                code
                __typename
              }
              ... on InvalidDate {
                start
                end
                __typename
              }
              ... on ClassroomClash {
                sessions {
                  id
                  assignment {
                    intervals {
                      id
                      name
                      startingDate
                      endingDate
                      __typename
                    }
                    blockRange {
                      start {
                        day
                        startingTime
                        __typename
                      }
                      end {
                        day
                        endingTime
                        __typename
                      }
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
