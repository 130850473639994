import React, { createContext, useReducer, Dispatch } from "react";
import { groupInfoReducer, GroupInfoActions, GroupInfoType } from "./groupInfo.reducer";

export type InitialStateType = {
  info: GroupInfoType;
};

export const initialState: InitialStateType = {
  info: null,
};

const GroupContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<GroupInfoActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ info }: InitialStateType, action: any) => ({
  info: groupInfoReducer(info, action),
});

const GroupProvider: React.FC<{ children?: React.ReactNode | undefined }> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return <GroupContext.Provider value={{ state, dispatch }}>{children}</GroupContext.Provider>;
};

export { GroupProvider, GroupContext };
