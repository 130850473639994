import gql from "graphql-tag";
import { FRAGMENT_SECTIONS } from "../../graphql/section.fragment";

export const classroomSection = gql`
  query classroomSection(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on ClassroomInfo {
            classroom {
              id
              code
              name
              capacity
              allowedOverflow
              floor
              usable
              classroomType {
                id
                code
                name
              }
              building {
                id
                code
                name
                __typename
                campus {
                  id
                  code
                  name
                  __typename
                }
              }
            }
          }
        }
        bookings {
          id
          title
          classroom {
            id
            code
            name
            building {
              id
              name
              code
              __typename
              campus {
                id
                code
                name
                __typename
              }
            }
            classroomType {
              id
              code
              name
              __typename
            }
          }
          responsible {
            id
            name
            lastName
          }
          status
          description
          type {
            id
            code
            name
          }
          sessions {
            id
            blocksCount
            section {
              id
              code
              vacancies
              usedCapacity
              waitingListsCount
              bundle {
                id
                code
                label
                name
                capacity
              }
              groups {
                id
                code
                label
                name
                type
              }
              course {
                id
                code
                name
                canEdit
                demand {
                  id
                  vacancies
                  crosslist
                }
                __typename
              }
              courseComponent {
                id
                maxBlocksPerSession
                component {
                  id
                  code
                  __typename
                }
                online
                stripePriorities {
                  id
                  stripe {
                    id
                    code
                    name
                  }
                }
                __typename
              }
              __typename
            }
            scheduleEvent {
              colorIndex
              id
              start
              end
              __typename
            }
            events {
              id
              block {
                id
                __typename
              }
              __typename
            }
            assignment {
              blockRange {
                start {
                  id
                  day
                  startingTime
                  __typename
                }
                end {
                  id
                  endingTime
                  __typename
                }
                __typename
              }
              instructor {
                id
                name
                code
                __typename
              }
              classroom {
                id
                code
                name
                capacity
                allowedOverflow
                floor
                usable
                classroomType {
                  id
                  code
                  name
                  __typename
                }
                building {
                  id
                  name
                  code
                  __typename
                  campus {
                    id
                    code
                    name
                    __typename
                  }
                }
                __typename
              }
              intervals {
                id
                name
                startingDate
                endingDate
                termParts {
                  id
                }
              }
              __typename
            }
            blocksCount
            classrooms {
              id
              code
              name
              classroomType {
                id
                code
                name
              }
              building {
                id
                name
                code
                __typename
                campus {
                  id
                  code
                  name
                  __typename
                }
              }
              capacity
              allowedOverflow
              floor
              usable
            }
            instructors {
              id
              name
              code
            }
            __typename
          }
        }
        ...CalendarSections
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
        termParts {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
    }
  }
  ${FRAGMENT_SECTIONS}
`;
