import React, { useState, useEffect } from "react";
import cx from "classnames";
import { IObjValidationError } from "../utils";
import { ILeague } from "../contextVacancies/ContextVacancies";
import Checkbox from "../../../common/components/Checkbox/Checkbox";
import css from "./groups.module.scss";

export interface IValidationsProps {
  item: ILeague;
  setDisabledConfirm: any;
  validationArray: Array<IObjValidationError>;
}

const Validations: React.FC<IValidationsProps> = (props: IValidationsProps) => {
  const { item, setDisabledConfirm, validationArray } = props;

  const [check, setCheck] = useState(false);
  const [validationArrayState, setValidationArrayState] = useState(null);
  const [errorArray, setErrorArray] = useState([]);

  useEffect(() => {
    if (validationArrayState?.length) {
      setErrorArray(
        validationArrayState.filter(
          (validation: IObjValidationError) =>
            validation.leagueCode === item.code && validation.type === "error",
        ),
      );
    }
  }, [validationArrayState]);

  const warningArray = validationArrayState
    ? validationArrayState.filter(
        (validation: IObjValidationError) =>
          validation.leagueCode === item.code && validation.type === "warning",
      )
    : [];

  useEffect(() => {
    if (validationArray && validationArray.length > 0) {
      setValidationArrayState(validationArray);
    }
  }, [validationArray]);

  return (
    <>
      {/* Mensajes de error */}
      <ul className={cx(css.list_validation, "container-row")}>
        {errorArray.map((validation: any, iValidation: number) => (
          <li
            key={iValidation}
            className={cx(css.list_validation_item, css.list_validation_item__error, "col_12")}
          >
            {validation.text}
          </li>
        ))}
        {warningArray.map((validation: any, iValidation: number) => (
          <li key={iValidation} className={cx(css.list_validation_item, "col_12")}>
            {validation.text}
          </li>
        ))}
      </ul>
      {warningArray.length > 0 && errorArray.length === 0 && (
        <Checkbox
          label="Ignorar advertencias"
          check={check}
          onChange={value => {
            setCheck(value);
            if (value) {
              setDisabledConfirm(false);
            } else {
              setDisabledConfirm(true);
            }
          }}
        />
      )}
    </>
  );
};

export default Validations;
