import { ActionMap } from "@models/IContext";
import { Classroom, ClassroomBooking } from "@models/ISchema";
import {
  clearSearch,
  setBlockOptions,
  setBuildingOptions,
  setClassroom,
  setClassroomTypesOptionsOptions,
  setCurrentBooking,
  setDateTime,
  setDateTimeFormatted,
  setIntervals,
  setPreferences,
  setRecurrence,
  setRequirements,
  setView,
} from "./search.actions";
import {
  DayRowData,
  IRequirements,
  Preferences,
  Option,
  IntervalWeek,
  SearchTypes,
  BlockOptions,
  RecurrencePayload,
} from "./types";

export enum Types {
  ClearSearch = "CLEAR_SEARCH",
  SetBlockOptions = "SET_BLOCK_OPTIONS",
  SetBuildingOptions = "SET_BUILDING_OPTIONS",
  SetClassroom = "SET_CLASSROOM",
  SetClassroomTypesOptionsOptions = "SET_CLASSROOM_TYPES_OPTIONS",
  SetCurrentBooking = "SET_CURRENT_BOOKING",
  SetDateTime = "SET_DATETIME",
  SetDateTimeFormatted = "SET_DATETIME_FORMATTED",
  SetIntervals = "SET_INTERVALS",
  SetPreferences = "SET_PREFERENCES",
  SetRecurrence = "SET_RECURRENCE",
  SetRequirements = "SET_REQUIREMENTS",
  SetView = "SET_VIEW",
}

type SearchPayload = {
  [Types.ClearSearch]: void;
  [Types.SetBlockOptions]: BlockOptions;
  [Types.SetBuildingOptions]: Option[];
  [Types.SetClassroomTypesOptionsOptions]: Option[];
  [Types.SetClassroom]: Classroom;
  [Types.SetCurrentBooking]: ClassroomBooking;
  [Types.SetDateTime]: DayRowData[];
  [Types.SetDateTimeFormatted]: DayRowData[];
  [Types.SetIntervals]: IntervalWeek[];
  [Types.SetPreferences]: Preferences;
  [Types.SetRecurrence]: RecurrencePayload;
  [Types.SetRequirements]: IRequirements;
  [Types.SetView]: "search" | "result";
};

export type SearchActions = ActionMap<SearchPayload>[keyof ActionMap<SearchPayload>];

export const searchReducer = (state: SearchTypes, action: SearchActions) => {
  switch (action.type) {
    case Types.ClearSearch:
      return clearSearch(state);
    case Types.SetView:
      return setView(action?.payload, state);
    case Types.SetPreferences:
      return setPreferences(action?.payload, state);
    case Types.SetDateTime:
      return setDateTime(action?.payload, state);
    case Types.SetDateTimeFormatted:
      return setDateTimeFormatted(action?.payload, state);
    case Types.SetRequirements:
      return setRequirements(action?.payload, state);
    case Types.SetBlockOptions:
      return setBlockOptions(action?.payload, state);
    case Types.SetBuildingOptions:
      return setBuildingOptions(action?.payload, state);
    case Types.SetClassroomTypesOptionsOptions:
      return setClassroomTypesOptionsOptions(action?.payload, state);
    case Types.SetCurrentBooking:
      return setCurrentBooking(action?.payload, state);
    case Types.SetIntervals:
      return setIntervals([...action?.payload], state);
    case Types.SetRecurrence:
      return setRecurrence(action?.payload, state);
    case Types.SetClassroom:
      return setClassroom(action?.payload, state);
    default:
      return state;
  }
};
