import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Population } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, setPage, loading, error } = props;
  const title = generateTitle(pageInfo?.total || 0, search, "población");
  const route = `/scheduler/editor/population/${workspace}/${scenario}/${origin}`;

  const columns = [
    {
      header: getHeadCell("Código"),
      renderer: ({ id }: Population) => getLinkCell(route, id, id),
    },
    {
      header: getHeadCell("Periodo"),
      renderer: ({ term }: Population) => getColumnCell(term?.code),
    },
    {
      header: getHeadCell("Sede"),
      renderer: ({ campus }: Population) => getColumnCell(campus?.code),
    },
    {
      header: getHeadCell("Modalidad"),
      renderer: ({ modality }: Population) => getColumnCell(modality?.code),
    },
    {
      header: getHeadCell("Jornada"),
      renderer: ({ shift }: Population) => getColumnCell(shift?.code),
    },
    {
      header: getHeadCell("Carrera"),
      renderer: ({ program }: Population) => getColumnCell(`${program?.code} ${program?.name}`),
      styles: {
        desktop: {
          width: 250,
        },
      },
    },
    {
      header: getHeadCell("Currículo"),
      renderer: ({ curriculum }: Population) => getColumnCell(curriculum),
    },
    {
      header: getHeadCell("Nivel"),
      renderer: ({ level }: Population) => getColumnCell(level),
    },
    {
      header: getHeadCell("Vacantes"),
      renderer: ({ size }: Population) => getColumnCell(size),
    },
  ];
  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension="población"
      advanceLink={`/editor/advanceSearch/population/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
