const messages = {
  allDay: "Todo el día",
  previous: "<",
  next: ">",
  today: "Hoy",
  month: "Mes",
  week: "Semana",
  day: "Día",
  agenda: "Agenda",
  date: "Fecha",
  time: "Hora",
  event: "Evento",
  showMore: (total: number) => `+ Ver más (${total})`,
};

const errors: any = {
  ClassroomClash: "Choque de sala",
  InstructorClash: "Choque en instructor",
  LinkClash: "Choque en liga",
  SectionClash: "Choque en sección",
  PackageClash: "Choque en paquete",
  InvalidInstructorTravel: "Traslado de docente",
  InvalidPackageTravel: "Traslado de estudiantes",
  InvalidBuildingForCourse: "Edificio no permitido",
  InvalidBlock: "Rango de horarios inválido",
  InvalidBlockForInstructor: "Horario fuera de disponibilidad docente configurada",
  ExceededAssignableBlocksUpcomingYear: "Horas docente excedidas - año siguiente",
  ExceededAssignableBlocksCurrentYear: "Horas docente excedidas - año actual",
  ENROLLMENTS_NOT_COVERED:
    "Las vacantes definidas son menores a los estudiantes actualmente inscritos. Sección 111111 (inscritos: 11,  ajuste:11)",
  EXEEDS_MAX:
    "Ajuste de vacantes excede estándar académico definido. Sección 111111 (estándar: 11,  ajuste:11)",
  LOWER_THAN_MIN:
    "Ajuste de vacantes es menor a mínimo definido. Sección 111111 (mínimo: 11,  ajuste:11)",
  CLASSROOM_CAPACITY_EXCEEDED:
    "Ajuste de vacantes excede la capacidad de la sala asignada. Sección 111111 (Capacidad sala: 11,  ajuste:11)",
  GROUP_CODE_ALREADY_TAKEN: "Código de grupo repetido",
};

const daysOptions = [
  { key: "l", text: "Lunes", value: "MONDAY" },
  { key: "m", text: "Martes", value: "TUESDAY" },
  { key: "mm", text: "Miércoles", value: "WEDNESDAY" },
  { key: "j", text: "Jueves", value: "THURSDAY" },
  { key: "v", text: "Viernes", value: "FRIDAY" },
  { key: "s", text: "Sábado", value: "SATURDAY" },
  { key: "d", text: "Domingo", value: "SUNDAY" },
];

export { messages, errors, daysOptions };
