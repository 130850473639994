import gql from "graphql-tag";

export const getTermParts = gql`
  query termParts($filter: TermPartFilterInput, $scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      termParts(filter: $filter) {
        items {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
    }
  }
`;
