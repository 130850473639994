import { Interval, Session } from "@models/ISchema";
import { useMemo } from "react";

type UseSelectedWeekIntervalsReturn = [{ [id: string | number]: Interval }];

const useSelectedWeekIntervals = (sessions: Session[] = []): UseSelectedWeekIntervalsReturn => {
  const selectedWeekIntervals = useMemo(() => {
    const weekIds = {};

    sessions.forEach(session => {
      const events = session?.events ?? [];

      events.forEach(({ interval }) => {
        if (!(interval.id in weekIds)) {
          weekIds[interval.id] = interval;
        }
      });
    });

    return weekIds;
  }, [sessions]);

  return [selectedWeekIntervals];
};

export default useSelectedWeekIntervals;
