import gql from "graphql-tag";

export const instructorSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: InstructorFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createInstructorFilter(filter: $filter) {
        id
        instructors(pagination: $pagination) {
          items {
            id
            code
            name
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
            total
          }
        }
      }
    }
  }
`;

export const instructorSearchQuery = gql`
  query filter($filterId: ID, $scenarioId: ID!, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      instructors(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          name
          priority
          assignment {
            blocks
            averagePerIntervalBlocks
            usagePercentage
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
