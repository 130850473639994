import gql from "graphql-tag";

export const createClassroomBookingMutation = gql`
  mutation createClassroomBooking(
    $scenarioId: ID!
    $originId: ID!
    $input: CreateClassroomBookingInput!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      createClassroomBooking(input: $input) {
        skippedValidations
        commited
        validationErrors {
          __typename
          ... on InvalidInput {
            code
          }
          ... on InvalidDate {
            start
            end
          }
          ... on ClassroomClash {
            sessions {
              id
              assignment {
                intervals {
                  id
                  name
                  startingDate
                  endingDate
                }
                blockRange {
                  start {
                    day
                    startingTime
                  }
                  end {
                    day
                    endingTime
                  }
                }
              }
            }
          }
        }
        booking {
          id
          title
          isRecurrent
          status
          program
          responsible {
            id
          }
          sessions {
            scheduleEvent {
              start
              end
            }
          }
          classroom {
            id
            code
            name
            building {
              id
              code
            }
          }
        }
      }
    }
  }
`;
