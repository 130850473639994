import React from "react";
import cx from "classnames";
import css from "./Loader.module.scss";

interface LoaderProps {
  className?: string;
  size?: "mini" | "tiny" | "small" | "large" | "big" | "huge" | "massive";
}

function Loader(props: LoaderProps) {
  const { className = "", size = "" } = props;
  const sizeClass = size ? css?.[`loader__${size}`] : "";

  return <div className={cx(css.loader, sizeClass, className)}></div>;
}

export { Loader };
