import { useState, useEffect } from "react";
import * as R from "ramda";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { TermPart } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { TermPartsByCategory } from "../GridSections/ItemEdit/ContextEdit/ContextEdit";
import { getTermParts } from "../graphql/termParts.query";

export interface IUseGetTermPartsByCategoryProps {
  linkId?: string;
  onMount: (termPartsByCategory: TermPartsByCategory[]) => void;
}

export const useGetTermPartsByCategory = ({
  onMount,
  linkId,
}: IUseGetTermPartsByCategoryProps): [TermPartsByCategory[], () => void] => {
  const { scenario }: IParams = useParams();
  const client = useApolloClient();
  const [termPartsByCategory, setTermPartsByCategory] = useState<TermPartsByCategory[]>(null);

  const variables = {
    scenarioId: scenario,
    filter: linkId && {
      fields: {
        weeksByLinkId: {
          eq: linkId,
        },
      },
    },
  };

  const getTermPartsByCategoryInfo = async () => {
    try {
      const { data, errors } = await client.query({
        query: getTermParts,
        variables,
        fetchPolicy: "no-cache",
      });

      // if errors exists, console.error them and return right away
      if (errors) {
        console.error("[GetTermPartsByCategory error]", errors);
        return null;
      }

      // define two objects:
      //    - tpByCategories: array of objects where each key is a `categoryId`
      //          and the value is an array of the termParts of that category.
      //    - categoriesById: object where each key is a `categoryId` and each
      //          value is the category itself.
      const { tpByCategories, categoriesById } = R.pipe(
        R.view(R.lensPath(["data", "termParts", "items"])),
        R.reduce(
          ({ tpByCategories, categoriesById }, tp: TermPart) => {
            categoriesById[tp.category.id] = tp.category;
            if (R.not(R.has(tp.category.id, tpByCategories))) tpByCategories[tp.category.id] = [];
            tpByCategories[tp.category.id].push(tp);
            return { tpByCategories, categoriesById };
          },
          { tpByCategories: {}, categoriesById: {} },
        ),
      )(data);

      // define and set the termPartsByCategory
      const newTermPartsByCategory = R.pipe(
        R.toPairs,
        R.map(([categoryId, termParts]: [string, TermPart[]]) => ({
          category: categoriesById[categoryId],
          termParts,
        })),
      )(tpByCategories);
      setTermPartsByCategory(newTermPartsByCategory);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (R.not(R.isEmpty(termPartsByCategory))) onMount(termPartsByCategory);
  }, []);

  return [termPartsByCategory, getTermPartsByCategoryInfo];
};
