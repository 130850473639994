import gql from "graphql-tag";

export const FRAGMENT_SECTIONS = gql`
  fragment CalendarSections on EditorView {
    sections {
      id
      code
      component {
        id
        code
      }
      unasignedSessions {
        id
        blocksCount
        section {
          id
          code
          vacancies
          usedCapacity
          waitingListsCount
          links {
            id
          }
          bundle {
            id
            code
            label
            name
            capacity
          }
          groups {
            id
            code
            label
            name
          }
          course {
            id
            code
            name
            canEdit
            __typename
          }
          courseComponent {
            id
            requiresClassroom
            requiresInstructor
            requiresTimeslot
            component {
              id
              code
              __typename
            }
            online
            stripePriorities {
              id
              stripe {
                id
                code
                name
              }
            }
            __typename
          }
          __typename
        }
        assignment {
          intervals {
            id
            name
            startingDate
            endingDate
            termParts {
              id
              code
              name
            }
          }
          __typename
        }
        blocksCount
        classrooms {
          id
          code
          name
          classroomType {
            id
            code
            name
          }
          building {
            id
            code
            name
            campus {
              id
              code
              name
              __typename
            }
            __typename
          }
          capacity
          allowedOverflow
          floor
          usable
        }
        instructors {
          id
          name
          code
        }

        __typename
      }
      sessions {
        id
        blocksCount
        section {
          id
          code
          vacancies
          usedCapacity
          waitingListsCount
          links {
            id
          }
          bundle {
            id
            code
            label
            name
            capacity
          }
          groups {
            id
            code
            label
            name
            type
          }
          course {
            id
            code
            name
            canEdit
            curriculum {
              program {
                campus {
                  id
                  name
                  code
                }
              }
            }
            demand {
              id
              vacancies
              crosslist
            }
            __typename
          }
          courseComponent {
            id
            requiresClassroom
            requiresInstructor
            requiresTimeslot
            maxBlocksPerSession
            component {
              id
              code
              __typename
            }
            online
            stripePriorities {
              id
              stripe {
                id
                code
                name
              }
            }
            __typename
          }
          __typename
        }
        scheduleEvent {
          colorIndex
          id
          start
          end
          __typename
        }
        events {
          id
          block {
            id
            __typename
          }
          __typename
        }
        assignment {
          blockRange {
            start {
              id
              day
              startingTime
              __typename
            }
            end {
              id
              endingTime
              __typename
            }
            __typename
          }
          intervals {
            id
            name
            startingDate
            endingDate
            termParts {
              id
              code
              name
            }
          }
          __typename
        }
        blocksCount
        classrooms {
          id
          code
          name
          classroomType {
            id
            code
            name
          }
          building {
            id
            code
            name
            campus {
              id
              code
              name
              __typename
            }
            __typename
          }
          capacity
          allowedOverflow
          floor
          usable
        }
        instructors {
          id
          name
          code
        }
        __typename
      }
      __typename
    }
  }
`;
