export enum Dimension {
  classroom = "classroom",
  instructor = "instructor",
  course = "course",
  league = "league",
  section = "section",
  population = "population",
  package = "package",
  group = "group",
}

export type DimensionType =
  | "classroom"
  | "instructor"
  | "course"
  | "link"
  | "section"
  | "population"
  | "package"
  | "group";

export type DimensionNameEs =
  | "sala"
  | "docente"
  | "asignatura"
  | "liga"
  | "sección"
  | "población"
  | "paquete"
  | "grupo";
