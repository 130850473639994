import React, { useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { Loading } from "@foris/avocado-ui";
import { IParams } from "../../../../models/IParams";
import { ShiftDependency, LevelDependency } from "../../../shared/selectors";
import { EmptyResult } from "../../../shared/modals";
import { validations } from "../../../advanceSearch/utils";
import {
  IShiftDependency,
  ILevelDependency,
  ICourseLevel,
  ICurriculumLevel,
} from "../../../advanceSearch/IAdvanceSearch";
import { ProgramFilterFieldsInput, PackageFilterInput } from "../../../../models/ISchema";
import Button from "../../../../common/components/Button/Button";
import { objectEmpty } from "../../../../utils/validations";
import { packageSearchMutation } from "../graphql/advanceSearch.queries";
import css from "../../../advanceSearch/advance-search.module.scss";

const AdvanceSearchPackage: React.FC = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(packageSearchMutation, {});
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [shiftValues, setShiftValues] = useState(null);
  const [arrayProgram, setArrayProgram] = useState<ProgramFilterFieldsInput[]>([]);
  const [objLevel, setObjLevel] = useState<ICourseLevel>({});
  const [objCurriculum, setObjCurriculum] = useState<ICurriculumLevel>({});
  const [loading, setLoading] = useState(false);

  const setLevelDependency = (value: ILevelDependency) => {
    const validation = validations.setLevelDependency(value, arrayProgram, objLevel, objCurriculum);
    validation.arrayProgram && setArrayProgram(validation.arrayProgram);
    validation.objCurriculum && setObjCurriculum(validation.objCurriculum);
    validation.objLevel && setObjLevel(validation.objLevel);
  };

  const setShiftDependency = (value: IShiftDependency) => {
    // validation array content
    const validation = validations.shiftDependency(value);
    setArrayProgram(validation);
    // selected shift values
    setShiftValues(value);
    // set level-course dependencies
    setObjLevel({});
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  const createObjectMutation = () => {
    const filterArray = [];
    if (!objectEmpty(objLevel)) {
      const levelId = objLevel.level ? { level: objLevel.level } : {};
      filterArray.push(levelId);
    }
    if (!objectEmpty(objCurriculum)) {
      const curriculumId = objCurriculum.curriculumId
        ? { curriculum: objCurriculum.curriculumId }
        : {};
      filterArray.push(curriculumId);
    }
    const program = arrayProgram.map(value => {
      if (value.id) return { programId: value.id };
      return value;
    });

    const filter: PackageFilterInput = {
      fields: {
        population: {
          and: [...program, ...filterArray],
        },
      },
    };

    const variables = {
      originId: origin,
      scenarioId: scenario,
      filter,
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return variables;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createPackageFilter;
      if (dataItems.packages.items.length > 0) {
        const idSearch = dataItems.id;
        const context = `${workspace}/${scenario}/${origin}`;
        const urlSearch = `/scheduler/editor/searchBy/package/${context}/?advance=${idSearch}`;
        history.push(urlSearch);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />
      {advanceRowContent(
        "Dependencia",
        <>
          <ShiftDependency
            onCallback={values => {
              setShiftDependency(values);
            }}
            className={css.activePaddingGroup}
          />
          <LevelDependency
            {...shiftValues}
            onCallback={values => {
              setLevelDependency(values);
            }}
            className={css.activePaddingEnd}
            activeCode
          />
        </>,
      )}
      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <div className={cx(css.advance_item, "container-row")}>
          <Button onClick={() => callUpdateHook()} disabled={error}>
            Buscar
          </Button>
        </div>
      )}
    </>
  );
};

export default AdvanceSearchPackage;
