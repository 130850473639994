import gql from "graphql-tag";

export const MIGRATE_GROUP_MUTATION = gql`
  mutation createGroup($scenarioId: ID!, $originId: ID!, $input: GroupMigrationInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      migrateGroup(input: $input) {
        skippedValidations
        commited
        group {
          code
          name
          __typename
        }
        validationErrors {
          ...InvalidFieldValidation
          ...InvalidLinkChanges
          ...InvalidGroupEnrollment
          ...InvalidGroupCodeValidation
          __typename
        }
        __typename
      }
      __typename
    }
  }

  fragment InvalidFieldValidation on InvalidFieldValidation {
    value
    reason
    meta
    __typename
  }

  fragment InvalidLinkChanges on InvalidLinkChanges {
    link {
      id
      __typename
    }
    sectionChanges {
      section {
        id
        courseComponent {
          id
          academicStandard
          __typename
        }
        __typename
      }
      validationErrors {
        ...InvalidComponentSize
        __typename
      }
      __typename
    }
    groupChanges {
      group {
        id
        code
        enrollmentStats {
          id
          enrollmentsCount
          __typename
        }
        __typename
      }
      validationErrors {
        ...InvalidGroupEnrollment
        __typename
      }
      __typename
    }
    sessionChanges {
      session {
        id
        vacancies
        section {
          id
          __typename
        }
        __typename
      }
      validationErrors {
        __typename
        ...InvalidClassroomCapacity
      }
      __typename
    }
    __typename
  }

  fragment InvalidComponentSize on InvalidComponentSize {
    reason
    component {
      academicStandard
      __typename
    }
    __typename
  }

  fragment InvalidGroupEnrollment on InvalidGroupEnrollment {
    reason
    __typename
  }

  fragment InvalidClassroomCapacity on InvalidClassroomCapacity {
    classroom {
      id
      capacity
      __typename
    }
    reason
    __typename
  }

  fragment InvalidGroupCodeValidation on InvalidGroupCodeValidation {
    rejected_groups {
      id
      capacity
      __typename
    }
    allowed
    __typename
  }
`;
