import React from "react";
import { Program } from "@models/ISchema";
import css from "../components/Preferences/preferences.module.scss";

export const getProgramSelectorLabel = (program: Program) => {
  if (!program) return "";

  return (
    <div>
      <h5 className={css.selector__input__population__label_top}>
        {program?.code} <b>{program?.name}</b>
      </h5>
      <p className={css.selector__input__population__label_bottom}>
        SE: {program?.campus?.name} | ES: {program?.department?.name} | MO:{" "}
        {program?.modality?.name} |JO: {program?.shift?.name}
      </p>
    </div>
  );
};
