import { useState } from "react";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Recommendation } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { sessionsRecommendations } from "../graphql/recommendation.query";
import { AdaptedRecommendation } from "../context/linkData.reducer";
import { recommendationAdapter } from "../adapters/recommendation";

interface IVariables {
  scenarioId: string;
  originId: string;
  sessionIds: string[];
}

export const useGetSessionsRecommendations = (): [
  { [key: string]: AdaptedRecommendation },
  (sessionIds: string[]) => void,
] => {
  const { origin: originId, scenario: scenarioId }: IParams = useParams();
  const apolloClient = useApolloClient();
  const [recommendationsResponse, setRecommendationsResponse] = useState<any>(null);

  const variables: IVariables = {
    scenarioId,
    originId,
    sessionIds: null,
  };

  const getSessionsRecomendations = async (sessionIds: string[]) => {
    const { data, errors } = await apolloClient.query({
      query: sessionsRecommendations,
      variables: R.set(R.lensProp<IVariables>("sessionIds"), sessionIds, variables),
      fetchPolicy: "no-cache",
    });

    if (errors) {
      console.error("[sessionsRecommendations error]", errors);
      return null;
    }

    const recommendationBySessionId = R.reduce(
      (acc, recommendation) => {
        acc[recommendation?.session?.id] = recommendationAdapter(recommendation);
        return acc;
      },
      {},
      (data?.cube?.sessionRecommendations?.recommendation as Recommendation[]) ?? [],
    );

    setRecommendationsResponse(recommendationBySessionId);
  };

  return [recommendationsResponse, getSessionsRecomendations];
};
