import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { lensPath, map, prepend, set } from "ramda";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { Campus, CampusSearchFieldsInput } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { Context } from "../../context/GroupsManagerContext";
import { Types } from "../../context/filters.reducer";
import { campusSearch } from "../../graphql/campusSearch.query";
import { SelectableOption } from "../../models";

const CampusSelector: React.FC = () => {
  const { state, dispatch } = useContext(Context);
  const client = useApolloClient();
  const allCampusesOption = { id: "*", value: "*", label: "Buscar o seleccionar", self: null };
  const [selectedCampus, setSelectedCampus] = useState<SelectableOption<Campus>>(
    Boolean(state?.filters?.campus)
      ? {
          id: state?.filters?.campus?.id,
          value: state?.filters?.campus?.id,
          label: `${state?.filters?.campus?.code} | ${state?.filters?.campus?.name}`,
          self: state?.filters?.campus,
        }
      : allCampusesOption,
  );
  const { origin, scenario }: IParams = useParams();
  const [page, setPage] = useState(0);
  const [, setSearchTerm] = useState("");
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);

  const variableFields: CampusSearchFieldsInput = useMemo(() => {
    const subjectId = state?.filters?.subject?.id;
    if (!subjectId) return undefined;
    return set(lensPath(["subject", "eq"]), parseInt(subjectId), {});
  }, [state?.filters?.subject]);

  useEffect(() => {
    dispatch({ type: Types.SetCampus, payload: selectedCampus?.self });
  }, [selectedCampus]);

  /**
   * Clean the selection if the filters are updated from outside this component
   * (in particular, with the context's action `CleanFilters`).
   */
  useEffect(() => {
    if (!state?.filters?.campus) setSelectedCampus(allCampusesOption);
  }, [state?.filters?.campus]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const variables = {
          query: campusSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              pagination: { page, size, searchTerm },
              fields: variableFields,
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery = data?.data;
        const pageInfo = dataQuery?.groupsManagerCampuses?.pageInfo;
        const campuses = dataQuery?.groupsManagerCampuses?.items;

        const options = map(
          (campus: Campus) => ({
            id: campus?.id,
            value: campus?.id,
            label: `${campus?.code} | ${campus?.name}`,
            self: campus,
          }),
          campuses ?? [],
        );

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: newSearchPage === 1 ? prepend(allCampusesOption, options) : options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  return (
    <SelectPagination
      label={`Sede`}
      isDisabled={false}
      onInputChange={setSearchTerm}
      value={selectedCampus}
      onChange={setSelectedCampus}
      loadOptions={loadOptions}
    />
  );
};

export default CampusSelector;
