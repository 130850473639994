import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { levelQuery } from "../graphql/level.queries";
import { Level, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorLevel, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const LevelSelect: React.FC<ISelectorLevel> = (props: ISelectorLevel) => {
  const client = useApolloClient();
  const { onCallback, curriculum, label, className } = props;
  const { scenario }: IParams = useParams();
  const [optionsLevels, setOptionesLevels] = useState([]);
  const [valueLevel, setValueLevel] = useState(null);
  const [prevCurriculum, setPrevCurriculum] = useState(curriculum);
  const [disabled, setDisabled] = useState(false);

  const variablesLevel = {
    query: levelQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setLevelOptions = useCallback(async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optLevels = [];
      if (dataQuery.levels.length > 0) {
        dataQuery.levels.forEach((level: Level) => {
          optLevels.push({ label: level.level, value: level.id });
        });
      }
      setOptionesLevels(optLevels);
    }
  }, []);

  const getLevelOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesLevel);
      await setLevelOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [client, variablesLevel, setLevelOptions]);

  const getCacheLevelOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesLevel);
      await setLevelOptions(data);
    } catch (error) {
      getLevelOptions();
    }
  }, [client, variablesLevel, setLevelOptions, getLevelOptions]);

  useEffect(() => {
    if (curriculum && prevCurriculum !== curriculum && disabled) {
      getCacheLevelOptions();
      setPrevCurriculum(curriculum);
      setDisabled(false);
    }
    if (!curriculum) {
      setPrevCurriculum(null);
      setValueLevel("");
      setDisabled(true);
    }
  }, [disabled, curriculum, prevCurriculum, getCacheLevelOptions]);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Nivel</label>}
      <Select
        options={optionsLevels}
        value={valueLevel}
        placeholder={label ? "Seleccionar" : "Nivel"}
        isClearable
        isDisabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValueLevel(value);
            onCallback(value);
          } else {
            setValueLevel("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default LevelSelect;
