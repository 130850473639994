import { useState } from "react";
import { view, lensPath, pipe, when, assoc, isEmpty, omit } from "ramda";
import { GroupChange, PageInfo } from "@models/ISchema";
import { useApolloClient } from "react-apollo";
import { groupChangesRequest } from "../graphql/groupChangesRequest.query";
import { TableFiltersReducerType } from "../context/types";

type Response = [
  GroupChange[],
  boolean,
  PageInfo,
  (
    page: number,
    owned: boolean,
    groupId: string,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => void,
];

const ROWS_PER_PAGE = 25;

export const useGetGroupsChanges = (scenarioId: string, originId: string): Response => {
  const client = useApolloClient();
  const [response, setResponse] = useState<GroupChange[]>(null);
  const [pageInfo, setPageInfo] = useState<PageInfo>(null);
  const [loading, setLoading] = useState(false);

  const viewGroupChanges = view(lensPath(["cube", "groupsManagerGroupChanges", "items"]));
  const viewPageInfo = view(lensPath(["cube", "groupsManagerGroupChanges", "pageInfo"]));

  const buildFieldsToFilterBy = (groupId: string, owned: boolean) =>
    pipe(
      when(() => Boolean(groupId), assoc("groupId", { eq: groupId })),
      when(() => owned, assoc("isOwner", { is: owned })),
    )({});

  const getGroupChanges = async (
    page: number,
    owned: boolean,
    groupId: string,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    setLoading(true);
    const fields = buildFieldsToFilterBy(groupId, owned);

    try {
      const { data, errors } = await client.query({
        query: groupChangesRequest,
        variables: {
          scenarioId,
          originId,
          filter: {
            fields: isEmpty(fields) ? undefined : fields,
            pagination: {
              page,
              size: ROWS_PER_PAGE,
            },
            orderBy:
              isEmpty(orderBy) || orderBy?.field == null ? undefined : omit(["header"], orderBy),
            searchBy: isEmpty(searchBy) || searchBy?.text == null ? undefined : searchBy,
          },
        },
        fetchPolicy: "no-cache",
      });

      if (errors) {
        setLoading(false);
        setPageInfo({});
        setResponse([]);
        console.error(errors);
        return null;
      }

      const groupChanges = viewGroupChanges(data);
      const newPageInfo = viewPageInfo(data);

      setResponse(groupChanges ?? []);
      setPageInfo(newPageInfo);
    } catch (error) {
      setPageInfo({});
      setResponse([]);
      console.error(error);
    }

    setLoading(false);
  };

  return [response, loading, pageInfo, getGroupChanges];
};
