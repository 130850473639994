import React from "react";
import Header from "../../../../common/components/Header/Header";

interface IHeaderEdit {
  event: any;
  createSession?: boolean;
  component?: any;
  sectionId?: any;
  vacancies?: number;
  title?: string;
}

const HeaderItemEdit: React.FC<IHeaderEdit> = (props: IHeaderEdit) => {
  const { event, createSession, component, sectionId, vacancies, title } = props;
  const courseComponent =
    event.resource && event.resource.info && event.resource.info.courseComponent
      ? event.resource.info.courseComponent
      : "";
  const stripeProperties = event.stripeProperties ? event.stripeProperties : null;
  const blocksCount =
    event.resource && event.resource.blocksCount ? event.resource.blocksCount : null;
  const infoEvent = event.resource && event.resource.info ? event.resource.info : null;

  const moreDetails = [
    {
      title: "Sección",
      content: !createSession ? event.sectionId : sectionId,
    },
    {
      title: "Componente",
      content: !createSession ? courseComponent && courseComponent.component.code : component,
    },
    {
      title: "Bloques",
      content: blocksCount ? blocksCount : "",
    },
    {
      title: "Vacantes",
      content: !createSession ? infoEvent && infoEvent.section.vacancies : vacancies,
    },
    {
      title: "Franjas",
      content: stripeProperties
        ? stripeProperties.map((stripeItem: any) => stripeItem.stripe.name)
        : "",
    },
  ];

  const titleHeader = event.title ? event.title : title;
  const topTitleHeader = event.sessionId ? `Sesión ${event.sessionId}` : `Sección ${sectionId}`;

  return <Header moreDetails={moreDetails} title={titleHeader} topTitle={topTitleHeader} />;
};

export default HeaderItemEdit;
