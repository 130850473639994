import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContextEDH } from "@context/ContextEDH";
import List from "../../modules/booking/pages/BookingList/BookingList";
import Classroom from "../../modules/booking/pages/Classroom/Classroom";
import Request from "../../modules/booking/pages/Request/Request";
import Detail from "../../modules/booking/pages/BookingDetail/BookingDetail";
import { Module, RouteType } from "./types";
import { requiredAbilitiesByModuleName } from "./settings";
import moduleIsAccessible from "./utils/moduleIsAccessible";

export const base = "/booking";
const context = ":workspace/:scenario/:origin";

const bookingsModule: Module = {
  name: "bookings",
  routes: [
    {
      path: `${base}/list/${context}`,
      component: List,
    },
    {
      path: `${base}/detail/${context}/:id`,
      component: Detail,
    },
    {
      path: `${base}/search/${context}/:id`,
      component: Classroom,
    },
    {
      path: `${base}/search/${context}`,
      component: Classroom,
    },
    {
      path: `${base}/request/${context}/:classroomId/:id`,
      component: Request,
    },
    {
      path: `${base}/request/${context}/:classroomId`,
      component: Request,
    },
  ],
};

const RouteBooking: React.FC = () => {
  const { state } = useContext(ContextEDH);

  const accessible = moduleIsAccessible(
    requiredAbilitiesByModuleName[bookingsModule?.name],
    state?.base?.base?.user?.abilities ?? {},
    state?.base?.base?.user?.permissions ?? {},
  );

  // If the module is not accesible, redirect the user to the home page
  if (!accessible) {
    return (
      <Switch>
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <Switch>
      {bookingsModule?.routes?.map((route: RouteType) => (
        <Route key={route.path} path={route.path} component={route.component} />
      ))}
    </Switch>
  );
};

export default RouteBooking;
