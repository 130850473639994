import React, { useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Table } from "@foris/avocado-ui";
import cx from "classnames";
import { IContextEdit, ContextEdit } from "../ContextEdit/ContextEdit";
import WeekColumn from "./WeekColumn";
import {
  IntervalValidationErrors,
  EditorValidationError,
  CustomValidation,
} from "./../../../../../models/ISchema";
import IncidenceByType from "../../../../../models/Incidences/SessionByType";
import IncidenceByCode from "../../../../../models/Incidences/SessionByCode";
import css from "./validation.module.scss";

interface ITableIncidencesProps {
  sessionId: string;
  currentWeeks?: any;
}

const TableIncidences: React.FC<ITableIncidencesProps> = (props: ITableIncidencesProps) => {
  const { sessionId, currentWeeks } = props;
  const context: IContextEdit = useContext(ContextEdit);
  const arrayValidations: (
    | EditorValidationError
    | CustomValidation
    | IntervalValidationErrors
  )[] = [
    ...context.intervalValidationErrors,
    ...context.validationErrors,
    ...context.customValidations,
  ];
  const tableValidations = arrayValidations.map(value => {
    const intervalId = value.__typename === "IntervalValidationErrors" ? value.interval.id : "0";
    return { data: value, id: intervalId };
  });

  /**
   * Incidence by intervals
   * @param validationRow
   */
  const incidenceByInterval = (validationRow: IntervalValidationErrors) => {
    let validationErrors = [];
    if (validationRow.validationErrors) validationErrors = validationRow.validationErrors;

    if (validationErrors.length === 0) {
      return (
        <div className="container-row row--wrap">
          <span className={cx(css.incidenceValidation)}>Semana sin incidencias</span>
        </div>
      );
    } else {
      return (
        <>
          {validationErrors.map((incidence: any, indexVal: number) => {
            const errorType = new IncidenceByType({ incidence });
            const incidences = incidence.sessions || [];
            if (incidences.length > 0) {
              return incidences.map((session: any, index: number) => {
                if (session.id !== sessionId) {
                  return (
                    <div
                      className={cx(css.incidenceValidation, "container-row", "row--wrap")}
                      key={index}
                    >
                      <span>{errorType.getMessage()}</span>
                      <span className={cx(css.incidenceValidation_session)}>
                        {session.section ? ` Sección ${session.section.id} - ` : ""} Sesión{" "}
                        {session.id}
                      </span>
                    </div>
                  );
                }
                return null;
              });
            } else {
              if (incidence)
                return (
                  <div key={indexVal}>
                    <span className={cx(css.incidenceValidation)}>{errorType.getMessage()}</span>
                  </div>
                );
            }
            return null;
          })}
        </>
      );
    }
  };

  /**
   * Function validation by type of incidence
   * @param row "row of table - accessor "
   */
  const incidenceValidation = (row: any) => {
    const validationRow: EditorValidationError | CustomValidation | IntervalValidationErrors =
      row.value;

    switch (validationRow.__typename) {
      case "IntervalValidationErrors":
        return incidenceByInterval(validationRow);
      case "CustomValidation":
        const errorCode = new IncidenceByCode(validationRow);
        return errorCode.getMessage();
      default:
        const errorType = new IncidenceByType({ incidence: validationRow });
        return errorType.getMessage();
    }
  };

  const weekColumn = (currentWeeks: any, row: any) => {
    const validationRow: EditorValidationError | CustomValidation | IntervalValidationErrors =
      row.value;

    return <WeekColumn currentWeeks={currentWeeks} column={validationRow} />;
  };

  const sortIncidence = (a: any, b: any) => {
    return parseInt(a.id) - parseInt(b.id);
  };

  const columns = [
    {
      id: "weeks",
      Header: "Semana",
      accessor: "data",
      maxWidth: 400,
      Cell: (row: any) => weekColumn(currentWeeks, row),
    },
    {
      id: "incidence",
      Header: "Incidencia",
      accessor: "data",
      Cell: (row: any) => incidenceValidation(row),
    },
  ];

  return (
    <Tabs>
      <TabList>
        {/* Opción Currículos */}
        <Tab>Incidencias</Tab>
        {/* Opción Asignaturas */}
        {/* <Tab> Empalme estudiantes</Tab> */}
      </TabList>
      {/* Sección opción Incidencias */}
      <TabPanel>
        <Table.Container data={tableValidations.sort(sortIncidence)} columns={columns} />
      </TabPanel>
      {/* Sección opción Empalme estudiantes */}
      {/* <TabPanel>
        <div>Empalme estudiantes</div>
      </TabPanel> */}
    </Tabs>
  );
};

export default TableIncidences;
