import React, { memo } from "react";
import { CardState } from "@foris/avocado-ui";
import css from "./bottomInfo.module.scss";
import { Link } from "react-router-dom";

interface Props {
  contextUrl: string;
}

const BottomInfo: React.FC<Props> = ({ contextUrl }) => {
  return (
    <CardState
      className={css.infoContainer}
      typeCard="informative"
      title={"Estamos validando los datos."}
    >
      <p className={css.info}>
        <span>El proceso puede demorar varios minutos.</span>
        <span>
          Actualiza la página o revisa el{" "}
          <Link to={`/editor/groups-manager/change_history/${contextUrl}`}>
            historial de cambios
          </Link>
          , para ver los cambios.
        </span>
      </p>
    </CardState>
  );
};

export default memo(BottomInfo);
