import { defaultTo } from "ramda";
import { AdaptedGroup } from "../models";

export const getTotal = (group: Partial<AdaptedGroup>): number => {
  return Boolean(group?.externalStats?.capacity != undefined)
    ? group?.externalStats?.capacity
    : group?.capacity;
};

export const getWaiting = (group: Partial<AdaptedGroup>): number => {
  return Boolean(group?.externalStats?.waitingListCount != undefined)
    ? group?.externalStats?.waitingListCount
    : group?.enrollmentStats?.waitingListsCount;
};

export const getUsed = (group: Partial<AdaptedGroup>): number => {
  return Boolean(group?.externalStats?.usedCapacity != undefined)
    ? group?.externalStats?.usedCapacity
    : group?.enrollmentStats?.usedCapacity;
};

export interface GetDonutRowsProps {
  total: number;
  used: number;
  waiting: number;
}

export const getDonutRows = ({ total, waiting, used }: GetDonutRowsProps) => {
  const free = defaultTo(0, total - used);

  const freePercentage = free / total >= 0 ? Math.floor((free / total) * 100) : 100;
  const usedPercentage = defaultTo(0, Math.ceil((used / total) * 100));
  const waitingPercetage = defaultTo(0, Math.floor((waiting / total) * 100));

  return [
    {
      name: "Libre",
      percentage: freePercentage,
      value: free,
    },
    {
      name: "Usado",
      percentage: usedPercentage,
      value: used,
    },
    {
      name: "En espera",
      percentage: waitingPercetage,
      value: waiting,
    },
  ];
};

export interface DonutRow {
  name: string;
  percentage: number;
  value: number;
}

const donut = (group: Partial<AdaptedGroup>) => {
  const total = defaultTo(0, getTotal(group));
  const waiting = defaultTo(0, getWaiting(group));
  const used = defaultTo(0, getUsed(group));

  return getDonutRows({
    total,
    waiting,
    used,
  });
};

export default donut;
