import * as React from "react";
import "./button.scss";

type Colors = "white" | "blue" | "transparent" | "error";
interface IButton {
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  color?: Colors;
}

class Button extends React.Component<IButton, any> {
  render() {
    const { className, disabled, children, onClick, color } = this.props;

    return (
      <button
        className={`button ${className} ${color && `button--${color}`}`}
        type="button"
        disabled={disabled}
        onClick={e => onClick && onClick(e)}
      >
        {children}
      </button>
    );
  }
}

export default Button;
