import gql from "graphql-tag";

export const sectionSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: SectionFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createSectionFilter(filter: $filter) {
        id
        sections(pagination: $pagination) {
          items {
            id
            course {
              id
              name
              code
            }
          }
        }
      }
    }
  }
`;

export const sectionSearchQuery = gql`
  query filter($scenarioId: ID!, $filterId: ID, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      sections(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          vacancies
          course {
            id
            name
            curriculum {
              id
              code
            }
          }
          sessions {
            id
            assignment {
              classroom {
                id
                code
                name
              }
              instructor {
                id
                code
                name
              }
            }
          }
          schedule {
            resourceAssigment {
              schedule
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
      }
    }
  }
`;
