import React, { useContext } from "react";
import * as R from "ramda";
import cx from "classnames";
import { Icon } from "@foris/avocado-ui";
import CardSession, { ClassroomCard } from "../CardSession/CardSession";
import { AppContext } from "../../context/EditSessionsContext";
import { blocksFormat } from "../../utils/sessionFormat";
import { selectedDayEn } from "@utils/days";
import css from "./compare.module.scss";
import { Week } from "@modules/sections/ISections";

const Compare: React.FC = () => {
  const { state } = useContext(AppContext);
  const session = state?.result?.selectSession;
  const sessionCreate = state?.result?.selectCreateSession;
  const sessionToDeleteSelected = state?.result?.sessionToDeleteSelected;

  let origin = null;
  let edited = null;

  const formatDay = (day: string, start: string, end: string) => {
    const dayFormat = selectedDayEn(day).label || "";
    const startTime = start?.substring(0, 5) || "";
    const endTime = end?.substring(0, 5) || "";
    return `${dayFormat} ${startTime} - ${endTime}`;
  };

  const intervalsEdition = (
    originalSessionIntervals: string[],
    modifiedSessionIntervals: string[],
  ) => {
    if (R.equals(modifiedSessionIntervals, originalSessionIntervals)) {
      return { value: null, isSame: true };
    }

    const aditions = R.difference(modifiedSessionIntervals, originalSessionIntervals);
    const removals = R.difference(originalSessionIntervals, modifiedSessionIntervals);

    return {
      isSame: false,
      value: [
        { type: "added", values: aditions },
        { type: "deleted", values: removals },
      ],
    };
  };

  if (session) {
    const blockRange = blocksFormat(session);
    const dayFormat = formatDay(blockRange?.day, blockRange?.startTime, blockRange?.endTime);
    const classrooms: ClassroomCard[] = session.classrooms.map(clas => ({
      code: clas.code,
      name: clas.name,
      building: clas.building?.code,
    }));
    origin = {
      blockRange: blockRange.selected === "hours" ? dayFormat : "No requiere horario",
      instructors: R.map(R.pick(["code", "name"]), session?.instructors),
      classrooms,
      vacancies: session?.section?.vacancies?.toString() || "-",
    };
  }

  if (session && state?.form?.savedSessions) {
    const saved = R.find(save => save.id === session.id, state?.form?.savedSessions);

    if (saved) {
      const dayFormat = formatDay(
        saved.blocks?.day,
        saved.blocks?.startTime,
        saved.blocks?.endTime,
      );
      const classrooms: ClassroomCard[] = saved.classrooms.map(clas => ({
        code: clas.code,
        name: clas.name,
        building: clas.building?.code,
      }));

      const linkWeekValuesById = R.reduce(
        (acc, week) => R.assoc(week?.id, week?.value?.toString(), acc),
        {},
        state?.link?.weeks ?? [],
      );
      const [originalSessionIntervals, modifiedSessionIntervals] = R.ap(
        [R.map(interval => (state?.link?.weeks ? linkWeekValuesById[interval?.id] : interval?.id))],
        [
          session?.assignment?.intervals ?? [],
          R.filter(R.propOr(false, "checked"), saved?.intervals ?? []),
        ],
      );

      edited = {
        blockRange: saved.blocks?.selected === "hours" ? dayFormat : "No requiere horario",
        instructors: R.map(R.pick(["code", "name"]), saved?.instructors),
        classrooms,
        vacancies: session?.section?.vacancies?.toString() || "-",
        intervals: intervalsEdition(originalSessionIntervals, modifiedSessionIntervals),
      };
    }
  }

  if (sessionCreate || sessionToDeleteSelected) {
    const session = sessionCreate ?? sessionToDeleteSelected;

    const dayFormat = formatDay(
      session.blocks?.day,
      session.blocks?.startTime,
      session.blocks?.endTime,
    );

    const classrooms: ClassroomCard[] = session?.classrooms?.map(clas => ({
      code: clas.code,
      name: clas.name,
      building: clas.building?.code,
    }));

    const linkWeeksById = R.reduce(
      (acc, week) => R.assoc(week.id, week, acc),
      {},
      state?.link?.weeks ?? [],
    );
    const newSessionWeeksIds = R.pipe(
      R.filter(R.propOr(false, "checked")),
      R.map((interval: Week) =>
        interval.id in linkWeeksById ? linkWeeksById[interval.id] : interval.id,
      ),
      R.map(R.pipe(R.propOr("", "value"), value => value?.toString())),
    )(session?.intervals ?? []);

    origin = {
      blockRange: session?.blocks?.selected === "hours" ? dayFormat : "No requiere horario",
      instructors: session?.instructors ?? [],
      classrooms,
      intervals: intervalsEdition([], newSessionWeeksIds),
    };
  }

  return (
    <section className={cx(css.compare, "container-row", "row--between")}>
      {session && (
        <>
          <CardSession
            title="Sesión origen"
            blockRange={{ value: origin?.blockRange }}
            instructors={{ value: origin?.instructors }}
            classrooms={{ value: origin?.classrooms }}
            vacancies={{ value: edited?.vacancies }}
          />
          <Icon icon="repeat" size={40} className={css.compare_icon} />
          <CardSession
            title="Cambios"
            blockRange={{
              value: edited.blockRange,
              isSame: R.equals(origin.blockRange, edited.blockRange),
            }}
            instructors={{
              value: edited.instructors,
              isSame: R.equals(origin.instructors, edited.instructors),
            }}
            classrooms={{
              value: edited.classrooms,
              isSame: R.equals(origin.classrooms, edited.classrooms),
            }}
            vacancies={{ value: edited.vacancies, isSame: true }}
            intervals={edited.intervals}
          />
        </>
      )}
      {sessionCreate && (
        <CardSession
          title="Sesión nueva"
          blockRange={{ value: origin.blockRange }}
          instructors={{ value: origin.instructors }}
          classrooms={{ value: origin.classrooms }}
          vacancies={{ value: "" }}
          intervals={origin.intervals}
        />
      )}
      {sessionToDeleteSelected && (
        <CardSession
          title="Sesión a eliminar"
          blockRange={{ value: origin.blockRange }}
          instructors={{ value: origin.instructors }}
          classrooms={{ value: origin.classrooms }}
          vacancies={{ value: "" }}
          intervals={origin.intervals}
          className={cx(css.redTitleCard)}
          isDeleted={true}
        />
      )}
    </section>
  );
};

export default Compare;
