import gql from "graphql-tag";

export const groupEnrollmentsByGroupId = gql`
  query groupEnrollmentsByGroupId(
    $scenarioId: ID!
    $originId: ID!
    $groupId: ID
    $filter: GroupsManagerEnrollmentsFilterInputType
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      groupEnrollments(groupId: $groupId, filter: $filter) {
        items {
          isWaitingList
          student {
            id
            code
            name
            firstName
            status
            meta
          }
          term {
            id
            code
            name
          }
          campus {
            id
            code
            name
          }
          program {
            id
            code
            name
          }
          enrollmentDate
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
        __typename
      }
      __typename
    }
  }
`;
