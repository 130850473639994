import React, { memo } from "react";
import { keys, map } from "ramda";
import { CardState } from "@foris/avocado-ui";
import { AdaptedGroup, GroupEditionErrors } from "../../models";
import messageByGroupEditionError from "../../utils/messageByGroupEditionError";
import css from "./bottomWarnings.module.scss";

interface Props {
  errorsByGroupId: Record<AdaptedGroup["id"], GroupEditionErrors>;
  groupsById: Record<AdaptedGroup["id"], AdaptedGroup>;
}

const BottomWarnings: React.FC<Props> = ({ errorsByGroupId, groupsById }) => {
  const title = () => {
    const length = keys(errorsByGroupId)?.length;
    if (length === 1) return "1 Grupo con alertas";
    else return `${length} Grupos con alertas`;
  };

  return (
    <CardState className={css.errors} typeCard="warning" title={title()}>
      <div className={css.groups}>
        {keys(errorsByGroupId)?.map((groupId: string) => {
          const errors = errorsByGroupId[groupId];
          return (
            <div key={groupId}>
              <h2 className={css.error_title}>{`Grupo ${groupsById[groupId]?.code}`}</h2>
              {map(
                error => (
                  <p key={`${groupId}-${error}`} className={css.error_message}>
                    {messageByGroupEditionError(error)?.verbose}
                  </p>
                ),
                keys(errors),
              )}
            </div>
          );
        })}
      </div>
    </CardState>
  );
};

export default memo(BottomWarnings);
