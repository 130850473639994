import { useCallback, useEffect, useMemo } from "react";
import { DayRowData } from "../context/types";

interface UseGetEventsCollision {
  days?: DayRowData[];
  onCollission?: (hasCollision: boolean) => void;
}

const useGetEventsCollision = ({ days = [], onCollission }: UseGetEventsCollision) => {
  const hasCollision = useCallback(
    (day: DayRowData) => {
      const { start: dayStart, end: dayEnd, index: dayIndex } = day;

      return days.some(stateDay => {
        const { start: stateStart, end: stateEnd, index: stateIndex } = stateDay;

        if (dayIndex === stateIndex || day.day.value !== stateDay.day.value) {
          return false;
        }

        if (
          !dayStart.value?.value ||
          !dayEnd.value?.value ||
          !stateStart.value?.value ||
          !stateEnd.value?.value
        ) {
          return false;
        }

        if (
          stateStart.value?.value === dayStart.value?.value ||
          stateEnd.value?.value === dayEnd.value?.value
        ) {
          return true;
        }

        if (stateIndex < dayIndex) {
          return dayStart.value?.value < stateEnd.value?.value;
        }

        return dayEnd.value?.value > stateStart.value?.value;
      });
    },
    [days],
  );

  const hasDaysCollision = useMemo(() => days.some(hasCollision), [days]);

  useEffect(() => {
    onCollission?.(hasDaysCollision);
  }, [hasDaysCollision]);

  return [hasDaysCollision];
};

export default useGetEventsCollision;
