import { Classroom, ClassroomBooking } from "@models/ISchema";
import dayjs from "dayjs";
import {
  SearchTypes,
  Preferences,
  DayRowData,
  IRequirements,
  Option,
  IntervalWeek,
  BlockOptions,
  RecurrencePayload,
} from "./types";

/**
 * Set the given `currentBooking` as the `RequestTypes`'s currentBooking.
 *
 * @param currentBooking ClassroomBooking
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setCurrentBooking = (currentBooking: ClassroomBooking, state: SearchTypes) => {
  return {
    ...state,
    currentBooking,
  };
};

/**
 * Clear the SearchTypes context.
 *
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const clearSearch = (state: SearchTypes) => {
  const searchClear: SearchTypes = {
    view: "search",
    requirements: {
      people: {
        count: null,
        error: false,
      },
    },
    preferences: {
      building: null,
      classroomType: null,
      program: null,
    },
    blockOptions: state?.blockOptions,
    buildingOptions: state?.buildingOptions,
    classroomTypesOptions: state?.classroomTypesOptions,
    intervals: state?.intervals,
    currentBooking: null,
    dateTimeFormatted: [],
    dateTime: [
      {
        index: 1,
        recurrence: false,
        day: {
          value: dayjs(new Date()).format("YYYY-MM-DD"),
          error: false,
        },
        start: {
          value: null,
          error: false,
        },
        end: {
          value: null,
          error: false,
        },
      },
    ],
  };

  return {
    ...state,
    ...searchClear,
  };
};

/**
 * Set the given `view` as the `RequestTypes`'s view.
 *
 * @param view "search" | "result"
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setView = (view: "search" | "result", state: SearchTypes) => {
  return {
    ...state,
    view,
  };
};

/**
 * Set the given `preferences` as the `RequestTypes`'s preferences.
 *
 * @param preferences Preferences
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setPreferences = (preferences: Preferences, state: SearchTypes) => {
  return {
    ...state,
    preferences,
  };
};

/**
 * Set the given `dateTime` as the `RequestTypes`'s dateTime.
 *
 * @param dateTime DayRowData[]
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setDateTime = (dateTime: DayRowData[], state: SearchTypes) => {
  return {
    ...state,
    dateTime,
  };
};

/**
 * Set the given `dateTime` as the `RequestTypes`'s dateTime.
 *
 * @param dateTime DayRowData[]
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setDateTimeFormatted = (dateTimeFormatted: DayRowData[], state: SearchTypes) => {
  return {
    ...state,
    dateTimeFormatted,
  };
};

/**
 * Set the given `requirements` as the `RequestTypes`'s requirements.
 *
 * @param requirements IRequirements
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setRequirements = (requirements: IRequirements, state: SearchTypes) => {
  return {
    ...state,
    requirements,
  };
};

/**
 * Set the given `blockOptions` as the `RequestTypes`'s blockOptions.
 *
 * @param requirements Option[]
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setBlockOptions = (blockOptions: BlockOptions, state: SearchTypes) => {
  return {
    ...state,
    blockOptions,
  };
};

/**
 * Set the given `buildingOptions` as the `RequestTypes`'s buildingOptions.
 *
 * @param buildingOptions Option[]
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setBuildingOptions = (buildingOptions: Option[], state: SearchTypes) => {
  return {
    ...state,
    buildingOptions,
  };
};

/**
 * Set the given `classroomTypesOptions` as the `RequestTypes`'s classroomTypesOptions.
 *
 * @param classroomTypesOptions Option[]
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setClassroomTypesOptionsOptions = (
  classroomTypesOptions: Option[],
  state: SearchTypes,
) => {
  return {
    ...state,
    classroomTypesOptions,
  };
};

/**
 * Set the given `intervals` as the `RequestTypes`'s intervals.
 *
 * @param newInterval IntervalWeek[]
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setIntervals = (newInterval: IntervalWeek[], state: SearchTypes) => {
  const newWee: IntervalWeek[] = newInterval?.map((interval, index) => {
    return {
      id: interval.id,
      value: (index + 1).toString(),
      endingDate: new Date(`${interval.endingDate} 00:00:00`).toDateString(),
      startingDate: new Date(`${interval.startingDate} 00:00:00`).toDateString(),
      eventCount: interval.eventCount,
      selected: interval.selected,
      name: "",
    };
  });
  return {
    ...state,
    intervals: newWee,
  };
};

/**
 * Set `RequestTypes`'s `recurrence` and `datetime`.
 *
 * @param recurrence boolean
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setRecurrence = (
  { recurrence, shouldResetDateTime = true }: RecurrencePayload,
  state: SearchTypes,
) => {
  return {
    ...state,
    recurrence,
    dateTime: shouldResetDateTime
      ? [
          {
            index: 1,
            recurrence,
            day: {
              value: recurrence ? null : dayjs(new Date()).format("YYYY-MM-DD"),
              error: false,
            },
            start: { value: null, error: false },
            end: { value: null, error: false },
          },
        ]
      : state.dateTime,
  };
};

/**
 * Set the given `classroom` as the `RequestTypes`'s classroom.
 *
 * @param classroom Classroom
 * @param state SearchTypes
 *
 * @return SearchTypes
 */
export const setClassroom = (classroom: Classroom, state: SearchTypes) => {
  return {
    ...state,
    classroom,
  };
};
