import React, { useEffect, useState } from "react";
import cx from "classnames";
import { map, pipe, prop, join, reduce, has, toPairs, propOr, sort } from "ramda";
import { Classroom, GroupScheduleSummary, Instructor } from "@models/ISchema";
import css from "./groupDetail.module.scss";

interface Props {
  schedules: GroupScheduleSummary[];
  primaryInstructorId: string;
}

interface IStringifiedSchedule {
  days: string;
  hourTime: string;
  classrooms: string;
}

const ScheduleSummary = ({ schedules, primaryInstructorId }: Props) => {
  const [groupedSchedules, setGroupedSchedules] = useState({});
  // for every schedule, sort the instructors by `primaryInstructorId` first
  const instructorsBySchedule = map(
    pipe(
      propOr([], "instructors"),
      sort((a: Instructor, b: Instructor) => {
        if (a?.id === primaryInstructorId) return -1;
        if (b?.id === primaryInstructorId) return 1;
        return 0;
      }),
    ),
    schedules ?? [],
  );

  /**
   * Update groupedSchedules if `schedules` are changed
   */
  useEffect(() => {
    // set each schedule properties as a string, except for the instructors
    const stringifySchedules: IStringifiedSchedule[] = schedules?.map(
      (schedule: GroupScheduleSummary) => {
        const days = schedule?.days?.map(prop("code"))?.join(", ");
        const hourTime = schedule?.schedule;
        const dateRange = schedule?.dateRange;
        const classrooms = schedule?.classrooms
          ?.map((classroom: Classroom) => {
            const building = classroom?.building?.code;
            const campus = classroom?.building?.campus?.code;
            const capacity = classroom?.capacity;
            return `${classroom?.code} | ${building} | ${campus} (${capacity})`;
          })
          .join(", ");

        return { days, hourTime, classrooms, dateRange };
      },
    );

    // group schedules by `datetime`, `classrooms` and `instructors`
    const newGroupedSchedules = reduce(
      (acc: any, schedule: IStringifiedSchedule) => {
        const key = `${schedule?.days}${schedule?.hourTime}-${schedule?.classrooms}`;
        if (!has(key, acc)) acc[key] = [] as IStringifiedSchedule[];
        acc[key].push(schedule);
        return acc;
      },
      {},
      stringifySchedules ?? [],
    );

    setGroupedSchedules(newGroupedSchedules);
  }, [schedules]);

  return (
    <>
      {toPairs<IStringifiedSchedule[]>(groupedSchedules)?.map(([key, stringifySchedules], idx) => {
        const orDash = (value: string) => (value ? value : "--");
        const days = stringifySchedules[0]?.days;
        const hourTime = stringifySchedules[0]?.hourTime;
        const dateRange = pipe(map(prop("dateRange")), join(" | "))(stringifySchedules);
        const classrooms = stringifySchedules[0]?.classrooms;

        return (
          <li key={key} className={"container-row"}>
            <ul className={css.schedule_item}>
              <li>
                <h3 className={css.schedule_item_title}>Periodo</h3>
                <p className={css.schedule_item_value}>{orDash(dateRange)}</p>
              </li>
            </ul>

            <ul className={css.schedule_item}>
              <li>
                <h3 className={css.schedule_item_title}>Horario</h3>
                <p className={css.schedule_item_value}>{`${days} ${hourTime}`}</p>
              </li>
            </ul>

            <ul className={css.schedule_item}>
              <li>
                <h3 className={css.schedule_item_title}>Salas</h3>
                <p className={css.schedule_item_value}>{orDash(classrooms)}</p>
              </li>
            </ul>

            <ul className={cx(css.schedule_item, css.instructors)}>
              <h3 className={css.schedule_item_title}>Docentes</h3>
              {(instructorsBySchedule[idx] as Instructor[])?.map((instructor: Instructor) => (
                <li key={`${idx}-${instructor?.id}`} className={css.instructor}>
                  <p className={css.instructor_name}>{instructor?.name}</p>
                  {instructor?.id === primaryInstructorId && (
                    <div className={css.instructor_mainLabel}>Principal</div>
                  )}
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </>
  );
};

export default ScheduleSummary;
