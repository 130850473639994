import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { ContextEdit, IContextEdit, ICurrentData, IClassRoom } from "../../ContextEdit/ContextEdit";
import { IParams } from "@models/IParams";
import Select from "../../../../../../common/components/Select/Select";
import { classroomQuery } from "../graphql/classroom.queries";
import css from "../../itemEdit.module.scss";

const FIRST_SEARCH = 20;

const ClassRoomsSearch: React.FC = () => {
  const client = useApolloClient();
  const context: IContextEdit = useContext(ContextEdit);
  const { scenario, origin }: IParams = useParams();
  const [valueSearch, setValueSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [selectValue, setSelectValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const serializeOptions = (classrooms: any) => {
    const classRoomOptions = [];

    if (classrooms.length === 0) return classRoomOptions;

    classrooms.forEach((croom: any) => {
      classRoomOptions.push({
        label: `${croom.code} - ${croom.name} - ${croom?.building?.campus?.code ?? "-"}`,
        value: croom.id,
        code: croom.code,
        name: croom.name,
        building: croom.building,
      });
    });

    return classRoomOptions;
  };

  useEffect(() => {
    const queryOptions = (value: string) => {
      return {
        query: classroomQuery,
        variables: {
          originId: origin,
          scenarioId: scenario,
          filter: {
            page: 1,
            size: FIRST_SEARCH,
            searchTerm: value,
          },
        },
      };
    };
    const getDataOptions = async (value: any) => {
      try {
        setLoading(true);
        if (value !== "") {
          const query = queryOptions(value);
          const { data } = await client.query(query);
          const clsrOptions = serializeOptions(data.cube.classrooms.items);
          setOptions(clsrOptions);
          setLoading(false);
        }
      } catch (error) {
        setOptions([]);
        setLoading(false);
      }
    };

    const getCacheDataOptions = async (value: any) => {
      try {
        setLoading(true);
        const query = queryOptions(value);
        const data = await client.readQuery(query);
        const clsrOptions = serializeOptions(data.cube.classrooms.items);
        setOptions(clsrOptions);
        setLoading(false);
      } catch (error) {
        getDataOptions(value);
      }
    };
    if (valueSearch !== "") getCacheDataOptions(valueSearch);
    else setOptions([]);
  }, [valueSearch, client, origin, scenario]);

  useEffect(() => {
    const oldData: ICurrentData = context.currentData;
    if (selectValue !== null) {
      const classroom: IClassRoom = {
        id: selectValue.value,
        name: selectValue.name,
        code: selectValue.code,
        building: selectValue.building,
      };
      const newData: ICurrentData = { ...oldData, classroom };
      context.setCurrentData(newData);
    } else {
      const newData: ICurrentData = { ...oldData, classroom: null };
      context.setCurrentData(newData);
      setOptions([]);
    }
  }, [selectValue]);

  return (
    <React.Fragment>
      <Select
        isClearable
        isLoading={loading}
        className={css.item_select}
        placeholder="Sala"
        onInputChange={(value: any) => setValueSearch(value)}
        options={options}
        value={selectValue}
        onChange={(value: any) => {
          const disabled = value
            ? context.classrooms.find((item: any) => item.id === value.value)
            : true;
          if (!disabled) {
            const classroomsList = [...context.classrooms];
            const classroom: IClassRoom = {
              id: value.value,
              name: value.name,
              code: value.code,
              building: value.building,
            };
            classroomsList.push(classroom);
            context.setClassrooms(classroomsList);
          }
          setSelectValue(null);
        }}
      />
    </React.Fragment>
  );
};

export default ClassRoomsSearch;
