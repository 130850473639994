import React, { useContext, useEffect, useMemo, useState } from "react";
import Layout from "@common/layout/Layout";
import { IParams } from "@models/IParams";
import { courseMultiKeyQuery } from "@modules/vacancies/graphql/courseMultiKey.queries";
import { useLazyQuery, useMutation } from "react-apollo";
import { useHistory, useParams } from "react-router-dom";
import HeaderVacancies from "@modules/vacancies/Header";
import { Button, CardState, Loading, TextField, Tooltip } from "@foris/avocado-ui";
import { LINK_CRUD_MUTATION } from "@dimensions/link/graphql/linkCrudMutation";
import { CubeMutation, GenericInvalidValidation, InvalidLabelLink, Link } from "@models/ISchema";
import { LINK_ERROR_LABELS } from "@models/Errors/LinkErrors/LinkErrors";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import useGoBack from "@common/hooks/useGoBack";
import css from "./clientCodeManagement.module.scss";

const getErrorMessage = (label: string, isValid: boolean) => {
  if (!label || isValid) {
    return "";
  }

  if (label?.length !== 2) {
    return "Longitud debe ser de 2 caracteres.";
  }

  return `No usar comillas (' "), saltos de línea, tabulación o espacios.`;
};

const getMutationErrorMessage = (reason: string, params: any) => {
  if (!reason || !(reason in LINK_ERROR_LABELS)) {
    return null;
  }

  return LINK_ERROR_LABELS?.[reason](params);
};

const ClientCodeManagement = () => {
  const { origin, scenario, workspace, id }: IParams = useParams();
  const history = useHistory();
  const context: IContextApp = useContext(ContextApp);
  const [linkLabel, setLinkLabel] = useState("");

  const { handleGoBack } = useGoBack({ workspace, scenario, origin });

  const [getLinkData, { data, loading: loadingData }] = useLazyQuery<any, any>(
    courseMultiKeyQuery,
    {
      variables: {
        scenarioId: scenario,
        originId: origin,
        id,
      },
    },
  );

  const bundlePath = `/editor/vacancies/${workspace}/${scenario}/${origin}/${data?.cube?.link?.bundle?.id}`;

  const [updateLinkLabel, { loading, data: mutationData }] = useMutation(LINK_CRUD_MUTATION, {
    onCompleted: data => {
      const cube = data?.cube as CubeMutation;

      if (cube?.linksCrud?.commited) {
        history.replace(bundlePath);
      }
    },
  });

  const isLabelValid = /^(?!.*[\s\n'""])(?!.*\\n)[^\s\n'""]{2}$/.test(linkLabel);
  const errorMessage = getErrorMessage(linkLabel, isLabelValid);

  const mutationError = useMemo(() => {
    const error = (mutationData?.cube as CubeMutation)?.linksCrud?.payload?.updates?.[0]
      ?.validationErrors?.[0] as GenericInvalidValidation | InvalidLabelLink;
    const errorLink = !!error && "link" in error ? error?.link : undefined;
    const errorPayload = !!errorLink
      ? {
          link: errorLink,
          scenario,
          workspace,
          origin,
        }
      : undefined;

    return getMutationErrorMessage(error?.reason ?? "", errorPayload);
  }, [mutationData]);

  useEffect(() => {
    if (context?.user?.abilities?.can_edit_links_and_sections) {
      getLinkData();
    } else {
      handleGoBack();
    }
  }, []);

  useEffect(() => {
    const link = data?.cube?.link as Link | null;

    if (!!link && link?.label) {
      handleGoBack();
    }
  }, [data]);

  const handleUpdateLinkLabel = () => {
    updateLinkLabel({
      variables: {
        originId: origin,
        scenarioId: scenario,
        input: {
          dryRun: false,
          skipValidations: false,
          changesets: {
            updates: [
              {
                linkId: id,
                label: linkLabel,
              },
            ],
          },
        },
      },
    });
  };

  return (
    <Layout contextSearch>
      {loading || loadingData ? <Loading /> : null}

      {data?.cube?.link?.bundle && <HeaderVacancies data={data?.cube?.link?.bundle} />}

      <div className={css.clientCodeManagement}>
        <h2 className={css.clientCodeManagement_title}>Añadir código personalizado a Liga</h2>

        <div className={css.clientCodeManagement_control}>
          <Tooltip
            label={
              (
                <ul className={css.tooltipLabel}>
                  <li>Admite caracteres de tipo letras, números, símbolos.</li>
                  <li>
                    No admite comillas (&#39; &quot;), saltos de línea, tabulación o espacios.
                  </li>
                  <li>Longitud debe ser de 2 caracteres.</li>
                </ul>
              ) as any
            }
            placement="right"
          >
            <label className={css.label} htmlFor="linkLabel">
              Código Liga
            </label>
          </Tooltip>

          <TextField
            id="linkLabel"
            className={css.textField}
            placeholder="A0"
            value={linkLabel}
            status={!!errorMessage ? "error" : undefined}
            onChange={e => setLinkLabel(e?.target?.value ?? "")}
          />

          {!!errorMessage && <span className={css.errorLabel}>{errorMessage}</span>}

          {mutationError && !loading && (
            <CardState
              className={css.errorCard}
              typeCard="error"
              title={mutationError?.title as string}
            >
              {mutationError?.message}
            </CardState>
          )}
        </div>

        <div className={css.clientCodeManagement_actions}>
          <Button
            variant="outline"
            className={css.actionButton}
            onClick={() => history.replace(bundlePath)}
          >
            Cancelar
          </Button>
          <Button
            className={css.actionButton}
            disabled={!isLabelValid || loading}
            onClick={handleUpdateLinkLabel}
          >
            Añadir código
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default ClientCodeManagement;
