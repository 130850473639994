import gql from "graphql-tag";
import { linkInfoFragment } from "@dimensions/link/graphql/linkInfo.fragment";
import { FRAGMENT_SECTIONS } from "@dimensions/graphql/section.fragment";

export const linkInfo = gql`
  query linkSection(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ...LinkInfo
        }
        ...CalendarSections
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
        termParts {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
    }
  }
  ${linkInfoFragment}
  ${FRAGMENT_SECTIONS}
`;
