import React, { useState, useContext, useEffect } from "react";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { Table, Pager, Button, Loading } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "../../../../../../models/IParams";
import { InstructorPage, PageInfo } from "../../../../../../models/ISchema";
import { ContextEdit, IContextEdit, IInstructor } from "../../ContextEdit/ContextEdit";
import { instructorSearchQuery } from "../graphql/instructor.queries";
import css from "../search.module.scss";

interface ITableResultsProps {
  dataId: string;
  onDisabled: (value: boolean, notResult: boolean) => void;
}
const TableResults: React.FC<ITableResultsProps> = (props: ITableResultsProps) => {
  const { dataId, onDisabled } = props;
  const { scenario }: IParams = useParams();
  const client = useApolloClient();
  const context: IContextEdit = useContext(ContextEdit);
  const [pageCount, setPageCount] = useState(1);
  const [dataPager, setDataPager] = useState<PageInfo>(null);
  const [dataCheck, setDataCheck] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [prevDataId, setPrevDataId] = useState(null);
  const [loading, setLoading] = useState(false);

  const saveData = () => {
    const arrayDataCheck: IInstructor[] = [...context.instructors];
    dataCheck.forEach((item: any) => {
      const findInstructor = context.instructors.find(value => value.id === item.id);
      if (!findInstructor) {
        const objData: IInstructor = {
          code: item.code,
          id: item.id,
          name: item.name,
        };
        arrayDataCheck.push(objData);
      }
    });
    context.setInstructors(arrayDataCheck);
  };

  const renderCheck = (row: any) => {
    return (
      <Table.Checkbox
        {...row.row.getToggleRowSelectedProps()}
        checked={dataCheck && dataCheck.find(value => value.id === row.value)}
        onChange={() => {
          const checked = [...dataCheck];
          const find = dataCheck && dataCheck.find(value => value.id === row.value);
          if (!find) {
            checked.push(row.row.original);
            setDataCheck(checked);
          } else {
            const removeChecked = checked.filter(check => check.id !== row.value);
            setDataCheck(removeChecked);
          }
        }}
      />
    );
  };

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 30,
      maxWidth: 50,
      Cell: (row: any) => renderCheck(row),
    },
    {
      Header: "Código",
      accessor: "code",
      maxWidth: 90,
    },
    {
      Header: "Nombre",
      accessor: "name",
      minWidth: 120,
    },
    {
      Header: "Prioridad",
      accessor: "priority",
      maxWidth: 90,
    },
    {
      Header: "Bloques Asignados (% semanal)",
      accessor: "assignment.averagePerIntervalBlocks",
      maxWidth: 220,
    },
    {
      Header: "Total Bloques Asignados",
      accessor: "assignment.blocks",
      maxWidth: 200,
    },
    {
      Header: "Ocupación (%)",
      accessor: "assignment.usagePercentage",
      maxWidth: 110,
    },
  ];

  const getDataTable = async (page = pageCount) => {
    setLoading(true);
    onDisabled(true, false);
    try {
      const { data } = await client.query({
        query: instructorSearchQuery,
        variables: {
          scenarioId: scenario,
          filterId: dataId,
          pagination: {
            page,
            size: 20,
          },
        },
      });

      const instructor: InstructorPage = data?.filter?.instructors;
      const dataItems = instructor.items;
      const totalItems = instructor.pageInfo.total;
      setDataTable(dataItems);
      setDataPager(instructor.pageInfo);
      setTotalCount(totalItems);

      if (totalItems === 0) {
        onDisabled(false, true);
      } else {
        onDisabled(false, false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      onDisabled(false, false);
    }
  };

  const onPageChange = (pageIndex: number) => {
    const numberPage = pageIndex;
    getDataTable(numberPage);
    setPageCount(numberPage);
  };

  useEffect(() => {
    if (dataId && dataId !== prevDataId && !loading) {
      getDataTable();
      setPrevDataId(dataId);
    }
  }, [dataId]);

  return (
    <>
      {!dataTable && loading && <Loading />}
      {dataTable && dataTable.length > 0 && dataPager && (
        <>
          <h3 className={css.cntSearch_title}>{`${totalCount} resultados`}</h3>
          <Table.Container
            data={dataTable}
            columns={columns}
            pagerTop={true}
            pagerBottom={true}
            loading={loading}
          >
            <Pager
              {...dataPager}
              total={dataPager?.total ?? 20}
              size={20}
              page={pageCount}
              onPageChange={pageIndex => onPageChange(pageIndex)}
            />
          </Table.Container>
          <div className={cx(css.cntBtn, "container-row")}>
            <Button
              className={cx(css.cntBtn_item)}
              typeButton="transparent"
              onClick={() => context.setCurrentView("form")}
            >
              Cancelar
            </Button>
            <Button
              className={cx(css.cntBtn_item)}
              onClick={() => {
                saveData();
                context.setCurrentView("form");
              }}
              disabled={dataCheck.length === 0}
            >
              Agregar
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default TableResults;
