import gql from "graphql-tag";

export const megaSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!, $campusId: ID) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      packages(filter: $filter, campusId: $campusId) {
        items {
          id
          vacancies
          index
          isPublished
          population {
            id
            code
            level
            curriculum
            term {
              id
              name
              code
            }
            campus {
              id
              code
              isGlobal
            }
            modality {
              id
              code
            }
            shift {
              id
              code
            }
            program {
              id
              code

              department {
                code
              }
            }
            courses {
              id
              curriculum {
                id
                code
                program {
                  id
                  code
                  campus {
                    id
                    code
                  }
                  modality {
                    id
                    code
                  }
                  shift {
                    id
                    code
                  }
                }
              }
            }
          }
          code
          __typename
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
