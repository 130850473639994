import React, { useState } from "react";
import { Campus, Building } from "../Selectors";

export interface IBuildingGroup {
  campus: string;
  building: string;
}

interface IBuildingDependencyProps {
  onCallback: (value: IBuildingGroup) => void;
  className?: string;
}

const BuildingDependency: React.FC<IBuildingDependencyProps> = (
  props: IBuildingDependencyProps,
) => {
  const { onCallback, className } = props;
  const [values, setValues] = useState<IBuildingGroup>(null);
  return (
    <>
      <Campus
        onCallback={value => {
          const buildingValues: IBuildingGroup = {
            campus: value ? value.value : null,
            building: null,
          };
          setValues(buildingValues);
          onCallback(buildingValues);
        }}
        className={className}
      />
      <Building
        campus={values ? values.campus : null}
        onCallback={value => {
          const buildingValues: IBuildingGroup = {
            campus: values.campus,
            building: value ? value.value : null,
          };
          setValues(buildingValues);
          onCallback(buildingValues);
        }}
        className={className}
      />
    </>
  );
};

export default BuildingDependency;
