import React, { useState, useRef } from "react";
import { Icon } from "@foris/avocado-ui";
import Options, { IOption } from "./Options";
import cx from "classnames";
import css from "./../autocomplete.module.scss";
import useClickOutside from "@common/hooks/useClickOutside";

export interface IDropFilterProps {
  onSelect: (value: IOption) => void;
  value?: string;
  options: Array<IOption>;
  onCallback?: () => void;
}

const DropFilter: React.FC<IDropFilterProps> = ({ value, options, onSelect, onCallback }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectOption, setSelectOption] = useState(value || "Seleccionar");

  const ref = useRef(null);
  useClickOutside({ ref, onClickOutside: () => setShowOptions(false) });

  const toggleShowOptions = (show: boolean) => {
    setShowOptions(show);

    if (show) {
      onCallback?.();
    }
  };

  const handleSelectDrop = (value: IOption | null) => {
    const valueLabel = value ? value.label : selectOption;

    setSelectOption(valueLabel);
    toggleShowOptions(false);

    if (value) {
      onSelect?.(value);
    }
  };

  return (
    <section className={cx(css.cntSelect)} ref={ref}>
      <p className={css.cntSelect_text} onClick={() => toggleShowOptions(!showOptions)}>
        {selectOption}
        <Icon icon={showOptions ? "chevron-up" : "chevron-down"} size={16} />
      </p>

      {showOptions && <Options options={options} onSelect={handleSelectDrop} />}
    </section>
  );
};

export default DropFilter;
