import { ClassroomBookingStatusEnum } from "@models/ISchema";

enum StatusIcons {
  check = "check",
  clock = "clock",
  close = "close",
}

enum StatusClassName {
  check = "iconStatusBooking__check",
  clock = "iconStatusBooking__clock",
  close = "iconStatusBooking__rejected",
}

enum ClassName {
  check = "iconStatusBooking--check",
  clock = "iconStatusBooking--clock",
  close = "iconStatusBooking--rejected",
}

enum StatusText {
  pending = "Pendiente",
  rejected = "Rechazada",
  confirmed = "Reservada",
  cancelled = "Cancelada",
  expired = "Expirada",
}

const statusIcons = {
  pending: StatusIcons.clock,
  rejected: StatusIcons.close,
  confirmed: StatusIcons.check,
  cancelled: StatusIcons.close,
  expired: StatusIcons.close,
};

const statusClassName = {
  check: StatusClassName.check,
  clock: StatusClassName.clock,
  close: StatusClassName.close,
};

const statusText = {
  pending: StatusText.pending,
  rejected: StatusText.rejected,
  confirmed: StatusText.confirmed,
  cancelled: StatusText.cancelled,
  expired: StatusText.expired,
};

export const statusIcon = (code: ClassroomBookingStatusEnum | string) => {
  const icon: StatusIcons = statusIcons[code] || "check";
  return icon;
};

/**
 * @deprecated
 * replace for `iconClassName` (this implies a change on the scss's modified from `__` to `--`).
 */
export const statusIconClassName = (code: ClassroomBookingStatusEnum | string) => {
  const icon: StatusIcons = statusIcons[code] || "check";
  const className: StatusClassName = statusClassName[icon];
  return className;
};

/**
 * @deprecated
 * replace for `iconText`
 */
export const statusIconText = (code: ClassroomBookingStatusEnum | string) => {
  const text: StatusText = statusText[code] || "";
  return text;
};

export const iconText = (code: ClassroomBookingStatusEnum | string) => {
  const text: StatusText = statusText[code] || "";
  return text;
};

export const iconClassName = (code: ClassroomBookingStatusEnum | string) => {
  const icon: StatusIcons = statusIcons[code] || "check";
  const className = ClassName[icon];
  return className;
};
