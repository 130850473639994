import React, { useState, useContext, useEffect } from "react";
import { Table } from "@foris/avocado-ui";
import Checkbox from "../../../../common/components/Checkbox/Checkbox";
import { ContextDisabledSession, IContextDisabled } from "./ContextDisabled/ContextDisabled";

interface ITableSessionProps {
  dataTable: any;
  sectionCheck: boolean;
}

const TableSession: React.FC<ITableSessionProps> = (props: ITableSessionProps) => {
  const { dataTable, sectionCheck } = props;
  const context: IContextDisabled = useContext(ContextDisabledSession);
  const [allCheck, setAllCheck] = useState(false);
  const [data, setData] = useState(dataTable);

  const dataCheck = (row: any, value: boolean) => {
    const cloneData = [...data];
    const objIndex = cloneData.findIndex((item: any) => item.id === row.value.id);
    cloneData[objIndex].dataSession.checked = value;
    setData(cloneData);
  };

  const allDataCheck = (value: boolean) => {
    const cloneData = [...data];
    cloneData.forEach((item: any) => {
      item.dataSession.checked = value;
    });
    setData(cloneData);
    setAllCheck(value);
  };

  useEffect(() => {
    const dataCheck = [];
    data.forEach((item: any) => {
      if (item.dataSession.checked) {
        dataCheck.push(item.dataSession);
      }
    });
    context.setSessionData(dataCheck);
  }, [data]);

  useEffect(() => {
    allDataCheck(sectionCheck);
  }, [sectionCheck]);

  const alignLeftDiv = (props: any) => <span>{props.value}</span>;

  const renderCheck = (row: any) => {
    return (
      <Checkbox
        check={row.value.checked}
        disabled={sectionCheck}
        onChange={value => {
          dataCheck(row, value);
        }}
      />
    );
  };

  const columns = [
    {
      Header: (
        <Checkbox
          check={allCheck}
          disabled={sectionCheck}
          onChange={value => allDataCheck(value)}
        />
      ),
      accessor: "dataSession",
      maxWidth: 50,
      Cell: (row: any) => renderCheck(row),
    },
    {
      Header: "Sesión",
      accessor: "id",
      minWidth: 50,
      Cell: alignLeftDiv,
    },
    {
      Header: "Horario Sesión",
      accessor: "blockRange",
      minWidth: 200,
      Cell: alignLeftDiv,
    },
    {
      Header: "Docente",
      accessor: "instructors",
      minWidth: 300,
      Cell: alignLeftDiv,
    },
    {
      Header: "Sala",
      accessor: "classRooms",
      minWidth: 50,
      Cell: alignLeftDiv,
    },
    {
      Header: "Vacantes",
      accessor: "vacancies",
      minWidth: 50,
      Cell: alignLeftDiv,
    },
  ];
  return <Table.Container data={data} columns={columns} />;
};

export default TableSession;
