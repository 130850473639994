import React, { useState } from "react";
import cx from "classnames";
import Cleave from "cleave.js/react";
import css from "./inputs.module.scss";

export interface ITimeRangeValues {
  minTime: string;
  maxTime: string;
}

export interface ITimeRangeReturn {
  required: boolean;
  onCallback: (value: ITimeRangeValues, error?: boolean) => void;
}

const TimeRangeInput: React.FC<ITimeRangeReturn> = (props: ITimeRangeReturn) => {
  const { required, onCallback } = props;
  const [minTime, setMinTime] = useState("");
  const [maxTime, setmaxTime] = useState("");
  const [error, setErrorCapacity] = useState(false);
  const [errorText, setErrorText] = useState("");

  const validateMin = (min: string, max: string) => {
    min = min.replace(":", "");
    max = max.replace(":", "");
    return parseInt(min) > parseInt(max);
  };

  const formatValue = (value: any) => {
    const re = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return value !== "" && !value.match(re);
  };

  const validateInput = (value: string, min: string, max: string) => {
    if (formatValue(value)) {
      setErrorCapacity(true);
      setErrorText("Formato incorrecto de hora (HH:MM)");
      return true;
    }
    if (validateMin(min, max)) {
      setErrorCapacity(true);
      setErrorText("La hora de inicio no puede ser mayor a la hora final.");
      return true;
    }
    setErrorCapacity(false);
    setErrorText("");
    return false;
  };

  const styleError = (required && !error) || error;

  return (
    <div className={cx(css.shared_input, css.shared_input__wrap, "col_12")}>
      <div className={css.shared_input_md}>
        <Cleave
          options={{ time: true, timePattern: ["h", "m"], type: "text" }}
          className={cx(css.input_range, styleError && css.input_range__error)}
          placeholder="Horario inicio"
          onChange={(value: any) => {
            const newValue = value.target.value;
            setMinTime(newValue);
            const error = validateInput(newValue, newValue, maxTime);
            if (newValue) {
              onCallback(
                {
                  minTime: newValue,
                  maxTime: maxTime,
                },
                error,
              );
            } else {
              onCallback(
                {
                  minTime: "",
                  maxTime: maxTime,
                },
                error,
              );
            }
          }}
        />
      </div>

      <div className={css.shared_input_md}>
        <Cleave
          options={{ time: true, timePattern: ["h", "m"], type: "text" }}
          className={cx(css.input_range, styleError && css.input_range__error)}
          placeholder="Horario fin"
          onChange={(value: any) => {
            const newValue = value.target.value;
            setmaxTime(newValue);
            const error = validateInput(newValue, minTime, newValue);
            if (newValue) {
              onCallback(
                {
                  minTime: minTime,
                  maxTime: newValue,
                },
                error,
              );
            } else {
              onCallback(
                {
                  minTime: minTime,
                  maxTime: "",
                },
                error,
              );
            }
          }}
        />
      </div>
      {error && <p className={cx(css.shared_input_error, "col_12")}>{errorText}</p>}
      {required && !error && (
        <p className={cx(css.shared_input_error, "col_12")}>
          Ingresar rango de horas para el que se busca disponibilidad.
        </p>
      )}
    </div>
  );
};

export default TimeRangeInput;
