import { UserAbilities, UserPermissions } from "@models/ISchema";
import { ModuleName } from "./types";

export interface Requirements {
  abilities: (keyof UserAbilities)[];
  permissions: (keyof UserPermissions)[];
}

export const requiredAbilitiesByModuleName: Record<ModuleName, Requirements> = {
  groups_manager: {
    abilities: ["can_access_group_manager"],
    permissions: [],
  },
  bookings: {
    abilities: [],
    permissions: ["create", "update"],
  },
};
