import gql from "graphql-tag";

export const courseSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: CourseFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createCourseFilter(filter: $filter) {
        id
        courses(pagination: $pagination) {
          items {
            id
            code
            name
          }
        }
      }
    }
  }
`;

export const courseSearchQuery = gql`
  query filter($scenarioId: ID!, $filterId: ID, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      courses(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          name
          sectionsCount
          demand {
            id
            vacancies
            crosslist
          }
          curriculum {
            id
            name
            program {
              id
              code
              campus {
                id
                code
                name
                __typename
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
