import * as R from "ramda";
import { Recommendation, RecommendationAlternatives } from "@models/ISchema";
import { AdaptedRecommendationAlternative } from "../context/linkData.reducer";

const alternativeAdapter = (
  alternative: RecommendationAlternatives,
): AdaptedRecommendationAlternative => {
  return R.pipe(
    R.set(
      R.lensProp<any>("classrooms"),
      R.pipe(R.split(","), R.reject(R.isEmpty))(alternative?.classrooms),
    ),
    R.set(R.lensProp<any>("days"), R.pipe(R.split(","), R.reject(R.isEmpty))(alternative?.days)),
    R.set(
      R.lensProp<any>("instructors"),
      R.pipe(R.split(","), R.reject(R.isEmpty))(alternative?.instructors),
    ),
    R.set(R.lensProp<any>("startTime"), alternative?.start_time),
    R.set(R.lensProp<any>("currentAssignmentMark"), alternative?.current_asignment_mark),
    R.omit(["current_asignment_mark", "start_time", "__typename"]),
  )(alternative as RecommendationAlternatives);
};

/**
 * Adapt a `Recommendation` to an `AdaptedRecommendation`.
 *
 * @param recommendation Recommendation
 * @return AdaptedRecommendation
 */
export const recommendationAdapter = (recommendation: Recommendation) => {
  const assignedWeeks = R.split(",", recommendation?.assigned_weeks);
  const sessionWeeks = R.split(",", recommendation?.session?.weeks);
  const sessionPackages = R.split(",", recommendation?.session?.packages);
  return R.pipe(
    R.set(R.lensProp<any>("assignedWeeks"), assignedWeeks),
    R.set(R.lensProp<any>("processId"), recommendation?.process_id),
    R.set(
      R.lensPath<any>(["session", "weeks"]),
      sessionWeeks,
    ),
    R.set(
      R.lensPath<any>(["session", "packages"]),
      sessionPackages,
    ),
    R.over(R.lensProp<any>("alternatives"), R.map(alternativeAdapter) as any),
    R.omit(["assigned_weeks", "process_id"]),
  )((recommendation as unknown) as Recommendation);
};
