import React, { useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { ShiftDependency, LevelDependency, Course } from "../../../shared/selectors";
import { EmptyResult } from "../../../shared/modals";
import { validations } from "../../../advanceSearch/utils";
import {
  IShiftDependency,
  ILevelDependency,
  ICourseId,
  ICourseLevel,
  ICurriculumLevel,
} from "../../../advanceSearch/IAdvanceSearch";
import { ProgramFilterFieldsInput } from "../../../../models/ISchema";
import Button from "../../../../common/components/Button/Button";
import { Loading } from "@foris/avocado-ui";
import { objectEmpty } from "../../../../utils/validations";
import { groupSearchMutation } from "./../graphql/advanceSearch.queries";
import css from "../../../advanceSearch/advance-search.module.scss";

const AdvanceSearchGroup: React.FC<any> = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(groupSearchMutation, {});
  const [isEmpty, setIsEmpty] = useState(false);
  const [shiftValues, setShiftValues] = useState(null);
  const [levelValues, setLevelValues] = useState(null);
  const [arrayProgram, setArrayProgram] = useState<ProgramFilterFieldsInput[]>([]);
  const [objLevel, setObjLevel] = useState<ICourseLevel>({});
  const [objCurriculum, setObjCurriculum] = useState<ICurriculumLevel>({});
  const [objCourse, setObjCourse] = useState<ICourseId>({});

  const [loading, setLoading] = useState(false);

  const setLevelDependency = (value: ILevelDependency) => {
    const validation = validations.setLevelDependency(value, arrayProgram, objLevel, objCurriculum);
    validation.arrayProgram && setArrayProgram(validation.arrayProgram);
    validation.objCurriculum && setObjCurriculum(validation.objCurriculum);
    validation.objLevel && setObjLevel(validation.objLevel);
    setObjCourse(validation.objCourse);
  };

  const setShiftDependency = (value: IShiftDependency) => {
    // validation array content
    const validation = validations.shiftDependency(value);
    setArrayProgram(validation);
    // selected shift values
    setShiftValues(value);
    // set level-course dependencies
    setLevelValues({});
    setObjLevel({});
    setObjCourse({});
  };

  const setCourseDependency = (value: string) => {
    const validation = validations.courseDependencies(value, objCourse);
    setObjCourse(validation);
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  const createObjectMutation = () => {
    const filterArray = [];
    !objectEmpty(objCurriculum) && filterArray.push(objCurriculum);
    !objectEmpty(objCourse) && filterArray.push(objCourse);
    const arraySelectors =
      arrayProgram.length > 0 && [...arrayProgram].filter(select => !select.shiftId && !select.id);
    const program = arrayProgram.length > 0 && [...arrayProgram].filter(select => select.id);

    if (program.length > 0) filterArray.push({ programId: { eq: program[0].id.eq } });

    const filter = arraySelectors ? [...arraySelectors, ...filterArray] : filterArray;
    let fields = {};
    if (filter.length > 0)
      fields = {
        and: filter,
      };

    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields,
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createGroupFilter;
      if (dataItems.groups.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/group/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />
      {advanceRowContent(
        "Dependencia",
        <>
          <ShiftDependency
            onCallback={values => {
              setShiftDependency(values);
            }}
            className={css.activePaddingGroup}
          />
          <LevelDependency
            {...shiftValues}
            onCallback={values => {
              setLevelValues(values);
              setLevelDependency(values);
              setCourseDependency(null);
            }}
            className={css.activePaddingEnd}
          />
          <Course
            curriculum={levelValues ? levelValues.curriculum : null}
            level={levelValues ? levelValues.level : null}
            filterBy="level"
            onCallback={value => {
              if (value) setCourseDependency(value.value);
            }}
            className={css.activePadding}
          />
        </>,
      )}
      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <div className={cx(css.advance_item, "container-row")}>
          <Button onClick={() => callUpdateHook()}>Buscar</Button>
        </div>
      )}
    </>
  );
};

export default AdvanceSearchGroup;
