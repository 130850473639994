import { Lens } from "ramda";
import { ActionMap } from "@models/IContext";
import { FiltersReducerType } from "./types";
import {
  addCourseTypeById,
  cleanFilters,
  removeCourseTypeById,
  selectCrn,
  selectPackage,
  setAdvancedFilter,
  setCampus,
  setDepartment,
  setSchool,
  setSubject,
} from "./filters.actions";
import { AcademicUnit, Campus, Package, Subject } from "@models/ISchema";
import { AdaptedGroup } from "../models";

export enum Types {
  AddCourseTypeById = "ADD_COURSE_TYPE_BY_ID",
  CleanFilters = "CLEAN_FILTERS",
  RemoveCourseTypeById = "REMOVE_COURSE_TYPE_BY_ID",
  SelectCRN = "SELECT_CRN",
  SelectPackage = "SELECT_PACKAGE",
  SetAdvancedFilter = "SET_ADVANCED_FILTER",
  SetCampus = "SET_CAMPUS",
  SetDepartment = "SET_DEPARTMENT",
  SetSchool = "SET_SCHOOL",
  SetSubject = "SET_SUBJECT",
}

type FiltersPayload = {
  [Types.AddCourseTypeById]: AcademicUnit;
  [Types.CleanFilters]: undefined;
  [Types.RemoveCourseTypeById]: AcademicUnit;
  [Types.SelectCRN]: AdaptedGroup["code"];
  [Types.SelectPackage]: Package["id"];
  [Types.SetAdvancedFilter]: { lens: Lens<FiltersReducerType, boolean>; value: boolean };
  [Types.SetCampus]: Campus;
  [Types.SetDepartment]: AcademicUnit;
  [Types.SetSchool]: AcademicUnit;
  [Types.SetSubject]: Subject;
};

export type FiltersActions = ActionMap<FiltersPayload>[keyof ActionMap<FiltersPayload>];

export const filtersReducer = (state: FiltersReducerType, action: FiltersActions) => {
  switch (action?.type) {
    case Types.AddCourseTypeById:
      return addCourseTypeById(action?.payload, state);
    case Types.CleanFilters:
      return cleanFilters(state);
    case Types.RemoveCourseTypeById:
      return removeCourseTypeById(action?.payload, state);
    case Types.SelectCRN:
      return selectCrn(action?.payload, state);
    case Types.SelectPackage:
      return selectPackage(action?.payload, state);
    case Types.SetAdvancedFilter:
      return setAdvancedFilter(action?.payload?.lens, action?.payload?.value, state);
    case Types.SetCampus:
      return setCampus(action?.payload, state);
    case Types.SetDepartment:
      return setDepartment(action?.payload, state);
    case Types.SetSchool:
      return setSchool(action?.payload, state);
    case Types.SetSubject:
      return setSubject(action?.payload, state);
    default:
      return state;
  }
};
