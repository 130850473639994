import gql from "graphql-tag";

export const groupLabels = gql`
  query labels($filter: LabelFilterInput, $scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      labels(filter: $filter) {
        items {
          id
          code
          name
          category {
            id
            code
            name
            labelSource {
              id
              code
              name
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
