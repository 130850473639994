import { useState } from "react";
import { useApolloClient } from "react-apollo";
import { classroomsAvailabilities } from "../graphql/classroomSearch.query";
import { ClassroomFilterFieldsInput, ClassroomPage } from "@models/ISchema";

const useClassroomsAvailabilities = (origin: string, scenario: string, bookingId?: string) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const client = useApolloClient();

  const getClassroomsAvailabilites = async (page: number, fields: ClassroomFilterFieldsInput[]) => {
    setLoading(true);

    try {
      const { data } = await client.query({
        query: classroomsAvailabilities,
        variables: {
          scenarioId: scenario,
          originId: origin,
          classroomBookingId: bookingId,
          filter: {
            pagination: {
              page: page,
              size: 10,
            },
            fields: {
              and: fields,
            },
          },
        },
      });
      const results: ClassroomPage = data?.cube?.classroomsAvailabilities;
      setResult(results);
    } catch (error) {
      console.error(error);
      setError(true);
    }

    setLoading(false);
  };

  return [{ error, loading }, result, getClassroomsAvailabilites];
};

export default useClassroomsAvailabilities;
