import * as React from "react";
import { Icon } from "@foris/avocado-ui";
import cx from "classnames";
import "./accordion.scss";

interface IAccordionProps {
  className?: string;
  headerAccordion?: React.ReactNode;
  activeBorder?: boolean;
  accordion?: any;
  classContent?: string;
  activeShadow?: boolean;
  children?: React.ReactNode | undefined;
  setState?: any;
}

class Accordion extends React.Component<IAccordionProps, any> {
  handleAccordion() {
    this.setState({
      activeAccordion: !this.state.activeAccordion,
    });
  }
  render() {
    const {
      children,
      className,
      headerAccordion,
      activeBorder,
      accordion,
      classContent,
      activeShadow,
    } = this.props;
    return (
      <section
        className={cx(
          "cnt_accordion",
          { "cnt_accordion--shadow": activeShadow },
          className && className,
        )}
      >
        <div
          className={cx("cnt_item", "container-row", "row--between", {
            "cnt_item--active": accordion.active && activeBorder,
          })}
          onClick={() => accordion.onChange(accordion.id)}
        >
          {headerAccordion}

          <div className="cnt_item-btn">
            <Icon
              className={cx("btn_accordion", {
                "btn_accordion--active": accordion.active,
              })}
              icon="chevron-down"
              size={14}
            />
          </div>
        </div>
        <div
          className={cx("content_accordion", "container-row", classContent, {
            "content_accordion--active": accordion.active,
          })}
        >
          {children}
        </div>
      </section>
    );
  }
}

export default Accordion;
