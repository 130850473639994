import { GroupEditionErrors } from "../models";

const messageByGroupEditionError = (
  reason: keyof GroupEditionErrors,
): { verbose: string; abbreviated: string } => {
  switch (reason) {
    case "CapacityGTMaxEfficiency":
      return {
        verbose: "Está aumentando el cupo por sobre el 100% del máximo de eficiencia.",
        abbreviated: "Excede máximo de eficiencia",
      };
    case "CapacityGTSmallerClassroom":
      return {
        verbose:
          "La suma de los cupos no debe sobrepasar la capacidad total del salón de menor tamaño asignado en el CRN.",
        abbreviated: "Excede sala de menor tamaño",
      };
    case "CapacityChangeOnCrnWithoutClassrooms":
      return {
        verbose: "El CRN no tiene salones asignados.",
        abbreviated: "CRN sin salones",
      };
    case "CapacityLT80MaxEfficiency":
      return {
        verbose: "Está disminuyendo el cupo total por debajo del 80% del máximo de eficiencia.",
        abbreviated: "Menor al 80% del máximo de eficiencia",
      };
    case "CapacityLTEnrollments":
      return {
        verbose:
          "No se puede disminuir el cupo a un número menor al de los alumnos ya inscritos en el grupo.",
        abbreviated: "Menor que estudiantes inscritos",
      };
    case "CapacityAugmentedForInactive":
      return {
        verbose: "No se puede aumentar la capacidad de un grupo inactivo.",
        abbreviated: "Aumento de capacidad de un grupo inactivo",
      };
    default:
      return { verbose: "Grupo con errores", abbreviated: "Grupo con errores" };
  }
};

export default messageByGroupEditionError;
