import gql from "graphql-tag";

export const SECTION_OPTIONS = gql`
  query($scenarioId: ID!, $filter: SectionFilterInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sectionsSearch(filter: $filter) {
        items {
          id
          code
          course {
            id
            name
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
