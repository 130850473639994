import React, { useMemo } from "react";
import { DataGrid, Icon, Pill } from "@foris/avocado-ui";
import { CustomPackage, LinkPackageObj } from "../context/types";
import { key } from "../utils";
import css from "./packagesTable.module.scss";
import { Link, useParams } from "react-router-dom";
import { IParams } from "@models/IParams";

interface Props {
  linkPackages: CustomPackage[];
  onDelete: (customPackage: CustomPackage) => void;
  linkId: string;
  deletions: LinkPackageObj;
  linkPackagesErrors: { [key: string]: boolean };
  hasEditPermissions?: boolean;
}

const headers = {
  code: "Código",
  term: "Periodo",
  campus: "Sede",
  modality: "Modalidad",
  shift: "Jornada",
  program: "Carrera",
  curriculum: "Curriculo",
  level: "Nivel",
  packageIndex: "Paquete",
  vacancies: "Vacantes",
  status: "Estado",
};

const getTableCell = (content: string | number) => (
  <span className={css.table_cell}>{content}</span>
);

const PackagesTable = (props: Props) => {
  const {
    linkPackages,
    onDelete,
    linkId,
    deletions,
    linkPackagesErrors,
    hasEditPermissions,
  } = props;

  const { origin, scenario, workspace }: IParams = useParams();
  const basePackageRoute = `/scheduler/editor/package/${workspace}/${scenario}/${origin}`;

  const getLeftActionColumn = (id: string, isNew = false) => {
    if (key(id, linkId) in linkPackagesErrors) {
      return <Icon className={css.alert_icon} icon="circle-full-alert" size={16} />;
    }

    if (key(id, linkId) in deletions) {
      return <Icon className={css.trash_icon} icon="trash" size={16} />;
    }

    if (isNew) {
      return <Icon className={css.is_new_icon} icon="circle-full-plus" size={16} />;
    }

    return "";
  };

  const newcolumns = [
    {
      header: headers.code,
      renderer: ({ code, id, isNew }: CustomPackage) => (
        <div className={css.table_cell_left_actions}>
          {getLeftActionColumn(id, isNew)}
          <Link to={`${basePackageRoute}/${id}`} className={css.table_cell_code}>
            {code}
          </Link>
        </div>
      ),
      styles: { desktop: { minWidth: "190px" } },
    },
    {
      header: headers.term,
      renderer: ({ population }: CustomPackage) => getTableCell(population?.term?.name),
    },
    {
      header: headers.campus,
      renderer: ({ population }: CustomPackage) => getTableCell(population?.campus?.code),
    },
    {
      header: headers.modality,
      renderer: ({ population }: CustomPackage) => getTableCell(population?.modality?.code),
    },
    {
      header: headers.shift,
      renderer: ({ population }: CustomPackage) => getTableCell(population?.shift?.code),
    },
    {
      header: headers.program,
      renderer: ({ population }: CustomPackage) => getTableCell(population?.program?.code),
    },
    {
      header: headers.curriculum,
      renderer: ({ population }: CustomPackage) => getTableCell(population?.curriculum),
    },
    {
      header: headers.level,
      renderer: ({ population }: CustomPackage) => getTableCell(population?.level),
    },
    {
      header: headers.packageIndex,
      renderer: ({ index }: CustomPackage) => getTableCell(index),
    },
    {
      header: headers.vacancies,
      renderer: ({ vacancies }: CustomPackage) => getTableCell(vacancies),
    },
    {
      header: headers.status,
      renderer: ({ isPublished }: CustomPackage) => (
        <Pill>
          {isPublished && <Icon className={css.checkIcon} icon="check" size={12} />}
          {isPublished ? "Publicado" : "No publicado"}
        </Pill>
      ),
    },
  ];

  const headersToAvoidSort = useMemo((): Set<string> => {
    const headersSet = new Set();

    Object.values(headers).forEach(header => {
      headersSet.add(header);
    });

    return headersSet as Set<string>;
  }, []);

  return (
    <DataGrid
      className={css.table}
      columns={newcolumns}
      batch={linkPackages}
      onHeaderClick={() => null}
      nonSortableColumns={headersToAvoidSort}
      rightActions={
        hasEditPermissions && [
          {
            onClick: onDelete,
            isVisible: () => true,
            icon: () => "circle-full-minus",
            mobileLabel: "Eliminar",
            disabled: (item: CustomPackage) => item?.isPublished,
          },
        ]
      }
    />
  );
};

export default PackagesTable;
