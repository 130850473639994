import gql from "graphql-tag";

export const sessionsRecommendations = gql`
  query recommendation($scenarioId: ID!, $originId: ID!, $sessionIds: [ID!]) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sessionRecommendations(sessionIds: $sessionIds) {
        recommendation {
          process_id
          assigned_weeks
          session {
            id
            duration
            weeks
            packages
          }
          alternatives {
            days
            start_time
            instructors
            classrooms
            current_asignment_mark
          }
        }
        __typename
      }
      __typename
    }
  }
`;
