import gql from "graphql-tag";

export const instructorSearch = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      instructors(filter: $filter) {
        items {
          id
          name
          code
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const instructorSearchMutation = gql`
  mutation filter($scenarioId: ID!, $originId: ID!, $filter: InstructorFilterInput!) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createInstructorFilter(filter: $filter) {
        id
      }
    }
  }
`;

export const instructorSearchQuery = gql`
  query filter($filterId: ID, $scenarioId: ID!, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      instructors(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          name
          priority
          assignment {
            blocks
            averagePerIntervalBlocks
            usagePercentage
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
