import React, { useState } from "react";
import cx from "classnames";
import Input from "../../../common/components/Input/Input";
import Checkbox from "../../../common/components/Checkbox/Checkbox";
import css from "./inputs.module.scss";

export interface ICapacityValues {
  minCapacity: string;
  maxCapacity: string;
  overflow: boolean;
}

export interface ICapacityReturn {
  onCallback: (value: ICapacityValues, error?: boolean) => void;
  desactivePaddging?: boolean;
}

const CapacityInput: React.FC<ICapacityReturn> = (props: ICapacityReturn) => {
  const { onCallback, desactivePaddging } = props;
  const [error, setErrorCapacity] = useState(false);
  const [capacityCheck, setCapacityCheck] = useState(false);
  const [minCapacity, setMinCapacity] = useState("");
  const [maxCapacity, setMaxCapacity] = useState("");

  const handleError = (min: string, max: string) => {
    if (parseInt(min) > parseInt(max)) {
      setErrorCapacity(true);
      return true;
    } else {
      setErrorCapacity(false);
      return false;
    }
  };

  return (
    <div className={cx(css.shared_input, css.shared_input__wrap, "col_12")}>
      <div
        className={cx(css.shared_input_sm, desactivePaddging && css.shared_input_sm__notPadding)}
      >
        <Input
          type="number"
          placeholder="Min"
          error={error}
          value={minCapacity}
          onChange={value => {
            if (value >= 0) {
              setMinCapacity(value);
              const error = handleError(value, maxCapacity);
              onCallback(
                {
                  minCapacity: value,
                  maxCapacity: maxCapacity,
                  overflow: capacityCheck,
                },
                error,
              );
            }
          }}
        />
      </div>

      <div className={css.shared_input_sm}>
        <Input
          type="number"
          placeholder="Max"
          error={error}
          value={maxCapacity}
          onChange={value => {
            if (value >= 0) {
              setMaxCapacity(value);
              const error = handleError(minCapacity, value);
              onCallback(
                {
                  minCapacity: minCapacity,
                  maxCapacity: value,
                  overflow: capacityCheck,
                },
                error,
              );
            }
          }}
        />
      </div>

      <div className={cx(css.shared_input_item_select)}>
        <Checkbox
          className={css.shared_input_item_title}
          label="Considerar sobrecupo"
          check={capacityCheck}
          onClick={() => {
            setCapacityCheck(!capacityCheck);
            onCallback({
              minCapacity: minCapacity,
              maxCapacity: maxCapacity,
              overflow: !capacityCheck,
            });
          }}
        />
      </div>
      {error && (
        <p className={cx(css.shared_input_error, "col_12")}>
          La capacidad mínima no puede ser mayor a la capacidad máxima.
        </p>
      )}
    </div>
  );
};

export default CapacityInput;
