import React, { useEffect, useState } from "react";
import { isEmpty, map } from "ramda";
import { useParams, useHistory } from "react-router-dom";
import cx from "classnames";
import { Loading, Button } from "@foris/avocado-ui";
import Layout from "../../../../common/layout/Layout";
import { ContextEditLabel, useContextEditLabel } from "./ContextEditLabels";
import HeaderLabels from "./HeaderLabels";
import SelectLabels from "./SelectLabels";
import ListLabels from "./ListLabels";
import { IParams } from "../../../../models/IParams";
import css from "./editLabels.module.scss";
import useUpdateGroupLabel from "../hooks/useUpdateGroupLabel";

const EditLabels = () => {
  const { id, scenario, origin, workspace }: IParams = useParams();
  const history = useHistory();
  const contextEdit = useContextEditLabel();
  const [loading, setLoading] = useState(false);
  const [updateGroupLabelsResponse, updateGroupLabels] = useUpdateGroupLabel(id, scenario, origin);
  const { labels, options, setLabels, setOptions } = contextEdit;
  const updateLabels = labels?.filter(label => label.isNew);
  const updateOptions = options?.filter(label => !label.value.isNew);
  const isUpdate = updateLabels?.length > 0 || updateOptions?.length > 0;

  const onSaveLabels = async () => {
    if (labels && isUpdate) {
      setLoading(true);
      updateGroupLabels(labels);
    }
  };

  useEffect(() => {
    if (!isEmpty(updateGroupLabelsResponse ?? {}) && loading) {
      if (updateGroupLabelsResponse?.commited) {
        const newLabels = updateGroupLabelsResponse.group.labels.map(value => ({
          ...value,
          isNew: false,
        }));
        if (updateOptions?.length) {
          const newOptions = map(
            value => ({
              label: value.label,
              value: { ...value.value, isNew: true },
            }),
            options ?? [],
          );
          setOptions(newOptions);
        }

        setLabels(newLabels);
        setLoading(false);
        history.push(`/scheduler/editor/group/${workspace}/${scenario}/${origin}/${id}`);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [updateGroupLabelsResponse]);

  return (
    <ContextEditLabel.Provider value={contextEdit}>
      <Layout contextSearch>
        {loading && <Loading />}
        <HeaderLabels onLoading={value => setLoading(value)} />
        {!loading && (
          <article className={cx(css.editLabels, "container-row")}>
            <h2 className={cx(css.editLabels_title, "col_12")}>Editar etiquetas</h2>
            <SelectLabels />
            <ListLabels />
            <footer className={cx(css.footer, "container-row")}>
              <Button
                typeButton="transparent"
                className={css.footer_btn}
                onClick={() => {
                  history.push(`/scheduler/editor/group/${workspace}/${scenario}/${origin}/${id}`);
                }}
              >
                Volver
              </Button>
              <Button className={css.footer_btn} disabled={!isUpdate} onClick={onSaveLabels}>
                Confirmar
              </Button>
            </footer>
          </article>
        )}
      </Layout>
    </ContextEditLabel.Provider>
  );
};

export default EditLabels;
