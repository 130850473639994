import gql from "graphql-tag";

export const buildingQuery = gql`
  query buildings($scenarioId: ID!, $filter: BuildingFilterInput) {
    data(scenarioId: $scenarioId) {
      buildings(filter: $filter) {
        items {
          id
          code
          name
        }
      }
    }
  }
`;
