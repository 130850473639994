import React from "react";
import { Icon } from "@foris/avocado-ui";
import cx from "classnames";
import css from "./groupStatusIcon.module.scss";

interface GroupStatusIconProps {
  isActive?: boolean;
}

const GroupStatusIcon = ({ isActive }: GroupStatusIconProps) => {
  const icon = isActive ? "circle-full-check" : "circle-full-error";

  return (
    <Icon
      className={cx(css.groupStatusIcon, !isActive ? css.groupStatusIcon__error : "")}
      icon={icon}
      size={16}
    />
  );
};

export default GroupStatusIcon;
