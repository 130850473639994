import * as R from "ramda";
import { cloneDeep } from "lodash";
import { Session } from "@models/ISchema";
import { InitialStateType } from "../context/EditSessionsContext";
import {
  sessionsHaveTheSameBlocks,
  sessionsHaveTheSameInstructors,
  sessionsHaveTheSameClassrooms,
  sessionsHaveTheSameCheckedIntervals,
  sessionsHaveTheSameDay,
} from "./compareSelected";
import { Assignment, EditedSession } from "../context/formData.reducer";

/**
 * Given an array of `sessions` and an object with the edited resources,
 * return an `Assignment` object where each value it's true by default if the
 * assignment hasn't been edited, otherwise, a resource it's true only if all
 * the given sessions have the same assignment on the specific resource.
 *
 * @param sessions - EditedSession[]
 * @param assignments - Assignment
 *
 * @returns Assignment
 */
const sessionsHaveTheSameEditedResources = (
  sessions: EditedSession[] = null,
  assignments: Assignment,
): Assignment => {
  // lenses
  const blocksLens = R.lensProp<Assignment>("blocks");
  const dayLens = R.lensProp<Assignment>("day");
  const instructorsLens = R.lensProp<Assignment>("instructors");
  const classroomsLens = R.lensProp<Assignment>("classrooms");
  const intervalsLens = R.lensProp<Assignment>("intervals");

  return R.pipe(
    R.ifElse(
      R.always(assignments?.blocks),
      R.set(blocksLens, sessionsHaveTheSameBlocks(sessions)),
      R.set(blocksLens, true),
    ),
    R.ifElse(
      R.always(assignments?.day),
      R.set(dayLens, sessionsHaveTheSameDay(sessions)),
      R.set(dayLens, true),
    ),
    R.ifElse(
      R.always(assignments?.instructors),
      R.set(instructorsLens, sessionsHaveTheSameInstructors(sessions)),
      R.set(instructorsLens, true),
    ),
    R.ifElse(
      R.always(assignments?.classrooms),
      R.set(classroomsLens, sessionsHaveTheSameClassrooms(sessions)),
      R.set(classroomsLens, true),
    ),
    R.ifElse(
      R.always(assignments?.intervals),
      R.set(intervalsLens, sessionsHaveTheSameCheckedIntervals(sessions)),
      R.set(intervalsLens, true),
    ),
  )({});
};

export const compare = (newSession: Session | EditedSession, state: InitialStateType): boolean => {
  const eqById = (a: Session | EditedSession) => (b: Session | EditedSession) => a?.id === b?.id;

  const {
    selectedSessions: initialSelectedSessions,
    savedSessions: initialSavedSessions,
    assignmentEdited,
    sessionsToCreate,
    editedSessions,
  } = state?.form;

  const selectedSessions = initialSelectedSessions ? cloneDeep(initialSelectedSessions) : [];
  const savedSessions = initialSavedSessions ? cloneDeep(initialSavedSessions) : [];

  // Busca si ya estaba seleccionada esta sesión
  const clickedSession = R.find(eqById(newSession), selectedSessions);
  const savedSession =
    R.find(eqById(newSession), savedSessions) ?? R.find(eqById(newSession), sessionsToCreate);

  /** Si la sesión NO está guardada y hay cambios */
  if (clickedSession && !savedSession) {
    const selected = clickedSession;
    const equalityByResource = sessionsHaveTheSameEditedResources(
      [selected, editedSessions],
      assignmentEdited,
    );
    return R.all(R.equals(true), R.values(equalityByResource));
  }

  // Si alguna sección del formulario está habilitada para editar
  if (R.any(R.equals(true), R.values(assignmentEdited))) {
    /** Si la sesión está guardada y hay cambios */
    if (clickedSession && savedSession) {
      const equalityByResource = sessionsHaveTheSameEditedResources(
        R.append(editedSessions, [savedSession]),
        assignmentEdited,
      );
      return R.all(R.equals(true), R.values(equalityByResource));
    }
    // Si está agregando una sesión nueva - validar las otras sesiones
    if (!clickedSession) {
      const savedSessionsById = R.reduce(
        (acc, session) => R.assoc(session.id, session, acc),
        {},
        savedSessions,
      );
      let selectedReturn = true;
      selectedSessions.forEach(clone => {
        const selected = clone.id in savedSessionsById ? savedSessionsById[clone.id] : clone;
        const equalityByResource = sessionsHaveTheSameEditedResources(
          R.append(editedSessions, [selected]),
          assignmentEdited,
        );
        selectedReturn = R.all(R.equals(true), R.values(equalityByResource));
      });
      return selectedReturn;
    }
  }

  return true;
};
