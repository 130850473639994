import gql from "graphql-tag";

export const usersQuery = gql`
  query users($filter: UserFilterInput) {
    base {
      users(filter: $filter) {
        items {
          id
          name
          lastName
          __typename
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
