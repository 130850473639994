import gql from "graphql-tag";

export const DELETE_SESSIONS_BY_HOLIDAY = gql`
  mutation deleteSessionsByHolidays(
    $scenarioId: ID!
    $originId: ID!
    $input: DeleteSessionsByHolidaysInput!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sessionsByHoliday(input: $input) {
        commited
        link {
          id
          code
          sections {
            id
            code
          }
        }
      }
      __typename
    }
  }
`;
