import { set, lensProp, groupBy, prop, head, map, pipe } from "ramda";
import { PageInfo } from "@models/ISchema";
import { AdaptedGroup } from "../models";
import { DataReducerType } from "./types";

/**
 * Set the given `Group` as the `group` of the `GroupManager`'s context.
 *
 * @param AdaptedGroup
 * @param DataReducerType
 *
 * @return DataReducerType
 */
export const setGroup: (group: AdaptedGroup, state: DataReducerType) => DataReducerType = set(
  lensProp<DataReducerType>("group"),
);

/**
 * Create an hashmap where the `key` is the `groupId` and the `value` is the
 * `group` itself using the given `AdaptedGroup[]` array and set it as the
 * context's `groupsById`.
 *
 * @param groups AdaptedGroup
 * @param state DataReducerType
 *
 * @return DataReducerType
 */
export const setGroupsById = (groups: AdaptedGroup[], state: DataReducerType): DataReducerType => {
  const groupById = pipe(
    groupBy<AdaptedGroup>(prop<AdaptedGroup["id"]>("id")),
    map<Record<AdaptedGroup["id"], AdaptedGroup[]>, Record<AdaptedGroup["id"], AdaptedGroup>>(head),
  );
  return set(lensProp("groupsById"), groupById(groups), state);
};

/**
 * Set the given `Groups` as the `groups` of the `GroupManager`'s context.
 *
 * @param AdaptedGroup[]
 * @param DataReducerType
 *
 * @return DataReducerType
 */
export const setGroups: (groups: AdaptedGroup[], state: DataReducerType) => DataReducerType = set(
  lensProp<DataReducerType>("groups"),
);

/**
 * Set the given `GroupPage` as the `groupsPageInfo` of the `GroupManager`'s context.
 *
 * @param PageInfo
 * @param DataReducerType
 *
 * @return DataReducerType
 */
export const setGroupsPageInfo: (
  pageInfo: PageInfo,
  state: DataReducerType,
) => DataReducerType = set(lensProp<DataReducerType>("groupsPageInfo"));
