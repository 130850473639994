import dayjs from "dayjs";
import toArray from "dayjs/plugin/toArray";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(toArray);
dayjs.extend(customParseFormat);

export const ENGLISH_DAY_TO_SPANISH_DAY = {
  Mo: "Lu",
  Tu: "Ma",
  We: "Mi",
  Th: "Ju",
  Fr: "Vi",
  Sa: "Sa",
  Su: "Do",
};

export const dateToWeekday = (date: string) => {
  const rawWeekday = dayjs(date).format("dd");
  return ENGLISH_DAY_TO_SPANISH_DAY[rawWeekday] ?? "--";
};

const formatDate = (date: string) => {
  const arrDate = dayjs(date).toArray();
  return new Date(arrDate[0], arrDate[1], arrDate[2], arrDate[3], arrDate[4]);
};

export const day = (date: string, format?: string) => {
  const arrayDayToDate = formatDate(date);
  const day = dayjs(arrayDayToDate).format(format || "DD.MM.YYYY");
  return day;
};

export const time = (date: string, format?: string) => {
  const arrayDayToDate = formatDate(date);
  const time = dayjs(arrayDayToDate).format(format || "HH:mm");
  return time;
};

export const DAYS_OPTIONS = [
  { label: "Lunes", value: "1" },
  { label: "Martes", value: "2" },
  { label: "Miércoles", value: "3" },
  { label: "Jueves", value: "4" },
  { label: "Viernes", value: "5" },
  { label: "Sábado", value: "6" },
  { label: "Domingo", value: "7" },
];
export const DAYS_EN = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const DAYS_ES = ["LUNES", "MARTES", "MIÉRCOLES", "JUEVES", "VIERNES", "SÁBADO", "DOMINGO"];

export const MONTHS_ES = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const daysEnToEs = (dayEn: string) => {
  let valueEs = "";
  DAYS_EN.forEach((value: string, index: number) => {
    if (value === dayEn) valueEs = DAYS_ES[index];
  });
  return valueEs;
};

export const getSelectableDay = (dayValue: string) => {
  const isNumeric = (value: string) => /^-?\d+$/.test(value);

  if (isNumeric(dayValue)) {
    return DAYS_OPTIONS.find(value => value.value === dayValue) ?? null;
  }

  const rawDayId = dayjs(dayValue).day();
  const dayId = rawDayId === 0 ? "7" : rawDayId.toString();

  return DAYS_OPTIONS.find(value => value.value === dayId);
};
