import { GroupsManagerSearchOrderByFields, OrderByDirection } from "@models/ISchema";
import { TableFiltersReducerType } from "../context/types";
import { SubGroupsTableColumn } from "../models";

const headerToOrderByField = {
  CRN: GroupsManagerSearchOrderByFields.GroupCode,
  "Grupo | Campus": GroupsManagerSearchOrderByFields.GroupOrCampus,
  Asignatura: GroupsManagerSearchOrderByFields.CourseName,
  Subperiodo: GroupsManagerSearchOrderByFields.Subterm,
  "Docente principal": GroupsManagerSearchOrderByFields.MainInstructor,
  Estado: GroupsManagerSearchOrderByFields.State,
  Cupos: GroupsManagerSearchOrderByFields.Capacity,
  // "Utilización":GroupsManagerSearchOrderByFields.GroupOrCampus, -- not supported
  // "Cap.":GroupsManagerSearchOrderByFields.GroupOrCampus, -- not supported
  "Min - Max": GroupsManagerSearchOrderByFields.MinMax,
  Sede: GroupsManagerSearchOrderByFields.Campus,
};

/**
 * Given a GroupsManagerTable's column header, return the corresponding
 * GroupsManagerEnrollmentsOrderByFieldsEnum.
 *
 * @param header The GroupsManagerTable's column header.
 *
 * @returns TableFiltersReducerType["orderBy"]
 */
const subgroupsTableHeaderToOrderByObj = (
  header: SubGroupsTableColumn,
): TableFiltersReducerType["orderBy"] => {
  if (header == null || !(header in headerToOrderByField)) {
    return {
      header: "Sede",
      field: headerToOrderByField["Sede"],
      direction: OrderByDirection.Desc,
    };
  }
  return {
    header,
    field: headerToOrderByField[header],
    direction: OrderByDirection.Desc,
  };
};

export default subgroupsTableHeaderToOrderByObj;
