import React, { memo } from "react";
import { keys, map } from "ramda";
import { CardState } from "@foris/avocado-ui";
import { AdaptedGroup, GroupEditionErrors } from "../../models";
import messageByGroupEditionError from "../../utils/messageByGroupEditionError";
import css from "./bottomErrors.module.scss";

interface Props {
  errorsByGroupId: Record<AdaptedGroup["id"], GroupEditionErrors>;
  capacityError: {
    display: boolean;
    group: AdaptedGroup;
  };
  groupsById: Record<AdaptedGroup["id"], AdaptedGroup>;
}

const BottomErrors: React.FC<Props> = ({ errorsByGroupId, capacityError, groupsById }) => {
  const title = () => {
    const length = keys(errorsByGroupId)?.length + (capacityError?.display ? 1 : 0);
    return length === 1 ? "1 Grupo con errores" : `${length} Grupos con errores`;
  };

  return (
    <CardState className={css.errors} typeCard="error" title={title()}>
      <div className={css.groups}>
        {capacityError?.display && (
          <div>
            <h2 className={css.error_title}>{`Grupo ${capacityError?.group?.code}`}</h2>
            <p className={css.error_message}>La suma de los cupos sobrepasa la capacidad</p>
          </div>
        )}

        {keys(errorsByGroupId)?.map((groupId: string) => {
          const errors = errorsByGroupId[groupId];
          return (
            <div key={groupId}>
              <h2 className={css.error_title}>{`Grupo ${groupsById[groupId]?.code}`}</h2>
              {map(
                error => (
                  <p key={`${groupId}-${error}`} className={css.error_message}>
                    {messageByGroupEditionError(error)?.verbose}
                  </p>
                ),
                keys(errors),
              )}
            </div>
          );
        })}
      </div>
    </CardState>
  );
};

export default memo(BottomErrors);
