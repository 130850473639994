import { ScheduleEvent, Session } from "@models/ISchema";
import { getSelectableDay } from "./format";

export const getParsedDatetime = (event: ScheduleEvent, index?: number) => {
  const [date, rawStartTime] = (event?.start || "").split(" ");
  const [, rawEndTime] = (event?.end || "").split(" ");

  const [startHour, startMins] = rawStartTime.split(":");
  const startTime = `${startHour}:${startMins}`;

  const [endHour, endMins] = rawEndTime.split(":");
  const endTime = `${endHour}:${endMins}`;

  return {
    day: { value: date, error: false, index },
    start: { value: { label: startTime, value: startTime }, error: false, index },
    end: {
      value: { label: endTime, value: endTime },
      error: false,
      recurrence: false,
      index,
    },
    index,
  };
};

export const getUniqueRecurrentSessions = (sessions: Session[] = []) => {
  const savedDays = {};
  const datetimes = [];

  for (const { scheduleEvent } of sessions) {
    for (const schedule of scheduleEvent) {
      const [startDate, startHour] = (schedule?.start || "").split(" ");
      const [endDate, endHour] = (schedule?.end || "").split(" ");

      const { value: startDay } = getSelectableDay(startDate);
      const { value: endDay } = getSelectableDay(endDate);

      const dayKey = `${startDay}-${startHour}-${endDay}-${endHour}`;

      if (!(dayKey in savedDays)) {
        savedDays[dayKey] = dayKey;
        const index = datetimes.length + 1;

        datetimes.push(getParsedDatetime(schedule, index));
      }
    }
  }

  return datetimes;
};

export const getSingleEventSessions = (sessions: Session[] = []) => {
  const datetimes = [];

  for (const { scheduleEvent } of sessions) {
    for (const schedule of scheduleEvent) {
      const index = datetimes.length + 1;
      datetimes.push(getParsedDatetime(schedule, index));
    }
  }

  return datetimes;
};
