import React, { useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { Header } from "../../../../common/components";
import { IParams } from "../../../../models/IParams";
import { CubeQuery, Group } from "../../../../models/ISchema";
import { ContextEditLabel } from "./ContextEditLabels";
import { groupSection } from "../graphql/groupInfo.query";

interface IHeaderLabels {
  onLoading: (value: boolean) => void;
}

const HeaderLabels: React.FC<IHeaderLabels> = (props: IHeaderLabels) => {
  const { onLoading } = props;
  const client = useApolloClient();
  const context = useContext(ContextEditLabel);
  const { id, origin, scenario }: IParams = useParams();
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState(null);
  const [error, setError] = useState(false);

  const headerInfo = (groupInfo: Group, customLabel?: any) => {
    const details = [];
    const uniqueRow = [];
    const itemsDetail = [
      {
        title: "Vacantes",
        content: groupInfo?.capacity?.toString() || "",
      },
    ];

    if (groupInfo.type)
      itemsDetail.push({
        title: "Tipo de grupo",
        content: groupInfo?.type || "",
      });
    details.push(itemsDetail);

    if (customLabel) {
      customLabel.forEach((item: any) => {
        const value = groupInfo.customFields.find(customData => customData.code === item.code);
        if (value) {
          uniqueRow.push({
            title: item.label,
            content: value?.value?.replace(",", ", "),
          });
        }
      });
    }
    details.push(uniqueRow);

    return {
      title: groupInfo?.name || "",
      topTitle: groupInfo?.code?.toString() || "",
      moreDetails: details,
    };
  };

  const getGroupInfo = useCallback(async () => {
    setLoading(true);
    onLoading(true);
    try {
      const variables = {
        query: groupSection,
        variables: {
          resourceId: id,
          scenarioId: scenario,
          originId: origin,
          resourceType: "GROUP",
        },
      };
      const { data } = await client.query(variables);
      if (data) {
        const cubeQuery: CubeQuery = data.cube;
        const customFieldsQuery = data?.base?.customFields;
        const info: any = cubeQuery.editorView.info;
        const groupInfo: Group = info.group;
        if (groupInfo) {
          const groupInfoState = headerInfo(groupInfo, customFieldsQuery);
          const labels = groupInfo.labels.map(value => {
            return { ...value, isNew: false };
          });
          context.setLabels(labels);
          setHeader(groupInfoState);
        } else {
          setError(true);
        }
      }
      onLoading(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      onLoading(false);
      setLoading(false);
    }
  }, [client]);

  useEffect(() => {
    if (!header && !loading && !error) getGroupInfo();
  }, [header, error, getGroupInfo]);

  return header && <Header {...header} />;
};

export default HeaderLabels;
