import React, { useContext, useMemo } from "react";
import { CardState } from "@foris/avocado-ui";
import { ContextApp } from "@config/Context/contextApp";

interface AbilitiesMessageProps {
  className?: string;
}

const permissionsLabel = {
  can_assign_instructors: "Asignación de Docentes",
  can_assign_intervals: "Asignación de Semanas",
  can_assign_classrooms: "Asignación de Salas",
  can_assign_blocks: "Asignación de Horarios",
};

const AbilitiesMessage = ({ className = "" }: AbilitiesMessageProps) => {
  const { user } = useContext(ContextApp);
  const sessionAbilitiesDisabled = useMemo(
    () => Object.keys(permissionsLabel)?.filter(key => !user?.abilities?.[key]),
    [user?.abilities],
  );

  const labels = useMemo(
    () => sessionAbilitiesDisabled.map(key => permissionsLabel[key]).join(", "),
    [sessionAbilitiesDisabled],
  );

  if (!user?.abilities || !sessionAbilitiesDisabled?.length) {
    return null;
  }

  return (
    <CardState
      className={className}
      title={`No tienes ${sessionAbilitiesDisabled?.length} permisos`}
      typeCard="informative"
    >
      No tienes permisos para {labels}.
    </CardState>
  );
};

export default AbilitiesMessage;
