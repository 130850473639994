import * as R from "ramda";
import { Section } from "@models/ISchema";
import { Week } from "@modules/sections/ISections";
import orderSessionsByWeeks from "../utils/orderSessionsByWeeks";
import sessionAdapter from "./session";

/**
 * Given a raw `Section`, return a new `Section` with:
 * - sessions and unasignedSessions as `editedSessions`.
 * - the weeks of all the sessions ordered by weeks.
 *
 * @param linkWeeks - Week[]
 * @param section - Section
 * @return Section
 */
const sectionAdapter = R.curry(
  (linkWeeks: Week[], section: Section): Section =>
    R.pipe(
      R.over(R.lensProp<any>("sessions"), R.map<any, any>(sessionAdapter(linkWeeks))),
      R.over(R.lensProp<any>("sessions"), orderSessionsByWeeks),
      R.over(R.lensProp<any>("unasignedSessions"), R.map<any, any>(sessionAdapter(linkWeeks))),
      R.over(R.lensProp<any>("unasignedSessions"), orderSessionsByWeeks),
    )(section),
);

export default sectionAdapter;
