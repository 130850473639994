import React from "react";
import Modal from "../../../common/components/modal/Modal";
import css from "./modals.module.scss";

interface IEmptyModal {
  show: boolean;
  onClose: any;
}

const EmptyModal: React.FC<IEmptyModal> = (props: IEmptyModal) => {
  return (
    <Modal
      onClose={() => props.onClose()}
      show={props.show}
      headerColor="#294870"
      title="Búsqueda sin resultados"
    >
      <div className={css.not_results}>
        <section>
          Intente ajustar su búsqueda, menos filtros o restricciones aumentan la posibilidad de
          obtener resultados.
        </section>
        <footer>
          <button className={css.btn_footer_primary} onClick={() => props.onClose()}>
            Aceptar
          </button>
        </footer>
      </div>
    </Modal>
  );
};

export default EmptyModal;
