import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { megaSearch } from "../../graphql/linkMegaSearch.query";
import { linkSearchQuery } from "../../graphql/advanceSearch.query";
import { LinkPage } from "@models/ISchema";
import SearchResults from "./SearchResults";
import Layout from "../../../../../common/layout/Layout";
import { IParams } from "@models/IParams";
import { variablesSearch } from "../../../utils/searchDimension";

const SearchLink: React.FC = () => {
  const { origin, scenario }: IParams = useParams();
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);
  const [page, setPageCount] = useState(1);
  const variables = variablesSearch(scenario, origin, params, page);
  const querySearch = params?.advance ? linkSearchQuery : megaSearch;

  const { loading, error, data } = useQuery(querySearch, { variables });
  let links: LinkPage = data?.cube?.links;
  if (params?.advance) links = data?.filter?.links;

  return (
    <Layout contextSearch>
      <SearchResults
        data={links?.items}
        search={params?.search?.toString()}
        pageInfo={links?.pageInfo}
        setPage={(value: number) => setPageCount(value)}
        loading={loading}
        error={error}
      />
    </Layout>
  );
};

export default SearchLink;
