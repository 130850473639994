import { createContext, useCallback, useState } from "react";

export interface IDataSession {
  checked: boolean;
  id: string;
  intervals: IIntervals;
}

export interface IIntervals {
  id: string;
}

export interface IContextDisabled {
  sessionData: Array<IDataSession>;
  setSessionData: (value: Array<IDataSession>) => void;
}

const defaultContext: IContextDisabled = {
  sessionData: null,
  setSessionData: null,
};

export const ContextDisabledSession = createContext<IContextDisabled>(defaultContext);

export const useContextDisabledSession = (): IContextDisabled => {
  const [sessionData, setSessionDataState] = useState(defaultContext.sessionData);

  const setSessionData = useCallback((value: Array<IDataSession>) => {
    if (value) setSessionDataState(value);
  }, []);

  return {
    sessionData,
    setSessionData,
  };
};
