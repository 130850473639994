import { Dimension } from "../../../models/IDimension";

export const optionsFilter = [
  {
    label: "Sala",
    value: Dimension.classroom,
  },
  {
    label: "Docente",
    value: Dimension.instructor,
  },
  {
    label: "Asignatura",
    value: Dimension.course,
  },
  {
    label: "Liga",
    value: Dimension.league,
  },
  {
    label: "Sección",
    value: Dimension.section,
  },
  {
    label: "Población",
    value: Dimension.population,
  },
  {
    label: "Paquete",
    value: Dimension.package,
  },
  {
    label: "Grupo",
    value: Dimension.group,
  },
];

export const getQueryString = (value: string) => {
  let queryString = "";
  switch (value) {
    case Dimension.instructor:
      queryString = "instructors";
      break;
    case Dimension.classroom:
      queryString = "classrooms";
      break;
    case Dimension.course:
      queryString = "coursesWithSections";
      break;
    case Dimension.section:
      queryString = "sections";
      break;
    case Dimension.population:
      queryString = "populations";
      break;
    case Dimension.league:
      queryString = "links";
      break;
    case Dimension.package:
      queryString = "packages";
      break;
    case Dimension.group:
      queryString = "groups";
      break;
  }
  return queryString;
};
