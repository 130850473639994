import gql from "graphql-tag";

export const intervalQuery = gql`
  query interval($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      intervals {
        id
        startingDate
        endingDate
      }
    }
  }
`;
