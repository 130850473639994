import React, { useContext } from "react";
import { Icon, Table } from "@foris/avocado-ui";
import cx from "classnames";
import { ContextEdit, IContextEdit } from "../ContextEdit/ContextEdit";
import AutocompleteInstructor from "./Instructor/Autocomplete";
import AutocompleteClassRoom from "./ClassRoom/Autocomplete";
import css from "./search.module.scss";

const Searchs: React.FC = () => {
  const context: IContextEdit = useContext(ContextEdit);

  type Columns = "code" | "name" | "deleteInstructor" | "deleteClassrooms";

  const dataTable = (row: any, typeColumn: Columns) => {
    let element = null;
    switch (typeColumn) {
      case "code":
        element = <span className="code">{row.row.original.code}</span>;
        break;
      case "name":
        element = <span className="info">{row.row.original.name}</span>;
        break;
      case "deleteInstructor":
        element = (
          <button
            className={css.btnTrash}
            onClick={() => {
              const deleteRow = row.row.original.id;
              const listInstructors = [...context.instructors];
              const indexInstructor = listInstructors.findIndex(item => item.id === deleteRow);
              listInstructors.splice(indexInstructor, 1);
              context.setInstructors(listInstructors);
            }}
          >
            <Icon className={css.btnTrash_icon} icon="trash" size={18} />
          </button>
        );
        break;
      case "deleteClassrooms":
        element = (
          <button
            className={css.btnTrash}
            onClick={() => {
              const deleteRow = row.row.original.id;
              const listClassrooms = [...context.classrooms];
              const indexClassrooms = listClassrooms.findIndex(item => item.id === deleteRow);
              listClassrooms.splice(indexClassrooms, 1);
              context.setClassrooms(listClassrooms);
            }}
          >
            <Icon className={css.btnTrash_icon} icon="trash" size={18} />
          </button>
        );
        break;
    }
    return element;
  };

  const columnsInstructors = [
    {
      Header: "Código",
      accessor: "code",
      minWidth: 100,
      Cell: (row: any) => dataTable(row, "code"),
    },
    {
      Header: "Nombre Docente",
      accessor: "name",
      minWidth: 250,
      Cell: (row: any) => dataTable(row, "name"),
    },
    {
      Header: "",
      id: "delete",
      accessor: "delete",
      minWidth: 50,
      Cell: (row: any) => dataTable(row, "deleteInstructor"),
    },
  ];

  const columnsClassrooms = [
    {
      Header: "Código",
      accessor: "code",
      minWidth: 100,
      Cell: (row: any) => dataTable(row, "code"),
    },
    {
      Header: "Nombre Sala",
      accessor: "name",
      minWidth: 250,
      Cell: (row: any) => dataTable(row, "name"),
    },
    {
      Header: "",
      id: "delete",
      accessor: "delete",
      minWidth: 50,
      Cell: (row: any) => dataTable(row, "deleteClassrooms"),
    },
  ];

  const renderTable = (data, columns) => {
    return (
      <Table.Container data={data} columns={columns} secondary className={cx(css.item_table)} />
    );
  };

  return (
    <section className={css.cntSearch}>
      <div className={cx(css.item, "container-row", "row_align--center")}>
        <label className={cx(css.item_label, "col_12")}>Docente</label>
        <AutocompleteInstructor />
        <label
          className={cx(css.item_link, "col_4")}
          onClick={() => context.setCurrentView("serachInstructor")}
        >
          Búsqueda Avanzada
        </label>
        {context.instructors.length > 0 && renderTable(context.instructors, columnsInstructors)}
      </div>
      <div className={cx(css.item, "container-row", "row_align--center")}>
        <label className={cx(css.item_label, "col_12")}>Sala</label>
        <AutocompleteClassRoom />
        <label
          className={cx(css.item_link, "col_4")}
          onClick={() => context.setCurrentView("searchClassRoom")}
        >
          Búsqueda Avanzada
        </label>
        {context.classrooms.length > 0 && renderTable(context.classrooms, columnsClassrooms)}
      </div>
    </section>
  );
};

export default Searchs;
