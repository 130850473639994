import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { ContextEdit, IContextEdit } from "../../ContextEdit/ContextEdit";
import { instructorSearchMutation } from "../../../../../dimensions/instructor/graphql/advanceSearch.queries";
import Button from "../../../../../../common/components/Button/Button";
import { Campus, Department, Program, Course, ContractType } from "../../../../../shared/selectors";
import { Blocks } from "../../../../../shared/inputs";
import {
  IAssignedBlocks,
  IBlocksDependency,
  IContractTypeId,
  IAvailability,
  ICourseId,
} from "../../../../../advanceSearch/IAdvanceSearch";
import { validations } from "../../../../../advanceSearch/utils";
import { daysEn } from "../../../../../../utils/days";
import { objectEmpty } from "../../../../../../utils/validations";
import { IParams } from "../../../../../../models/IParams";
import TableResults from "./TableResults";
import css from "../search.module.scss";

type DependencieSelect = "Campus" | "Department" | "Program" | "Course";

const SearchInstructor: React.FC<any> = () => {
  const { origin, scenario }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(instructorSearchMutation, {});
  const context: IContextEdit = useContext(ContextEdit);
  const [campus, setCampus] = useState(null);
  const [department, setDepartment] = useState(null);
  const [program, setProgram] = useState(null);
  const [error, setError] = useState(false);
  const [objAssignedBlocks, setObjAssignedBlock] = useState<IAssignedBlocks>({});
  const [objContractType, setObjContractType] = useState<IContractTypeId>({});
  const [objAvailability, setObjAvailability] = useState<IAvailability>({});
  const [objCourse, setObjCourse] = useState<ICourseId>({});
  const [idSearch, setIdSearch] = useState(null);
  const [notResult, setNotResult] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const day = context.currentData.blockRanges.day;
  const weeks =
    context.currentData.repeat === "weekSelect"
      ? context.currentData.weeksRepeat
          .filter(weekChecked => weekChecked.checked === true)
          .map(week => parseInt(week.id))
      : context.currentData.weeksRepeat.map(week => parseInt(week.id));

  const setBlocks = (values: IBlocksDependency, error: boolean) => {
    if (error) {
      setError(error);
      return;
    }
    const validation = validations.blocks(values, objAssignedBlocks);
    setObjAssignedBlock(validation.assignedBlocks);
    setError(validation.error);
  };

  const setContractType = (value: string) => {
    const validation = validations.contractType(value, objContractType);
    setObjContractType(validation);
  };

  const setCourseDependency = (value: string) => {
    const validation = validations.courseDependencies(value, objCourse);
    setObjCourse(validation);
  };

  const setDependencies = (type: DependencieSelect, value: string) => {
    switch (type) {
      case "Campus":
        setCampus(value);
        setDepartment(null);
        setProgram(null);
        setCourseDependency(null);
        break;
      case "Department":
        setDepartment(value);
        setProgram(null);
        setCourseDependency(null);
        break;
      case "Program":
        setProgram(value);
        setCourseDependency(null);
        break;
      case "Course":
        setCourseDependency(value);
        break;
    }
  };

  const createObjectMutation = () => {
    const filterArray = [];
    const arrayProgram = [];
    !objectEmpty(objCourse) && filterArray.push(objCourse);
    !objectEmpty(objContractType) && filterArray.push(objContractType);
    !objectEmpty(objAssignedBlocks) && filterArray.push(objAssignedBlocks);
    !objectEmpty(objAvailability) && filterArray.push(objAvailability);

    if (campus)
      arrayProgram.push({
        campusId: {
          eq: campus,
        },
      });
    if (department)
      arrayProgram.push({
        departmentId: {
          eq: department,
        },
      });
    if (program) {
      arrayProgram.push({
        id: {
          eq: program,
        },
      });
    }
    if (arrayProgram.length > 0) {
      filterArray.push({
        program: {
          and: arrayProgram,
        },
      });
    }
    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [{ hasEvents: false }, ...filterArray],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };

  const callUpdateHook = async () => {
    setDisabledSearch(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      const dataItems = data.filter.createInstructorFilter;
      setIdSearch(dataItems.id);
      setDisabledSearch(false);
    } catch (error) {
      console.error(error);
      setDisabledSearch(false);
    }
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.searchSelect, "container-row")}>
      <h5 className={cx(css.searchSelect_title, "col_12")}>{title}</h5>
      <div className={cx(css.searchSelect_row, "container-row", "row_align--center")}>
        {component}
      </div>
    </div>
  );

  useEffect(() => {
    const validationDays = (daysEn.findIndex(dayArray => dayArray === day.value) + 1).toString();
    const validationTime = {
      start: context.currentData.blockRanges.startTime.value,
      end: context.currentData.blockRanges.endTime.value,
    };

    let availabilityObj: IAvailability = {};

    if (context.currentData.optionHour === "hourRequiere") {
      availabilityObj = {
        ...availabilityObj,
        availability: {
          ...availabilityObj.availability,
          dayId: [validationDays],
          blockRange: { ...validationTime },
          intervals: [...weeks],
        },
      };
    }
    setObjAvailability(availabilityObj);
  }, []);

  return (
    <section className={cx(css.cntSearch, css.cntSearch__advance, "container-row")}>
      <h3 className={cx(css.cntSearch_title, "col_12")}>Buscar Docente</h3>
      <div className="container-row">
        {advanceRowContent(
          "Dependencia",
          <>
            <Campus
              className={css.activePadding}
              onCallback={value => {
                const campusValue = value ? value.value : null;
                setDependencies("Campus", campusValue);
              }}
            />
            <Department
              className={css.activePadding}
              campus={campus}
              filterBy="campus"
              onCallback={value => {
                const departmentValue = value ? value.value : null;
                setDependencies("Department", departmentValue);
              }}
            />
            <Program
              className={css.activePadding}
              campus={campus}
              department={department}
              filterBy="department"
              onCallback={value => {
                const programValue = value ? value.value : null;
                setDependencies("Program", programValue);
              }}
            />
            <Course
              className={css.activePadding}
              program={program}
              filterBy="program"
              onCallback={value => {
                const courseValue = value ? value.value : null;
                setDependencies("Course", courseValue);
              }}
            />
          </>,
        )}
        {advanceRowContent(
          "Tipo de contrato",
          <ContractType
            className={css.activePadding}
            placeholder="Todos"
            onCallback={value => {
              const contract = value ? value.value : null;
              setContractType(contract);
            }}
          />,
        )}
        {advanceRowContent(
          "Bloques asignados",
          <Blocks
            className={css.inputBlocks}
            onCallback={(value, error) => setBlocks(value, error)}
          />,
        )}
      </div>
      <div className={cx(css.cntBtn, "container-row")}>
        <Button
          color="transparent"
          className={cx(css.cntBtn_item, "col_1")}
          onClick={() => context.setCurrentView("form")}
        >
          Cancelar
        </Button>
        <Button
          disabled={error || disabledSearch}
          onClick={() => callUpdateHook()}
          className={cx(css.cntBtn_item, "col_1")}
        >
          Buscar
        </Button>
      </div>
      {notResult && <>Sin Resultados</>}
      {idSearch && (
        <TableResults
          dataId={idSearch}
          onDisabled={(value: boolean, result: boolean) => {
            setNotResult(result);
            setDisabledSearch(value);
          }}
        />
      )}
    </section>
  );
};

export default SearchInstructor;
