import React from "react";
import css from "./instructor.module.scss";

export interface IHeaderProps {
  index: number;
}

const HeaderGroup: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { index } = props;

  return (
    <ul className={css.headerList} key={`header-list-${index}`}>
      <li className={css.headerList_item}>Docente</li>
      <li className={css.headerList_item}>Bloques</li>
      <li className={css.headerList_item}>Responsabilidad</li>
      <li className={css.headerList_item}>Carga</li>
      <li className={css.headerList_item}>Componente</li>
    </ul>
  );
};

export default HeaderGroup;
