import { CustomValidation } from "../ISchema";

type Exceeded = "CURRENT_YEAR" | "NEXT_YEAR" | "WEEK";
type BlocksKeys = "exceeded_blocks" | "assignable_blocks";

class SessionByCode {
  private incidence: CustomValidation;
  constructor(error: CustomValidation) {
    this.incidence = error;
  }

  private getBlockByKey = (blockKey: BlocksKeys) => {
    const item = this.incidence.items.find(error => error.key === blockKey);
    return item ? item.value : 0;
  };

  private getMessageByInstructor = (stringType: string) => {
    if (this.incidence.info.__typename === "InstructorInfo") {
      const instructorCode = this.incidence.info.instructor
        ? this.incidence.info.instructor.code
        : "";
      const exceededBlocks = this.getBlockByKey("exceeded_blocks");
      const assignableBlocks = this.getBlockByKey("assignable_blocks");
      return `Horas ${stringType} excedidas - Docente ${instructorCode} (configuradas: ${assignableBlocks}, excedidas: ${exceededBlocks})`;
    }
  };

  private incidenceByDate = (type: Exceeded) => {
    let stringType = "";
    switch (type) {
      case "CURRENT_YEAR":
        stringType = "año actual";
        break;
      case "NEXT_YEAR":
        stringType = "año siguiente";
        break;
      case "WEEK":
        stringType = "semanales";
        break;
    }
    if (this.incidence.info.__typename === "InstructorInfo")
      return this.getMessageByInstructor(stringType);
    return null;
  };

  private incidenceCip = () => {
    if (this.incidence.info.__typename === "InstructorInfo") {
      const code = this.incidence.info.instructor?.code;
      return code ? `El código CIP del docente ${code} no coincide con la asignatura` : null;
    }
    return null;
  };

  public getMessage() {
    if (this.incidence && this.incidence.code) {
      switch (this.incidence.code) {
        case "ExceededAssignableBlocksCurrentYear":
          return this.incidenceByDate("CURRENT_YEAR");

        case "ExceededAssignableBlocksUpcomingYear":
          return this.incidenceByDate("NEXT_YEAR");

        case "ExceededWeeklyAssignableBlocks":
          return this.incidenceByDate("WEEK");
        case "CipCode":
          return this.incidenceCip();
        default:
          return null;
      }
    } else {
      return null;
    }
  }
}

export default SessionByCode;
