import { ActionMap } from "@models/IContext";
import { Group, Tag } from "@models/ISchema";

export type GroupInfoType = {
  info: Group;
  customFields: Tag[];
};

export enum Types {
  SetGroupInfo = "SET_GROUP_INFO",
  SetGroupCustomFields = "SET_GROUP_CUSTOM_FIELDS",
}

type GroupInfoPayload = {
  [Types.SetGroupInfo]: Group;
  [Types.SetGroupCustomFields]: Tag[];
};

export type GroupInfoActions = ActionMap<GroupInfoPayload>[keyof ActionMap<GroupInfoPayload>];

export const groupInfoReducer = (state: GroupInfoType, action: GroupInfoActions) => {
  switch (action.type) {
    case Types.SetGroupInfo:
      return {
        ...state,
        info: action.payload,
      };
    case Types.SetGroupCustomFields:
      return {
        ...state,
        customFields: action.payload,
      };
    default:
      return state;
  }
};
