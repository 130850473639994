import React, { useState } from "react";
import cx from "classnames";
import Checkbox from "../../../../common/components/Checkbox/Checkbox";
import { IErrorWarning } from "../../../../models/Errors/ErrorWarning";
import css from "./../../../vacancies/Groups/groups.module.scss";

export interface IValidationsProps {
  /** Array of errors and warnings  */
  validations: IErrorWarning[];
  /** Callback of selected check  */
  onCallback: (check: boolean) => void;
}

const Validations: React.FC<IValidationsProps> = (props: IValidationsProps) => {
  const { onCallback, validations } = props;
  const [check, setCheck] = useState(false);

  // Filter array by type ErrorWarning
  const errors = validations ? validations.filter(error => error.type === "ERROR") : [];
  const warnings = validations ? validations.filter(warning => warning.type === "WARNING") : [];

  /**
   * set local state check and return this value
   * @param value "value of check"
   */
  const onChangeCheck = (value: boolean) => {
    setCheck(value);
    onCallback(value);
  };

  return (
    <>
      <ul className={cx(css.list_validation, "container-row")}>
        {errors.map((error: IErrorWarning, indexError: number) => (
          <li
            key={indexError}
            className={cx(css.list_validation_item, css.list_validation_item__error, "col_12")}
          >
            {error.message}
          </li>
        ))}
        {warnings.map((warning: IErrorWarning, indexWarning: number) => (
          <li key={indexWarning} className={cx(css.list_validation_item, "col_12")}>
            {warning.message}
          </li>
        ))}
      </ul>
      {warnings.length > 0 && errors.length === 0 && (
        <Checkbox
          label="Ignorar advertencias"
          check={check}
          onChange={(value: boolean) => {
            onChangeCheck(value);
          }}
        />
      )}
    </>
  );
};

export default Validations;
