import gql from "graphql-tag";

export const BLOCKING_THRESHOLD_QUERY = gql`
  query getBlockingThreshold($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      blockingThreshold {
        current_datetime
      }
    }
  }
`;
