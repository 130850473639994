import React, { useState, useEffect, useCallback, useContext, ReactNode } from "react";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/search.reducer";
import { DataQuery, PageInfo } from "@models/ISchema";
import { programsQuery } from "../../graphql/programs.query";
import css from "../Preferences/preferences.module.scss";
import { find } from "ramda";
import { getProgramSelectorLabel } from "@modules/booking/utils/programs";

interface Props {
  className?: string;
  initialValue?: { value: string | number; label: ReactNode };
}

const ProgramSelect: React.FC<Props> = ({ className = "", initialValue }) => {
  const { scenario }: IParams = useParams();
  const { state, dispatch } = useContext(BookingContext);
  const client = useApolloClient();
  const defaultProgram =
    typeof state?.search?.preferences?.program === "object"
      ? {
          label: getProgramSelectorLabel(state?.search?.preferences?.program),
          value: state?.search?.preferences?.program?.id,
        }
      : { label: state?.search?.preferences?.program };
  const [programPage, setProgramPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [optionsProgram, setOptionsProgram] = useState(null);
  const [valueProgram, setValueProgram] = useState(initialValue || defaultProgram);
  const [termToSearch, setTermToSearch] = useState("");

  const getPrograms = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;
      setLoading(true);

      try {
        const variables = {
          query: programsQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery: DataQuery = data?.data;
        const pageInfo: PageInfo = dataQuery?.programs?.pageInfo;
        const programs = dataQuery?.programs;

        const options = [];

        if (programs) {
          programs.items.forEach(program => {
            options.push({
              label: getProgramSelectorLabel(program),
              value: program.id,
            });
          });
        }

        setOptionsProgram(options);

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        setLoading(false);
        return null;
      }
    },
    [client],
  );

  const loadProgramOptions = async (searchTerm: string) => {
    let searchPage = 1;

    if (termToSearch === searchTerm) {
      searchPage = programPage;
    } else {
      setTermToSearch(searchTerm);
    }
    const { pageInfo, options: programOptions } = await getPrograms(searchTerm, searchPage);
    setProgramPage(programPage + 1);
    return {
      options: programOptions,
      hasMore: pageInfo?.hasNextPage,
      additional: {
        page: programPage,
      },
    };
  };

  useEffect(() => {
    if (valueProgram?.value) {
      dispatch({
        type: Types.SetPreferences,
        payload: {
          ...state?.search?.preferences,
          program: valueProgram,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!optionsProgram && !loading) {
      getPrograms();
    } else if (
      optionsProgram?.length &&
      !loading &&
      state?.search?.preferences?.program &&
      !valueProgram
    ) {
      const programLabel: string = state?.search?.preferences?.program?.label;
      const program = find(
        (optionProgram: any) => optionProgram?.label === programLabel,
        optionsProgram,
      );
      setValueProgram(program);
    }
  }, [optionsProgram]);

  const preferences = { ...state?.search?.preferences };

  return (
    <SelectPagination
      label="Carreras"
      error={preferences?.program?.error ? "Ingresa una carrera" : null}
      placeholder="Todas las carreras"
      loadOptions={loadProgramOptions}
      className={cx(css.preferencesContent_select, "col_4", "col_sm_12", className)}
      isDisabled={false}
      value={valueProgram?.label | valueProgram?.value ? valueProgram : null}
      debounceTimeout={1000}
      isClearable={true}
      onChange={(selectedProgram: any) => {
        if (selectedProgram) {
          setValueProgram(selectedProgram);
          preferences.program = selectedProgram;

          dispatch({ type: Types.SetPreferences, payload: preferences });
        } else {
          setValueProgram(null);
          const preferences = { ...state?.search?.preferences };
          preferences.program = selectedProgram?.program;

          dispatch({ type: Types.SetPreferences, payload: preferences });
        }
      }}
    />
  );
};

export default ProgramSelect;
