import { Campus } from "@models/ISchema";
import { lensPath, view } from "ramda";
import { useState } from "react";
import { useApolloClient } from "react-apollo";
import { campusSearch } from "../graphql/campusSearch.query";

type Response = [
  { campuses: Campus[]; isLoading: boolean },
  (scenarioId: string, originId: string) => void,
];

export const useGetCampuses = (): Response => {
  const client = useApolloClient();
  const [response, setResponse] = useState<Campus[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const viewCampuses = view(lensPath(["data", "groupsManagerCampuses", "items"]));

  const getCampuses = async (scenarioId: string, originId: string) => {
    try {
      setIsLoading(true);

      const variables = {
        query: campusSearch,
        variables: {
          scenarioId,
          originId,
          filter: {
            pagination: { page: 1, size: 999, searchTerm: "" },
          },
        },
      };
      const { data } = await client.query(variables);
      setResponse(viewCampuses(data ?? {}));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ campuses: response, isLoading }, getCampuses];
};
