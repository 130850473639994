import gql from "graphql-tag";

export const departmentSearch = gql`
  query groupsManagerData($scenarioId: ID!, $filter: AcademicUnitFilterInput!) {
    data(scenarioId: $scenarioId) {
      groupsManagerDepartments(filter: $filter) {
        items {
          id
          name
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
        __typename
      }
      __typename
    }
  }
`;
