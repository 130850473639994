import { useState } from "react";
import { append, reduce, toPairs } from "ramda";
import { useMutation } from "react-apollo";
import { GroupsManagerCrudResult } from "@models/ISchema";
import { groupsManagerCrud } from "../graphql/groupsManagerCrud.mutation";
import { GroupEdition } from "../models";

interface Props {
  origin: string;
  scenario: string;
}

type ReturnType = [
  { result: any; error: boolean; isLoading: boolean },
  (
    editionByGroupId: Record<string, GroupEdition>,
    dryRun?: boolean,
    skipValidations?: boolean,
  ) => Promise<void>,
];

const useGroupsManagerCrud = (props: Props): ReturnType => {
  const { scenario, origin } = props;
  const [error, setError] = useState(false);
  const [result, setResult] = useState<GroupsManagerCrudResult>({});
  const [isLoading, setIsLoading] = useState(false);
  const [groupsManagerCrudMutation] = useMutation(groupsManagerCrud, {});

  const buildChangeset = (editionByGroupId: Record<string, GroupEdition>) => {
    const editions = reduce(
      (acc, [groupId, edition]) => {
        const visibility = edition?.visibleForEnrollment;
        const capacity = edition?.capacity;
        return append({ groupId, visibility, capacity }, acc);
      },
      [],
      toPairs(editionByGroupId),
    );

    return { updates: editions };
  };

  const submitEditions = async (
    editionByGroupId: Record<string, GroupEdition>,
    dryRun = false,
    skipValidations = true,
  ) => {
    try {
      setIsLoading(true);

      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun,
          skipValidations,
          changesets: buildChangeset(editionByGroupId),
        },
      };
      const { data: responseData } = await groupsManagerCrudMutation({ variables });
      setResult(responseData?.cube?.groupsManagerCrud);
    } catch (e) {
      setError(true);
      console.error("[GroupsManagerCrud]: ", e);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ result, error, isLoading }, submitEditions];
};

export default useGroupsManagerCrud;
