import gql from "graphql-tag";

export const intervalsQuery = gql`
  query intervals($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      intervals {
        id
        name
        startingDate
        endingDate
      }
      __typename
    }
  }
`;
