import { ClassroomBooking } from "@models/ISchema";
import { Preferences, SavedRequestTypes } from "./types";
import { initialState } from "./BookingContext";

export const setSavedEditedBooking = (
  editedBooking: ClassroomBooking,
  state: SavedRequestTypes,
) => {
  return {
    ...state,
    editedBooking,
  };
};

export const setSavedRecurrence = (recurrence: boolean, state: SavedRequestTypes) => {
  return {
    ...state,
    recurrence,
  };
};

export const setSavedBookingCapacity = (capacity: number, state: SavedRequestTypes) => {
  return {
    ...state,
    bookingCapacity: capacity,
  };
};

export const setSavedBookingPreferences = (preferences: Preferences, state: SavedRequestTypes) => {
  return {
    ...state,
    bookingPreferences: preferences,
  };
};

export const clearSavedData = (state: SavedRequestTypes) => {
  return {
    ...state,
    ...initialState.savedRequest,
  };
};
