import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Range, getTrackBackground } from "react-range";
import css from "./range.module.scss";

interface IRangeCompProps {
  step: number;
  min: number;
  max: number;
  textRange?: string;
  className?: string;
  valuesRange: number[];
  onChange?: (e: any) => void;
  disabled?: boolean;
}

export const RangeComponent: React.FC<IRangeCompProps> = (props: IRangeCompProps) => {
  const { step, min, max, textRange, className, onChange, disabled, valuesRange } = props;
  const [values, setValues] = useState<number[]>(valuesRange);

  const compare = (arr1: any, arr2: any) => {
    if (!arr1 || !arr2) return false;
    let result = false;
    arr1.forEach((e1: any) =>
      arr2.forEach((e2: any) => {
        if (e1.length > 1 && e2.length) {
          result = compare(e1, e2);
        } else if (e1 !== e2) {
          result = false;
        } else {
          result = true;
        }
      }),
    );
    return result;
  };

  useEffect(() => {
    if (!compare(valuesRange, values)) {
      setValues(values);
    }
  });

  if (!values) return null;

  const colors = values.length;
  let arrayColors = [];
  if (colors === 1) {
    arrayColors = ["#507aaf", "#c5c8cd"];
  } else {
    arrayColors = ["#c5c8cd", "#507aaf", "#c5c8cd"];
  }
  if (disabled && colors === 1) {
    arrayColors = ["#e5e8ec", "#c5c8cd"];
  } else if (disabled && colors !== 1) {
    arrayColors = ["#c5c8cd", "#e5e8ec", "#c5c8cd"];
  }

  return (
    <section className={cx(css.cnt_range, className && className)}>
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={values => {
          setValues(values);
          onChange && onChange(values);
        }}
        disabled={disabled && disabled}
        renderTrack={({ props, children }) => (
          <div
            className={cx(css.range_content, disabled && css.range_content__disabled)}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
          >
            <span className={css.range_content_init}>{min}</span>
            <div
              className={css.range_content_line}
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values: values,
                  colors: arrayColors,
                  min: min,
                  max: max,
                }),
              }}
            >
              {children}
            </div>
            <span className={css.range_content_end}>{max}</span>
          </div>
        )}
        renderThumb={({ index, props }) => (
          <div className={css.range_content_pointer} {...props}>
            <p className={css.detail_range}>
              {values[index].toFixed(0)} {textRange}
            </p>
          </div>
        )}
      />
    </section>
  );
};

export default RangeComponent;
