import * as R from "ramda";
import { Session } from "@models/ISchema";
import { BlockRanges } from "../context/formData.reducer";

const blockAdapter = (session: Session): BlockRanges => {
  const day = R.view(R.lensPath(["start", "day"]));
  const startTime = R.view(R.lensPath(["start", "startingTime"]));
  const endingTime = R.view(R.lensPath(["end", "endingTime"]));

  const blockRange = session?.assignment?.blockRange;

  const isWithHours =
    Boolean(day(blockRange)) && Boolean(startTime(blockRange)) && Boolean(endingTime(blockRange));

  return {
    day: day(blockRange),
    startTime: startTime(blockRange),
    endTime: endingTime(blockRange),
    blocks: session?.blocksCount,
    selected: isWithHours ? "hours" : "blocks",
  };
};

export default blockAdapter;
