import {
  GroupsManagerSearchOrderByFields,
  OrderByDirection,
  GroupsManagerEnrollmentsOrderByFieldsEnum,
} from "@models/ISchema";
import { TableFiltersReducerType } from "../context/types";

const defaultTableFiltersForEnrollments = (): TableFiltersReducerType => {
  return {
    columnsToHide: new Set(),
    orderBy: {
      header: "Estudiante",
      field: GroupsManagerEnrollmentsOrderByFieldsEnum.Student,
      direction: OrderByDirection.Desc,
    },
    searchBy: {
      text: "",
      fields: [],
    },
  };
};

const defaultTableFiltersForCampuses = (): TableFiltersReducerType => {
  return {
    columnsToHide: new Set(),
    orderBy: {
      header: "Sede",
      field: GroupsManagerSearchOrderByFields.Campus,
      direction: OrderByDirection.Desc,
    },
    searchBy: {
      text: "",
      fields: [],
    },
  };
};

const buildDefaultTableFilters = (
  tableType: "campuses" | "enrollments",
): TableFiltersReducerType => {
  switch (tableType) {
    case "campuses":
      return defaultTableFiltersForCampuses();
    case "enrollments":
      return defaultTableFiltersForEnrollments();
    default:
      throw new Error("Invalid table type");
  }
};

export default buildDefaultTableFilters;
