import React, { useContext, useRef, useState } from "react";
import { Icon } from "@foris/avocado-ui";
import cx from "classnames";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import { Module } from "../../../models/ISchema";
import catalogos from "./../../../assets/img/menuForisIcons/catalogos.svg";
import proyecciones from "./../../../assets/img/menuForisIcons/proyecciones.svg";
import asignaciones from "./../../../assets/img/menuForisIcons/asignaciones.svg";
import horarios from "./../../../assets/img/menuForisIcons/analytics.svg";
import edd from "./../../../assets/img/menuForisIcons/edd.svg";
import edh from "./../../../assets/img/menuForisIcons/edh.svg";
import espacios from "./../../../assets/img/menuForisIcons/espacios.svg";
import css from "./main-menu.module.scss";
import useClickOutside from "@common/hooks/useClickOutside";

interface IModules extends Module {
  title: string;
  icon: any;
  order: number;
}

const MainMenu = () => {
  const context: IContextApp = useContext(ContextApp);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useClickOutside({
    ref: menuRef,
    onClickOutside() {
      setShowMenu(false);
    },
  });

  const icons = {
    catalogs: {
      icon: catalogos,
      title: "Catálogos",
      order: 1,
    },
    forecast: {
      icon: proyecciones,
      title: "Proyecciones",
      order: 2,
    },
    assignation: {
      icon: asignaciones,
      title: "Asignaciones",
      order: 3,
    },
    schedule: {
      icon: horarios,
      title: "Horarios",
      order: 4,
    },
    scheduler_editor: {
      icon: edh,
      title: "Editor de horarios",
      order: 2,
    },
    demand_editor: {
      icon: edd,
      title: "Editor de demanda",
      order: 1,
    },
    bookings: {
      icon: espacios,
      title: "Reserva de espacios",
      order: 3,
    },
  };

  const firstModules: IModules[] = [];
  const secondModules: IModules[] = [];

  if (context.modules) {
    context.modules.forEach((value, index) => {
      const findModule = icons[value.code];
      if (findModule && index <= 3) {
        firstModules.push({
          ...value,
          title: findModule.title,
          icon: findModule.icon,
          order: findModule.order,
        });
      }
      if (findModule && index > 3) {
        secondModules.push({
          ...value,
          title: findModule.title,
          icon: findModule.icon,
          order: findModule.order,
        });
      }
    });
  }

  const orderFirst =
    firstModules.length > 0 ? firstModules.sort((a, b) => a.order - b.order) : null;
  const orderSecond =
    secondModules.length > 0 ? secondModules.sort((a, b) => a.order - b.order) : null;

  const token = localStorage.getItem("token");

  if (orderFirst || secondModules)
    return (
      <>
        <div className={css.mainMenu} ref={menuRef}>
          <div className={css.mainMenu_trigger} onClick={() => setShowMenu(!showMenu)}>
            <Icon icon="cube" size={20} className={css.mainMenu_icon} />
          </div>

          <div className={cx(css.mainMenu_products, showMenu ? css.mainMenu_products__active : "")}>
            {orderFirst && (
              <section className={cx(css.productsRow, "container-row")}>
                <p className={css.productsRow_title}>Módulos de optimización</p>

                <div className={css.productsRow_list}>
                  {orderFirst.map((value, index) => {
                    if (value.accesible)
                      return (
                        <figure
                          key={index}
                          onClick={() => (window.location.href = value.url)}
                          className={css.productsRow_item}
                        >
                          <img className={css.logo} src={value.icon} />
                          <p className={css.nameProduct}>{value.title}</p>
                        </figure>
                      );
                    return null;
                  })}
                </div>
              </section>
            )}

            {orderSecond && (
              <section className={cx(css.productsRow, "container-row")}>
                <p className={css.productsRow_title}>Módulos de gestión</p>
                {orderSecond.map((value, index) => {
                  if (value.accesible) {
                    let urlProduct = value.url;
                    if (value.code == "demand_editor" && token) {
                      urlProduct = `${value.url}login?token=${token}`;
                    }
                    if (value.code == "scheduler_editor" && token) {
                      urlProduct = `${value.url}auth/${token}`;
                    }
                    return (
                      <figure
                        key={index}
                        onClick={() => (window.location.href = urlProduct)}
                        className={css.productsRow_item}
                      >
                        <img className={css.logo} src={value.icon} />
                        <p className={css.nameProduct}>{value.title}</p>
                      </figure>
                    );
                  }
                  return null;
                })}
              </section>
            )}
          </div>
        </div>
      </>
    );

  return null;
};

export default MainMenu;
