import React, { useContext } from "react";
import { CardState } from "@foris/avocado-ui";
import * as R from "ramda";
import cx from "classnames";
import { AppContext } from "../../context/EditSessionsContext";
import { FormPageType, EditedSession } from "../../context/formData.reducer";
import css from "./formHeader.module.scss";
import { Session } from "@models/ISchema";

const FormHeader: React.FC = () => {
  const { state } = useContext(AppContext);

  /**
   * Return a title for the form considering the sessions that has been selected
   */
  const sessionTitle = (): string => {
    const selectedSessionToCreate = ({
      selectedSessions,
      sessionsToCreate,
    }: FormPageType): EditedSession[] => {
      const selectedSessionsById = R.reduce(
        (acc, session) => R.assoc(session?.id, true, acc),
        {},
        selectedSessions,
      );
      return R.filter(
        R.pipe(R.propOr("-", "id"), R.flip(R.has)(selectedSessionsById)),
        sessionsToCreate,
      );
    };

    const { selectedSection, savedSessions, selectedSessions } = state?.form;
    const numberOfSelectedSessions = selectedSessions?.length ?? 0;
    const sessionsToCreateSelected = selectedSessionToCreate(state?.form);

    if (numberOfSelectedSessions > 1) {
      return `${numberOfSelectedSessions} sesiones a editar`;
    } else if (sessionsToCreateSelected.length === 1) {
      const section = selectedSessions[0].section;
      return `Nueva sesión para ${section?.component?.code ?? ""} ${section?.id}`;
    } else if (numberOfSelectedSessions === 1) {
      const session = selectedSessions[0];
      const findNewSession = savedSessions.find(value => value?.id === session?.id && value.isNew);
      return findNewSession ? "Edición de la nueva sesión" : `Edición ${session?.id}`;
    }

    return selectedSection
      ? `Nueva sesión para ${selectedSection?.component?.code} ${selectedSection?.id}`
      : "";
  };

  /**
   * Return the number of blocks between selected, created and to-create sessions
   */
  const numberOfSelectedBlocks = () => {
    const selectedSessions = state?.form?.selectedSessions ?? [];
    const savedSessions = state?.form?.savedSessions ?? [];
    const sessionsToCreate = state?.form?.sessionsToCreate ?? [];

    const isClonedSession = (session: Session | EditedSession) => session?.id?.includes("-");

    const numberOfBlocksFromSessions = (sessions: Session[]): number => {
      if (!sessions?.length) return 0;
      return sessions
        .filter(session => session?.blocksCount || session?.assignment?.intervals)
        .reduce(
          (acc, session) =>
            acc + (session?.blocksCount || 0) * (session?.assignment?.intervals?.length || 1),
          0,
        );
    };

    const numberOfBlocksFromEditedSessions = (sessions: EditedSession[]): number => {
      const lteTime = (time1: string, time2: string) => {
        const [[hour1, min1], [hour2, min2]] = R.ap(
          [R.pipe(R.split(":"), R.map(parseInt))],
          [time1, time2],
        );
        return hour1 < hour2 || (hour1 === hour2 && min1 <= min2);
      };

      if (sessions.length) {
        const rawBlocks = state?.link?.rawBlocks;
        return sessions
          .filter(({ blocks }) => !!blocks?.startTime && !!blocks?.endTime)
          .reduce((acc, { blocks: { startTime, endTime }, intervals }) => {
            const numberOfBlocks =
              rawBlocks?.filter(
                ({ startingTime, endingTime }) =>
                  lteTime(startTime, startingTime) && lteTime(endingTime, endTime),
              ).length || 0;
            const numberOfIntervals =
              R.filter(R.propOr(false, "checked"), intervals ?? []).length || 1;
            return acc + numberOfBlocks * numberOfIntervals;
          }, 0);
      }

      return 0;
    };

    return (
      numberOfBlocksFromSessions(
        R.filter(R.pipe(isClonedSession, R.not), selectedSessions) as Session[],
      ) +
      numberOfBlocksFromEditedSessions(sessionsToCreate) +
      numberOfBlocksFromEditedSessions(R.filter(R.propOr("", "isNew"), savedSessions))
    );
  };

  /**
   * Return the number of blocks of the edited sessions
   */
  const modifiedBlocks = () => {
    const editedSessions = state?.form?.savedSessions.filter(session => !session.isNew);
    let blocks = 0;
    if (editedSessions) {
      editedSessions.forEach(({ session }) => {
        if (session.blocksCount || session.assignment?.intervals) {
          const numberOfBlocks = session.blocksCount || 1;
          const numberOfIntervals = session.assignment?.intervals?.length || 1;
          blocks += numberOfBlocks * numberOfIntervals;
        }
      });
    }
    return blocks;
  };

  const someSelectedSessionHasBeenDeleted = (
    sessions: EditedSession[],
    deletedSessionsIds: { [key: string]: boolean },
  ) => R.any(R.pipe(R.propOr("-", "id"), R.flip(R.has)(deletedSessionsIds)), sessions ?? []);

  if (!state?.form?.selectedSessions?.length) return <></>;

  return (
    <>
      <header className={cx(css.formHeader, "container-row", "row--between", "col_12")}>
        <h1 className={cx(css.formHeader_title)}>{sessionTitle()}</h1>
        <section className={cx(css.formHeader_blocksInfo)}>
          <p>
            <strong>Bloques seleccionados:</strong> {numberOfSelectedBlocks()}
          </p>
          <p className={cx(css.formHeader_separator)}> | </p>
          <p>modificados: {modifiedBlocks()}</p>
        </section>
      </header>
      {someSelectedSessionHasBeenDeleted(
        state?.form?.selectedSessions,
        state?.form?.sessionsToDelete,
      ) && (
        <CardState
          typeCard="informative"
          title="Sesión por eliminar"
          className={cx(css.formHeader_card)}
          classNameTitle={cx(css.formHeader_card_title)}
        >
          Sesion en el basurero no se pueden editar. La eliminación se realiza después de realizar
          todos los cambios. Si lo quieres sacar del basurero puedes deshacer el cambio.
        </CardState>
      )}
    </>
  );
};

export default FormHeader;
