import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { BookingContext } from "../context/BookingContext";
import { Types } from "../context/request.reducer";
import { BaseQuery, ClassroomBookingType } from "@models/ISchema";
import { eventTypesQuery } from "../graphql/eventTypes.query";

const useGetEventTypes = (): [{ data: boolean; error: boolean; loading: boolean }, () => void] => {
  const client = useApolloClient();
  const { dispatch } = useContext(BookingContext);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setBookingTypesOptions = async (data: ClassroomBookingType[]) => {
    if (data) {
      const optionsTypes = [];
      if (data.length > 0) {
        data.forEach(types => {
          optionsTypes.push({ label: types.name, value: types.id, ...types });
        });
      }
      dispatch({ type: Types.SetEventTypesOptions, payload: optionsTypes });
      setResult(true);
      setError(false);
      setLoading(false);
    }
  };

  const getBookingTypesOptions = async () => {
    try {
      setLoading(true);
      const { data } = await client.query({ query: eventTypesQuery });
      const dataQuery: BaseQuery = data?.base;
      await setBookingTypesOptions(dataQuery?.classroomBookingTypes?.items);
    } catch (error) {
      console.error("[getBookingTypesOptions]", error);
      dispatch({ type: Types.SetEventTypesOptions, payload: [] });
      setResult(true);
      setError(true);
      setLoading(false);
    }
  };

  return [{ data: result, error, loading }, getBookingTypesOptions];
};

export default useGetEventTypes;
