import { pipe, values, isEmpty, head } from "ramda";
import splitGroupErrors from "./splitGroupErrors";

/**
 * Return false if any group edition has a "hard" error.
 * If there are no errors or only "soft" errors, return true.
 *
 * @param edition EditionsReducerType
 *
 * @return boolean
 */
const savingAllowed = pipe(splitGroupErrors, head, values as any, isEmpty);

export default savingAllowed;
