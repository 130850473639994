import { IEvent } from "../ISections";
import { enums } from "../utils";
import { formats } from "../utils";

class SchedulerAPI {
  moveEvent = function(eventList: Array<IEvent>, currentEvent: IEvent) {
    if (!eventList.length) return [];

    const newEventList = eventList;
    const sessionId = currentEvent.sessionId;
    const startDate = currentEvent.start;
    const endDate = currentEvent.end;

    newEventList.map((event: IEvent, index: number) => {
      if (event.sessionId === sessionId) {
        const date = newEventList[index]["start"];
        const daytoset = startDate.getDay();
        const currentDay = date.getDay();
        const distance = daytoset - currentDay;
        const tmpDate = new Date(date.setDate(date.getDate() + distance));

        newEventList[index]["start"] = new Date(
          tmpDate.setHours(startDate.getHours(), startDate.getMinutes(), startDate.getSeconds()),
        );

        // updating block range
        const dateEventStart = newEventList[index]["start"];
        const formatEventStart = formats.formatMinutesDay(dateEventStart);
        newEventList[
          index
        ].resource.blockRange.start.startingTime = `${dateEventStart.getHours()}:${formatEventStart}:00`;

        newEventList[index].resource.blockRange.start.day =
          enums.DAY_NAMES[dateEventStart.getDay() - 1];

        newEventList[index]["end"] = new Date(
          tmpDate.setHours(endDate.getHours(), endDate.getMinutes(), endDate.getSeconds()),
        );
        // updating block range
        const dateEventEnd = newEventList[index]["end"];
        const formatEventEnd = formats.formatMinutesDay(dateEventEnd);
        newEventList[
          index
        ].resource.blockRange.end.endingTime = `${dateEventEnd.getHours()}:${formatEventEnd}:00`;
      }
      return null;
    });

    return newEventList;
  };

  resizeEvent = function(eventList: Array<IEvent>, currentEvent: IEvent) {
    const nextEvents = eventList.map((existingEvent: IEvent) => {
      return existingEvent.sessionId === currentEvent.sessionId
        ? { ...existingEvent, start: currentEvent.start, end: currentEvent.end }
        : existingEvent;
    });

    return nextEvents;
  };

  // actions
  addRestriction = (): void => null;
  addSection = (): void => null;

  /**
   * Receive list of events and filter by criteria */
  filterEventList = (
    originalEventArray: Array<IEvent>,
    currentEventArray: Array<IEvent>,
    categoryID: number,
    display: boolean,
    filtercriteria: string,
  ) => {
    if (!originalEventArray.length) return [];

    let events: Array<any & any> = [];
    if (display === false) {
      events = currentEventArray.filter((item: any) => item[filtercriteria] !== categoryID);
    } else {
      events = [
        ...currentEventArray,
        ...originalEventArray.filter((item: any) => item[filtercriteria] === categoryID),
      ];
    }
    return events;
  };

  /**
   * Filter restricted areas */
  filterRestrictedAreas = ({ areatype, checked }: { areatype: string; checked: boolean }) => {
    // DANGER: this method (first approach) modifies the DOM directly. Avoid if possible.
    const className = `.restrictedArea.${areatype}`;
    const domNodesArray: any = window.document.querySelectorAll(className);

    if (checked) {
      for (let i = domNodesArray.length; i--; ) {
        domNodesArray[i].className = domNodesArray[i].className + " active";
      }
    } else {
      for (let i = domNodesArray.length; i--; ) {
        domNodesArray[i].classList.remove("active");
      }
    }
  };
}

export default SchedulerAPI;
