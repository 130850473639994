import React from "react";
import { ContextProvider } from "../context/PackagesContext";
import Layout from "../../../../common/layout/Layout";
import PackagesApp from "./PackagesApp";

const Packages: React.FC = () => {
  return (
    <Layout contextSearch>
      <ContextProvider>
        <PackagesApp />
      </ContextProvider>
    </Layout>
  );
};

export default Packages;
