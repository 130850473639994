import gql from "graphql-tag";

export const classroomQuery = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classrooms(filter: $filter) {
        items {
          id
          code
          capacity
          allowedOverflow
          name
          classroomType {
            id
            code
            name
          }
          building {
            id
            code
            name
            campus {
              id
              code
              name
              __typename
            }
            __typename
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const classroomSearchMutation = gql`
  mutation filter($scenarioId: ID!, $originId: ID!, $filter: ClassroomFilterInput!) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createClassroomFilter(filter: $filter) {
        id
      }
    }
  }
`;

export const classroomSearchQuery = gql`
  query filter($scenarioId: ID!, $filterId: ID, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      classrooms(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          name
          capacity
          allowedOverflow
          floor
          building {
            id
            name
            __typename
            campus {
              id
              code
              name
              __typename
            }
          }
          classroomType {
            id
            code
            name
            __typename
          }
          assignment {
            usagePercentage
            chairsOccupationPercentage
          }
          __typename
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
