import { useState } from "react";
import { useMutation } from "react-apollo";
import { EditPackageLabelsPayload } from "@models/ISchema";
import { ILabel } from "@dimensions/group/EditLabels/ContextEditLabels";
import { editPackageLabelMutation } from "../graphql/packageLabels.query";

type Response = [
  { isSavingLabels: boolean; response: EditPackageLabelsPayload },
  (labels: ILabel[]) => void,
];

const useEditPackageLabels = (id: string, scenario: string, origin: string): Response => {
  const [isSavingLabels, setIsSavingLabels] = useState(false);
  const [response, setResponse] = useState<EditPackageLabelsPayload>(null);
  const [labelsMutation] = useMutation(editPackageLabelMutation, {});

  const updatePackageLabels = async (labels: ILabel[] = []) => {
    try {
      setIsSavingLabels(true);

      const input = {
        dryRun: false,
        packageId: id,
        labelIds: labels.map(label => label.id),
      };
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input,
      };
      const { data } = await labelsMutation({ variables });
      const newResponse: EditPackageLabelsPayload = data?.cube?.editPackageLabels;
      setResponse(newResponse);
    } catch (e) {
      setResponse(null);
      console.error("[labels]", e);
    } finally {
      setIsSavingLabels(false);
    }
  };

  return [{ response, isSavingLabels }, updatePackageLabels];
};

export default useEditPackageLabels;
