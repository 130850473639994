import React, { memo } from "react";
import * as R from "ramda";
import { Link as LinkRouter, useParams } from "react-router-dom";
import { Icon } from "@foris/avocado-ui";
import { Link } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { Header } from "@components/index";
import css from "@components/Header/header.module.scss";

interface ILinkHeader {
  setHeight?: any;
  link: Link;
  isCreation: boolean;
}
const LinkHeader: React.FC<ILinkHeader> = (props: ILinkHeader) => {
  const { setHeight, link, isCreation } = props;
  const { origin, scenario, workspace }: IParams = useParams();

  if (!link) return null;

  const program = link?.course?.curriculum?.program;
  const courseCode = link?.course?.code || "";
  const courseName = link?.course?.name || "";

  let typeGroup = "";
  link?.groups?.forEach(group => {
    const groupsSection = typeGroup && typeGroup.split(",").find(find => find === group.type);
    if (typeGroup === "") {
      typeGroup = group?.type;
    } else if (!groupsSection) {
      typeGroup = `${typeGroup}, ${group?.type}`;
    }
  });

  const subTitle = (empty: boolean) => {
    if (empty) return undefined;

    const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${link?.bundle?.id}`;

    return (
      Boolean(link?.bundle?.id) && (
        <h2 className={css.infoItem_subTitle}>
          <LinkRouter to={routeBundle} className={css.link}>
            <span className={css.link_text}>{`Bundle: ${link?.bundle?.code}`}</span>
            <Icon icon="external-link" size={14} className={css.link_icon} />
          </LinkRouter>
        </h2>
      )
    );
  };

  const topTitle = R.ifElse(
    R.equals(true),
    R.always(courseCode && courseName ? `Liga ${courseCode}, ${courseName}` : ""),
    R.always(link?.code || ""),
  );

  const title = R.ifElse(
    R.equals(true),
    R.always(`Crear y enlazar nuevo paquete para Liga ${courseCode}`),
    R.always(courseCode && courseName ? `${courseCode}, ${courseName}` : ""),
  );

  const detailsCofiguration = [
    { title: "Sede", content: program?.campus?.code || "" },
    { title: "Escuela", content: program?.department?.code || "" },
    { title: "Modalidad", content: program?.modality?.code || "" },
    { title: "Jornada", content: program?.shift?.code || "" },
    { title: "Carrera", content: program?.code || "" },
    { title: "Currículo", content: link?.course?.curriculum?.code || "" },
    { title: "Nivel", content: link?.course?.level?.toString() || "" },
    { title: "Vacantes", content: link?.vacancies?.toString() || "" },
    { title: "Bloques totales liga", content: link?.totalBlocks?.toString() || "" },
  ];
  if (typeGroup) detailsCofiguration.push({ title: "Tipos de grupo", content: typeGroup });

  const details = R.ifElse(R.equals(true), R.always(undefined), R.always(detailsCofiguration));

  return (
    <Header
      title={title(isCreation)}
      topTitle={topTitle(isCreation)}
      subTitle={subTitle(isCreation)}
      moreDetails={details(isCreation)}
      setHeight={setHeight}
    />
  );
};

export default memo(LinkHeader);
