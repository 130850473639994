import * as React from "react";
import { Icon, IconType } from "@foris/avocado-ui";
import cx from "classnames";
import css from "./sessionBox.module.scss";

export interface ISessionBox {
  /** content sessionBox */
  children: React.ReactNode | string;
  /** overwrite className */
  className?: string;
  /** overwrite className in button*/
  classNameButton?: string;
  /** inverse state */
  inverse?: boolean;
  /** disabled state */
  disabled?: boolean;
  /** deleted state */
  deleted?: boolean;
  /** remove or delete action icon */
  icon?: IconType;
  /** option to action in sessionBox*/
  onClick?: () => void;
  /** option to remove  sessionBox*/
  onRemove?: () => void;
  /** color from palette */
  colorIndex?: number;
}

const DELETED_COLOR_INDEX = 27;

const SessionBox: React.FC<ISessionBox> = (props: ISessionBox) => {
  const {
    children,
    deleted,
    className,
    classNameButton,
    inverse,
    disabled,
    icon,
    onClick,
    onRemove,
    colorIndex,
  } = props;
  return (
    <section
      className={cx(
        css.boxSession,
        disabled && css.boxSession__disabled,
        deleted && css.boxSession__deleted,
        inverse && css.boxSession__inverse,
        colorIndex && css[`boxSession__palette${deleted ? DELETED_COLOR_INDEX : colorIndex}`],
        className && className,
      )}
      onClick={() => onClick && onClick()}
    >
      <div className={cx(css.boxSession_content, icon && css.boxSession_content__full)}>
        {children}
      </div>

      {icon ? (
        <button
          className={cx(css.boxSession_btnRemove, classNameButton && classNameButton)}
          onClick={() => onRemove && onRemove()}
        >
          <Icon icon={icon} size={14} className={cx(css.icon)} />
          {/* También debería ser una props para modificar el texto en caso de
          eliminar o remover de edición y concatenar el nombre d ela sesión */}
          <p className={cx(css.textRemove)}>Eliminar </p>
        </button>
      ) : null}
    </section>
  );
};

export default SessionBox;
