import * as React from "react";
import cx from "classnames";
import { Checkbox, Icon } from "@foris/avocado-ui";
import { Section, Session } from "@models/ISchema";
import { AppContext } from "../../context/EditSessionsContext";
import { EditedSessions } from "../../context/formData.reducer";
import css from "./accordion.module.scss";

interface IAccordionHeaderProps {
  section?: Section;
  updated?: boolean;
  onCheck?: (
    sessions: Session[],
    section: Section,
    checked: boolean,
    newSessions: EditedSessions[],
  ) => boolean;
  chevron?: "chevron-up" | "chevron-down";
}

const AccordionHeader: React.FC<IAccordionHeaderProps> = (props: IAccordionHeaderProps) => {
  const { updated, section, onCheck, chevron } = props;
  const [checked, setChecked] = React.useState(false);
  const { state } = React.useContext(AppContext);

  const onChangeSection = () => {
    const sessions = [...section.sessions, ...section.unasignedSessions];
    const savedNewSessions = state?.form?.savedSessions?.filter(
      value => value?.section?.id === section.id && value?.isNew,
    );
    const setCheck = onCheck(sessions, section, checked, savedNewSessions);
    setChecked(setCheck);
  };

  React.useEffect(() => {
    if (onCheck) {
      const sessionLength = state.form?.selectedSessions?.filter(
        selected => selected?.section?.id === section?.id,
      ).length;
      const filterNewSessions = state?.form?.savedSessions?.filter(value => value?.isNew);
      const sumSessions =
        section.sessions?.length + section.unasignedSessions?.length + filterNewSessions?.length;

      if (sumSessions === sessionLength) setChecked(true);
      if (sessionLength === 0) setChecked(false);
      if (sessionLength < sumSessions && checked) setChecked(false);
    }
  }, [state?.form?.selectedSessions]);

  const component = section?.component ?? section?.courseComponent?.component;

  return (
    <>
      {onCheck && (
        <>
          <Checkbox
            labelRight={
              <p className={css.accordionHeader_labelCheckbox}>
                <span className={css.regular}>{`${section?.component?.code} `}</span> {section?.id}
              </p>
            }
            className={cx(css.accordionHeader_checkbox)}
            checked={checked}
            onClick={e => e.stopPropagation()}
            onChange={() => onChangeSection()}
          />
          <span className={cx(css.accordionHeader_icon, updated && css.accordionHeader_icon__edit)}>
            <Icon icon={!!chevron ? chevron : "chevron-down"} size={16} />
          </span>
        </>
      )}
      {!onCheck && (
        <>
          <p className={css.accordionHeader_title}>
            {`${component?.code || ""}`}
            <span className={css.bold}>{` ${section?.id}`}</span>
          </p>
          <span className={cx(css.accordionHeader_icon, updated && css.accordionHeader_icon__edit)}>
            <Icon icon="chevron-down" size={16} />
          </span>
        </>
      )}
    </>
  );
};

export default AccordionHeader;
