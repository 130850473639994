import React, { useEffect, useMemo } from "react";
import { Header } from "@common/components";
import Layout from "@common/layout/Layout";
import useGetDimension from "@dimensions/hooks/useGetDimension";
import { Button, Loading } from "@foris/avocado-ui";
import { Dimension } from "@models/IDimension";
import { IParams } from "@models/IParams";
import { useHistory, useParams } from "react-router-dom";
import useGetPackageSection from "../hooks/useGetPackageSection";
import SelectLabels from "@dimensions/group/EditLabels/SelectLabels";
import ListLabels from "@dimensions/group/EditLabels/ListLabels";
import {
  ContextEditLabel,
  useContextEditLabel,
} from "@dimensions/group/EditLabels/ContextEditLabels";
import useEditPackageLabels from "../hooks/useEditPackageLabels";
import cx from "classnames";
import css from "../../group/EditLabels/editLabels.module.scss";

const PackageEditLabels = () => {
  const { id, origin, scenario, workspace }: IParams = useParams();
  const history = useHistory();
  const contextEdit = useContextEditLabel();
  const [dimension] = useGetDimension(Dimension.package);
  const [{ isSavingLabels, response }, updatePackageLabels] = useEditPackageLabels(
    id,
    scenario,
    origin,
  );
  const { isLoading, headerData, loadPackageData } = useGetPackageSection({
    resourceId: id,
    scenarioId: scenario,
    originId: origin,
    workspaceId: workspace,
  });

  const packageRoute = `/scheduler/editor/package/${workspace}/${scenario}/${origin}/${id}`;

  const hasChanges = useMemo(() => {
    const hasNewLabels = (contextEdit?.labels ?? []).some(label => !!label.isNew);
    const labels = [...(headerData?.systemLabels || []), ...(headerData?.labels || [])];
    const hasDeletedLabels = labels?.length !== contextEdit?.labels?.length;

    return hasNewLabels || hasDeletedLabels;
  }, [contextEdit?.labels]);

  useEffect(() => {
    loadPackageData();

    return () => {
      contextEdit.setLabels([]);
      contextEdit.setOptions([]);
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const labels = [...(headerData?.systemLabels || []), ...(headerData?.labels || [])];
      const headerLabels = (labels ?? []).map(value => {
        return { ...value, isNew: false };
      });

      contextEdit.setLabels(headerLabels);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isSavingLabels && response?.commited) {
      history.push(packageRoute);
    }
  }, [isSavingLabels]);

  return (
    <ContextEditLabel.Provider value={contextEdit}>
      <Layout contextSearch>
        {(isLoading || isSavingLabels) && <Loading />}

        <Header dimension={dimension.label} {...(headerData ?? {})} />

        {!isLoading && (
          <section className={cx(css.editLabels, "container-row")}>
            <h2 className={cx(css.editLabels_title, "col_12")}>Editar etiquetas</h2>

            <SelectLabels />
            <ListLabels />

            <footer className={cx(css.footer, "container-row")}>
              <Button
                variant="ghost"
                className={css.footer_btn}
                disabled={isSavingLabels}
                onClick={() => history.push(packageRoute)}
              >
                Volver
              </Button>
              <Button
                className={css.footer_btn}
                disabled={isSavingLabels || !hasChanges}
                onClick={() => updatePackageLabels(contextEdit.labels)}
              >
                Confirmar
              </Button>
            </footer>
          </section>
        )}
      </Layout>
    </ContextEditLabel.Provider>
  );
};

export default PackageEditLabels;
