import gql from "graphql-tag";

export const instructorsAdvancedSearch = gql`
  query instructorsAdvancedSearch(
    $originId: ID!
    $scenarioId: ID!
    $filter: InstructorFilterInput
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      instructorsAdvancedSearch(filter: $filter) {
        items {
          id
          code
          name
          priority
          assignment {
            averagePerIntervalBlocks
            blocks
            usagePercentage
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
