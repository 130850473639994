import React, { useState, useContext, useCallback, useEffect } from "react";
import { map, prepend } from "ramda";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { IParams } from "@models/IParams";
import { Context } from "../../context/GroupsManagerContext";
import { Types } from "../../context/filters.reducer";
import { groupsSearch } from "../../graphql/groups.query";
import { groupAdapter } from "../../adapters/group";
import { AdaptedGroup, SelectableOption } from "../../models";

const REQUEST_SIZE = 20;

const CrnSelector: React.FC = () => {
  const { state, dispatch } = useContext(Context);
  const client = useApolloClient();
  const noSelectedOption = { id: "*", value: "*", label: "Buscar", self: null };
  const [selectedCrn, setSelectedCrn] = useState<SelectableOption<AdaptedGroup>>(noSelectedOption);
  const [page, setPage] = useState(0);
  const [, setSearchTerm] = useState("");
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const { origin, scenario }: IParams = useParams();
  const params: queryString.ParsedQuery<string> = queryString.parse(location.search);

  useEffect(() => {
    dispatch({ type: Types.SelectCRN, payload: selectedCrn?.self?.code });
  }, [selectedCrn]);

  /**
   * If the user clicks the `Borrar selección` button, reset the selected CRN.
   */
  useEffect(() => {
    if (!state?.filters?.selectedCrn && selectedCrn?.self?.code) {
      setSelectedCrn(noSelectedOption);
    }
  }, [state?.filters?.selectedCrn]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      try {
        const variables = {
          query: groupsSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              pagination: { page, size: REQUEST_SIZE },
              fields: {
                clientCode: { contains: searchTerm },
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const cube = data?.cube;
        const pageInfo = cube?.groupsManagerSearch?.pageInfo;
        const adaptedGroups = map(groupAdapter, cube?.groupsManagerSearch?.items ?? []);

        const options = map(
          adaptedGroup => ({
            id: adaptedGroup?.id,
            value: adaptedGroup?.id,
            label: adaptedGroup?.code ?? "",
            self: adaptedGroup,
          }),
          adaptedGroups ?? [],
        );

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: newSearchPage === 1 ? prepend(noSelectedOption, options) : options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  return (
    <SelectPagination
      label="CRN"
      isDisabled={false}
      value={selectedCrn}
      onInputChange={setSearchTerm}
      onChange={setSelectedCrn}
      loadOptions={loadOptions}
    />
  );
};

export default CrnSelector;
