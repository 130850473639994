import queryString from "query-string";
import { DimensionNameEs } from "../../../models/IDimension";

export const variablesSearch = (
  scenario: string,
  origin: string,
  params: queryString.ParsedQuery<string>,
  page: number,
) => {
  const variables = {
    scenarioId: scenario,
    originId: origin,
    filterId: params?.advance,
    filter: {
      page: page,
      size: 20,
      searchTerm: params?.search,
    },
    pagination: {
      page: page,
      size: 20,
    },
  };
  if (params?.search) {
    delete variables.filterId;
    delete variables.pagination;
  }
  if (params?.advance) {
    delete variables.filter;
    delete variables.originId;
  }
  return variables;
};

export const generateTitle = (total: number, search: string, dimension: DimensionNameEs) =>
  search
    ? `${total} Resultados en ${dimension} para "${search}"`
    : `${total} Resultados en ${dimension}`;
