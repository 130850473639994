import gql from "graphql-tag";

export const contractTypeQuery = gql`
  query campus($scenarioId: ID!, $filter: InstructorContractTypeFilterInput) {
    data(scenarioId: $scenarioId) {
      instructorContractTypes(filter: $filter) {
        items {
          id
          code
          name
        }
      }
    }
  }
`;
