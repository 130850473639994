import React, { useState, useEffect, useCallback, useContext } from "react";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/request.reducer";
import { BaseQuery, PageInfo } from "@models/ISchema";
import { usersQuery } from "../../graphql/users.query";
import css from "../Preferences/preferences.module.scss";
import { find } from "ramda";

interface Props {
  className?: string;
}

const UserSelector: React.FC<Props> = ({ className = "" }) => {
  const { scenario }: IParams = useParams();
  const { state, dispatch } = useContext(BookingContext);
  const client = useApolloClient();
  const defaultUser =
    typeof state?.request?.userResponsible === "object"
      ? state?.request?.userResponsible
      : { label: state?.request?.userResponsible };
  const [userPage, setUserPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [optionsUser, setOptionsUser] = useState(null);
  const [valueUser, setValueUser] = useState(defaultUser);
  const [termToSearch, setTermToSearch] = useState("");

  const getUsers = useCallback(
    async (searchTerm = "", page = 1) => {
      const optionsPerPage = 10;
      setLoading(true);

      try {
        const variables = {
          query: usersQuery,
          variables: {
            scenarioId: scenario,
            filter: {
              pagination: {
                page,
                size: optionsPerPage,
                searchTerm,
              },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery: BaseQuery = data?.base;
        const pageInfo: PageInfo = dataQuery?.users?.pageInfo;
        const users = dataQuery?.users;

        const options = [];
        if (users) {
          users.items.forEach(user => {
            const lastName = user.lastName == null ? "" : user.lastName;

            options.push({
              label: `${user.name} ${lastName}`,
              value: user.id,
            });
          });
        }

        setOptionsUser(options);

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        setLoading(false);
        return null;
      }
    },
    [client],
  );

  const loadUserOptions = async (searchTerm: string) => {
    let searchPage = 1;

    if (termToSearch === searchTerm) {
      searchPage = userPage;
    } else {
      setTermToSearch(searchTerm);
    }

    const { pageInfo, options: userOptions } = await getUsers(searchTerm, searchPage);
    setUserPage(userPage + 1);

    return {
      options: userOptions,
      hasMore: pageInfo?.hasNextPage,
      additional: {
        page: userPage,
      },
    };
  };

  useEffect(() => {
    if (!optionsUser && !loading) {
      getUsers();
    } else if (optionsUser?.length && !loading && state?.request?.userResponsible && !valueUser) {
      const userLabel: string = state?.request?.userResponsible?.value;
      const user = find((optionUser: any) => optionUser?.label === userLabel, optionsUser);
      setValueUser(user);
    }
  }, [optionsUser]);

  const selectedUser = state?.request?.userResponsible;
  const request = { ...state?.request };

  return (
    <SelectPagination
      label="Responsables"
      error={selectedUser?.error ? "Ingresa un nombre" : null}
      placeholder="Todas los responsable"
      loadOptions={loadUserOptions}
      className={cx(css.preferencesContent_select, "col_4", "col_sm_12", className)}
      isDisabled={false}
      value={valueUser}
      debounceTimeout={1000}
      onChange={(selectedUser: any) => {
        if (selectedUser) {
          setValueUser(selectedUser);
          request.userResponsible = selectedUser;

          dispatch({
            type: Types.SetUserResponsible,
            payload: {
              value: selectedUser?.value,
              label: selectedUser?.label,
              id: selectedUser?.value,
              error: false,
            },
          });
        } else {
          setValueUser(null);
          const requests = { ...state?.request };
          requests.userResponsible = selectedUser.user;

          dispatch({
            type: Types.SetUserResponsible,
            payload: {
              value: selectedUser?.value,
              label: selectedUser?.label,
              id: selectedUser?.value,
              error: false,
            },
          });
        }
      }}
    />
  );
};

export default UserSelector;
