import { useState, useContext } from "react";
import { useApolloClient } from "react-apollo";
import { buildingQuery } from "../graphql/building.query";
import { BookingContext } from "../context/BookingContext";
import { Types } from "../context/search.reducer";
import { DataQuery } from "@models/ISchema";

const useGetBuildings = (): [
  { data: boolean; error: boolean; loading: boolean },
  (scenario: string) => void,
] => {
  const client = useApolloClient();
  const { dispatch } = useContext(BookingContext);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setBuildingOptions = async (data: any) => {
    if (data) {
      const optBuildings = [];
      if (data.length > 0) {
        data.forEach((building: any) => {
          optBuildings.push({ label: building.name, value: building.id, ...building });
        });
      }
      dispatch({ type: Types.SetBuildingOptions, payload: optBuildings });
      setResult(true);
      setError(false);
      setLoading(false);
    }
  };

  const getBuildingOptions = async (scenario: string) => {
    try {
      const variablesBuilding = {
        query: buildingQuery,
        variables: {
          scenarioId: scenario,
        },
      };
      const { data } = await client.query(variablesBuilding);
      const dataQuery: DataQuery = data?.data;
      await setBuildingOptions(dataQuery?.buildings?.items);
    } catch (error) {
      console.error("[BuildingOptions]", error);
      dispatch({ type: Types.SetBuildingOptions, payload: [] });
      setResult(true);
      setError(true);
      setLoading(false);
    }
  };

  const getCacheBuildingOptions = async (scenario: string) => {
    setLoading(true);
    try {
      const variablesBuilding = {
        query: buildingQuery,
        variables: {
          scenarioId: scenario,
        },
      };
      const { data } = await client.readQuery(variablesBuilding);
      const dataQuery: DataQuery = data?.data;
      await setBuildingOptions(dataQuery?.buildings?.items);
    } catch (error) {
      getBuildingOptions(scenario);
    }
  };

  return [{ data: result, error, loading }, getCacheBuildingOptions];
};

export default useGetBuildings;
