import gql from "graphql-tag";

export const groupLabelsMutation = gql`
  mutation($scenarioId: ID!, $originId: ID!, $input: EditGroupLabelsInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editGroupLabels(input: $input) {
        commited
        group {
          id
          course {
            code
            name
            buildings {
              id
              code
              name
              __typename
              campus {
                id
                code
                name
                __typename
              }
            }
          }
          labels {
            id
            code
            name
            category {
              id
              code
              name
            }
          }
        }
      }
    }
  }
`;
