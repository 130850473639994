import * as R from "ramda";
import { Session } from "@models/ISchema";
import { BlockRanges, EditedSession } from "../context/formData.reducer";
import { Week } from "@modules/sections/ISections";

const isEditedSession = (sessionOrEditedSession: Session | EditedSession): boolean => {
  const session = sessionOrEditedSession as EditedSession;
  return session?.isCloned !== undefined;
};

export const blocksFormat = (sessionOrEditedSession: Session | EditedSession): BlockRanges => {
  if (isEditedSession(sessionOrEditedSession)) {
    const editedSession = sessionOrEditedSession as EditedSession;
    const block = editedSession?.blocks;
    const selected = block?.selected;

    return {
      day: block?.day,
      days: block?.days,
      startTime: selected === "hours" ? block?.startTime : undefined,
      endTime: selected === "hours" ? block?.endTime : undefined,
      blocks: block?.blocks,
      selected,
    };
  }

  const session = sessionOrEditedSession as Session;
  const blockRange = session?.assignment?.blockRange;
  const isWithHours =
    blockRange?.start?.day && blockRange?.start?.startingTime && blockRange?.end?.endingTime
      ? true
      : false;

  return {
    day: blockRange?.start?.day,
    startTime: blockRange?.start?.startingTime,
    endTime: blockRange?.end?.endingTime,
    blocks: session?.blocksCount,
    selected: isWithHours ? "hours" : "blocks",
  };
};

export const intervalsFormat = (
  sessionOrEditedSession: Session | EditedSession,
  linkWeeks: Week[],
) => {
  if (isEditedSession(sessionOrEditedSession)) {
    return R.view(R.lensProp("intervals"), sessionOrEditedSession as EditedSession);
  }

  const sessionWeeksById: { [key: string]: boolean } = R.reduce(
    (acc, week) => R.assoc(week.id, true, acc),
    {},
    (sessionOrEditedSession as Session)?.assignment?.intervals || [],
  );

  return R.map(
    week =>
      R.pipe(R.assoc("checked", week.id in sessionWeeksById), R.assoc("highlight", false))(week),
    linkWeeks || [],
  );
};
