import { ActionMap } from "@models/IContext";
import { Link, Population } from "@models/ISchema";
import { DataReducerType, LinkPopulationPair, LinkPackagePair, LinkPackageObj } from "./types";
import {
  addAssignment,
  addAssignments,
  addCreation,
  addDeletion,
  cleanAssignments,
  cleanCreations,
  cleanDeletions,
  deleteAssignment,
  deleteAssignments,
  deleteCreation,
  setLink,
  setPopulations,
} from "./data.actions";

export enum Types {
  AddAssignments = "ADD_ASSIGNMENTS",
  AddAssignment = "ADD_ASSIGNMENT",
  AddCreation = "ADD_CREATION",
  AddDeletion = "ADD_DELETION",
  CleanAssignments = "CLEAN_ASSIGNMENTS",
  CleanCreations = "CLEAN_CREATIONS",
  CleanDeletions = "CLEAN_DELETIONS",
  DeleteAssignment = "DELETE_ASSIGNMENT",
  DeleteAssignments = "DELETE_ASSIGNMENTS",
  DeleteCreation = "DELETE_CREATION",
  SetLink = "SET_LINK",
  SetPopulations = "SET_POPULATIONS",
}

type DataPayload = {
  [Types.AddAssignments]: LinkPackagePair[];
  [Types.AddAssignment]: LinkPackagePair;
  [Types.AddCreation]: LinkPopulationPair;
  [Types.AddDeletion]: LinkPackagePair;
  [Types.CleanAssignments]: undefined;
  [Types.CleanCreations]: undefined;
  [Types.CleanDeletions]: undefined;
  [Types.DeleteAssignment]: LinkPackagePair;
  [Types.DeleteAssignments]: LinkPackageObj;
  [Types.DeleteCreation]: LinkPopulationPair;
  [Types.SetLink]: Link;
  [Types.SetPopulations]: Population[];
};

export type DataActions = ActionMap<DataPayload>[keyof ActionMap<DataPayload>];

export const dataReducer = (state: DataReducerType, action: DataActions) => {
  switch (action?.type) {
    case Types.AddAssignments:
      return addAssignments(action?.payload, state);
    case Types.AddAssignment:
      return addAssignment(action?.payload, state);
    case Types.AddCreation:
      return addCreation(action?.payload, state);
    case Types.AddDeletion:
      return addDeletion(action?.payload, state);
    case Types.CleanAssignments:
      return cleanAssignments(state);
    case Types.CleanCreations:
      return cleanCreations(state);
    case Types.CleanDeletions:
      return cleanDeletions(state);
    case Types.DeleteAssignment:
      return deleteAssignment(action?.payload, state);
    case Types.DeleteAssignments:
      return deleteAssignments(action?.payload, state);
    case Types.DeleteCreation:
      return deleteCreation(action?.payload, state);
    case Types.SetLink:
      return setLink(action?.payload, state);
    case Types.SetPopulations:
      return setPopulations(action?.payload, state);
    default:
      return state;
  }
};
