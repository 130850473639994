import { HOLIDAYS_QUERY } from "@config/CalendarRanges/calendar.queries";
import { Holiday } from "@models/ISchema";
import { useState } from "react";
import { useApolloClient } from "react-apollo";

interface UseGetHolidaysProps {
  scenario: string;
}

type Holidays = { [key: string]: Holiday };

type UseGetHolidays = [
  {
    isLoading: boolean;
    holidays: Holidays;
  },
  () => Promise<void>,
];

export const useGetHolidays = (props: UseGetHolidaysProps): UseGetHolidays => {
  const { scenario } = props;
  const client = useApolloClient();

  const [isLoading, setIsLoading] = useState(false);
  const [holidays, setHolidays] = useState<Holidays>({});

  const getHolidays = async () => {
    setIsLoading(true);

    const { data } = await client.query({
      query: HOLIDAYS_QUERY,
      variables: {
        scenarioId: scenario,
      },
    });

    const holidaysObj = {};

    (data?.data?.holidays ?? []).forEach((holiday: Holiday) => {
      holidaysObj[holiday?.date] = holiday;
    });

    setHolidays(holidaysObj);
    setIsLoading(false);
  };

  return [
    {
      holidays,
      isLoading,
    },
    getHolidays,
  ];
};
