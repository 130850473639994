import React, { useContext, useMemo } from "react";
import cx from "classnames";
import { Button, Icon, Tag } from "@foris/avocado-ui";
import { groupBy, orderBy } from "lodash";
import { ContextEditLabel, ILabel } from "./ContextEditLabels";
import css from "./editLabels.module.scss";

interface ITagGroup {
  title: string;
  isSystem?: boolean;
  value: ILabel[];
}

const ListLabels: React.FC = () => {
  const context = useContext(ContextEditLabel);
  const groups = groupBy(context.labels, value => value.category.name);
  const orderGroups = useMemo(() => {
    const groupsMap: ITagGroup[] = [];

    for (const [key, value] of Object.entries(groups)) {
      const objectLabel: ITagGroup = {
        title: key,
        isSystem: value[0]?.category?.labelSource?.code !== "USER-DEFINED",
        value,
      };
      groupsMap.push(objectLabel);
    }

    return orderBy(groupsMap, ["title"], ["asc"]);
  }, [groups]);

  const userDefinedGroups = useMemo(() => {
    return orderGroups.filter(value => value.isSystem === false);
  }, [orderGroups]);

  const deleteLabels = (label: ILabel | ILabel[]) => {
    const { labels, options } = context;
    const cloneLabels = labels ? [...labels] : [];
    const cloneOptions = options ? [...options] : [];

    const removeLabel = (tag: ILabel) => {
      const find = cloneLabels.findIndex(value => value.id === tag.id);
      cloneLabels.splice(find, 1);
    };

    const addOption = (tag: ILabel) => {
      const option = {
        label: `${tag?.code} | ${tag?.name} | ${tag?.category?.name}`,
        value: tag,
      };
      cloneOptions.push(option);
    };

    if (Array.isArray(label)) {
      label.forEach(tag => {
        removeLabel(tag);
        addOption(tag);
      });
    } else {
      removeLabel(label);
      addOption(label);
    }

    context.setLabels(cloneLabels);
    context.setOptions(cloneOptions);
  };

  const onDeleteCategory = (category: string) => {
    const deleteTags = orderGroups.filter(value => value.title === category);
    deleteTags.forEach(group => deleteLabels(group.value));
  };

  return (
    <>
      {userDefinedGroups &&
        userDefinedGroups.map((value, index) => (
          <section key={index} className={cx(css.cntLabels, "col_12")}>
            <div className={cx(css.cntLabelsTitle, "container-row")}>
              <h3 className={cx(css.cntLabelsTitle_title)}>{value.title}</h3>
              <Button
                type="button"
                variant="ghost"
                onClick={() => onDeleteCategory(value.title)}
                className={cx(css.cntLabelsTitle_delete)}
              >
                <span className={cx(css.icon)}>
                  <Icon icon="minus" size={12} />
                </span>
                Eliminar categoría
              </Button>
            </div>
            <section className={cx("container-row")}>
              {value.value &&
                value.value.map((label, index) => (
                  <Tag
                    key={`label_${label?.code}_${index}`}
                    type="info"
                    size="lg"
                    className={cx(css.cntLabelsTagItem, label.isNew && css.cntLabelsTagItem__new)}
                    classNameButton={cx(label.isNew && css.cntLabelsTagButton__new)}
                    onRemove={() => deleteLabels(label)}
                  >
                    <span className={css.cntLabelsTagItem_code}>{label.code}</span>
                    <span className={css.cntLabelsTagItem_name}>{` | ${label.name}`}</span>
                  </Tag>
                ))}
            </section>
          </section>
        ))}
    </>
  );
};

export default ListLabels;
