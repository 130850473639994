import React, { useState } from "react";
import { Campus, Department, Modality, Shift } from "../Selectors";

export interface IShiftGroup {
  campus: string;
  department: string;
  modality: string;
  shift: string;
}

export interface IShiftDependencyProps {
  onCallback: (value: IShiftGroup) => void;
  label?: boolean;
  className?: string;
}

const ShiftDependency: React.FC<IShiftDependencyProps> = (props: IShiftDependencyProps) => {
  const { onCallback, label, className } = props;
  const [values, setValues] = useState<IShiftGroup>(null);

  return (
    <>
      <Campus
        className={className}
        label={label}
        onCallback={value => {
          const shiftValues: IShiftGroup = {
            campus: value ? value.value : null,
            department: null,
            modality: null,
            shift: null,
          };
          setValues(shiftValues);
          onCallback(shiftValues);
        }}
      />
      <Department
        className={className}
        label={label}
        campus={values ? values.campus : null}
        onCallback={value => {
          const shiftValues: IShiftGroup = {
            campus: values.campus,
            department: value ? value.value : null,
            modality: null,
            shift: null,
          };
          setValues(shiftValues);
          onCallback(shiftValues);
        }}
      />
      <Modality
        className={className}
        label={label}
        department={values ? values.department : null}
        onCallback={value => {
          const shiftValues: IShiftGroup = {
            campus: values.campus,
            department: values.department,
            modality: value ? value.value : null,
            shift: null,
          };
          setValues(shiftValues);
          onCallback(shiftValues);
        }}
      />
      <Shift
        className={className}
        label={label}
        modality={values ? values.modality : null}
        onCallback={value => {
          const shiftValues: IShiftGroup = {
            campus: values.campus,
            department: values.department,
            modality: values.modality,
            shift: value ? value.value : null,
          };
          setValues(shiftValues);
          onCallback(shiftValues);
        }}
      />
    </>
  );
};

export default ShiftDependency;
