import React, { useContext } from "react";
import { BookingContext } from "../../context/BookingContext";
import Search from "./Search/Search";
import Results from "./Results/Results";
import Layout from "../../../../common/layout/Layout";

const ClassRoom = () => {
  const { state } = useContext(BookingContext);
  return (
    <Layout contextSearch={false}>
      {state.search?.view === "search" && <Search />}
      {state.search?.view === "result" && <Results />}
    </Layout>
  );
};

export default ClassRoom;
