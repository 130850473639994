import { forEach, join, keys, pipe } from "ramda";
import { FiltersReducerType } from "../context/types";

/**
 * Returns an stringify version of the given filters.
 *
 * @param filters FiltersReducerType
 * @return string
 */
const stringifyFilters = (filters: FiltersReducerType): string => {
  const acc = [];

  // main filters
  acc?.push(
    filters?.campus?.id ?? 0,
    filters?.school?.id ?? 0,
    filters?.department?.id ?? 0,
    filters?.subject?.id ?? 0,
  );
  // advanced filters
  acc?.push(
    filters?.owned ? 1 : 0,
    filters?.state?.active ? 1 : 0,
    filters?.state?.inactive ? 1 : 0,
    filters?.visibility?.visible ? 1 : 0,
    filters?.visibility?.invisible ? 1 : 0,
    filters?.availability?.available ? 1 : 0,
    filters?.availability?.unavailable ? 1 : 0,
    filters?.enrollments?.with ? 1 : 0,
    filters?.enrollments?.without ? 1 : 0,
  );
  // course types
  pipe(
    keys,
    forEach(ct => acc?.push(ct)),
  )(filters?.courseTypesById ?? {});

  return join(",", acc);
};

export default stringifyFilters;
