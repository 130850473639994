import { ActionMap } from "@models/IContext";
import { ClassroomBooking } from "@models/ISchema";
import { Preferences, SavedRequestTypes } from "./types";
import {
  clearSavedData,
  setSavedBookingCapacity,
  setSavedBookingPreferences,
  setSavedEditedBooking,
  setSavedRecurrence,
} from "./savedRequest.actions";

export enum Types {
  setSaveEditedBooking = "SET_SAVED_EDITED_BOOKING",
  setSavedRecurrence = "SET_SAVED_RECURRENCE",
  setSavedBookingCapacity = "SET_SAVED_BOOKING_CAPACITY",
  setSavedBookingPreferences = "SET_SAVED_BOOKING_PREFERENCES",
  clearSavedData = "CLEAR_SAVED_DATA",
}

type SavedRequestPayload = {
  [Types.setSaveEditedBooking]: ClassroomBooking;
  [Types.setSavedRecurrence]: boolean;
  [Types.setSavedBookingCapacity]: number;
  [Types.setSavedBookingPreferences]: Preferences;
  [Types.clearSavedData]: void;
};

export type SavedRequestActions = ActionMap<SavedRequestPayload>[keyof ActionMap<
  SavedRequestPayload
>];

export const savedRequestReducer = (state: SavedRequestTypes, action: SavedRequestActions) => {
  switch (action.type) {
    case Types.setSaveEditedBooking:
      return setSavedEditedBooking(action?.payload, state);
    case Types.setSavedRecurrence:
      return setSavedRecurrence(action?.payload, state);
    case Types.setSavedBookingCapacity:
      return setSavedBookingCapacity(action?.payload, state);
    case Types.setSavedBookingPreferences:
      return setSavedBookingPreferences(action?.payload, state);
    case Types.clearSavedData:
      return clearSavedData(state);
    default:
      return state;
  }
};
