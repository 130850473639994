import React, { useRef, useEffect, useReducer } from "react";
import { Icon } from "@foris/avocado-ui";
import DropFilter from "./DropFilter/DropFilter";
import { IDropFilterProps } from "./DropFilter/DropFilter";
import Options, { IOption } from "./Options";
import cx from "classnames";
import useClickOutside from "@common/hooks/useClickOutside";
import css from "./autocomplete.module.scss";

export interface IAutocompleteInput {
  onChange?: (value: string) => void;
  onSelect?: (value: IOption) => void;
  onEnter?: (value: string) => void;
  value?: string;
  options?: Array<IOption>;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
}

interface IAutocompleteProps {
  filter?: IDropFilterProps;
  input: IAutocompleteInput;
  className?: string;
}

interface IAutocompleteState {
  options: Array<IOption>;
  inputValue: string;
  showOptions: boolean;
}

const stateReducer = (current: IAutocompleteState, updated: Partial<IAutocompleteState>) => ({
  ...current,
  ...updated,
});

const Autocomplete: React.FC<IAutocompleteProps> = ({ filter, input, className = "" }) => {
  const [state, setState] = useReducer(stateReducer, {
    inputValue: input.value || "",
    options: input.options,
    showOptions: false,
  });

  const ref = useRef(null);
  useClickOutside({ ref, onClickOutside: () => setState({ showOptions: false }) });

  const handleSelect = (value: IOption | null) => {
    const { inputValue } = state;
    const valueInput = value ? value.label : inputValue;

    setState({ options: [], inputValue: valueInput.toString(), showOptions: false });

    if (value && value.value) {
      input.onSelect(value);
    }
  };

  const handleChangeInput = (valueInp: React.ChangeEvent<HTMLInputElement>) => {
    const valueChange = valueInp.currentTarget.value;

    setState({ inputValue: valueChange });
    input.onChange(valueChange);
  };

  useEffect(() => {
    const valueOptions = input.options;
    let showOpt = valueOptions && valueOptions.length > 0;

    if (showOpt && !state.inputValue) {
      showOpt = false;
    }

    setState({
      options: valueOptions,
      showOptions: showOpt,
      inputValue: input.value,
    });
  }, [input.options, state.inputValue]);

  return (
    <section className={cx(css.cntAutocomplete, className)}>
      {filter && (
        <DropFilter
          options={filter.options}
          onSelect={value => {
            setState({ options: [] });
            filter.onSelect(value);
          }}
          value={filter.value || null}
          onCallback={() => setState({ showOptions: false })}
        />
      )}

      <div
        className={cx(
          css.cntAutocomplete_input,
          input.disabled && css.cntAutocomplete_input__disabled,
        )}
        ref={ref}
      >
        <input
          type="text"
          disabled={input.disabled}
          className={css.inputAutocomplete}
          placeholder={input.placeholder || ""}
          onChange={handleChangeInput}
          onKeyPress={event => {
            if (event.key === "Enter" && input.onEnter) {
              input.onEnter(state.inputValue);
            }
          }}
          value={state.inputValue}
        />
        {input.loading && <Options loading={input.loading} options={[]} />}
        {!input.loading && (
          <>
            {state.showOptions && !!state.inputValue && (
              <Options options={state.options} onSelect={handleSelect} />
            )}
          </>
        )}
      </div>
      <button
        className={css.cntAutocomplete_btn}
        onClick={() => {
          if (input.onEnter) {
            input.onEnter(state.inputValue);
          }
        }}
      >
        <Icon icon="search" size={18} className={css.iconSearch} />
      </button>
    </section>
  );
};

export default Autocomplete;
