import { isEmpty, join, keys } from "ramda";
import { FiltersReducerType } from "../context/types";
import { AdaptedGroup } from "../models";

type ReportType = "groups" | "changeHistory" | "enrollments";

export interface ReportPayload {
  filters?: Partial<FiltersReducerType>;
  testToken?: string;
  testDarwinUrl?: string;
  groupId?: AdaptedGroup["id"];
  dates?: {
    startDate: Date;
    endDate: Date;
  };
  context: {
    origin: string;
    scenario: string;
  };
}

const formatDate = (date: Date): string => {
  const startYear = date?.getFullYear();
  const startMonth = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
  const startDay = date
    ?.getDate()
    ?.toString()
    ?.padStart(2, "0");
  return `${startYear}-${startMonth}-${startDay}`;
};

const buildGroupsReportUrl = (payload: ReportPayload): string => {
  const {
    availability,
    campus,
    courseTypesById,
    department,
    enrollments,
    school,
    state: filterState,
    subject,
    visibility,
  } = payload?.filters;
  const { origin, scenario } = payload?.context;

  const rawHost = payload?.testDarwinUrl
    ? payload?.testDarwinUrl
    : process.env.REACT_APP_DARWINED_URL;
  const url = new URL(rawHost + "groups_manager/groups" + `/${origin}`);
  const token = payload?.testToken ? payload?.testToken : localStorage?.getItem("token") ?? "";
  const strBool = (x: boolean) => (x ? "true" : "false");

  // context
  url?.searchParams?.set("token", token);
  url?.searchParams?.set("scenario_id", scenario);
  url?.searchParams?.set("origin_id", origin);

  // main filters
  if (Boolean(campus?.id)) url?.searchParams?.set("campus_id", campus?.id);
  if (Boolean(department?.id)) url?.searchParams?.set("department_id", department?.id);
  if (Boolean(school?.id)) url?.searchParams?.set("school_id", school?.id);
  if (Boolean(subject?.id)) url?.searchParams?.set("subject_id", subject?.id);

  // advanced filters
  if (filterState?.active !== filterState?.inactive) {
    url?.searchParams?.set("state", strBool(filterState?.active));
  }
  if (visibility?.visible !== visibility?.invisible) {
    url?.searchParams?.set("visibility", strBool(visibility?.visible));
  }
  if (availability?.available !== availability?.unavailable) {
    url?.searchParams?.set("availability", strBool(availability?.available));
  }
  if (enrollments?.with !== enrollments?.without) {
    url?.searchParams?.set("enrollments", enrollments?.with ? "with" : "without");
  }
  // course types
  const courseTypeIds = keys(courseTypesById);
  if (!isEmpty(courseTypeIds)) {
    url?.searchParams?.set("course_types", join(",", courseTypeIds));
  }

  return url?.href;
};

const buildChangeHistoryReportUrl = (payload: ReportPayload): string => {
  const { scenario, origin } = payload?.context ?? {};
  const groupId = payload?.groupId ?? null;
  const { startDate, endDate } = payload?.dates ?? {};
  const {
    availability,
    campus,
    courseTypesById,
    department,
    enrollments,
    owned,
    school,
    state: filterState,
    subject,
    visibility,
  } = payload?.filters ?? {};

  const strBool = (x: boolean) => (x ? "true" : "false");

  // format start date
  const formattedStart = Boolean(startDate) ? formatDate(startDate) : null;
  const formattedEnd = Boolean(endDate) ? formatDate(endDate) : null;

  const rawHost = payload?.testDarwinUrl
    ? payload?.testDarwinUrl
    : process.env.REACT_APP_DARWINED_URL;
  const url = new URL(rawHost + "groups_manager/change_history" + `/${origin}`);
  const token = payload?.testToken ? payload?.testToken : localStorage.getItem("token") ?? "";

  // context
  url?.searchParams?.set("token", token);
  url?.searchParams?.set("scenario_id", scenario);
  url?.searchParams?.set("origin_id", origin);

  // dates
  if (Boolean(formattedStart)) url?.searchParams?.set("start_date", formattedStart);
  if (Boolean(formattedEnd)) url?.searchParams?.set("end_date", formattedEnd);
  if (Boolean(groupId)) url?.searchParams?.set("group_id", groupId);

  // advanced filters
  if (filterState?.active !== filterState?.inactive) {
    url?.searchParams?.set("state", strBool(filterState?.active));
  }
  if (visibility?.visible !== visibility?.invisible) {
    url?.searchParams?.set("visibility", strBool(visibility?.visible));
  }
  if (availability?.available !== availability?.unavailable) {
    url?.searchParams?.set("availability", strBool(availability?.available));
  }
  if (enrollments?.with !== enrollments?.without) {
    url?.searchParams?.set("enrollments", enrollments?.with ? "with" : "without");
  }

  // main filters
  if (Boolean(campus?.id)) url?.searchParams?.set("campus_id", campus?.id);
  if (Boolean(department?.id)) url?.searchParams?.set("department_id", department?.id);
  if (Boolean(school?.id)) url?.searchParams?.set("school_id", school?.id);
  if (Boolean(subject?.id)) url?.searchParams?.set("subject_id", subject?.id);

  // owner
  if (owned) url?.searchParams?.set("owned", owned ? "true" : "false");

  // course types
  const courseTypeIds = keys(courseTypesById);
  if (!isEmpty(courseTypeIds)) {
    url?.searchParams?.set("course_types", join(",", courseTypeIds));
  }

  return url?.href;
};

// TODO: remove this comment when this function is implemented
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const buildEnrollmentsReportUrl = ({ filters }: ReportPayload): string => {
  // TODO //
  return "";
};

const buildReport = (report: ReportType, payload: ReportPayload): string => {
  switch (report) {
    case "groups":
      return buildGroupsReportUrl(payload);
    case "changeHistory":
      return buildChangeHistoryReportUrl(payload);
    case "enrollments":
      return buildEnrollmentsReportUrl(payload);
    default:
      throw new Error(`Can't handle "${report}" report`);
  }
};

export default buildReport;
