import gql from "graphql-tag";

export const populationSearchMutation = gql`
  mutation filter(
    $scenarioId: ID!
    $originId: ID!
    $filter: PopulationFilterInput!
    $pagination: PaginationInput
  ) {
    filter(scenarioId: $scenarioId, originId: $originId) {
      createPopulationFilter(filter: $filter) {
        id
        populations(pagination: $pagination) {
          items {
            code
            size
            level
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            page
            size
          }
        }
      }
    }
  }
`;

export const populationSearchQuery = gql`
  query filter($filterId: ID, $scenarioId: ID!, $pagination: PaginationInput) {
    filter(scenarioId: $scenarioId) {
      populations(filterId: $filterId, pagination: $pagination) {
        items {
          id
          code
          size
          level
          campus {
            id
            code
            name
          }
          modality {
            id
            code
          }
          shift {
            id
            code
          }
          program {
            id
            code
          }
          curriculum
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
