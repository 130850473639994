import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as R from "ramda";
import cx from "classnames";
import { Button, CardState, Snackbar, Card } from "@foris/avocado-ui";
import { AppContext } from "../../context/EditSessionsContext";
import { Types, EditedSession } from "../../context/formData.reducer";
import Classroom from "./Classroom/Classroom";
import Recommendations from "./Recommendations/Recommendations";
import Repeat from "../../components/Repeat/Repeat";
import Days from "../../components/Days/Days";
import BlockRange from "../../components/BlockRange/BlockRange";
import FormHeader from "../../components/FormEdit/FormHeader";
import Instructor from "./Instructor/Instructor";
import { sessionsHaveTheSameResources } from "../../utils/sessionsHaveTheSameResources";
import { IEvent, Week } from "@modules/sections/ISections";
import { Section, Session } from "@models/ISchema";
import { ContextApp } from "@config/Context/contextApp";
import AbilitiesMessage from "./AbilitiesMessage";
import css from "./formEdit.module.scss";

interface ILocationState {
  sessionId?: number;
  event?: IEvent;
}

const EditSession: React.FC = () => {
  const { user } = useContext(ContextApp);
  const { state, dispatch } = useContext(AppContext);
  const [currentEditedSessions, setCurrentEditedSessions] = useState<EditedSession>({});
  const [forkingIsAllowed, setForkingIsAllowed] = useState(true);
  const [originalWeeksBySessionId, setOriginalWeeksBySessionId] = useState<{
    [key: string]: string[];
  }>({});
  const location = useLocation();
  const locationState: ILocationState = location.state;
  const eventState = locationState?.event;
  const [isInitialEditSet, setIsInitialEditSet] = useState(false);
  const [activeSanckbar, setActiveSanckbar] = useState(false);
  const [forkedWeeksBySessionIds, setForkedWeeksBySessionIds] = useState({});

  const selectedSessions = state?.form?.selectedSessions;
  const abilities = user?.abilities;

  const removedWeeksLabels = () => {
    if (R.isEmpty(state?.form?.selectedSessions ?? [])) return "";

    const removedWeekIdsBySessionId = {};
    Object.keys(state?.form?.unmarkedWeekIdsBySessionId ?? {}).forEach(sessionId => {
      const unmarkedWeeksIdArr = Object.keys(state?.form?.unmarkedWeekIdsBySessionId[sessionId]);
      removedWeekIdsBySessionId[sessionId] = unmarkedWeeksIdArr;
    });

    const weeksLabelById = R.reduce(
      (acc, week: Week) => R.assoc(week?.id, week?.name, acc),
      {},
    )(state?.form?.editedSessions?.intervals ?? []);

    // we can asume that the label will dislay only if all the selectedSessions
    // have the same *original* intervals, so we can just take the removed
    // weeks of first one.
    return R.pipe(
      R.head,
      R.prop<"id", string>("id"),
      R.propOr([], R.__, removedWeekIdsBySessionId),
      R.map(R.prop(R.__, weeksLabelById)),
      R.join(", "),
    )(state?.form?.selectedSessions);
  };

  /**
   * Allow/Disallow the sessions forking
   */
  useEffect(() => {
    const sameIntervals = R.pipe(
      R.filter((session: EditedSession) => R.not(session?.id?.includes("-"))),
      R.map(R.pipe(R.prop("id"), R.propOr([], R.__, originalWeeksBySessionId))),
      R.reduce(
        ({ eq, prev }, curr) => ({
          eq: !prev || !eq ? eq : R.equals(prev, curr),
          prev: curr,
        }),
        { eq: true, prev: null },
      ),
      R.prop("eq"),
    )(state?.form?.selectedSessions ?? []);

    const intervalsHasBeenEdited = Boolean(state?.form?.assignmentEdited?.intervals);
    const someSelectedSessionHasDeletedWeeks = R.reduce(
      (acc, session) => R.or(acc, R.has(session.id, state?.form?.removedWeekIdsBySessionId)),
      false,
      selectedSessions,
    );
    const someSelectedSessionIsNew = R.reduce(
      (acc, session) => R.or(acc, session?.id?.includes("-")),
      false,
      selectedSessions,
    );
    const sessionHasClonnedWeeks = selectedSessions?.some(session => {
      return state?.form?.sessionsToCreate?.find(
        sessionToCreate =>
          sessionToCreate?.isCloned && sessionToCreate?.session?.id === session?.id,
      );
    });

    setForkingIsAllowed(
      sameIntervals &&
        intervalsHasBeenEdited &&
        someSelectedSessionHasDeletedWeeks &&
        R.not(someSelectedSessionIsNew) &&
        !sessionHasClonnedWeeks &&
        Object.keys(state?.form?.unmarkedWeekIdsBySessionId ?? {}).length > 0,
    );
  }, [
    state?.form?.assignmentEdited,
    state?.form?.assignmentSame,
    state?.form?.removedWeekIdsBySessionId,
    state?.form?.unmarkedWeekIdsBySessionId,
    selectedSessions,
  ]);

  useEffect(() => {
    if (!locationState?.sessionId || !state?.link?.sections?.length) return;

    const { sessionId } = locationState;
    const selectedSession = R.pipe(
      R.map((section: Section) => {
        const [sessions, unasignedSessions] = R.ap(
          [R.propOr([], "sessions"), R.propOr([], "unasignedSessions")],
          [section],
        );
        return [...(sessions as EditedSession[]), ...(unasignedSessions as EditedSession[])];
      }),
      R.flatten,
      R.reduce(
        (acc, session) =>
          Object.keys(acc)?.length
            ? acc
            : parseInt(R.propOr("0", "id", session)) == sessionId
            ? session
            : {},
        {},
      ),
    )(state?.link?.sections);

    if (R.not(R.isEmpty(selectedSession)) && R.not(state?.form?.sessionFromLocationSelected)) {
      dispatch({ type: Types.SelectedSessions, payload: selectedSession });
      dispatch({ type: Types.SetSessionFromLocationSelected, payload: true });
    }
  }, []);

  useEffect(() => {
    if (!isInitialEditSet && !!eventState?.id && !!state?.form?.editedSessions?.blocks) {
      setIsInitialEditSet(true);

      dispatch({
        type: Types.BlocksEditedSessions,
        payload: {
          blocks: {
            ...state?.form?.editedSessions?.blocks,
            startTime: eventState?.resource?.blockRange?.start?.startingTime,
            endTime: eventState?.resource?.blockRange?.end?.endingTime,
            day: eventState?.resource?.blockRange?.start?.day,
          },
        },
      });
    }

    if (
      Boolean(state?.form?.editedSessions) &&
      R.not(sessionsHaveTheSameResources(currentEditedSessions, state?.form?.editedSessions))
    ) {
      setCurrentEditedSessions(state?.form?.editedSessions);
    }
  }, [state?.form?.editedSessions]);

  useEffect(() => {
    if (Boolean(currentEditedSessions)) {
      dispatch({ type: Types.SavedSessions, payload: [currentEditedSessions] });
    }
  }, [currentEditedSessions]);

  useEffect(() => {
    if (R.not(Boolean(state?.link?.sections))) return;
    const sortedIds = R.pipe(R.sortBy(R.propOr("", "id")), R.map(R.propOr("", "id")));

    setOriginalWeeksBySessionId(
      R.pipe(
        R.reduce((acc, section: Section) => {
          const sessionsWithSchedules = R.prop("sessions", section);
          const sessionsWithoutSchedules = R.prop("unasignedSessions", section);
          return R.concat(acc, R.concat(sessionsWithSchedules, sessionsWithoutSchedules));
        }, []),
        R.reduce(
          (acc, session: Session) =>
            R.assoc(session.id, sortedIds(session?.assignment?.intervals), acc),
          {},
        ),
      )(state?.link?.sections),
    );
  }, [state?.link?.sections]);

  useEffect(() => {
    const isForkedSession = R.pipe(R.propOr(false, "forkedWeeksByOriginSessions"), Boolean);

    const newForkedWeeksBySessionIds = R.pipe(
      R.filter(isForkedSession),
      R.reduce((acc, session) => {
        const weeksBySessionIds = R.toPairs(session?.forkedWeeksByOriginSessions);
        weeksBySessionIds.forEach(([sessionId, weekIds]) => {
          if (R.not(R.has(sessionId, acc))) acc[sessionId] = [];
          acc[sessionId].push(weekIds);
        });
        return acc;
      }, {}),
      R.mapObjIndexed(R.uniq),
    )(state?.form?.sessionsToCreate);

    setForkedWeeksBySessionIds(newForkedWeeksBySessionIds);
  }, [state?.form?.sessionsToCreate]);

  useEffect(() => {
    const removedWeekIdsBySessionId = state?.form?.removedWeekIdsBySessionId;
    const forkingAlreadyMade = R.reduce(
      (acc, session) => {
        if (acc) return true;

        const sessionWeekForkings = forkedWeeksBySessionIds[session?.id] ?? [];
        const currentRemovedWeeks = removedWeekIdsBySessionId[session?.id] ?? [];

        return R.reduce(
          (acc, weeksForking) => {
            if (acc) return true;

            return (
              Boolean((weeksForking as string[])?.length) &&
              Boolean(currentRemovedWeeks?.length) &&
              R.equals(weeksForking, currentRemovedWeeks)
            );
          },
          false,
          sessionWeekForkings,
        );
      },
      false,
      state?.form?.selectedSessions,
    );

    if (forkingAlreadyMade) setForkingIsAllowed(false);
  }, [forkedWeeksBySessionIds]);

  return (
    <>
      <Snackbar
        type="confirm"
        setValueActive={(value: any) => setActiveSanckbar(value)}
        active={activeSanckbar}
        icon="circle-check"
        duration={3}
      >
        Sesión creada exitosamente
      </Snackbar>
      <section className={css.formEdit}>
        <>
          <FormHeader />
          {selectedSessions?.length ? (
            <div className={css.formEdit_content}>
              <AbilitiesMessage className={css.infoMessage} />

              <section className={cx(css.fields)}>
                <section className={cx(css.fields_content)}>
                  <BlockRange disabled={!abilities?.can_assign_blocks} />
                  <Days disabled={!abilities?.can_assign_blocks} />
                  <Instructor disabled={!abilities?.can_assign_instructors} />
                  <Classroom disabled={!abilities?.can_assign_classrooms} />
                  <Repeat
                    disabled={!abilities?.can_assign_intervals}
                    originalWeeksBySessionId={originalWeeksBySessionId}
                  />
                  {forkingIsAllowed && (
                    <Card.Simple className={cx(css.forkCard)}>
                      <Card.Content>
                        <p className={css.forkCard_title}>
                          Trasladar semanas marcadas y recursos editados a nueva sesión
                        </p>

                        <p className={css.forkCard_description}>
                          Manteniendo las semanas desmarcadas y recursos originales en la sesión
                          actual.
                        </p>

                        <p>Semanas desmarcadas: {removedWeeksLabels()}</p>

                        <Button
                          variant="solid"
                          className={css.forkCard_button}
                          onClick={() => {
                            dispatch({ type: Types.ForkSelectedSessions });
                            setActiveSanckbar(true);
                          }}
                        >
                          Trasladar a nueva sesión
                        </Button>
                      </Card.Content>
                    </Card.Simple>
                  )}
                </section>
                <section className={cx(css.recommendationsContainer)}>
                  <Recommendations />
                </section>
              </section>
            </div>
          ) : (
            <CardState
              typeCard="informative"
              title="Edición de horarios multi-sesión"
              className={css.initCard}
            >
              {`Selecciona una o varias sesiones del listado de la izquierda para realizar ajustes de
            asignación. También puedes crear nuevas sesiones presionando el botón "agregar sesión".`}
            </CardState>
          )}
        </>
      </section>
    </>
  );
};

export default EditSession;
