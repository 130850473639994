import React, { useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { ShiftDependency, LevelDependency, Course } from "../../../shared/selectors";
import { TimeRange } from "../../../shared/inputs";
import { Days, Assignment } from "../../../shared/checks";
import { EmptyResult } from "../../../shared/modals";
import WeeksRange from "../../../shared/weeksRange/WeeksRange";
import { validations } from "../../../advanceSearch/utils";
import {
  IShiftDependency,
  ILevelDependency,
  IAvailability,
  ITimeRangeDependency,
  IWeeksRangeDependency,
  ICourseId,
  ICourseLevel,
  IAssignment,
} from "../../../advanceSearch/IAdvanceSearch";
import { ProgramFilterFieldsInput, AssignmentFilterInput } from "../../../../models/ISchema";
import Button from "../../../../common/components/Button/Button";
import { Loading } from "@foris/avocado-ui";
import { objectEmpty } from "../../../../utils/validations";
import { sectionSearchMutation } from "../graphql/advanceSearch.queries";
import css from "../../../advanceSearch/advance-search.module.scss";

const AdvanceSearchSection: React.FC<any> = (props: any) => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(sectionSearchMutation, {});
  const [daysError, setDaysError] = useState(false);
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [shiftValues, setShiftValues] = useState(null);
  const [levelValues, setLevelValues] = useState(null);
  const [timeRangeError, setTimeRangeError] = useState(false);
  const [arrayProgram, setArrayProgram] = useState<ProgramFilterFieldsInput[]>([]);
  const [objCourse, setObjCourse] = useState<ICourseId>({});
  const [objAvailability, setObjAvailability] = useState<IAvailability>({});
  const [objLevel, setObjLevel] = useState<ICourseLevel>({});
  const [objAssignment, setObjAssignment] = useState<AssignmentFilterInput>({});
  const [objCurriculum, setObjCurriculum] = useState({});
  const [loading, setLoading] = useState(false);

  const setLevelDependency = (value: ILevelDependency) => {
    const validation = validations.setLevelDependency(value, arrayProgram, objLevel, objCurriculum);
    validation.arrayProgram && setArrayProgram(validation.arrayProgram);
    validation.objCurriculum && setObjCurriculum(validation.objCurriculum);
    validation.objLevel && setObjLevel(validation.objLevel);
    setObjCourse(validation.objCourse);
  };

  const setShiftDependency = (value: IShiftDependency) => {
    // validation array content
    const validation = validations.shiftDependency(value);
    setArrayProgram(validation);
    // selected shift values
    setShiftValues(value);
    // set level-course dependencies
    setLevelValues({});
    setObjLevel({});
    setObjCourse({});
    setObjCurriculum({});
  };

  const setCourseDependency = (value: string) => {
    const validation = validations.courseDependencies(value, objCourse);
    setObjCourse(validation);
  };

  const setDays = (value: number[]) => {
    const validation = validations.days(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setTimeRange = (value: ITimeRangeDependency, error: boolean) => {
    if (error) {
      setError(true);
      return;
    }
    const validation = validations.timeRange(value, objAvailability);
    setObjAvailability(validation.availability);
    setError(validation.error);
    setTimeRangeError(validation.timeRangeError);
    setDaysError(validation.daysError);
  };

  const setWeeksRange = (values: IWeeksRangeDependency) => {
    const validation = validations.weeksRange(values, objAvailability);
    setObjAvailability(validation.availability);
  };

  const setAssignmentChecks = (values: IAssignment) => {
    const validation = validations.assignmentChecks(values, objAssignment);
    setObjAssignment(validation);
  };

  const createObjectMutation = () => {
    const filterArray = [];
    !objectEmpty(objCourse) && filterArray.push(objCourse);
    !objectEmpty(objAvailability) && filterArray.push(objAvailability);
    !objectEmpty(objAssignment) && filterArray.push({ assignment: { ...objAssignment } });
    !objectEmpty(objCurriculum) && filterArray.push(objCurriculum);
    !objectEmpty(objLevel) && filterArray.push(objLevel);

    if (arrayProgram.length > 0) {
      filterArray.push({
        program: {
          and: arrayProgram,
        },
      });
    }
    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [...filterArray],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    console.debug(mutationFilter);
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createSectionFilter;
      if (dataItems.sections.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/section/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  let weeksDisabled = true;
  if (objAvailability.availability) {
    const daysExist = objAvailability.availability.hasOwnProperty("dayId");
    const rangeExist = objAvailability.availability.hasOwnProperty("blockRange");
    if (rangeExist) {
      const start = objAvailability.availability.blockRange.hasOwnProperty("start");
      const end = objAvailability.availability.blockRange.hasOwnProperty("end");
      if (start && end && daysExist) weeksDisabled = false;
    }
  }

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />
      {advanceRowContent(
        "Dependencia",
        <>
          <ShiftDependency
            onCallback={values => {
              setShiftDependency(values);
            }}
            className={css.activePaddingGroup}
          />
          <LevelDependency
            {...shiftValues}
            onCallback={values => {
              setLevelValues(values);
              setLevelDependency(values);
              setCourseDependency(null);
            }}
            className={css.activePaddingEnd}
          />
          <Course
            curriculum={levelValues ? levelValues.curriculum : null}
            level={levelValues ? levelValues.level : null}
            filterBy="level"
            onCallback={value => {
              if (value) setCourseDependency(value.value);
            }}
            className={css.activePadding}
          />
        </>,
      )}
      {advanceRowContent(
        "Días",
        <Days required={daysError} onCallback={values => setDays(values)} />,
      )}
      {advanceRowContent(
        "Rango de horario",
        <TimeRange
          required={timeRangeError}
          onCallback={(values, error) => setTimeRange(values, error)}
        />,
      )}
      <WeeksRange {...props} disabled={weeksDisabled} onCallback={value => setWeeksRange(value)} />

      {advanceRowContent(
        "Asignación",
        <Assignment onCallback={value => setAssignmentChecks(value)} />,
      )}

      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <div className={cx(css.advance_item, "container-row")}>
          <Button disabled={error} onClick={() => callUpdateHook()}>
            Buscar
          </Button>
        </div>
      )}
    </>
  );
};

export default AdvanceSearchSection;
