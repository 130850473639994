import React, { Suspense } from "react";
import * as R from "ramda";
import moment from "moment";
import cx from "classnames";
import { Loading } from "@foris/avocado-ui";
import Modal from "../../../common/components/modal/Modal";
import SchedulerAPI from "../Scheduler/SchedulerAPI";
import { serializeSectionMutation } from "../utils/serializeData";
import { IGridSectionsState, ISerialzeEvent, IColor } from "./IGridSections";
import { IEvent, ISectionsProps, IWeek } from "../ISections";
import { enums } from "../utils";
import { colors } from "../../../utils/dataColors";
import ItemInfo from "./ItemInfo/ItemInfo";
import ItemBooking from "./ItemInfo/ItemBooking";
import ItemEdit from "./ItemEdit/ItemEdit";
import InfoSections from "../InfoSections/InfoSections";
import AccordionContainer from "../../../common/components/Accordion/AccordionContainer";
import Accordion from "../../../common/components/Accordion/Accordion";
import { Block, Day } from "../../../models/ISchema";
import { daysEn, daysEs } from "../../../utils/days";
import css from "./grid-sections.module.scss";

const SchedulerLazyComponent = React.lazy(() => import("../Scheduler/Scheduler"));

declare interface ISchedulerState {
  events: any;
}

class GridSections extends React.Component<ISectionsProps, IGridSectionsState, ISchedulerState> {
  SAPI = new SchedulerAPI();

  static getDerivedStateFromProps = (props: ISectionsProps) => {
    let objWeek = {};

    if (props.currentWeek) {
      objWeek = {
        currentSelectedWeek: props.currentWeek,
      };
    }

    return {
      events: props.data.eventList,
      currentWeekList: props.data.weekList,
      unassigned: props.data.unassigned,
      ...objWeek,
    };
  };

  constructor(props: ISectionsProps) {
    super(props);
    this.state = {
      loading: true,
      events: props.data.eventList,
      activeInfoModal: false,
      activeEditModal: false,
      activeInfoModalAccordion: false,
      currentSelectedEvent: {},
      errorSelectedEvent: false,
      currentWeekList: props.data.weekList,
      updatedWeekList: [],
      currentSelectedWeek: props.data.selectedWeek
        ? props.data.selectedWeek
        : props.data.weekList[0]
        ? props.data.weekList[0]
        : new Date(),
      unassigned: props.data.unassigned,
      notRequireSchedule: false,
    };
  }

  formatNewTimes = (event: any, start: Date, end: Date) => {
    const day = moment(start)
      .format("dddd")
      .toLocaleUpperCase();
    let newDay = "";
    daysEs.forEach((value: string, index: number) => {
      if (value === day) newDay = daysEn[index];
    });
    const lowerDay = newDay?.toLowerCase();
    const dayEnum = lowerDay.charAt(0).toUpperCase() + lowerDay.slice(1);
    const startBlock: Block = {
      id: event.resource.blockRange.start.id,
      startingTime: moment(start).format("HH:mm:ss"),
      day: Day[dayEnum],
    };
    const endBlock: Block = {
      id: event.resource.blockRange.end.id,
      endingTime: moment(end).format("HH:mm:ss"),
      day: Day[dayEnum],
    };
    return {
      start: startBlock,
      end: endBlock,
    };
  };

  serializeEventList = ({ event, start, end }: ISerialzeEvent) => {
    const { data } = this.props;
    const currentEvent = { ...event, start, end };
    const newEventList = this.SAPI.moveEvent(data.eventList, currentEvent);
    const tmpList: Array<IEvent> = newEventList || [];
    tmpList.forEach((item: IEvent, index: number) => {
      if (item.sessionId === currentEvent.sessionId) {
        tmpList[index]["state"] = enums.EVENT_STATES.MODIFIED;
      }
    });
    const newData = { ...data, eventList: tmpList };
    return newData;
  };

  serializeErrorList = (eventStatus: any) => {
    const { data } = this.props;
    const tmpListError: Array<IEvent> = data.eventList || [];
    const commitedError = eventStatus.commited;
    tmpListError.forEach((item, index) => {
      if (item.sessionId === eventStatus.sessionId) {
        tmpListError[index]["error"] =
          commitedError === false
            ? { status: true, details: eventStatus.validationErrors }
            : { status: false, details: [] };
        tmpListError[index]["state"] = enums.EVENT_STATES.UPDATED;
      }
    });
    const newData = { ...data, eventList: tmpListError };
    return newData;
  };

  eventHandlers = {
    onEventDrop: ({ event, start, end }: ISerialzeEvent) => {
      const times = this.formatNewTimes(event, start, end);
      const eventObj = {
        ...event,
        resource: {
          ...event.resource,
          blockRange: {
            start: times.start,
            end: times.end,
          },
        },
      };

      this.props?.onOpenEdit?.({
        sessionId: String(event?.sessionId),
        currentSelectedWeek: this.state.currentSelectedWeek,
        event: eventObj,
      });
    },
    onEventResize: ({ event, start, end }: ISerialzeEvent) => {
      const times = this.formatNewTimes(event, start, end);
      const eventObj = {
        ...event,
        resource: {
          ...event.resource,
          blockRange: {
            start: times.start,
            end: times.end,
          },
        },
      };

      this.props?.onOpenEdit?.({
        sessionId: String(event?.sessionId),
        currentSelectedWeek: this.state.currentSelectedWeek,
        event: eventObj,
      });
    },
    filterCategories: (
      e: React.FormEvent<HTMLSelectElement>,
      { id, checked, filtercriteria }: { id: number; checked: boolean; filtercriteria: string },
    ) => {
      console.log(e, id, checked, filtercriteria);
    },
    onFilterRestrictedAreas: (
      e: React.FormEvent<HTMLSelectElement>,
      { areatype, checked }: { areatype: string; checked: boolean },
    ) => {
      this.SAPI.filterRestrictedAreas({ areatype, checked });
    },
    onSelectEvent: (event: IEvent) => {
      let errorState = false;
      if (event.error.status) {
        errorState = true;
      }
      this.setState({
        currentSelectedEvent: event,
        activeInfoModal: true,
        errorSelectedEvent: errorState,
        notRequireSchedule: false,
      });
    },
    onToolbarNavigate: (date: Date, view: string, action: string) => {
      const { currentSelectedWeek, currentWeekList, updatedWeekList } = this.state;
      const weekList = updatedWeekList.length ? updatedWeekList : currentWeekList;
      let newSelectedWeek = currentSelectedWeek;
      const list = R.filter(R.pipe(R.propOr("off", "status"), R.equals("on")), weekList ?? []);
      const elementToFind: IWeek = currentSelectedWeek ? currentSelectedWeek : ({} as IWeek);
      const currentIndex = list.findIndex(
        (element: IWeek) => element.startingDate === elementToFind.startingDate,
      );

      if (action === "PREV" && list.length) {
        newSelectedWeek = currentIndex > 0 ? list[currentIndex - 1] : list[list.length - 1];
      }

      if (action === "NEXT" && list.length) {
        newSelectedWeek = currentIndex < list.length - 1 ? list[currentIndex + 1] : list[0];
      }

      if (this.props.setCurrentWeek) {
        this.props.setCurrentWeek(newSelectedWeek);
      }
      this.setState({ currentSelectedWeek: newSelectedWeek });
    },
    closeModals: () => {
      this.setState({ activeInfoModal: false, activeEditModal: false });
    },
    openEditModal: () => {
      this.setState({ activeEditModal: true, activeInfoModal: false });
    },
  };

  updateEvent = (eventObject: IEvent) => {
    const { config, setDataGrid, updateEvent } = this.props;
    const startTime = eventObject.start;
    const endTime = eventObject.end;
    const sessionId = eventObject.sessionId;
    const params = serializeSectionMutation(config, sessionId, startTime, endTime);

    /** function mutation - props from module */
    updateEvent(params, (response: any) => {
      if (response.status === "ERROR") console.log("error", response);
      if (response.status === "OK") {
        const eventStatus = {
          event: { ...response.content.data.cube.editSession.session },
          sessionId: sessionId,
          commited: response.content.data.cube.editSession.commited,
          validationErrors: response.content.data.cube.editSession.validationErrors,
        };
        const newData = this.serializeErrorList(eventStatus);
        setDataGrid(newData);
      }
    });
  };

  undoPosition = (event: IEvent) => {
    const { data, setDataGrid } = this.props;
    const currentEvent = { ...event };
    this.SAPI.moveEvent(data.eventList, currentEvent);
    this.setState({ events: data.eventList }, () => {
      const eventStatus = {
        event: currentEvent,
        sessionId: currentEvent.sessionId,
        commited: true,
        validationErrors: [],
      };
      const newData = this.serializeErrorList(eventStatus);
      setDataGrid(newData, () => {
        this.setState({
          events: data.eventList,
          activeInfoModal: false,
        });
      });
    });
  };

  handleColor = (data: any) => {
    const matchColor = colors.find((item: IColor) => item.id === data.colorIndex);
    let newStyle = { backgroundColor: "#507aaf" };
    const dimension = data.type;
    if (dimension === "ClassroomInfo") {
      if (data.bookings) {
        return {
          style: { backgroundColor: "#9bced4" },
        };
      } else {
        return {
          style: newStyle,
        };
      }
    } else {
      if (matchColor) {
        newStyle = {
          ...newStyle,
          backgroundColor: matchColor.color,
        };
        return {
          style: newStyle,
        };
      } else {
        return {
          style: newStyle,
        };
      }
    }
  };

  onSelectEventUnassigned = (event: IEvent) => {
    this.setState({
      currentSelectedEvent: event,
      activeInfoModal: true,
      errorSelectedEvent: false,
      notRequireSchedule: true,
    });
  };

  listUnassigned = () => {
    const { unassigned, currentSelectedWeek } = this.state;
    const dataUnassigned = [];

    unassigned.forEach((element: any) => {
      let newObj = {};

      element.resource.intervals.forEach((week: any) => {
        if (currentSelectedWeek) {
          if (parseInt(currentSelectedWeek.id) === parseInt(week.id)) {
            newObj = {
              ...newObj,
              ...element,
              id: element.sessionId,
              name: element.name,
              status: element.status,
              intervals: week,
            };
            dataUnassigned.push(newObj);
          }
        }
      });
    });

    if (dataUnassigned.length > 0) {
      return (
        <AccordionContainer>
          <Accordion
            className={css.cntAccordion}
            activeBorder={true}
            headerAccordion={
              <h5 className={css.cntAccordion_title}>
                Mostrar {dataUnassigned.length > 1 ? "Sesiones" : "Sesión"} sin horario (
                {dataUnassigned.length})
              </h5>
            }
          >
            {dataUnassigned.map((item: any, index: number) => {
              const nameComponent = item.resource.info.courseComponent.component
                ? item.resource.info.courseComponent.component.code
                : "";
              return (
                <>
                  <div
                    className={cx(css.cntItem, "container-row")}
                    key={index}
                    onClick={() => {
                      this.onSelectEventUnassigned(item);
                    }}
                  >
                    <p className={cx(css.cntItem_text, "col_1")}>{item.id}</p>
                    <p className={cx(css.cntItem_text, "col_6")}>{item.name}</p>
                    <p className={cx(css.cntItem_text, "col_3")}>{nameComponent}</p>
                    <p
                      className={cx(
                        css.cntItem_text,
                        "col_2",
                        !item.status && css.cntItem_text__error,
                      )}
                    >
                      {item.status ? "No requiere horario" : "Sin asignación"}
                    </p>
                  </div>
                </>
              );
            })}
          </Accordion>
        </AccordionContainer>
      );
    }
  };

  handleUpdateWeeks = (weeks: IWeek[]) => {
    this.setState({ updatedWeekList: weeks });
  };

  render() {
    const {
      events,
      activeInfoModal,
      activeEditModal,
      currentSelectedEvent,
      errorSelectedEvent,
      currentWeekList,
      currentSelectedWeek,
      unassigned,
      notRequireSchedule,
    } = this.state;
    const { config, data, dimension } = this.props;
    const colorModal = this.handleColor(currentSelectedEvent).style.backgroundColor;
    return (
      <React.Fragment>
        <Modal
          onClose={() => this.eventHandlers.closeModals()}
          show={activeInfoModal}
          headerColor={errorSelectedEvent ? "#d8a89d" : colorModal}
          header={() => (
            <div>
              {!currentSelectedEvent.bookings && (
                <span> {`Sesión ${currentSelectedEvent.sessionId}`}</span>
              )}
              <span>{currentSelectedEvent.title}</span>
            </div>
          )}
        >
          {currentSelectedEvent.bookings ? (
            <ItemBooking
              event={currentSelectedEvent}
              onUndo={(event: IEvent) => this.undoPosition(event)}
            />
          ) : (
            <ItemInfo
              event={currentSelectedEvent}
              error={errorSelectedEvent}
              onUndo={(event: IEvent) => this.undoPosition(event)}
              openEditModal={this.eventHandlers.openEditModal}
              dimension={dimension}
              currentSelectedWeek={currentSelectedWeek}
            />
          )}
        </Modal>

        {/* ItemEdit */}
        {activeEditModal && (
          <ItemEdit
            onClose={() => this.eventHandlers.closeModals()}
            weeks={currentWeekList}
            selected={currentSelectedWeek}
            event={currentSelectedEvent}
            notRequireSchedule={notRequireSchedule}
            recommendationsAvailable={true}
          />
        )}

        {/* ItemInfo */}
        {!activeEditModal && (
          <div className={css.gridInfo}>
            <div className={css.infoWeeks}>
              <InfoSections
                defaultTerm={this.props.defaultTerm}
                onUpdateWeeks={this.handleUpdateWeeks}
                onSelectWeek={(currentSelectedWeek: IWeek) => {
                  this.setState({ currentSelectedWeek }, () => {
                    if (this.props.setCurrentWeek) {
                      this.props.setCurrentWeek(currentSelectedWeek);
                    }
                    this.setState({ currentSelectedWeek });
                  });
                }}
                weeks={currentWeekList}
                selected={currentSelectedWeek}
              />
            </div>
            <div className={css.sectionGrid}>
              <Suspense fallback={<Loading />}>
                <SchedulerLazyComponent
                  config={config}
                  eventHandlers={this.eventHandlers}
                  events={events}
                  restrictions={data.calendarRestrictions}
                  dateToDisplay={(() => {
                    if (currentSelectedWeek && currentSelectedWeek.startingDate) {
                      return currentSelectedWeek.startingDate;
                    } else {
                      const dateDisplay = data.eventList[0]
                        ? data.eventList[0].start
                        : data.weekList[0]
                        ? data.weekList[0].startingDate
                        : new Date();
                      return dateDisplay;
                    }
                  })()}
                  handleColor={this.handleColor}
                  dataUnassigned={unassigned}
                  currentSelectedWeek={currentSelectedWeek}
                  listUnassigned={this.listUnassigned}
                />
              </Suspense>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default GridSections;
