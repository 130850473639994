import { all, chain, isEmpty, keys, pipe, values, F, ifElse } from "ramda";
import { ErrorsByGroupId } from "../models";
import isHardError from "./isHardError";

/**
 * Returns true if at least one edition has been made and all the editions
 * triggered hard errors.
 *
 * @param errorsByGroupId ErrorsByGroupId
 *
 * @return boolean
 */
const allEditionsWithHardErrors = (errorsByGroupId: ErrorsByGroupId): boolean => {
  return pipe(values, chain(keys), ifElse(isEmpty, F, all(isHardError)))(errorsByGroupId);
};

export default allEditionsWithHardErrors;
