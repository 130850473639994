import { useState } from "react";
import { useMutation } from "react-apollo";
import {
  ClassroomBooking,
  ClassroomBookingCrudResult,
  ScheduleEvent,
  Session,
} from "@models/ISchema";
import { classroomBookingsUpdate } from "../graphql/classroomBookingsUpdate.mutation";
import { flatten, map, pipe, uniq } from "ramda";

interface Props {
  origin: string;
  scenario: string;
}

type ReturnType = [
  { result: any; error: boolean },
  (data: any, dryRun?: boolean, skipValidations?: boolean, onlyDetails?: boolean) => Promise<void>,
];

const useClassroomBookingsMutation = ({ origin, scenario }: Props): ReturnType => {
  const [classroomBookingsMutation] = useMutation(classroomBookingsUpdate, {});
  const [result, setResult] = useState<ClassroomBookingCrudResult>({});
  const [error, setError] = useState(false);

  const buildSessions = (sessions: Session[]) => {
    return pipe(
      map((session: Session) =>
        map(
          (event: ScheduleEvent) => ({ start: event?.start, end: event?.end }),
          session?.scheduleEvent,
        ),
      ),
      flatten,
      uniq,
    )(sessions ?? []);
  };

  const buildInput = (
    booking: ClassroomBooking,
    dryRun?: boolean,
    skipValidations?: boolean,
    onlyDetails?: boolean,
  ) => ({
    dryRun,
    skipValidations,
    onlyDetails,
    changesets: {
      creates: [],
      deletes: [],
      updates: [
        {
          classroomBookingId: booking?.id,
          responsibleId: booking?.responsible?.id,
          sessions: buildSessions(booking?.sessions),
          capacity: booking?.capacity,
          classroomId: booking?.classroom?.id,
          isRecurrent: booking?.isRecurrent,
          title: booking?.title,
          description: booking?.description,
          classroomBookingTypeId: booking?.type?.id,
          programId: booking?.program,
        },
      ],
    },
  });

  const submitClassroomBookingsMutation = async (
    booking: ClassroomBooking,
    dryRun?: boolean,
    skipValidations?: boolean,
    onlyDetails?: boolean,
  ) => {
    try {
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: buildInput(booking, dryRun, skipValidations, onlyDetails),
      };
      const { data: responseData } = await classroomBookingsMutation({ variables });
      setResult(responseData?.cube?.classroomBookingsCrud);
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  return [{ result, error }, submitClassroomBookingsMutation];
};

export default useClassroomBookingsMutation;
