import React, { useContext } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { Button } from "@foris/avocado-ui";
import css from "./vacancies.module.scss";
import { ContextApp, IContextApp } from "@config/Context/contextApp";

interface IFooterVacancies {
  activeEdit: boolean;
  setActiveEdit: (active: boolean) => void;
  warningsCount: number;
  canEditSomeGroup: boolean;
  onSave: (commit: boolean) => void;
  disabledConfirm: boolean;
}

const FooterVacancies: React.FC<IFooterVacancies> = (props: IFooterVacancies) => {
  const {
    activeEdit,
    setActiveEdit,
    warningsCount,
    onSave,
    disabledConfirm,
    canEditSomeGroup,
  } = props;
  const context: IContextApp = useContext(ContextApp);
  const showEditButton =
    context?.user?.permissions?.update &&
    context?.user?.abilities?.can_edit_groups_in_bundle_view &&
    !context?.user?.abilities?.can_access_group_manager &&
    canEditSomeGroup;

  const history = useHistory();

  return (
    <footer className={cx(css.footer, "container-row")}>
      {!activeEdit && (
        <>
          <Button
            typeButton={"transparent"}
            className={cx(css.footer_btn)}
            onClick={() => {
              history.goBack();
            }}
          >
            Volver
          </Button>
          {showEditButton && (
            <Button
              onClick={() => {
                setActiveEdit(true);
              }}
              className={cx(css.footer_btn)}
            >
              Editar
            </Button>
          )}
        </>
      )}
      {activeEdit && (
        <>
          <Button
            typeButton={"transparent"}
            className={cx(css.footer_btn)}
            onClick={() => {
              setActiveEdit(false);
            }}
          >
            Cancelar
          </Button>
          {warningsCount === 0 && (
            <Button
              onClick={() => {
                onSave(false);
              }}
              className={cx(css.footer_btn)}
            >
              Guardar cambios
            </Button>
          )}
          {warningsCount > 0 && (
            <Button
              disabled={disabledConfirm}
              className={cx(css.footer_btn)}
              onClick={() => {
                onSave(true);
              }}
            >
              Confirmar cambios
            </Button>
          )}
        </>
      )}
    </footer>
  );
};

export default FooterVacancies;
