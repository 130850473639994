import React, { useMemo, useState } from "react";
import { assoc, curry, has, isEmpty, lensPath, map, omit, view } from "ramda";
import { useParams } from "react-router-dom";
import cx from "classnames";
import { Button, Card, Checkbox } from "@foris/avocado-ui";
import { Campus } from "@models/ISchema";
import { AdaptedGroup } from "../../models";
import { IParams } from "@models/IParams";
import { useCreateSubgroups } from "../../hooks/useCreateSubgroup";
import css from "./subgroupsCreation.module.scss";

interface Props {
  parentGroup: AdaptedGroup;
  setDisplayCreation: (display: boolean) => void;
  subgroups: AdaptedGroup[];
  requestSubgroups: () => void;
  campuses: Campus[];
}

const SubgroupsCreation: React.FC<Props> = ({
  parentGroup,
  setDisplayCreation,
  subgroups,
  requestSubgroups,
  campuses,
}) => {
  const { scenario, origin }: IParams = useParams();
  const [{ isLoading }, submitCreations] = useCreateSubgroups({ scenario, origin });
  const [selectedCampusIds, setSelectedCampusIds] = useState<Record<Campus["id"], boolean>>({});

  const alreadyCreatedCampusesById = useMemo(() => {
    const getCampusId = view(lensPath(["campus", "id"]));
    const campusIds = map(getCampusId, subgroups ?? []);
    return new Set(campusIds);
  }, [subgroups]);

  const toggle = curry((campusId: Campus["id"], event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.checked) {
      setSelectedCampusIds(assoc(campusId, true, selectedCampusIds));
    } else {
      setSelectedCampusIds(omit([campusId], selectedCampusIds));
    }
  });

  return (
    <section className={css.main}>
      <Card.Simple className={cx("container-row", css.card)}>
        <Card.Content>
          <header className={css.card__header}>
            <h3 className={css.card__header__title}>Agregar Subgrupos</h3>
            <span className={css.card__header__subtitle}>
              Seleccione las sedes para agregar subgrupos o desmarque las sedes para eliminarlas del
              grupo principal.
            </span>
          </header>
          <ul className={css.card__campuses}>
            {campuses?.map(campus => (
              <li key={campus?.id}>
                <Checkbox
                  className={css.card__campuses__item}
                  onChange={toggle(campus?.id)}
                  checked={
                    alreadyCreatedCampusesById?.has(campus?.id) ||
                    has(campus?.id, selectedCampusIds)
                  }
                  disabled={alreadyCreatedCampusesById.has(campus?.id)}
                  labelRight={`${campus?.code} ${campus?.name}`}
                />
              </li>
            ))}
          </ul>
          <div className={css.card__bottom}>
            <span className={css.card__bottom__label}>
              {alreadyCreatedCampusesById.size} Subgrupos seleccionados
            </span>
            <div className={css.card__bottom__buttons}>
              <Button
                disabled={isLoading}
                color="primary"
                variant="outline"
                onClick={() => setDisplayCreation(false)}
              >
                Cancelar
              </Button>
              <Button
                disabled={isEmpty(selectedCampusIds) || isLoading}
                onClick={async () => {
                  await submitCreations(parentGroup, selectedCampusIds);
                  requestSubgroups();
                  setDisplayCreation(false);
                }}
              >
                Guardar cambios
              </Button>
            </div>
          </div>
        </Card.Content>
      </Card.Simple>
    </section>
  );
};

export default SubgroupsCreation;
