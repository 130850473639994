import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { componentQuery } from "../graphql/component.queries";
import { Component, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorReturn, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const ComponentTypeSelect: React.FC<ISelectorReturn> = (props: ISelectorReturn) => {
  const client = useApolloClient();
  const { onCallback, label, className, placeholder } = props;
  const { scenario }: IParams = useParams();
  const [optionsComponent, setOptionsComponent] = useState([]);
  const [valueComponent, setValueComponent] = useState(null);

  const variablesComponents = {
    query: componentQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setComponentOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optCompuses = [];
      if (dataQuery.components.items.length > 0) {
        dataQuery.components.items.forEach((component: Component) => {
          optCompuses.push({ label: component.name, value: component.id });
        });
      }
      setOptionsComponent(optCompuses);
    }
  };

  const getComponentOptions = async () => {
    try {
      const data = await client.query(variablesComponents);
      await setComponentOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCacheComponentOptions = async () => {
    try {
      const data = await client.readQuery(variablesComponents);
      await setComponentOptions(data);
    } catch (error) {
      getComponentOptions();
    }
  };

  useEffect(() => {
    if (optionsComponent.length === 0) {
      getCacheComponentOptions();
    }
  });

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Componente</label>}
      <Select
        options={optionsComponent}
        placeholder={placeholder ? placeholder : label ? "Seleccionar" : "Componente"}
        isClearable
        value={valueComponent}
        onChange={(value: IOption) => {
          if (value) {
            setValueComponent(value);
            onCallback(value);
          } else {
            setValueComponent("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default ComponentTypeSelect;
