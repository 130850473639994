import React from "react";
import { useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../../common/components/Loader/Loader";
import { IParams } from "../../../../../models/IParams";
import { QUERY_RECOMMENDATIONS } from "../formEdit.queries";
import "./recommendations.scss";

interface IRecommendationsProps {
  event: any;
}

const Recommendations: React.FC<IRecommendationsProps> = (props: IRecommendationsProps) => {
  const { event } = props;
  const { scenario, origin }: IParams = useParams();

  const { loading, error, data } = useQuery<any, any>(QUERY_RECOMMENDATIONS, {
    variables: {
      scenarioId: scenario,
      originId: origin,
      sessionId: event.sessionId,
    },
  });

  if (loading) return <Loader />;
  if (error) return null;

  const renderHtml = data && data.cube.sessionRecommendations.html;

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: renderHtml,
      }}
    />
  );
};

export default Recommendations;
