import React, { useState } from "react";
import { Program, Curriculum, Level } from "../Selectors";

export interface IProgramGroup {
  program: string;
  curriculum: string;
  level: string;
}

export interface IShiftDependencyProps {
  campus: string;
  department: string;
  modality: string;
  shift: string;
  label?: boolean;
  className?: string;
  onCallback: (value: IProgramGroup) => void;
  activeCode?: boolean;
}

const LevelDependency: React.FC<IShiftDependencyProps> = (props: IShiftDependencyProps) => {
  const { onCallback, campus, department, modality, shift, label, className, activeCode } = props;
  const [values, setValues] = useState<IProgramGroup>(null);

  const disabled = !campus || !department || !modality || !shift;

  return (
    <>
      <Program
        label={label}
        className={className}
        campus={campus}
        department={department}
        modality={modality}
        shift={shift}
        filterBy="shift"
        onCallback={value => {
          const programValues: IProgramGroup = {
            program: value ? value.value : null,
            curriculum: null,
            level: null,
          };
          setValues(programValues);
          onCallback(programValues);
        }}
      />
      <Curriculum
        label={label}
        className={className}
        program={!disabled && values ? values.program : null}
        onCallback={value => {
          const programValues: IProgramGroup = {
            program: values.program,
            curriculum: value ? value.value : null,
            level: null,
          };
          setValues(programValues);
          onCallback(programValues);
        }}
        activeCode={activeCode}
      />
      <Level
        label={label}
        className={className}
        curriculum={!disabled && values ? values.curriculum : null}
        onCallback={value => {
          const programValues: IProgramGroup = {
            program: values.program,
            curriculum: values.curriculum,
            level: value ? value.value : null,
          };
          setValues(programValues);
          onCallback(programValues);
        }}
      />
    </>
  );
};

export default LevelDependency;
