import { useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { CubeQuery, Link, Section } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { linkSection } from "../graphql/linkSection.query";

export const useLinkSection = (): [
  { data: any; link: Link; sections: Section[]; error: boolean },
  () => void,
] => {
  const { id, origin, scenario }: IParams = useParams();
  const client = useApolloClient();
  const [infoLink, setInfoLink] = useState<Link>(null);
  const [sections, setSections] = useState<Section[]>(null);
  const [dataLink, setDataLink] = useState<Section[]>(null);
  const [error, setError] = useState<boolean>(false);

  const variables = {
    resourceId: id,
    scenarioId: scenario,
    originId: origin,
    resourceType: "LINK",
  };

  const getLinkInfo = async () => {
    try {
      const { data, errors } = await client.query({
        query: linkSection,
        variables,
        fetchPolicy: "no-cache",
      });
      if (errors) {
        console.debug("[LinkHeader error]", errors);
        return null;
      }
      const cubeQuery: CubeQuery = data.cube;
      const cubeInfo = cubeQuery.editorView.info;
      const info: Link = cubeInfo.__typename === "LinkInfo" ? cubeInfo.link : null;
      const sections: Section[] = cubeQuery?.editorView?.sections || [];

      if (info) {
        setInfoLink(info);
        setSections(sections);
        setDataLink(data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  };

  return [{ data: dataLink, link: infoLink, sections, error }, getLinkInfo];
};
