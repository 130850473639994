import * as R from "ramda";
import { Classroom, Instructor } from "@models/ISchema";
import { BlockRanges, EditedSession } from "../context/formData.reducer";
import { Week } from "../context/linkData.reducer";

const toIds = R.map(R.propOr("", "id"));

/**
 * Returns true if the two given arrays of instructors have the same IDs, false
 * otherwise.
 *
 * Time complexity: O(n)
 * Where `n` is the number of elements in the `i1` or `i2` array.
 *
 * @param i1 Instructor[]
 * @param i2 Instructor[]
 * @return boolean
 */
export const sameInstructors = (i1: Instructor[], i2: Instructor[]): boolean =>
  R.equals(toIds(i1), toIds(i2));

/**
 * Returns true if the two given arrays of `checked` intervals have the same
 * IDs, false otherwise.
 *
 * Time complexity: O(n)
 * Where `n` is the number of elements in the `w1` or `w2` array.
 *
 * @param w1 Week[]
 * @param w2 Week[]
 * @return boolean
 */
export const sameIntervals = (w1: Week[], w2: Week[]): boolean => {
  const checkedIds = R.pipe(R.filter(R.prop<Week, "checked">("checked")), toIds as any);
  return R.equals(checkedIds(w1), checkedIds(w2));
};

/**
 * Returns true if the two given arrays of classrooms have the same IDs, false
 * otherwise.
 *
 * Time complexity: O(n)
 * Where `n` is the number of elements in the `c1` or `c2` array.
 *
 * @param c1 Classroom[]
 * @param c2 Classroom[]
 * @return boolean
 */
export const sameClassrooms = (c1: Classroom[], c2: Classroom[]): boolean =>
  R.equals(toIds(c1), toIds(c2));

/**
 * Returns true if the two given objects of blockRanges have the same keys and
 * values, false otherwise.
 *
 * Time complexity: O(1)
 *
 * @param b1 BlockRanges
 * @param b2 BlockRanges
 * @return boolean
 */
export const sameBlocks = (b1: BlockRanges, b2: BlockRanges): boolean => {
  if (R.eqProps("selected", b1, b2)) {
    if (R.equals("blocks", b1?.selected)) {
      return R.eqProps("blocks", b1, b2);
    } else if (R.equals("hours", b1?.selected)) {
      return R.all(R.equals(true), [
        R.equals("hours", b1?.selected),
        R.eqProps("startTime", b1, b2),
        R.eqProps("endTime", b1, b2),
      ]);
    }
  }

  return R.and(R.not(b1?.selected), R.not(b2?.selected));
};

/**
 * Returns true if the two given days of classrooms have the same IDs, false
 * otherwise.
 *
 * Time complexity: O(1)
 *
 * @param c1 Classroom[]
 * @param c2 Classroom[]
 * @return boolean
 */
export const sameDays = (d1: Day, d2: Day): boolean => R.equals(d1, d2);

/**
 * Returns true if the two given sessions have the same resources (instructors,
 * blocks, classrooms and intervals), false otherwise.
 *
 * Time complexity: O(classrooms + instructors + intervals)
 * Where
 *   - `classrooms` is the number of elements of the longest s1.classrooms or s2.classrooms array
 *   - `instructors` is the number of elements of the longest s1.instructors or s2.instructors array
 *   - `intervals` is the number of elements of the longest s1.intervals or s2.intervals array
 *
 * @param s1 EditedSession
 * @param s2 EditedSession
 * @return boolean
 */
export const sessionsHaveTheSameResources = (s1: EditedSession, s2: EditedSession): boolean =>
  R.all(R.equals(true), [
    sameBlocks(R.propOr({}, "blocks", s1), R.propOr({}, "blocks", s2)),
    sameDays(
      (R.view(R.lensPath(["blocks", "day"]), s1) as unknown) as Day,
      (R.view(R.lensPath(["blocks", "day"]), s2) as unknown) as Day,
    ),
    sameClassrooms(R.propOr([], "classrooms", s1), R.propOr([], "classrooms", s2)),
    sameInstructors(R.propOr([], "instructors", s1), R.propOr([], "instructors", s2)),
    sameIntervals(R.propOr([], "intervals", s1), R.propOr([], "intervals", s2)),
  ]);
