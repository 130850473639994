import gql from "graphql-tag";

export const subjectSearch = gql`
  query groupsManagerData($scenarioId: ID!, $originId: ID!, $filter: SubjectFilterInput!) {
    data(scenarioId: $scenarioId, originId: $originId) {
      groupsManagerSubjects(filter: $filter) {
        items {
          id
          code
          name
          credits
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
        __typename
      }
      __typename
    }
  }
`;
