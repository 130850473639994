import React, { useState, useEffect, useContext, useCallback } from "react";
import { map, prepend } from "ramda";
import { useApolloClient } from "react-apollo";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import { SelectPagination } from "@foris/avocado-ui";
import { Subject } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { Context } from "../../context/GroupsManagerContext";
import { Types } from "../../context/filters.reducer";
import { subjectSearch } from "../../graphql/subjectSearch.query";
import { SelectableOption } from "../../models";

const SubjectSelector: React.FC = () => {
  const { state, dispatch } = useContext(Context);
  const client = useApolloClient();
  const allSubjectsOption = { id: "*", value: "*", label: "Buscar o seleccionar", self: null };
  const [selectedSubject, setSelectedSubject] = useState<SelectableOption<Subject>>(
    Boolean(state?.filters?.subject)
      ? {
          id: state?.filters?.subject?.id,
          value: state?.filters?.subject?.id,
          label: `${state?.filters?.subject?.code} | ${state?.filters?.subject?.name}`,
          self: state?.filters?.subject,
        }
      : allSubjectsOption,
  );
  const { origin, scenario }: IParams = useParams();
  const [, setSearchTerm] = useState("");
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const params = queryString.parse(location.search);

  useEffect(() => {
    dispatch({ type: Types.SetSubject, payload: selectedSubject?.self });
    dispatch({ type: Types.SetCampus, payload: null });
  }, [selectedSubject]);

  /**
   * Clean the selection if the filters are updated from outside this component
   * (in particular, with the context's action `CleanFilters`).
   */
  useEffect(() => {
    if (!state?.filters?.subject) setSelectedSubject(allSubjectsOption);
  }, [state?.filters?.subject]);

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const variables = {
          query: subjectSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filterId: params?.advance,
            filter: {
              pagination: { page, size, searchTerm },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery = data?.data;
        const pageInfo = dataQuery?.groupsManagerSubjects?.pageInfo;
        const subjects = dataQuery?.groupsManagerSubjects?.items;

        const options = map(
          (subject: Subject) => ({
            id: subject?.id,
            value: subject?.id,
            label: `${subject?.code} | ${subject?.name}`,
            self: subject,
          }),
          subjects ?? [],
        );

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client, page],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: newSearchPage === 1 ? prepend(allSubjectsOption, options) : options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  return (
    <SelectPagination
      label="Asignatura"
      isDisabled={false}
      value={selectedSubject}
      onInputChange={setSearchTerm}
      onChange={setSelectedSubject}
      loadOptions={loadOptions}
    />
  );
};

export default SubjectSelector;
