import React, { useState, useEffect, useContext } from "react";
import { useApolloClient, useMutation } from "react-apollo";
import { useHistory, useParams } from "react-router-dom";
import { Button, Input, Select, Pager } from "@foris/avocado-ui";
import cx from "classnames";
import Layout from "../../../../common/layout/Layout";
import BookingTable from "./BookingTable";
import { ClassroomBookingPage, ClassroomBooking } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import { classroomBookings } from "../../graphql/bookings.query";
import { classroomBookingChangeStatusMutation } from "../../graphql/classroomBookingChangeStatus.mutation";
import { getFields, getChangeStatusFields } from "./bookingFilterVariables";
import { Types } from "../../context/search.reducer";
import { BookingContext } from "@modules/booking/context/BookingContext";
import css from "./bookingList.module.scss";

interface ISelect {
  label: string;
  value: string;
}

const BookingList = () => {
  const { workspace, origin, scenario }: IParams = useParams();
  const { dispatch } = useContext(BookingContext);
  const [filterBooking, setFilterBooking] = useState<string>("");
  const [bookings, setBookings] = useState<Array<ClassroomBooking>>([]);
  const [bookingsSelectedIds, setBookingSelectedIds] = useState<Array<string>>([]);
  const [classroomBookingChangeStatus] = useMutation(classroomBookingChangeStatusMutation, {});
  const [selectedAction, setSelectedAction] = useState<ISelect>(null);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalBookings, setTotalBookings] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState([]);

  const sizePage = 10;
  const actionOptions = [
    { label: "Aprobar", value: "confirmed" },
    { label: "Rechazar", value: "rejected" },
  ];

  const client = useApolloClient();

  const getBookings = async (orderByFromParams = []) => {
    if (orderByFromParams) setOrderBy(orderByFromParams);
    const currentOrderBy = orderByFromParams.length ? orderByFromParams : orderBy;

    const variables = {
      originId: origin,
      scenarioId: scenario,
      filter: getFields({ filterBooking, page: currentPage, sizePage, orderBy: currentOrderBy }),
    };
    try {
      setLoading(true);
      const { data } = await client.query({
        query: classroomBookings,
        variables,
        fetchPolicy: "no-cache",
      });
      const response: ClassroomBookingPage = data?.cube?.classroomBookings;
      setBookings([...response?.items] || []);
      setTotalBookings(response.pageInfo.total);
      setPendingCount(response.pendingCount);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeStatus = async (event: any) => {
    if (event) {
      setSelectedAction(event);
      const status = event.value;
      try {
        const response = await classroomBookingChangeStatus({
          variables: {
            originId: origin,
            scenarioId: scenario,
            input: getChangeStatusFields({
              bookingIds: bookingsSelectedIds,
              status,
              comment: null,
            }),
          },
        });
        const changedBookings: Array<ClassroomBooking> =
          response.data.cube.classroomBookingChangeStatus.bookings;
        const newBookings = bookings.map(booking => {
          if (changedBookings.some(item => item.id === booking.id)) {
            booking.status = status;
          }
          return booking;
        });
        setBookings([...newBookings]);
        setPendingCount(pendingCount - bookingsSelectedIds.length);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const contextUrl = `${workspace}/${scenario}/${origin}/`;

  useEffect(() => {
    dispatch({ type: Types.ClearSearch, payload: null });
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      getBookings(orderBy);
      setBookingSelectedIds([]);
    }, 800);
    return () => clearTimeout(delay);
  }, [filterBooking, currentPage]);

  return (
    <Layout contextSearch={false}>
      <section className={cx(css.cntList)}>
        <h2 className={cx(css.cntList_title, "container-row")}>Reservas</h2>
        <Button onClick={() => history.push(`/booking/search/${contextUrl}`)}>
          Nueva Reservación
        </Button>
        <article className={cx(css.detaialList, "container-row")}>
          <header className={cx(css.header, "container-row", "row--between")}>
            <p className={cx(css.header_count, "col")}>
              {`${totalBookings} Reservaciones | ${pendingCount} Pendientes`}{" "}
            </p>
            <div className={cx("col", "container-row", "row--right", "row_align--center")}>
              <p
                className={cx(css.header_text)}
              >{`${bookingsSelectedIds.length} Reservaciones seleccionadas`}</p>
              <Select
                className={cx(css.header_select)}
                placeholder="Elige acción"
                value={selectedAction}
                options={actionOptions}
                isDisabled={bookingsSelectedIds.length == 0}
                onChange={handleChangeStatus}
              />
            </div>
          </header>
          <section className={cx(css.cntTable, "container-row")}>
            <div className="col_2">
              <Input
                placeholder="Filtrar resultados"
                className={cx(css.cntTable_input)}
                value={filterBooking}
                error={
                  filterBooking.length <= 3 && filterBooking.length !== 0
                    ? "Debe contener al menos 4 caracteres"
                    : null
                }
                onChange={event => {
                  setCurrentPage(1);
                  setFilterBooking(event.target.value);
                }}
              />
            </div>
            <div className={cx("row-container", "col_8")}>
              <Pager
                page={currentPage}
                size={sizePage}
                total={totalBookings}
                onPageChange={(page: number) => {
                  setCurrentPage(page);
                }}
              />
            </div>
            <BookingTable
              loading={loading}
              bookings={bookings}
              bookingsSelectedIds={bookingsSelectedIds}
              getBookings={getBookings}
              setBookingSelectedIds={item => {
                if (item.length === 0) {
                  setSelectedAction(null);
                }
                setBookingSelectedIds(item);
              }}
            />
            <div className={cx("row-container", "col_12", css.paginator)}>
              <Pager
                page={currentPage}
                size={sizePage}
                total={totalBookings}
                onPageChange={(page: number) => {
                  setCurrentPage(page);
                }}
              />
            </div>
          </section>
        </article>
      </section>
    </Layout>
  );
};

export default BookingList;
