import * as R from "ramda";
import { BlockRanges, EditedSession, Assignment } from "../context/formData.reducer";
import { Week } from "../context/linkData.reducer";
import {
  sameInstructors,
  sameClassrooms,
  sameIntervals,
  sameBlocks,
} from "./sessionsHaveTheSameResources";
import { Classroom, Instructor, Section, Session } from "@models/ISchema";

type EditedSessionLensType =
  | string
  | boolean
  | BlockRanges
  | Instructor[]
  | Classroom[]
  | Week[]
  | Session
  | Section;

type CompareSessionsOutputType = {
  editedSessions: EditedSession;
  assignmentSame: Assignment;
};

/**
 * Returns true if all the given sessions have the same `blocks`, false
 * otherwise.
 *
 * Time complexity: O(n)
 * Where `n` is the number of sessions in the given EditedSession[].
 *
 * @param EditedSession[]
 * @return boolean
 */
export const sessionsHaveTheSameBlocks = R.pipe(
  R.map(R.propOr({}, "blocks")),
  R.reduce(
    ({ eq, prev }, curr) => ({ eq: !eq || !prev ? eq : sameBlocks(prev, curr), prev: curr }),
    { eq: true, prev: null },
  ),
  R.prop("eq"),
);

/**
 * Returns true if all the given sessions have the same `days`,
 * false otherwise.
 *
 * Time complexity: O(n)
 * Where `n` is the number of sessions in the given EditedSession[].
 *
 * @param EditedSession[]
 * @return boolean
 */
export const sessionsHaveTheSameDay = R.pipe(
  R.map(R.propOr({}, "blocks")),
  R.reduce(
    ({ eq, prev }, curr) => ({ eq: !eq || !prev ? eq : R.eqProps("day", prev, curr), prev: curr }),
    { eq: true, prev: null },
  ),
  R.prop("eq"),
);

export const sessionsHaveTheSameClassrooms = R.pipe(
  R.map(R.propOr([], "classrooms")),
  R.reduce(
    ({ eq, prev }, curr) => ({
      eq: !eq || !prev ? eq : sameClassrooms(prev, (curr as unknown) as Classroom[]),
      prev: curr,
    }),
    { eq: true, prev: null },
  ),
  R.prop("eq"),
);

/**
 * Returns true if all the given sessions have the same `instructors`,
 * false otherwise.
 *
 * Time complexity: O(n)
 * Where `n` is the number of sessions in the given EditedSession[].
 *
 * @param EditedSession[]
 * @return boolean
 */
export const sessionsHaveTheSameInstructors = R.pipe(
  R.map(R.propOr([], "instructors")),
  R.reduce(
    ({ eq, prev }, curr) => ({
      eq: !eq || !prev ? eq : sameInstructors(prev, (curr as unknown) as Instructor[]),
      prev: curr,
    }),
    { eq: true, prev: null },
  ),
  R.prop("eq"),
);

/**
 * Returns true if all the given sessions have the same checked `intervals`,
 * false otherwise.
 *
 * Time complexity: O(n)
 * Where `n` is the number of sessions in the given EditedSession[].
 *
 * @param EditedSession[]
 * @return boolean
 */
export const sessionsHaveTheSameCheckedIntervals = R.pipe(
  R.map<EditedSession, Week[]>(R.propOr([], "intervals")),
  R.reduce(
    ({ eq, prev }, curr) => ({
      eq: !eq || !prev ? eq : sameIntervals(prev, curr),
      prev: curr,
    }),
    { eq: true, prev: null },
  ),
  R.prop("eq"),
);

/**
 * Given an EditedSession[] array, returns an `Assignment` object checking the
 * equality of each session resource against the others.
 *
 * Time complexity: O(n)
 * Where `n` is the number of sessions in the given EditedSession[].
 *
 * @param EditedSession[]
 * @return Assignment
 */
export const buildAssignmentSame = R.applySpec<Assignment>({
  blocks: sessionsHaveTheSameBlocks,
  day: sessionsHaveTheSameDay,
  instructors: sessionsHaveTheSameInstructors,
  classrooms: sessionsHaveTheSameClassrooms,
  intervals: sessionsHaveTheSameCheckedIntervals,
});

/**
 * Given an Assignment object, an EditedSession[] array, and a Week[] array,
 * return an EditedSession object.
 *
 * Time complexity: O(n)
 * Where `n` is the number of weeks in the given Week[] array.
 *
 * @param assignments Assignment
 * @param sessions EditedSession[]
 * @param linkWeeks Week[]
 * @return EditedSession
 */
const buildEditedSessions = (
  assignments: Assignment,
  sessions: EditedSession[],
  linkWeeks: Week[],
): EditedSession => {
  // lenses
  const blocks = R.lensProp<EditedSession>("blocks");
  const day = R.lensPath<EditedSession>(["blocks", "day"]);
  const selected = R.lensPath<EditedSession>(["blocks", "selected"]);
  const instructors = R.lensProp<EditedSession>("instructors");
  const classrooms = R.lensProp<EditedSession>("classrooms");
  const intervals = R.lensProp<EditedSession>("intervals");

  // helpers functions
  const lensOrNull = R.ifElse(R.always(R.isEmpty(sessions)), R.always(null), R.identity);
  const firstSession = (lens: R.Lens<EditedSession, EditedSessionLensType>) =>
    R.view(R.compose(R.lensIndex(0), lens), sessions);

  // new resources
  const newBlocks = lensOrNull(firstSession(blocks));
  const newDay = lensOrNull(firstSession(day));
  const newSelected = lensOrNull(firstSession(selected));
  const newInstructors = lensOrNull(firstSession(instructors));
  const newClassrooms = lensOrNull(firstSession(classrooms));
  const newIntervals = lensOrNull(firstSession(intervals));

  // handlers
  const setBlocks = R.when(
    R.always(assignments.blocks),
    R.pipe(R.set(blocks, newBlocks), R.set(day, null)),
  );
  const setDay = R.when(
    R.always(assignments.day),
    R.pipe(R.set(day, newDay), R.set(selected, newSelected)),
  );
  const setInstructors = R.when(
    R.always(assignments.instructors),
    R.set(instructors, newInstructors),
  );
  const setClassrooms = R.when(R.always(assignments.classrooms), R.set(classrooms, newClassrooms));
  const setIntervals = R.ifElse(
    R.always(assignments.intervals),
    R.set(intervals, newIntervals),
    R.set(intervals, R.map(R.assoc("checked", false), linkWeeks)),
  );

  // build and return editedSessions
  return R.pipe(setBlocks, setDay, setInstructors, setClassrooms, setIntervals)({});
};

/**
 * Given an EditedSession[] array and a Week[] array, build and return an
 * object with the following structure:
 * {
 *   editedSessions: EditedSession,
 *   assignmentSame: Assignment
 * }
 *
 * Time complexity: O(n)
 * Where `n` is the max number between the EditedSession[] length and the Week[] length.
 *
 * @param sessions EditedSession[]
 * @param linkWeeks Week[]
 * @return CompareSessionsOutputType
 */
export const compareSessions = (
  sessions: EditedSession[],
  linkWeeks: Week[],
): CompareSessionsOutputType => {
  const assignmentSame = buildAssignmentSame(sessions);
  const editedSessions = buildEditedSessions(assignmentSame, sessions, linkWeeks);
  return {
    editedSessions,
    assignmentSame,
  };
};
