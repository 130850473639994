import React, { useContext } from "react";
import cx from "classnames";
import { Icon } from "@foris/avocado-ui";
import { AppContext } from "../../context/EditSessionsContext";
import { EditedSessions } from "../../context/formData.reducer";
import { Session, Section } from "@models/ISchema";
import SessionBox from "@components/SessionBox/SessionBox";
import css from "./accordion.module.scss";

interface IAccordionTagProps {
  /** 1 - 99  */
  color: number;
  /** object session */
  session?: Session;
  /** section if session */
  section?: Section;
  /** object EditedSession */
  editedSession?: EditedSessions;
  /** S01-S02 */
  weeks: string;
  /** Lun 10:00 - 12:00 */
  date: string;
  /** type of tag (color)  */
  selected?: boolean;
  /** session has been edited */
  edited?: boolean;
  /** session has been deleted */
  deleted?: boolean;
  /** function of click  */
  onClick?: (session: Session, editedSession: EditedSessions, section: Section) => void;
  icon:
    | "repeat"
    | "check"
    | "close"
    | "circle-plus"
    | "trash"
    | "circle-full-check"
    | "circle-full-error";
}

const AccordionTag: React.FC<IAccordionTagProps> = (props: IAccordionTagProps) => {
  const { state } = useContext(AppContext);
  const {
    color,
    session,
    weeks,
    date,
    edited,
    selected,
    icon,
    editedSession,
    section,
    deleted,
  } = props;
  let sessionsLabel = "";
  if (session) sessionsLabel = session?.id;
  if (editedSession?.id) sessionsLabel = editedSession?.id;
  if (editedSession?.isNew || editedSession?.isCloned) sessionsLabel = "Nueva sesión";

  return (
    <div className={cx(css.item)}>
      <span
        className={cx(css.item_icon, {
          [css.accordionPanel_icon__repeat]: icon === "repeat",
          [css.accordionPanel_icon__check]: icon === "check" || icon === "circle-full-check",
          [css.accordionPanel_icon__close]: icon === "close" || icon === "circle-full-error",
          [css.accordionPanel_icon__plus]: icon === "circle-plus",
          [css.accordionPanel_icon__trash]: icon === "trash",
        })}
      >
        {((state?.page?.active?.form && (edited || deleted)) || state?.page?.active?.results) && (
          <Icon size={14} icon={icon} />
        )}
      </span>
      <SessionBox
        onClick={() => props.onClick && props.onClick(session, editedSession, section)}
        inverse={!selected}
        colorIndex={color || 0}
        deleted={deleted}
        className={cx(
          css.item_box,
          deleted ? css.item_box_deleted : "",
          deleted && selected ? css.item_box_deleted_background : "",
        )}
      >
        <p className={cx(css.text, deleted ? css.text_deleted : "")}>
          <span className={cx(css.text_bold)}>
            {" "}
            {`${sessionsLabel || ""} ${weeks ? "|" : ""} `}
          </span>
          {`${weeks || ""}`}
        </p>
        <p className={cx(css.text, deleted ? css.text_deleted : "")}>{date || ""}</p>
      </SessionBox>
    </div>
  );
};

export default AccordionTag;
