import React, { useState, useEffect, useContext } from "react";
import * as R from "ramda";
import cx from "classnames";
import { Checkbox } from "@foris/avocado-ui";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
} from "react-accessible-accordion";
import { AppContext } from "../../context/EditSessionsContext";
import AccordionSessionsBySection from "./AccordionSessionsBySection";
import { Session, Section } from "@models/ISchema";
import { Types, EditedSession } from "../../context/formData.reducer";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import css from "./accordion.module.scss";

interface AccordionSectionsProps {
  onCompareBySession: (value: Session) => void;
  onCompareBySection: (
    sessions: Session[],
    section: Section,
    checked: boolean,
    newSessions: EditedSession[],
  ) => boolean;
}

const AccordionSections = (props: AccordionSectionsProps) => {
  const { state, dispatch } = useContext(AppContext);
  const contextApp: IContextApp = useContext(ContextApp);
  const [orderedSections, setOrderedSections] = useState(null);
  const [allSessionsChecked, setAllSessionsChecked] = useState(false);

  if (!state?.link?.sections) return null;

  const userCanCreate =
    contextApp?.user?.permissions?.create &&
    contextApp?.user?.abilities?.can_edit_links_and_sections &&
    contextApp?.user?.abilities?.can_edit_or_delete_sessions;
  const allExpanded = orderedSections?.map((_: Section, index: number) => index.toString());

  useEffect(() => {
    if (!state?.link?.sections?.length) return;
    setOrderedSections(R.sortBy(R.view(R.lensPath(["component", "code"])), state?.link?.sections));
  }, []);

  const handleAllSessionsCheckedClick = () => {
    dispatch({
      type: Types.ToggleAllSessionsSelection,
      payload: { link: state?.link, currentCheckbox: allSessionsChecked },
    });
    setAllSessionsChecked(!allSessionsChecked);
  };

  if (!orderedSections?.length) return <></>;

  return (
    <Accordion
      preExpanded={allExpanded}
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className={cx(css.accordion)}
    >
      {(orderedSections?.length ?? 0) > 1 && (
        <AccordionItem className={cx(css.accordionItem)}>
          <AccordionItemHeading className={cx(css.accordionHeader)}>
            <AccordionItemButton
              className={cx("container-row", "row--between", "row_align--start")}
            >
              <Checkbox
                labelRight={
                  <p className={css.accordionHeader_labelCheckbox}>
                    <span className={css.regular}>Seleccionar todo</span>
                  </p>
                }
                className={cx(css.accordionHeader_checkbox)}
                checked={allSessionsChecked}
                onClick={e => e.stopPropagation()}
                onChange={handleAllSessionsCheckedClick}
              />
            </AccordionItemButton>
          </AccordionItemHeading>
        </AccordionItem>
      )}

      <AccordionSessionsBySection
        sections={orderedSections}
        setAllSessionsChecked={setAllSessionsChecked}
        onCompareBySession={props.onCompareBySession}
        onCompareBySection={props.onCompareBySection}
        userCanCreate={userCanCreate}
      />
    </Accordion>
  );
};

export default AccordionSections;
