import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Button } from "@foris/avocado-suite";
import Modal from "../../components/modal/Modal";
import ScenariosSelect from "./contextSelect/ScenariosSelect";
import OriginSelect from "./contextSelect/OriginsSelect";
import WorkspaceSelect from "./contextSelect/WorkspaceSelect";
import css from "./context-modal.module.scss";

interface ISelectValue {
  value: string;
  label: string;
}

interface ISelectOptions {
  value: ISelectValue;
  options: any;
}

interface IContextModalProps {
  open: boolean;
  onClose: () => void;
  workspace: ISelectOptions;
  scenario: ISelectOptions;
  origin: ISelectOptions;
  onSave: any;
}

const ContextModal: React.FC<IContextModalProps> = (props: IContextModalProps) => {
  const [open, setChange] = useState(props.open);
  const [workspaceValue, setWorkspace] = useState(props.workspace?.value);
  const [scenarioValue, setScenario] = useState(props.scenario?.value);
  const [originValue, setOrigin] = useState(props.origin?.value || null);
  const [showButtonLanguage, setShowButtonLanguage] = useState<boolean>(false);

  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const queryParms = new URLSearchParams(location.search);
    const flagLanguage = queryParms.get("flagLang");

    if (flagLanguage?.length > 0) {
      setShowButtonLanguage(true);
    }
  }, []);

  useEffect(() => {
    if (open !== props.open) setChange(props.open);
  }, [open, props.open]);

  const changeLanguage = () => {
    i18n.changeLanguage("en");
  };

  /* const validateData = (): void => {
    const { workspace, scenario, origin } = props;
    const newWorkspace = workspaceValue ? workspaceValue.value : null;
    if (workspace.value.value !== newWorkspace) setWorkspace(workspace?.value);
    const newScenario = scenarioValue ? scenarioValue.value : null;
    if (scenario.value.value !== newScenario) setScenario(scenario?.value);
    const newOrigin = originValue ? originValue?.value : null;
    if (origin?.value?.value !== newOrigin) setOrigin(origin?.value);
  };
 */
  let activeSave = false;
  if (workspaceValue && scenarioValue && originValue) activeSave = true;

  return (
    <Modal
      title={t("context-modal.title")}
      show={open}
      /* onClose={(): void => {
        validateData();
        props.onClose();
      }} */
      headerColor="#294870"
    >
      <div className={css.context_content}>
        <div className={css.context_select}>
          <div className={css.input_select}>
            <label>{t("context-modal.atmosphere")}</label>
            <WorkspaceSelect
              workspaceValue={workspaceValue}
              onChange={(value: any): void => {
                setWorkspace(value);
                setScenario(null);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>{t("context-modal.scenery")}</label>
            <ScenariosSelect
              workspaceValue={workspaceValue}
              scenarioValue={scenarioValue}
              onChange={(value: any): void => {
                setScenario(value);
                setOrigin(null);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>{t("context-modal.process")}</label>
            <OriginSelect
              scenarioValue={scenarioValue}
              originValue={originValue}
              onChange={(value: any) => setOrigin(value)}
            />
          </div>
        </div>
        <footer
          className={cx(css.context_form, showButtonLanguage && css.context_form__btnTranslation)}
        >
          {showButtonLanguage && (
            <Button onClick={changeLanguage}>{t("context-modal.btn-change-language")}</Button>
          )}
          <Button
            disabled={!activeSave}
            onClick={(): void => {
              props.onClose();
              props.onSave({
                context: {
                  workspace: workspaceValue,
                  scenario: scenarioValue,
                  origin: originValue,
                },
              });
            }}
          >
            {t("context-modal.btn-save")}
          </Button>
        </footer>
      </div>
    </Modal>
  );
};

export default ContextModal;
