import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  ap,
  assoc,
  concat,
  equals,
  filter,
  has,
  isEmpty,
  keys,
  lensProp,
  map,
  not,
  or,
  pipe,
  prop,
  propOr,
  reduce,
  reject,
  set,
  sortBy,
} from "ramda";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "@models/IParams";
import { Loading } from "@foris/avocado-ui";
import { Context } from "../context/PackagesContext";
import { CustomPackage } from "../context/types";
import { Types as DataTypes } from "../context/data.reducer";
import { Types as PageTypes } from "../context/page.reducer";
import LinkHeader from "../components/LinkHeader";
import usePackageLinkAssignment from "../hooks/usePackageLinkAssignment";
import usePackagesCrud from "../hooks/usePackagesCrud";
import { useGetLink } from "../hooks/useGetLinks";
import CreationForm from "./CreationForm";
import PackagesEdition, { linkPackagesClash } from "./PackagesEdition";
import { key } from "../utils";
import { PackageLinkAssignmentResult, PackagePayload } from "@models/ISchema";

const PackagesApp = () => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { state, dispatch } = useContext(Context);
  const [linkData, getLinkData] = useGetLink();
  const [evaluateSubmitionResult, setEvaluateSubmitionResult] = useState(false);
  const [updateLinkData, setUpdateLinkData] = useState(false);
  const [evaluatePackageLinkAssignmentResult, setEvaluatePackageLinkAssignmentResult] = useState(
    false,
  );
  const [linkPackages, setLinkPackages] = useState<CustomPackage[]>([]);
  const [resultPackagesCrud, submitPackagesCrud] = usePackagesCrud({ scenario, origin });
  const [resultPackageLinkAssignment, submitPackageLinkAssignment] = usePackageLinkAssignment({
    scenario,
    origin,
  });
  const [linkPackagesErrors, setLinkPackagesErrors] = useState<{
    [key: string]: boolean;
  }>({});
  const [linkPackagesClash, setLinkPackagesClash] = useState<linkPackagesClash>({});
  const history = useHistory();

  /**
   * Execute both `packageCrud` and `packageLinkAssignment`, if necessary. At
   * least one of them will be executed.
   */
  const onSave = async (dryRun: boolean, skipValidations: boolean) => {
    const { creations, deletions, assignments } = state?.data;
    const [someCreation, someDeletion, someAssignment] = ap(
      [pipe(isEmpty, not)],
      [creations, deletions, assignments],
    );

    dispatch({ type: PageTypes.SetLoading, payload: true });

    if (someCreation) {
      await submitPackagesCrud(state?.data, dryRun, skipValidations);
      setEvaluateSubmitionResult(true);
    }

    if (someDeletion || someAssignment) {
      await submitPackageLinkAssignment(state?.data, dryRun, skipValidations);
      setEvaluatePackageLinkAssignmentResult(true);
    }
  };

  /**
   * Submit a packageCrud request and force the evaluation of the submition.
   */
  const onPackageCrudSubmit = useCallback(
    async (dryRun: boolean, skipValidations: boolean) => {
      await submitPackagesCrud(state?.data, dryRun, skipValidations);
      setEvaluateSubmitionResult(true);
    },
    [state?.data],
  );

  /**
   * Submit a packageLinkAssignment request and force the evaluation of the submition.
   */
  const onPackageLinkAssignmentSubmit = async (dryRun: boolean, skipValidations: boolean) => {
    await submitPackageLinkAssignment(state?.data, dryRun, skipValidations);
    setEvaluatePackageLinkAssignmentResult(true);
  };

  /**
   * If no `linkData` has been defined just yet or `updateLinkData = true, get
   * and update the `linkData`.
   */
  useEffect(() => {
    if (linkData == null || updateLinkData) {
      getLinkData();
      setUpdateLinkData(false);
    }
  }, [updateLinkData]);

  /**
   * If no there's no `state?.data?.link`, set it in the context.
   */
  useEffect(() => {
    if (Boolean(state?.data?.link)) {
      setLinkPackages(sortBy(prop("index"), linkData?.packages ?? []));
      dispatch({ type: PageTypes.SetLoading, payload: false });
    }
  }, [state?.data?.link]);

  /**
   * Handle a `resultPackagesCrud` request.
   *
   * If the request wasn't commited we only will update the `linkPackages`. If
   * it was commited, we will also request the `linkData` again and clean the
   * `creations` from the context.
   */
  useEffect(() => {
    const { creations } = state?.data;
    if (
      !evaluateSubmitionResult ||
      !Boolean(resultPackagesCrud) ||
      isEmpty(resultPackagesCrud?.result ?? {}) ||
      isEmpty(creations)
    ) {
      return;
    }

    setEvaluateSubmitionResult(false);

    if (!resultPackagesCrud?.result?.commited) {
      // replace the `creation` rows for the real ones
      // `currentLinkPackages` is used to filter the new packages that are
      // already created. This is important because if we don't do it, this
      // useEffect will enter in an infinite loop...
      const currentLinkPackages = reduce(
        (acc, item: CustomPackage) => assoc(key(item?.code, state?.data?.link?.id), true, acc),
        {},
        linkPackages,
      );

      const newPackages: CustomPackage[] = pipe(
        map(pipe(prop("package"), set(lensProp<any>("isNew"), true))),
        reject((item: CustomPackage) => {
          return key(item?.code, state?.data?.link?.id) in currentLinkPackages;
        }),
      )(resultPackagesCrud?.result?.payload?.creates);

      if (!isEmpty(newPackages)) {
        setLinkPackages(concat(linkPackages, newPackages));
      }

      return;
    }

    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **
    // At this point we can assume that the request was commited
    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **

    setUpdateLinkData(true);

    dispatch({ type: DataTypes.CleanCreations });
  }, [resultPackagesCrud]);

  const assignReplicatedPackages = (packagePayload: PackagePayload[] = []) => {
    const payload = [];

    packagePayload.forEach(pkg => {
      payload.push({ package: pkg?.package, linkId: pkg?.link?.id });
    });

    dispatch({ type: DataTypes.AddAssignments, payload });
  };

  /**
   * Handle `packageLinkKey` request.
   *
   * If the request wasn't committed we will update the `linkPackages` and the
   * `linkPackagesErrors` if necessary. If it was commited, we will also request
   * the `linkData` again and clean the `assignments` and `deletions` from the context.
   */
  useEffect(() => {
    const { assignments, deletions } = state?.data;

    const result = resultPackageLinkAssignment?.result as PackageLinkAssignmentResult;

    if (
      result == null ||
      !evaluatePackageLinkAssignmentResult ||
      (isEmpty(assignments) && isEmpty(deletions))
    ) {
      return;
    }

    setEvaluatePackageLinkAssignmentResult(false);

    if (!result?.commited) {
      const linkId = state?.data?.link?.id;
      const creates = result?.payload?.creates ?? [];
      const canSkipValidations = result?.userCanSkipValidations;

      if (creates?.length) {
        assignReplicatedPackages(creates);
      }

      const currentLinkPackages = reduce(
        (acc, item: CustomPackage) => assoc(key(item?.id, linkId), true, acc),
        {},
        linkPackages,
      );
      const newPackages: CustomPackage[] = pipe(
        map(pipe(prop("package"), set(lensProp<any>("isNew"), true))),
        reject((item: CustomPackage) => {
          return key(item?.id, linkId) in currentLinkPackages;
        }),
      )(creates);

      // define rows with errros
      setLinkPackagesClash({});
      const errors = [...creates]
        .filter((payload: PackagePayload) => !!payload?.validationErrors?.length)
        .reduce((acc: { [key: string]: boolean }, payload: PackagePayload) => {
          if (payload.validationErrors[0].__typename === "ClashesBetweenPackages") {
            setLinkPackagesClash(state => ({
              ...state,
              [key(payload?.package?.id, linkId)]: canSkipValidations ? "warning" : "error",
            }));

            return acc;
          }

          return {
            ...acc,
            [key(payload?.package?.id, linkId)]: true,
          };
        }, {});

      setLinkPackagesErrors(errors);

      if (!isEmpty(newPackages)) {
        setLinkPackages(concat(linkPackages, newPackages));
      }

      return;
    }

    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **
    // At this point we can assume that the request was commited
    // *** --- *** --- *** --- *** --- *** --- *** --- *** --- **

    setUpdateLinkData(true);

    dispatch({ type: DataTypes.CleanAssignments });
    dispatch({ type: DataTypes.CleanDeletions });
  }, [resultPackageLinkAssignment]);

  /**
   * If new `linkData` was requested, update it in the context.
   */
  useEffect(() => {
    if (Boolean(linkData)) {
      dispatch({ type: DataTypes.SetLink, payload: linkData });
      dispatch({ type: PageTypes.SetLoading, payload: false });
    }
  }, [linkData]);

  /**
   * If any `creations` is deleted, update the `linkPackages` removing the
   * deleted package
   */
  useEffect(() => {
    if (state?.data?.creations == null) return;

    const { creations, assignments } = state?.data;
    const linkId = state?.data?.link?.id;

    const oldLinkPackages = reject(propOr(false, "isNew"), linkPackages);
    const newLinkPackages = pipe(
      filter(propOr(false, "isNew")),
      filter((pack: any) => {
        const populationLinkKey = key(pack?.population?.code, linkId);
        const packageLinkKey = key(pack?.id, linkId);
        return or(has(populationLinkKey, creations), has(packageLinkKey, assignments));
      }),
    )(linkPackages);

    const linkPackagesToSet = concat(oldLinkPackages, newLinkPackages);

    // remove the linkPackagesErrors that were removed from the assignments
    const currentLinkPackagesKeys = pipe(
      filter(propOr(false, "isNew")),
      map(prop<"id">("id")),
      reduce((acc, id: string) => assoc(key(id, linkId), true, acc), {}),
    )(linkPackagesToSet);
    const newLinkPackagesErrors = reduce(
      (acc, pair: string) => {
        if (has(pair, currentLinkPackagesKeys)) acc[pair] = true;
        return acc;
      },
      {},
      keys(linkPackagesErrors) as string[],
    );
    const newLinkPackagesClash = Object.keys(linkPackagesClash).reduce((acc, pair: string) => {
      if (pair in currentLinkPackagesKeys) {
        acc[pair] = linkPackagesClash[pair];
      }

      return acc;
    }, {});

    setLinkPackages(linkPackagesToSet);
    setLinkPackagesErrors(newLinkPackagesErrors);
    setLinkPackagesClash(newLinkPackagesClash);
  }, [state?.data?.creations, state?.data?.assignments]);

  if (state?.page?.loading) return <Loading />;

  if (!!state?.data?.link?.course && !state?.data?.link?.course?.generatesPackages) {
    const routeBundle = `/editor/vacancies/${workspace}/${scenario}/${origin}/${state?.data?.link?.bundle?.id}`;
    history.replace(routeBundle);
  }

  return (
    <>
      {resultPackageLinkAssignment?.isLoading && <Loading />}
      {Boolean(linkData) && (
        <LinkHeader link={linkData} isCreation={equals("CREATION", state?.page?.active)} />
      )}
      {state?.page?.active === "EDITION" && (
        <PackagesEdition
          onSubmit={onSave}
          linkPackages={linkPackages}
          onPackageLinkAssignmentSubmit={onPackageLinkAssignmentSubmit}
          linkPackagesErrors={linkPackagesErrors}
          linkPackagesClash={linkPackagesClash}
        />
      )}
      {state?.page?.active === "CREATION" && <CreationForm onSubmit={onPackageCrudSubmit} />}
    </>
  );
};

export default PackagesApp;
