import { useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { CubeQuery, Link } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { linkInfo } from "../graphql/linkInfo.query";

export const useGetLink = (): [Link, () => void] => {
  const { id, origin, scenario }: IParams = useParams();
  const client = useApolloClient();
  const [dataInfo, setDataInfo] = useState<Link>(null);

  const variables = {
    resourceId: id,
    scenarioId: scenario,
    originId: origin,
    resourceType: "LINK",
  };

  const getLinkInfo = async () => {
    try {
      const { data, errors } = await client.query({
        query: linkInfo,
        variables,
        fetchPolicy: "no-cache",
      });
      if (errors) {
        console.debug("[LinkHeader error]", errors);
        return null;
      }
      const cubeQuery: CubeQuery = data.cube;
      const cubeInfo = cubeQuery.editorView.info;
      const info: Link = cubeInfo.__typename === "LinkInfo" ? cubeInfo.link : null;
      const weeks = [];
      cubeQuery.editorWeeks.forEach((item, index) => {
        weeks.push({ ...item, value: index + 1 });
      });

      if (info) {
        setDataInfo(info);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [dataInfo, getLinkInfo];
};
