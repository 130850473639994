import React, { useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { Loading, Button } from "@foris/avocado-ui";
import { IParams } from "@models/IParams";
import { ProgramFilterFieldsInput } from "@models/ISchema";
import { ShiftDependency, LevelDependency, Course } from "../../../../shared/selectors";
import { EmptyResult } from "../../../../shared/modals";
import { validations } from "../../../../advanceSearch/utils";
import {
  IShiftDependency,
  ILevelDependency,
  ICourseId,
  ICourseLevel,
  ICurriculumLevel,
} from "../../../../advanceSearch/IAdvanceSearch";
import { objectEmpty } from "../../../../../utils/validations";
import { linkSearchMutation } from "../../graphql/advanceSearch.query";
import css from "../../../../advanceSearch/advance-search.module.scss";

const AdvanceSearchLink: React.FC = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(linkSearchMutation, {});
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [shiftValues, setShiftValues] = useState(null);
  const [levelValues, setLevelValues] = useState(null);
  const [arrayProgram, setArrayProgram] = useState<ProgramFilterFieldsInput[]>([]);
  const [objCourse, setObjCourse] = useState<ICourseId>({});
  const [objLevel, setObjLevel] = useState<ICourseLevel>({});
  const [objCurriculum, setObjCurriculum] = useState<ICurriculumLevel>({});
  const [loading, setLoading] = useState(false);

  const setLevelDependency = (value: ILevelDependency) => {
    const validation = validations.setLevelDependency(value, arrayProgram, objLevel, objCurriculum);
    validation.arrayProgram && setArrayProgram(validation.arrayProgram);
    validation.objCurriculum && setObjCurriculum(validation.objCurriculum);
    validation.objLevel && setObjLevel(validation.objLevel);
    setObjCourse(validation.objCourse);
  };

  const setShiftDependency = (value: IShiftDependency) => {
    // validation array content
    const validation = validations.shiftDependency(value);
    setArrayProgram(validation);
    // selected shift values
    setShiftValues(value);
    // set level-course dependencies
    setLevelValues({});
    setObjLevel({});
    setObjCourse({});
  };

  const setCourseDependency = (value: string) => {
    const validation = validations.courseDependencies(value, objCourse);
    setObjCourse(validation);
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  const createObjectMutation = () => {
    const filterArray = [];
    if (!objectEmpty(objCourse)) {
      const courseId = objCourse.courseId ? { id: objCourse.courseId } : {};
      filterArray.push(courseId);
    }
    if (!objectEmpty(objLevel)) {
      const levelId = objLevel.level ? { level: objLevel.level } : {};
      filterArray.push(levelId);
    }
    !objectEmpty(objCurriculum) && filterArray.push(objCurriculum);

    if (arrayProgram.length > 0) {
      filterArray.push({
        program: {
          and: arrayProgram,
        },
      });
    }

    const filter = {
      fields: {
        course: {
          and: [...filterArray],
        },
      },
    };

    const variables = {
      originId: origin,
      scenarioId: scenario,
      filter,
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return variables;
  };

  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createLinkFilter;
      if (dataItems.links.items.length > 0) {
        const idSearch = dataItems.id;
        const context = `${workspace}/${scenario}/${origin}`;
        const urlSearch = `/scheduler/editor/searchBy/link/${context}/?advance=${idSearch}`;
        history.push(urlSearch);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setError(true);
    }
  };

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />
      {advanceRowContent(
        "Dependencia",
        <>
          <ShiftDependency
            onCallback={values => {
              setShiftDependency(values);
            }}
            className={css.activePaddingGroup}
          />
          <LevelDependency
            {...shiftValues}
            onCallback={values => {
              setLevelValues(values);
              setLevelDependency(values);
              setCourseDependency(null);
            }}
            className={css.activePaddingEnd}
          />
          <Course
            curriculum={levelValues ? levelValues.curriculum : null}
            level={levelValues ? levelValues.level : null}
            filterBy="level"
            onCallback={value => {
              if (value) setCourseDependency(value.value);
            }}
            className={css.activePadding}
          />
        </>,
      )}
      {loading ? (
        <Loading />
      ) : (
        <div className={cx(css.advance_item, "container-row")}>
          <Button onClick={() => callUpdateHook()} disabled={error}>
            Buscar
          </Button>
        </div>
      )}
    </>
  );
};

export default AdvanceSearchLink;
