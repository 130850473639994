import { set, lensProp, append, init } from "ramda";
import { NavigationReducerType } from "./types";
import { Location } from "../models";

/**
 * Push the given `location` to the `locationStack`.
 *
 * @param location Location
 * @param NavigationReducerType
 *
 * @return NavigationReducerType
 */
export const addToLocationStack = (location: Location, state: NavigationReducerType) => {
  return set(
    lensProp<NavigationReducerType>("locationStack"),
    append(location, state?.locationStack),
    state,
  );
};

/**
 * Pop the last element from the `locationStack`
 *
 * @param NavigationReducerType
 *
 * @return NavigationReducerType
 */
export const popFromLocationStack = (state: NavigationReducerType) => {
  return set(lensProp<NavigationReducerType>("locationStack"), init(state?.locationStack), state);
};

/**
 * Set the given `boolean` value as the `forceFetching`.
 *
 * @param boolean
 * @param NavigationReducerType
 *
 * @return NavigationReducerType
 */
export const setForceFetching = set(lensProp<NavigationReducerType>("forceFetching"));
