import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";
import { megaSearch } from "../graphql/classRoomMegaSearch.query";
import { classRoomSearchQuery } from "../graphql/advanceSearch.queries";
import { ClassroomPage } from "../../../../models/ISchema";
import { IParams } from "../../../../models/IParams";
import SearchResults from "./SearchResults";
import Layout from "../../../../common/layout/Layout";
import { variablesSearch } from "../../utils/searchDimension";

const Search: React.FC = () => {
  const { origin, scenario }: IParams = useParams();
  const [page, setPageCount] = useState(1);
  const location = useLocation();
  const params: queryString.ParsedQuery<string> = queryString.parse(location?.search);
  const variables = variablesSearch(scenario, origin, params, page);
  const querySearch = params?.advance ? classRoomSearchQuery : megaSearch;

  const { loading, error, data } = useQuery(querySearch, { variables });
  let classroom: ClassroomPage = null;
  if (params?.advance) {
    classroom = data?.filter?.classrooms;
  } else {
    classroom = data?.cube?.classrooms;
  }

  return (
    <Layout contextSearch>
      <SearchResults
        data={classroom?.items}
        search={params?.search?.toString()}
        pageInfo={classroom?.pageInfo}
        setPage={(value: number) => setPageCount(value)}
        loading={loading}
        error={error}
      />
    </Layout>
  );
};

export default Search;
