import { useState } from "react";
import { view, lensPath, map, isEmpty, omit } from "ramda";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { PageInfo } from "@models/ISchema";
import { TableFiltersReducerType } from "../context/types";
import { groupsSearch as subgroups } from "../graphql/groups.query";
import { groupAdapter } from "../adapters/group";
import { AdaptedGroup } from "../models";

interface IPagination {
  page: number;
  size: number;
}

export const useGetSubgroups = (): [
  AdaptedGroup[],
  boolean,
  PageInfo,
  (
    group: AdaptedGroup,
    pagination: IPagination,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => void,
] => {
  const { scenario, origin }: IParams = useParams();
  const client = useApolloClient();
  const [response, setResponse] = useState<AdaptedGroup[]>(null);
  const [pageInfo, setPageInfo] = useState<PageInfo>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const viewItems = view(lensPath(["cube", "groupsManagerSearch", "items"]));
  const viewPageInfo = view(lensPath(["cube", "groupsManagerSearch", "pageInfo"]));

  const variables = (
    group: AdaptedGroup,
    pagination: IPagination,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    const fields = {
      isReferent: { is: false },
      clientCode: { eq: group?.clientCode },
    };

    return {
      scenarioId: scenario,
      originId: origin,
      filter: {
        fields,
        pagination,
        orderBy: isEmpty(orderBy) || orderBy?.field == null ? undefined : omit(["header"], orderBy),
        searchBy: isEmpty(searchBy) || searchBy?.text == null ? undefined : searchBy,
      },
    };
  };

  const getSubgroups = async (
    group: AdaptedGroup,
    pagination: IPagination,
    orderBy: TableFiltersReducerType["orderBy"],
    searchBy: TableFiltersReducerType["searchBy"],
  ) => {
    setLoading(true);
    try {
      const { data, errors } = await client.query({
        query: subgroups,
        variables: variables(group, pagination, orderBy, searchBy),
        fetchPolicy: "no-cache",
      });

      if (errors) {
        console.error("[Subgroups error]", errors);
        setLoading(false);
        return null;
      }

      setResponse(map(groupAdapter, viewItems(data)));
      setPageInfo(viewPageInfo(data));
      setLoading(false);
    } catch (error) {
      console.error("[Subgroups error]: ", error);
      setLoading(false);
    }
  };

  return [response, loading, pageInfo, getSubgroups];
};
