import React, { useState, useContext, useEffect } from "react";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import { Button, Icon, Snackbar } from "@foris/avocado-ui";
import { useParams, useHistory } from "react-router-dom";
import { Section, Session } from "@models/ISchema";
import { AppContext } from "../../context/EditSessionsContext";
import { Types as TypeResult } from "../../context/result.reducer";
import { Types as TypeSwitch } from "../../context/switchPage.reducer";
import { Types, FormActions, EditedSessions, EditedSession } from "../../context/formData.reducer";
import { Types as TypeLink } from "../../context/linkData.reducer";
import useSessionsCrudMutation from "../../hooks/useSessionsCrudMutation";
import Modal from "@components/modal/ModalState/ModalState";
import AccordionSections from "../../components/Accordion/AccordionSections";
import { compare } from "../../utils/compareEdited";
import { allSessionsCanBeSaved, sessionIsSavable } from "../../utils/context";
import EditSession from "./EditSession";
import CreateSession from "./CreateSession";
import { IParams } from "@models/IParams";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { IWeek } from "../../../ISections";
import css from "./formEdit.module.scss";

interface IFormEdit {
  heightHeader: number;
}

interface ILocationState {
  currentSelectedWeek?: IWeek;
}

const FormEdit: React.FC<IFormEdit> = (props: IFormEdit) => {
  const { heightHeader } = props;
  const location = useLocation();
  const locationState: ILocationState = location.state;
  const currentSelectedWeek = locationState?.currentSelectedWeek;
  const [resultSessionsCrud, submitSessionsCrud] = useSessionsCrudMutation({
    dryRun: true,
    skipValidations: true,
  });
  const { state, dispatch } = useContext(AppContext);
  const context: IContextApp = useContext(ContextApp);
  const [someCreatedSessionIsSavable, setSomeCreatedSessionIsSavable] = useState(false);
  const [allSavedSessionsAreSavable, setAllSavedSessionsAreSavable] = useState(true);
  const [activeDeleteSnackbar, setActiveDeleteSnackbar] = useState(false);
  const [activeDuplicationSnackbar, setActiveDuplicationSnackbar] = useState(false);
  const [activeUndoSnackbar, setActiveUndoSnackbar] = useState(false);
  const [warning, setWarning] = useState(false);
  const [showValidationWarning, setShowValidationWarning] = useState(false);
  const [, setVariables] = useState<FormActions>(null);
  const [error, setError] = useState(false);
  const hookParams: IParams = useParams();
  const history = useHistory();
  const numberOfSavedSessions = state?.form?.savedSessions?.length ?? 0;
  const numberOfSessionsToCreate = state?.form?.sessionsToCreate?.length ?? 0;
  const numberOfDeletedSessions = R.length(R.keys(state?.form?.sessionsToDelete ?? {}));

  const getSnackbarMessage = (): string => {
    if (activeDeleteSnackbar) {
      return "Sesiones eliminadas exitosamente";
    }
    if (activeDuplicationSnackbar) {
      return "Sesiones duplicadas exitosamente";
    }
    if (activeUndoSnackbar) return "Cambios deshechos exitosamente";
    else return "";
  };

  const linkUrl = () => {
    const { scenario, origin, workspace, id } = hookParams;
    return `/scheduler/editor/link/${workspace}/${scenario}/${origin}/${id}`;
  };

  const userCanEditOrDeleteSessions = context?.user?.abilities?.can_edit_or_delete_sessions;

  const userCanEdit = () => {
    if (!context?.user) return false;
    const { permissions, abilities } = context.user;
    return permissions?.update && abilities?.can_edit_assignment;
  };

  const selectedSessionsLabel = (selectedSessions: number): string => {
    return selectedSessions === 1
      ? "1 Sesión seleccionada"
      : `${selectedSessions} Sesiones seleccionadas`;
  };

  const handleCompareBySection = (
    sessions: Session[],
    section: Section,
    checked: boolean,
    newSessions: EditedSessions[],
  ) => {
    const mockSessions = newSessions.map(session => ({
      id: session?.id,
      origin: null,
      vacancies: null,
      events: null,
      section,
    }));

    const cloneSessions = [...mockSessions, ...sessions];
    const payload = {
      checked: !checked,
      sessionsOfSection: cloneSessions,
      sectionId: section.id,
    };

    if (R.all(session => compare(session, state), cloneSessions)) {
      dispatch({ type: Types.SelectedSessionsBySection, payload });
      setWarning(false);
      return !checked;
    } else {
      setWarning(true);
      setVariables({ type: Types.SelectedSessionsBySection, payload });
    }
    return checked;
  };

  const handleCompareSession = (
    session: Session,
    editedSession: EditedSession = null,
    section: Section = null,
  ) => {
    const isClonedSession = (session: EditedSession) => !!session?.isCloned;
    const { selectedCreateSession, selectedSessions, editedSessions } = state?.form;

    // Revisa si la sesión a crear tuvo alguna edición y todavía no ha sido
    // guardada (CASO EDITAR)
    if (!selectedCreateSession && session) {
      const compareEdited = compare(session, state);
      const fromSessionToCreate =
        selectedSessions?.length === 1 && selectedSessions[0]?.id?.includes("-");

      // this is false, we want it to be true
      if (fromSessionToCreate || compareEdited) {
        dispatch({ type: Types.SelectedSessions, payload: session });
        setWarning(false);
      } else {
        setWarning(true);
        setVariables({ type: Types.SelectedSessions, payload: session });
      }
    }

    // Si se está seleccionando una nueva sesión ya creada
    if (!selectedCreateSession && editedSession) {
      if (isClonedSession(editedSession)) {
        dispatch({ type: Types.SelectedSessions, payload: editedSession });
        setWarning(false);
      } else {
        const mockSession: Session = {
          id: editedSession?.id,
          origin: null,
          vacancies: null,
          events: null,
          section,
        };

        if (compare(mockSession, state)) {
          dispatch({ type: Types.SelectedSessions, payload: mockSession });
          setWarning(false);
        }
      }
    }

    // Revisa si la nueva sesión tuvo alguna edición (CASO NUEVA SESIÓN)
    if (selectedCreateSession && session) {
      const blocks = !!editedSessions?.blocks?.selected;
      const classrooms = !!editedSessions?.classrooms?.length;
      const instructors = !!editedSessions?.instructors?.length;
      const intervals = !!editedSessions?.intervals?.filter(R.propOr(false, "checked"))?.length;
      if (blocks || classrooms || instructors || intervals) {
        setWarning(true);
        setVariables({ type: Types.SelectedSessions, payload: session });
      } else {
        dispatch({ type: Types.CleanFormCreateSession });
        dispatch({ type: Types.SelectedSessions, payload: session });
      }
    }
    if (selectedCreateSession && editedSession) {
      const mockSession: Session = {
        id: editedSession?.id,
        origin: null,
        vacancies: null,
        events: null,
        section,
      };
      if (compare(mockSession, state)) {
        dispatch({ type: Types.SelectedSessions, payload: mockSession });
        setWarning(false);
      }
    }
  };

  const submitValidation = () => {
    if (numberOfSavedSessions || numberOfSessionsToCreate || numberOfDeletedSessions) {
      // If one or more sessionToCreate don't have enough resources to be
      // saved (meaning, at least one week and it's schedule) or a session is
      // been created, show the warning modal
      if (
        allSessionsCanBeSaved(state?.form?.sessionsToCreate) &&
        !state?.form?.selectedCreateSession
      ) {
        dispatch({ type: TypeSwitch.SetLoading, payload: true });
        submitSessionsCrud();
      } else {
        setShowValidationWarning(true);
      }
    }
  };

  useEffect(() => {
    if (currentSelectedWeek && !state.link.selectedWeek) {
      dispatch({ type: TypeLink.SetSelectedWeek, payload: currentSelectedWeek });
    }
  }, []);

  useEffect(() => {
    const responseData = resultSessionsCrud?.data;
    if (numberOfSavedSessions || numberOfSessionsToCreate || numberOfDeletedSessions) {
      const { numberOfEditedSessions, numberOfNewSessions } = R.reduce(
        (acc, session) => ({
          numberOfEditedSessions: acc.numberOfNewSessions + (session.isNew ? 0 : 1),
          numberOfNewSessions: acc.numberOfNewSessions + (session.isNew ? 1 : 0),
        }),
        { numberOfEditedSessions: 0, numberOfNewSessions: 0 },
        state?.form?.savedSessions ?? [],
      );

      let redirectToResultsView = false;

      const validateEdited = Boolean(numberOfEditedSessions > 0);
      const validateCreate = Boolean(numberOfNewSessions + state?.form?.sessionsToCreate.length);
      const validateDeleted = Boolean(responseData?.payload?.deletes?.length);

      if (
        (validateEdited || validateDeleted) &&
        (responseData?.payload?.updates ||
          responseData?.payload?.creates ||
          responseData?.payload?.deletes) &&
        !state?.result?.resultValidation
      ) {
        const { commited, skippedValidations, userCanSkipValidations } = responseData;
        dispatch({
          type: TypeResult.ResultValidation,
          payload: {
            sessionsPayload: [...responseData?.payload?.updates, ...responseData?.payload?.deletes],
            commited,
            skippedValidations,
            userCanSkipValidations,
          },
        });
      }

      if (validateCreate && responseData && !state?.result?.resultValidation) {
        const { commited, skippedValidations, userCanSkipValidations } = responseData;
        dispatch({
          type: TypeResult.CreateValidation,
          payload: {
            sessionsPayload: [...responseData?.payload?.creates],
            commited,
            skippedValidations,
            userCanSkipValidations,
          },
        });
      }

      if (responseData?.payload?.deletes?.length) {
        dispatch({
          type: TypeResult.DeleteValidation,
          payload: R.values(state?.form?.sessionsToDelete),
        });
      }

      redirectToResultsView =
        (validateEdited && responseData && !validateCreate) ||
        (!validateEdited && validateCreate && responseData) ||
        (validateCreate && responseData && validateEdited) ||
        validateDeleted;

      if (redirectToResultsView) {
        dispatch({ type: TypeResult.SelectCreateSession, payload: null });
        dispatch({ type: TypeSwitch.SetLoading, payload: false });
        dispatch({ type: TypeSwitch.ResultsPage, payload: true });
      }
    }

    if (resultSessionsCrud?.error) setError(true);
  }, [resultSessionsCrud]);

  useEffect(() => {
    const anyCreatedSessionIsSavable = R.any(sessionIsSavable, state?.form?.sessionsToCreate ?? []);
    const someSessionHasBeenSaved = Boolean(numberOfSavedSessions);
    const someSessionHasBeenDeleted = Boolean(numberOfDeletedSessions);
    setSomeCreatedSessionIsSavable(
      R.or(R.or(someSessionHasBeenSaved, someSessionHasBeenDeleted), anyCreatedSessionIsSavable),
    );
  }, [state?.form?.savedSessions, state?.form?.sessionsToCreate]);

  useEffect(() => {
    setAllSavedSessionsAreSavable(R.all(sessionIsSavable, state?.form?.savedSessions));
  }, [state?.form?.savedSessions]);

  const allSessionsAreDeleted = (
    sessions: EditedSession[],
    deletedSessionsIds: { [key: string]: boolean },
  ) => R.all(R.pipe(R.propOr("-", "id"), R.flip(R.has)(deletedSessionsIds)), sessions ?? []);

  const someSelectedSessionHasBeenDeleted = (
    sessions: EditedSession[],
    deletedSessionsIds: { [key: string]: boolean },
  ) => R.any(R.pipe(R.propOr("-", "id"), R.flip(R.has)(deletedSessionsIds)), sessions ?? []);

  return (
    <>
      <header className={cx(css.header, "container-row", "col_12")}>
        <Snackbar
          type="confirm"
          setValueActive={(value: boolean) => {
            if (activeDeleteSnackbar) setActiveDeleteSnackbar(value);
            if (activeDuplicationSnackbar) setActiveDuplicationSnackbar(value);
            if (activeUndoSnackbar) setActiveUndoSnackbar(value);
          }}
          active={activeDeleteSnackbar || activeDuplicationSnackbar || activeUndoSnackbar}
          icon="circle-check"
          duration={3}
        >
          {getSnackbarMessage()}
        </Snackbar>
        <section className={cx(css.actions)}>
          {selectedSessionsLabel(state?.form?.selectedSessions?.length ?? 0)}
          <>
            {userCanEditOrDeleteSessions && (
              <button
                disabled={allSessionsAreDeleted(
                  state?.form?.selectedSessions,
                  state?.form?.sessionsToDelete,
                )}
                className={cx(css.actions_button, css.actions_button__delete)}
                onClick={() => {
                  dispatch({
                    type: Types.RemoveSelectedSessions,
                    payload: state?.form?.selectedSessions,
                  });
                  setActiveDeleteSnackbar(true);
                }}
              >
                <Icon icon="trash" className={cx(css.icon)} />
                Eliminar
              </button>
            )}
            <button
              disabled={
                !state?.form?.selectedSessions?.length ||
                (!numberOfSavedSessions &&
                  !numberOfSessionsToCreate &&
                  R.pipe(someSelectedSessionHasBeenDeleted, R.not)(
                    state?.form?.selectedSessions,
                    state?.form?.sessionsToDelete,
                  ))
              }
              className={cx(css.actions_button, css.actions_button__info)}
              onClick={() => {
                setActiveUndoSnackbar(true);
                dispatch({
                  type: Types.UndoEditionOverSelectedSessions,
                  payload: state?.form?.selectedSessions,
                });
              }}
            >
              <Icon icon="repeat" className={cx(css.icon)} />
              Deshacer edición
            </button>
            {userCanEditOrDeleteSessions && (
              <button
                className={cx(css.actions_button, css.actions_button__info)}
                disabled={!state?.form?.selectedSessions?.length}
                onClick={() => {
                  dispatch({ type: Types.AddSessionsToCreate });
                  setActiveDuplicationSnackbar(true);
                }}
              >
                <Icon icon="copy" className={cx(css.icon)} />
                Duplicar
              </button>
            )}
          </>
        </section>
        <section className={cx(css.buttons)}>
          <Button
            className={css.buttons_item}
            typeButton="transparent"
            onClick={() => {
              history.push(linkUrl(), { currentSelectedWeek, sessionId: undefined });
            }}
          >
            Ir a liga
          </Button>
          {userCanEdit() && (
            <Button
              className={css.buttons_item}
              disabled={
                (numberOfSavedSessions === 0 &&
                  numberOfSessionsToCreate === 0 &&
                  numberOfDeletedSessions === 0) ||
                !someCreatedSessionIsSavable ||
                !allSavedSessionsAreSavable
              }
              onClick={submitValidation}
            >
              Validar
            </Button>
          )}
        </section>
      </header>
      <section className={cx(css.content, css[`content__height${heightHeader}`])}>
        <aside className={cx(css.aside)}>
          <AccordionSections
            onCompareBySession={handleCompareSession}
            onCompareBySection={handleCompareBySection}
          />
        </aside>
        {state?.form?.selectedCreateSession && (
          <CreateSession warning={warning} setWarning={setWarning} />
        )}
        {!state?.form?.selectedCreateSession && <EditSession />}
      </section>
      <Modal
        typeState="warning"
        title="¿Estás seguro?"
        show={showValidationWarning}
        textButtonPrincipal={"Confirmar"}
        textButtonSecondary={"Cancelar"}
        onClickSecondary={() => setShowValidationWarning(false)}
        onClickPrincipal={() => {
          setShowValidationWarning(false);
          dispatch({ type: TypeSwitch.SetLoading, payload: true });
          submitSessionsCrud();
          dispatch({ type: Types.CleanUnsavableSessionsToCreate });
        }}
      >
        Algunas sesiones creadas no tienen todas sus asignaciones. Si continuas con la validación,
        se perderán.
      </Modal>
      <Modal
        typeState="error"
        title="Error"
        show={error}
        textButtonPrincipal="Confirmar"
        onClickPrincipal={() => window.location.reload()}
      >
        Se ha encontrado un error en la validación
      </Modal>
    </>
  );
};

export default FormEdit;
