import { AcademicUnit, Campus, Package, Subject } from "@models/ISchema";
import { set, lensProp, lensPath, pipe, Lens, dissocPath, assocPath } from "ramda";
import { AdaptedGroup } from "../models";
import { FiltersReducerType } from "./types";

/**
 * Set all advanced filters values as `false` and the main filters as `null`.
 *
 * @return FiltersReducerType
 */
export const cleanFilters: (state: FiltersReducerType) => FiltersReducerType = pipe(
  set(lensProp("campus"), null),
  set(lensProp("courseTypesById"), {}),
  set(lensProp("department"), null),
  set(lensProp("school"), null),
  set(lensProp("selectedCrn"), null),
  set(lensProp("selectedPackage"), null),
  set(lensProp("subject"), null),
  set(lensPath(["state", "active"]), false),
  set(lensPath(["state", "inactive"]), false),
  set(lensPath(["visibility", "visible"]), false),
  set(lensPath(["visibility", "invisible"]), false),
  set(lensPath(["availability", "available"]), false),
  set(lensPath(["availability", "unavailable"]), false),
  set(lensPath(["enrollments", "with"]), false),
  set(lensPath(["enrollments", "without"]), false),
);

/**
 * Interface of ramda's `set` function
 *
 * @param Lens
 * @param boolean
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const setAdvancedFilter: (
  filter: Lens<FiltersReducerType, boolean>,
  value: boolean,
  state: FiltersReducerType,
) => FiltersReducerType = set;

/**
 * Set the given `Campus` as the `campus` of the `GroupManager`'s context.
 *
 * @param Campus
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const setCampus: (campus: Campus, state: FiltersReducerType) => FiltersReducerType = set(
  lensProp<FiltersReducerType>("campus"),
);

/**
 * Set the given `AcademicUnit` as the `department` of the `GroupManager`'s context.
 *
 * @param AcademicUnit
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const setDepartment: (
  department: AcademicUnit,
  state: FiltersReducerType,
) => FiltersReducerType = set(lensProp<FiltersReducerType>("department"));

/**
 * Add the given `AcademicUnit` to the `courseTypesById` of the `GroupManager`'s context.
 *
 * @param AcademicUnit
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const addCourseTypeById = (
  courseType: AcademicUnit,
  state: FiltersReducerType,
): FiltersReducerType => assocPath(["courseTypesById", courseType?.id], courseType, state);

/**
 * Remove the given `AcademicUnit` to the `courseTypesById` of the `GroupManager`'s context.
 *
 * @param AcademicUnit
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const removeCourseTypeById = (
  courseType: AcademicUnit,
  state: FiltersReducerType,
): FiltersReducerType => dissocPath(["courseTypesById", courseType?.id], state);

/**
 * Set the given `AcademicUnit` as the `school` of the `GroupManager`'s context.
 *
 * @param AcademicUnit
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const setSchool: (
  school: AcademicUnit,
  state: FiltersReducerType,
) => FiltersReducerType = set(lensProp<FiltersReducerType>("school"));

/**
 * Set the given `Subject` as the subject of the `GroupManager`'s context.
 *
 * @param Subject
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const setSubject: (subject: Subject, state: FiltersReducerType) => FiltersReducerType = set(
  lensProp<FiltersReducerType>("subject"),
);

/**
 * Set the given `string` as the 'selectedCrn' of the `GroupManager`'s context.
 *
 * @param AdaptedGroup['code']
 * @param FiltersReducerType
 *
 * @return FiltersReducerType
 */
export const selectCrn: (
  crn: AdaptedGroup["code"],
  state: FiltersReducerType,
) => FiltersReducerType = set(lensProp<FiltersReducerType>("selectedCrn"));

export const selectPackage = (packageId: Package["id"], state: FiltersReducerType) => ({
  ...state,
  selectedPackage: packageId,
});
