import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "@models/IParams";
import { CourseComponent, Link as Links } from "@models/ISchema";
import { generateTitle } from "../../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, loading, error, setPage, pageInfo } = props;
  const route = `/scheduler/editor/link/${workspace}/${scenario}/${origin}`;
  const title = generateTitle(pageInfo?.total || 0, search, "liga");

  const getComponentsText = (components: Partial<CourseComponent>[] = []) => {
    const names = components.reduce((prev, cmp) => {
      const name = cmp?.component?.name;
      if (cmp?.component?.name in prev || !name) return prev;

      return {
        ...prev,
        [name]: name,
      };
    }, {});

    return Object.keys(names).join(", ");
  };

  const columns = [
    {
      header: getHeadCell("Código"),
      renderer: ({ id, code }: Links) => getLinkCell(route, id, code),
      styles: {
        desktop: {
          minWidth: 200,
        },
      },
    },
    {
      header: getHeadCell("Código Asignatura"),
      renderer: ({ course }: Links) => getColumnCell(course?.code),
      styles: {
        desktop: {
          minWidth: 120,
        },
      },
    },
    {
      header: getHeadCell("Asignatura"),
      renderer: ({ course }: Links) => getColumnCell(course?.name),
      styles: {
        desktop: {
          minWidth: 260,
        },
      },
    },
    {
      header: getHeadCell("Periodo"),
      renderer: ({ bundle }: Links) => getColumnCell(bundle?.term?.code),
    },
    {
      header: getHeadCell("Secciones Asociadas"),
      renderer: ({ sectionsCount }: Links) => getColumnCell(sectionsCount),
    },
    {
      header: getHeadCell("Componentes"),
      renderer: ({ course }: Links) => getColumnCell(getComponentsText(course?.courseComponents)),
      styles: {
        desktop: {
          minWidth: 200,
        },
      },
    },
    {
      header: getHeadCell("Vacantes"),
      renderer: ({ vacancies }: Links) => getColumnCell(vacancies),
    },
  ];
  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension="liga"
      advanceLink={`/editor/advanceSearch/link/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
