import gql from "graphql-tag";

export const TERMS_QUERY = gql`
  query($scenarioId: ID!, $filter: TermFilterInput) {
    data(scenarioId: $scenarioId) {
      terms(filter: $filter) {
        items {
          id
          code
          start_date
          end_date
          name
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;

export const ADD_GROUPS_MUTATION = gql`
  mutation createGroup($scenarioId: ID!, $originId: ID!, $input: CreateGroupInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      createGroup(input: $input) {
        skippedValidations
        commited
        group {
          code
          name
          __typename
        }
        validationErrors {
          ...InvalidFieldValidation
          ...InvalidLinkChanges
          ...InvalidGroupEnrollment
          ...InvalidGroupCodeValidation
        }
      }
    }
  }

  fragment InvalidFieldValidation on InvalidFieldValidation {
    value
    reason
    meta
  }

  fragment InvalidLinkChanges on InvalidLinkChanges {
    link {
      id
    }
    sectionChanges {
      section {
        id
        courseComponent {
          id
          academicStandard
        }
      }
      validationErrors {
        ...InvalidComponentSize
        __typename
      }
    }
    groupChanges {
      group {
        id
        code
        enrollmentStats {
          id
          enrollmentsCount
        }
      }
      validationErrors {
        ...InvalidGroupEnrollment
        __typename
      }
    }
    sessionChanges {
      session {
        id
        vacancies
        section {
          id
        }
      }
      validationErrors {
        __typename
        ...InvalidClassroomCapacity
      }
    }
  }

  fragment InvalidComponentSize on InvalidComponentSize {
    reason
    component {
      academicStandard
    }
  }

  fragment InvalidGroupEnrollment on InvalidGroupEnrollment {
    reason
  }

  fragment InvalidClassroomCapacity on InvalidClassroomCapacity {
    classroom {
      id
      capacity
    }
    reason
  }

  fragment InvalidGroupCodeValidation on InvalidGroupCodeValidation {
    rejected_groups {
      id
      capacity
      __typename
    }
    allowed
    __typename
  }
`;

export const MUTATION_DISABLE_GROUP = gql`
  mutation disableGroup($scenarioId: ID!, $originId: ID!, $groupId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      disableGroup(groupId: $groupId) {
        commited
        link {
          id
          code
        }
        validationErrors {
          ...InvalidGroupEnrollment
          ... on ExternalGroupValidation {
            allowed
            rejected_groups
            reason
            __typename
          }
          __typename
        }
      }
    }
  }

  fragment InvalidGroupEnrollment on InvalidGroupEnrollment {
    reason
  }
`;

export const UPDATE_GROUP_VISIBILITY = gql`
  mutation updateGroupVisible($scenarioId: ID!, $originId: ID!, $groupId: ID!, $visible: Boolean!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      updateGroupVisible(groupId: $groupId, visible: $visible) {
        commited
        link {
          id
          code
        }
        validationErrors {
          ... on ExternalGroupValidation {
            allowed
            rejected_groups
            reason
            __typename
          }
          __typename
        }
      }
    }
  }
`;
