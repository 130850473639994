import { useState } from "react";
import { map, prop } from "ramda";
import { useMutation } from "react-apollo";
import { EditGroupLabelsInput, EditGroupLabelsPayload } from "@models/ISchema";
import { groupLabelsMutation } from "../graphql/groupLabes.mutation";
import { ILabel } from "../EditLabels/ContextEditLabels";

type Response = [EditGroupLabelsPayload, (labels: ILabel[]) => void];

const useUpdateGroupLabel = (id: string, scenario: string, origin: string): Response => {
  const [response, setResponse] = useState<EditGroupLabelsPayload>(null);

  const [labelsMutation] = useMutation(groupLabelsMutation, {});

  const updateGroupLabels = async (labels: ILabel[]) => {
    try {
      const input: EditGroupLabelsInput = {
        dryRun: false,
        groupId: id,
        labelIds: map(prop("id"), labels ?? []),
      };
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input,
      };
      const { data } = await labelsMutation({ variables });
      const newResponse: EditGroupLabelsPayload = data?.cube?.editGroupLabels;
      setResponse(newResponse);
    } catch (e) {
      setResponse(null);
      console.error("[labels]", e);
    }
  };

  return [response, updateGroupLabels];
};

export default useUpdateGroupLabel;
