import gql from "graphql-tag";

const disableSectionMutation = gql`
  mutation disableSection($scenarioId: ID!, $originId: ID!, $input: EditSectionInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      disableSection(input: $input) {
        skippedValidations
        commited
        section {
          id
        }
        validationErrors {
          ... on ExternalGroupValidation {
            allowed
            rejected_groups
            __typename
          }
          __typename
          ... on InvalidGroupEnrollment {
            reason
            value
          }
          ... on InvalidInput {
            code
          }
          ... on InvalidateSectionDeletion {
            code
            __typename
          }
        }
      }
    }
  }
`;

export { disableSectionMutation };
