import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { shiftQuery } from "../graphql/shift.queries";
import { Shift, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorShift, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const ShiftSelect: React.FC<ISelectorShift> = (props: ISelectorShift) => {
  const client = useApolloClient();
  const { onCallback, modality, label, className } = props;
  const { scenario }: IParams = useParams();
  const [optionsShift, setOptionsShift] = useState([]);
  const [valueShift, setValueShift] = useState(null);
  const [prevModality, setPrevModality] = useState(modality);
  const [disabled, setDisabled] = useState(false);

  const variablesShift = {
    query: shiftQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setShiftOptions = useCallback(async data => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optShift = [];
      if (dataQuery.shifts.items.length > 0) {
        dataQuery.shifts.items.forEach((shifts: Shift) => {
          optShift.push({ label: shifts.name, value: shifts.id });
        });
      }
      setOptionsShift(optShift);
    }
  }, []);

  const getShiftOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesShift);
      await setShiftOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [client, variablesShift, setShiftOptions]);

  const getCacheShiftOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesShift);
      await setShiftOptions(data);
    } catch (error) {
      getShiftOptions();
    }
  }, [client, variablesShift, setShiftOptions, getShiftOptions]);

  useEffect(() => {
    if (modality && prevModality !== modality && disabled) {
      getCacheShiftOptions();
      setPrevModality(modality);
      setDisabled(false);
    }
    if (!modality) {
      setPrevModality(null);
      setValueShift("");
      setDisabled(true);
    }
  }, [modality, prevModality, disabled, getCacheShiftOptions]);

  return (
    <div className={cx(css.selectors, css.selectors_select__padding, className)}>
      {label && <label className="labelItem">Jornada</label>}
      <Select
        options={optionsShift}
        value={valueShift}
        placeholder={label ? "Seleccionar" : "Jornada"}
        isClearable
        isDisabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValueShift(value);
            onCallback(value);
          } else {
            setValueShift("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default ShiftSelect;
