import React, { useState, useEffect } from "react";
import cx from "classnames";
import { ContextEdit, useContextEdit, ICurrentData } from "./ContextEdit/ContextEdit";
import SearchInstructor from "./Search/Instructor/SearchInstructor";
import SearchClassRoom from "./Search/ClassRoom/SearchClassRoom";
import ValidationReport from "./ValidationReport/ValidatioReport";
import FormEdit from "./FormEdit/FormEdit";
import HeaderEdit from "./../HeaderEdit/HeaderEdit";
import { IWeek } from "../../ISections";
import { selectedDayEn } from "../../../../utils/days";
import css from "./itemEdit.module.scss";

interface IItemEdit {
  selected: IWeek;
  weeks: any;
  onClose: (value: any) => void;
  event: any;
  notRequireSchedule?: boolean;
  createSession?: boolean;
  component?: any;
  sectionId?: any;
  vacancies?: number;
  title?: string;
  recommendationsAvailable?: boolean;
}

const EditModal: React.FC<IItemEdit> = (props: IItemEdit) => {
  const {
    selected,
    weeks,
    event,
    onClose,
    notRequireSchedule,
    createSession,
    component,
    sectionId,
    vacancies,
    title,
    recommendationsAvailable,
  } = props;
  const contextEdit = useContextEdit();
  const [propsData, setPropsData] = useState(null);
  const instructor = event.resource && event.resource.instructor ? event.resource.instructor : "";
  const classroom = event.resource && event.resource.classroom ? event.resource.classroom : "";
  const instructors =
    event.resource && event.resource.instructors ? event.resource.instructors : [];
  const classrooms = event.resource && event.resource.classrooms ? event.resource.classrooms : [];

  useEffect(() => {
    if (propsData === null) {
      const blocksCount = contextEdit.currentData.noScheduleEventsCount;
      const block = event.resource && event.resource.blockRange ? event.resource.blockRange : "";
      const day: any = block !== "" ? selectedDayEn(block.start.day) : "";
      const startTime: any = {
        label: block !== "" ? block.start.startingTime.replace(":00", "") : "",
        value: block !== "" ? block.start.startingTime : "",
      };
      const endTime: any = {
        label: block !== "" ? block.end.endingTime.replace(":00", "") : "",
        value: block !== "" ? block.end.endingTime : "",
      };
      const currentData: ICurrentData = {
        blockRanges: { startTime, endTime, day },
        instructor: {
          id: instructor && instructor.id,
          code: instructor && instructor.code,
          name: instructor && instructor.name,
        },
        classroom: {
          id: classroom && classroom.id,
          code: classroom && classroom.code,
          name: classroom && classroom.name,
          building: classroom && classroom.building.name,
        },
        repeat: "weekSelect",
        weeksRepeat: [],
        noScheduleEventsCount: blocksCount,
        optionHour: "hourRequiere",
      };
      contextEdit.setCurrentData(currentData);
      contextEdit.setInstructors(instructors);
      contextEdit.setClassrooms(classrooms);

      setPropsData(true);
    }
  }, [propsData, classroom, classrooms, event.resource, instructor, instructors]);

  return (
    <ContextEdit.Provider value={contextEdit}>
      <section
        className={cx(
          css.cntItemEdit,
          "contaianer-row",
          contextEdit.currentView === "validations" && css.cntItemEdit__validationReport,
        )}
      >
        <HeaderEdit
          event={event}
          createSession={createSession}
          component={component}
          sectionId={sectionId}
          vacancies={vacancies}
          title={title}
        />

        {contextEdit.currentView === "form" && propsData && (
          <FormEdit
            selected={selected}
            weeks={weeks}
            event={event}
            onClose={onClose}
            createSession={createSession}
            sectionId={sectionId}
            notRequireSchedule={notRequireSchedule}
            recommendationsAvailable={recommendationsAvailable}
          />
        )}
        {contextEdit.currentView === "searchClassRoom" && (
          <SearchClassRoom desactiveSearch={() => console.log(false)} />
        )}

        {contextEdit.currentView === "serachInstructor" && (
          <SearchInstructor desactiveSearch={() => console.log(false)} />
        )}

        {contextEdit.currentView === "validations" && (
          <div className="col_12">
            <ValidationReport
              event={event}
              selected={selected}
              onClose={onClose}
              createSession={createSession}
              sectionId={sectionId}
              vacancies={vacancies}
              currentWeeks={weeks}
            />
          </div>
        )}
      </section>
    </ContextEdit.Provider>
  );
};

export default EditModal;
