import React from "react";
import { AppProvider } from "../context/EditSessionsContext";
import Layout from "../../../../common/layout/Layout";
import InitEditSessions from "./InitEditSessions";

const EditSessions: React.FC = () => {
  return (
    <Layout contextSearch>
      <AppProvider>
        <InitEditSessions />
      </AppProvider>
    </Layout>
  );
};

export default EditSessions;
