import React, { useContext } from "react";
import * as R from "ramda";
import cx from "classnames";
import { Button } from "@foris/avocado-ui";
import { AppContext } from "../../context/EditSessionsContext";
import { Types } from "../../context/switchPage.reducer";
import { Types as TypeResult } from "../../context/result.reducer";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { Types as FormTypes } from "../../context/formData.reducer";
import css from "./resultHeader.module.scss";
interface ResultHeaderProps {
  onSubmit: () => void;
}

const ResultsHeader: React.FC<ResultHeaderProps> = (props: ResultHeaderProps) => {
  const { onSubmit } = props;
  const { state, dispatch } = useContext(AppContext);
  const context: IContextApp = useContext(ContextApp);
  const userCanEdit =
    context?.user?.permissions?.update && context?.user?.abilities?.can_edit_assignment;

  const numberOfSessionsToValidate =
    (state?.form?.savedSessions?.length ?? 0) +
    (state?.form?.sessionsToCreate.length ?? 0) +
    R.length(R.keys(state?.form?.sessionsToDelete ?? {}));

  const createValidation = state?.result?.createValidation;
  const editValidation = state?.result?.resultValidation;
  const createUserCanSkipValidations = createValidation
    ? createValidation?.userCanSkipValidations
    : true;
  const editUserCanSkipValidations = editValidation ? editValidation?.userCanSkipValidations : true;
  const userCanSkipValidations = createUserCanSkipValidations && editUserCanSkipValidations;

  let title = `${numberOfSessionsToValidate} sesiones a validar`;
  if (numberOfSessionsToValidate === 1) title = `${numberOfSessionsToValidate} Sesión a validar`;

  const userCanMutateSessions = () => {
    const abilitiesValidations = {
      InstructorAssignmentNotAllowed: true,
      BlocksAssignmentNotAllowed: true,
      ClassroomsAssignmentNotAllowed: true,
      IntervalsAssignmentNotAllowed: true,
    };

    const sessionPayload = [
      ...(editValidation?.sessionsPayload ?? []),
      ...(createValidation?.sessionsPayload ?? []),
    ];

    return R.isEmpty(sessionPayload)
      ? true
      : R.all(
          ({ validationErrors }) =>
            R.pipe(
              R.filter(({ __typename }) => __typename && __typename in abilitiesValidations),
              R.isEmpty,
            )(validationErrors),
          sessionPayload,
        );
  };

  return (
    <header className={cx(css.resultsHeader, "container-row", "row--between", "col_12")}>
      <h1 className={cx(css.resultsHeader_title)}>{title}</h1>
      <div className={cx(css.buttons)}>
        <Button
          typeButton="transparent"
          className={css.buttons_item}
          onClick={() => {
            dispatch({ type: FormTypes.CleanFormCreateSession });
            dispatch({ type: Types.FormPage, payload: true });
            dispatch({ type: TypeResult.SelectSession, payload: null });
            dispatch({ type: TypeResult.ResultValidation, payload: null });
            dispatch({ type: TypeResult.CreateValidation, payload: null });
          }}
        >
          Volver a editar
        </Button>
        {userCanEdit && (
          <Button
            disabled={!userCanSkipValidations || !userCanMutateSessions()}
            className={css.buttons_item}
            onClick={onSubmit}
          >
            Realizar cambios
          </Button>
        )}
      </div>
    </header>
  );
};

export default ResultsHeader;
