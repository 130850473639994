import React, { useState } from "react";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { ShiftDependency, LevelDependency, ComponentType } from "../../../shared/selectors";
import { EmptyResult } from "../../../shared/modals";
import { validations } from "../../../advanceSearch/utils";
import {
  IShiftDependency,
  ILevelDependency,
  ICourseLevel,
  IComponentTypeId,
} from "../../../advanceSearch/IAdvanceSearch";
import { ProgramFilterFieldsInput } from "../../../../models/ISchema";
import Button from "../../../../common/components/Button/Button";
import { Loading } from "@foris/avocado-ui";
import { objectEmpty } from "../../../../utils/validations";
import { courseSearchMutation } from "../graphql/advanceSearch.queries";
import css from "../../../advanceSearch/advance-search.module.scss";

const AdvanceSearchCourse: React.FC<any> = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const [advanceSearchMutation] = useMutation(courseSearchMutation, {});
  const [isEmpty, setIsEmpty] = useState(false);
  const [shiftValues, setShiftValues] = useState(null);
  const [arrayProgram, setArrayProgram] = useState<ProgramFilterFieldsInput[]>([]);
  const [objLevel, setObjLevel] = useState<ICourseLevel>({});
  const [objComponentType, setObjComponentType] = useState<IComponentTypeId>({});
  const [objCurriculum, setObjCurriculum] = useState({});
  const [loading, setLoading] = useState(false);

  const setLevelDependency = (value: ILevelDependency) => {
    const validation = validations.setLevelDependency(value, arrayProgram, objLevel, objCurriculum);
    validation.arrayProgram && setArrayProgram(validation.arrayProgram);
    validation.objCurriculum && setObjCurriculum(validation.objCurriculum);
    validation.objLevel && setObjLevel(validation.objLevel);
  };

  const setShiftDependency = (value: IShiftDependency) => {
    // validation array content
    const validation = validations.shiftDependency(value);
    setArrayProgram(validation);
    // selected shift values
    setShiftValues(value);
    // set level-course dependencies
    setObjLevel({});
    setObjCurriculum({});
  };

  const setComponentType = (value: string) => {
    const validation = validations.componentType(value, objComponentType);
    setObjComponentType(validation);
  };

  const createObjectMutation = () => {
    const filterArray = [];
    !objectEmpty(objCurriculum) && filterArray.push(objCurriculum);
    !objectEmpty(objLevel) && filterArray.push(objLevel);
    !objectEmpty(objComponentType) && filterArray.push(objComponentType);

    if (arrayProgram.length > 0) {
      filterArray.push({
        program: {
          and: arrayProgram,
        },
      });
    }
    const mutationFilter = {
      originId: origin,
      scenarioId: scenario,
      filter: {
        fields: {
          and: [
            // Set default filter 'cause now we bring only the ones with assignment
            { hasEvents: true },
            ...filterArray,
          ],
        },
      },
      pagination: {
        page: 1,
        size: 10,
      },
    };
    return mutationFilter;
  };
  const callUpdateHook = async () => {
    setLoading(true);
    try {
      const variables = createObjectMutation();
      const { data } = await advanceSearchMutation({ variables });
      setLoading(false);
      const dataItems = data.filter.createCourseFilter;
      if (dataItems.courses.items.length > 0) {
        const idSearch = dataItems.id;
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/scheduler/editor/searchBy/course/${contextUrl}/?advance=${idSearch}`);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const advanceRowContent = (title: string, component: React.ReactNode) => (
    <div className={cx(css.advance_item, "container-row")}>
      <h5 className={cx(css.advance_item_title, "col_12")}>{title}</h5>
      <div className={cx(css.advance_row, "container-row")}>{component}</div>
    </div>
  );

  return (
    <>
      <EmptyResult show={isEmpty} onClose={() => setIsEmpty(false)} />
      {advanceRowContent(
        "Dependencia",
        <>
          <ShiftDependency
            onCallback={values => {
              setShiftDependency(values);
            }}
            className={css.activePaddingGroup}
          />
          <LevelDependency
            {...shiftValues}
            onCallback={values => {
              setLevelDependency(values);
            }}
            className={css.activePaddingEnd}
          />
        </>,
      )}
      {advanceRowContent(
        "Componente",
        <ComponentType onCallback={value => setComponentType(value?.value)} />,
      )}
      {loading ? (
        <div className="loading_wrapper">
          <Loading />
        </div>
      ) : (
        <div className={cx(css.advance_item, "container-row")}>
          <Button onClick={() => callUpdateHook()}>Buscar</Button>
        </div>
      )}
    </>
  );
};

export default AdvanceSearchCourse;
