import React, { useContext, useState } from "react";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { ContextEdit, IContextEdit, IInstructor, IClassRoom } from "../ContextEdit/ContextEdit";
import { IWeek } from "../../../ISections";
import { IParams } from "../../../../../models/IParams";
import Button from "../../../../../common/components/Button/Button";
import BlockRanges from "../BlockRanges/BlockRanges";
import Repeat from "../Repeat/Repeat";
import Searchs from "../Search/Searchs";
import Recommendations from "../Recommendations/Recommendations";
import { UPDATE_EVENT_MUTATION, CREATE_EVENT_MUTATION } from "../formEdit.queries";
import { EditSessionPayload } from "../../../../../models/ISchema";
import { IContextApp, ContextApp } from "@config/Context/contextApp";
import { Loading } from "@foris/avocado-ui";
import css from "../itemEdit.module.scss";

interface IFormProps {
  selected: IWeek;
  weeks: any;
  onClose: (value: any) => void;
  event: any;
  notRequireSchedule: boolean;
  createSession?: boolean;
  sectionId?: any;
  recommendationsAvailable?: boolean;
}

const FormEdit: React.FC<IFormProps> = (props: IFormProps) => {
  const {
    selected,
    weeks,
    event,
    notRequireSchedule,
    createSession,
    sectionId,
    recommendationsAvailable,
  } = props;
  const { scenario, origin }: IParams = useParams();
  const context: IContextEdit = useContext(ContextEdit);
  const contextApp: IContextApp = useContext(ContextApp);
  const [updateSession] = useMutation(UPDATE_EVENT_MUTATION, {});
  const [createNewSession] = useMutation(CREATE_EVENT_MUTATION, {});
  const [activeLoading, setActiveLoading] = useState(false);
  const classrooms = context.classrooms;
  const instructors = context.instructors;
  const startTime = context.currentData.blockRanges.startTime;
  const endTime = context.currentData.blockRanges.endTime;
  const day = context.currentData.blockRanges.day;
  const eventSection = event.sectionId ? event.sectionId : sectionId;
  const blocksCount =
    event.resource && event.resource.blocksCount ? event.resource.blocksCount : null;
  const valueBlocksCount = context.currentData.noScheduleEventsCount;
  const intervalsSessionWeek =
    event.resource && event.resource.intervals ? event.resource.intervals : null;
  const idInstructors = instructors.map((valueInstructor: IInstructor) =>
    parseInt(valueInstructor.id),
  );
  const idClassrooms = classrooms.map((valueClassroom: IClassRoom) => parseInt(valueClassroom.id));
  const userCanEdit =
    contextApp?.user?.permissions?.update && contextApp?.user?.abilities?.can_edit_assignment;

  const callUpdateHook = (variables: any) => {
    setActiveLoading(true);
    if (createSession) {
      createNewSession({ variables })
        .then((data: any) => {
          const response: EditSessionPayload = data.data.cube.createSession;
          context.setCustomValidations(response.customValidations);
          context.setIntervalValidationErrors(response.intervalValidationErrors);
          context.setValidationErrors(response.validationErrors);
          context.setEditUserCanSkip(response.userCanSkipValidations);
          context.setCurrentView("validations");
          setActiveLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      updateSession({ variables })
        .then((data: any) => {
          const response: EditSessionPayload = data.data.cube.editSession;
          context.setCustomValidations(response.customValidations);
          context.setIntervalValidationErrors(response.intervalValidationErrors);
          context.setValidationErrors(response.validationErrors);
          context.setEditUserCanSkip(response.userCanSkipValidations);
          context.setCurrentView("validations");
          setActiveLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const repeatObj = () => {
    let objRepeat = {};

    switch (context.currentData.repeat) {
      case "onlySession":
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: [parseInt(selected.id)],
            uniqueInterval: true,
          },
        };
        break;
      case "allWeeks":
        const allWeeksFromSession = R.map(
          R.pipe(R.prop<"id", string>("id"), parseInt),
          intervalsSessionWeek,
        );
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: allWeeksFromSession,
            uniqueInterval: false,
          },
        };
        break;
      case "weekSelect":
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: R.pipe(
              R.view(R.lensPath(["currentData", "weeksRepeat"])),
              R.filter(R.propOr(false, "checked")),
              R.map(R.pipe(R.prop<"id", string>("id"), parseInt)),
            )(context),
            uniqueInterval: false,
            forceFork: true,
          },
        };
        break;
      case "withWeeksGrouper":
        const checkedWeeks = R.pipe(
          R.view(R.lensPath(["currentData", "weeksRepeat"])),
          R.filter(R.propOr(false, "checked")),
          R.map(R.pipe(R.prop<"id", string>("id"), parseInt)),
        )(context);
        objRepeat = {
          changeIntervals: {
            op: "CHANGE",
            intervalIds: checkedWeeks,
            uniqueInterval: false,
            forceFork: false,
          },
        };
        break;
    }
    return objRepeat;
  };

  const showBlockRange = () => {
    let objBlock = {};
    if (context.currentData.optionHour === "hourRequiere") {
      objBlock = {
        changeBlocks: {
          op: "CHANGE",
          day: day?.value ?? null,
          startTime: startTime.value ?? null,
          endTime: endTime.value ?? null,
        },
      };
    }
    return objBlock;
  };

  const showBlocks = () => {
    let objBlockCount = {};
    if (context.currentData.optionHour === "blockRequiere" && valueBlocksCount !== null) {
      objBlockCount = {
        noScheduleEventsCount: valueBlocksCount,
      };
    }
    return objBlockCount;
  };

  const mutationValidate = () => {
    const addRepeat = repeatObj();
    const blockRange = showBlockRange();
    const addBlocksCount = showBlocks();
    let sectionId = {};
    let sessionId = {};
    if (createSession) sectionId = { sectionId: eventSection };
    if (!createSession) sessionId = { sessionId: parseInt(event.sessionId) };
    const variables = {
      originId: origin,
      scenarioId: scenario,
      input: {
        ...sessionId,
        ...sectionId,
        dryRun: true,
        skipValidations: false,
        clientMutationId: "mutation",
        changeset: {
          changeClassroom: {
            op: "CHANGE",
            classroomIds: idClassrooms ? idClassrooms : [],
          },
          changeInstructor: {
            op: "CHANGE",
            instructorIds: idInstructors ? idInstructors : [],
          },
          ...addBlocksCount,
          ...addRepeat,
          ...blockRange,
        },
      },
    };
    callUpdateHook(variables);
  };

  const isDisabled = (): boolean => {
    if (context.currentData.optionHour === "hourRequiere") {
      return (
        (day && day.label === "") ||
        (startTime && startTime.label === "") ||
        (endTime && endTime.label === "") ||
        startTime.label >= endTime.label
      );
    }
    if (context.currentData.optionHour === "blockRequiere") {
      return !valueBlocksCount || valueBlocksCount < 1;
    }
  };

  return (
    <React.Fragment>
      {activeLoading && <Loading />}
      {!activeLoading && (
        <section className="container-row">
          <section className={cx(css.content, "container-row", "row--between")}>
            <article className="col_7">
              {/** block time range and days */}
              <BlockRanges
                notRequireSchedule={notRequireSchedule || createSession}
                blocksCount={blocksCount}
              />

              {/** search by instructor and classroom */}
              <Searchs />

              {/** weeks */}
              <Repeat
                selected={selected}
                weeks={weeks}
                intervalsSessionWeek={intervalsSessionWeek}
                createSession={createSession}
              />
              <footer className={cx(css.cntFooter, "container-row")}>
                <Button
                  color="transparent"
                  className={cx(css.cntFooter_btn)}
                  onClick={(e: any) => props.onClose(e)}
                >
                  Cancelar
                </Button>
                {userCanEdit && (
                  <Button
                    className={cx(css.cntFooter_btn)}
                    onClick={mutationValidate}
                    disabled={isDisabled()}
                  >
                    Validar
                  </Button>
                )}
              </footer>
            </article>
            <div className="col_5">
              {/** recommendations */}
              {recommendationsAvailable && <Recommendations event={event} />}
            </div>
          </section>
        </section>
      )}
    </React.Fragment>
  );
};

export default FormEdit;
