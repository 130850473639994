import React from "react";
import { useParams } from "react-router-dom";
import { IParams } from "../../../../models/IParams";
import { Package } from "../../../../models/ISchema";
import { generateTitle } from "../../utils/searchDimension";
import SearchTable from "@dimensions/common/SearchTable";
import { getColumnCell, getHeadCell, getLinkCell } from "@dimensions/utils/tableUtils";
import { ISearchProps } from "@dimensions/common/ISearch";
import utilsCss from "../../utils/tableUtils.module.scss";

const SearchResults = (props: ISearchProps) => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { data, search, pageInfo, loading, error, setPage } = props;
  const route = `/scheduler/editor/package/${workspace}/${scenario}/${origin}`;
  const title = generateTitle(pageInfo?.total || 0, search, "paquete");

  const columns = [
    {
      header: getHeadCell("Código"),
      renderer: ({ code, id }: Package) => getLinkCell(route, id, code),
      styles: {
        desktop: {
          minWidth: 180,
        },
      },
    },
    {
      header: getHeadCell("Periodo"),
      renderer: ({ population }: Package) => getColumnCell(population?.term?.code),
    },
    {
      header: getHeadCell("Sede"),
      renderer: ({ population }: Package) => getColumnCell(population?.campus?.code),
    },
    {
      header: getHeadCell("Modalidad"),
      renderer: ({ population }: Package) => getColumnCell(population?.modality?.code),
    },
    {
      header: getHeadCell("Jornada"),
      renderer: ({ population }: Package) => getColumnCell(population?.shift?.code),
    },
    {
      header: getHeadCell("Carrera"),
      renderer: ({ population }: Package) =>
        getColumnCell(
          <>
            <span className={utilsCss.codeText}>{population?.program?.code}</span>{" "}
            {population?.program?.name}
          </>,
        ),
      styles: {
        desktop: {
          width: 250,
        },
      },
    },
    {
      header: getHeadCell("Curriculo"),
      renderer: ({ population }: Package) => getColumnCell(population?.curriculum),
    },
    {
      header: getHeadCell("Nivel"),
      renderer: ({ population }: Package) => getColumnCell(population?.level),
    },
    {
      header: getHeadCell("Paquete"),
      renderer: ({ index }: Package) => getColumnCell(index),
    },
    {
      header: getHeadCell("Vacantes"),
      renderer: ({ vacancies }: Package) => getColumnCell(vacancies),
    },
  ];
  return (
    <SearchTable
      title={title}
      columns={columns}
      data={data}
      pageInfo={pageInfo}
      setPage={setPage}
      loading={loading}
      error={error}
      dimension="paquete"
      advanceLink={`/editor/advanceSearch/package/${workspace}/${scenario}/${origin}`}
    />
  );
};

export default SearchResults;
