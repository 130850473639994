import { UserAbilities, UserPermissions } from "@models/ISchema";
import { all, equals, isEmpty, map, pipe, propOr, __ } from "ramda";
import { Requirements } from "../settings";

/**
 * Returns true is all the given `requiredAbilities` exists and are true in the
 * given `userAbilities`.
 *
 * @param requirements Requirements
 * @param userAbilities UserAbilities
 * @param userPermissions ("read" | "update" | "create" | "delete")[]
 *
 * @return boolean
 */
const moduleIsAccessible = (
  requirements: Requirements,
  userAbilities: UserAbilities,
  userPermissions: UserPermissions,
): boolean => {
  const requiredAbilities = requirements?.abilities;
  const requiredPermissions = requirements?.permissions;

  if (isEmpty(requiredAbilities) && isEmpty(requiredPermissions)) {
    return true;
  }

  const validAbilities = pipe(
    map<keyof UserAbilities, boolean>(propOr(false, __, userAbilities)),
    all(equals(true)),
  )(requiredAbilities);
  const validPermissions = pipe(
    map<keyof UserPermissions, boolean>(propOr(false, __, userPermissions)),
    all(equals(true)),
  )(requiredPermissions);

  return validAbilities && validPermissions;
};

export default moduleIsAccessible;
