import gql from "graphql-tag";

export const getBlocksRanges = gql`
  query getBlocksRanges($scenarioId: ID!) {
    data(scenarioId: $scenarioId) {
      allBlocks {
        id
        startingTime
        endingTime
      }
    }
  }
`;
