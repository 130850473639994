import gql from "graphql-tag";

export const eventTypesQuery = gql`
  query eventTypes {
    base {
      classroomBookingTypes {
        items {
          id
          code
          name
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
        }
      }
    }
  }
`;
