import gql from "graphql-tag";

export const classroomBookings = gql`
  query classroomBookings($scenarioId: ID!, $originId: ID!, $filter: ClassroomBookingFilterInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classroomBookings(filter: $filter) {
        pendingCount
        items {
          id
          title
          status
          capacity
          createdAt
          sessions {
            id
            scheduleEvent {
              id
              start
              end
            }
          }
          responsible {
            id
            name
            lastName
          }
          classroom {
            id
            code
            name
            building {
              id
              code
              name
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          page
          size
          total
          __typename
        }
      }
    }
  }
`;
