import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Loading } from "@foris/avocado-ui";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { dataLeagues } from "./utils";
import {
  ContextVacancies,
  IContextVacancies,
  useContextVacancies,
} from "./contextVacancies/ContextVacancies";
import Layout from "../../common/layout/Layout";
import Header from "./Header";
import Groups from "./Groups";
import Instructor from "./Instructor";
import { BUNDLES_QUERY } from "./graphql/bundles.queries";
import { Bundle } from "../../models/ISchema";
import { IParams } from "../../models/IParams";
import css from "./vacancies.module.scss";
import "react-tabs/style/react-tabs.css";

export enum VacanciesType {
  instructor = "instructor",
  vacancies = "vacancies",
}

const Vacancies: React.FC<any> = () => {
  const { origin, scenario, id }: IParams = useParams();
  const [dataBundle, setDataBundle] = useState<Bundle>(null);
  const [loading, setLoading] = useState(false);
  const context: IContextVacancies = useContextVacancies();
  const client = useApolloClient();
  const [activeEditVacancies, setActiveEditVacancies] = useState(false);
  const [activeEditInstructor, setActiveEditInstructor] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await client.query({
        query: BUNDLES_QUERY,
        variables: {
          scenarioId: scenario,
          originId: origin,
          bundleId: id,
        },
        fetchPolicy: "no-cache",
      });
      const leagueBase: Bundle = data.cube.bundle;
      leagueBase ? setDataBundle(leagueBase) : console.error("vacancies data");
    } catch (error) {
      console.log("error vacancies", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!dataBundle) getData();
  }, []);

  useEffect(() => {
    if (dataBundle) {
      const formatData = dataLeagues(dataBundle);
      formatData ? context.setCurrentLeague(formatData) : console.error("vacancies format");
    }
  }, [dataBundle]);

  return (
    <Layout contextSearch>
      {loading && <Loading />}

      {!loading && !!dataBundle && !!context.currentLeague && (
        <ContextVacancies.Provider value={context}>
          <article className={cx(css.cntVacancies)}>
            <Header data={dataBundle} />
            <section className={css.contentBundle}>
              <Tabs>
                <TabList className={css.tab}>
                  <Tab>Grupos</Tab>
                  <Tab>Docentes</Tab>
                </TabList>
                <TabPanel>
                  <Groups
                    active={activeEditVacancies}
                    setActive={value => setActiveEditVacancies(value)}
                    dataBundle={dataBundle}
                  />
                </TabPanel>
                <TabPanel>
                  <Instructor
                    active={activeEditInstructor}
                    setActive={value => setActiveEditInstructor(value)}
                    dataBundle={dataBundle}
                  />
                </TabPanel>
              </Tabs>
            </section>
          </article>
        </ContextVacancies.Provider>
      )}
    </Layout>
  );
};

export default Vacancies;
