import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { buildingQuery } from "../graphql/building.queries";
import { Building, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorBuilding, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const BuildingSelect: React.FC<ISelectorBuilding> = (props: ISelectorBuilding) => {
  const client = useApolloClient();
  const { onCallback, campus, label, className, placeholder } = props;
  const { scenario }: IParams = useParams();
  const [optionsBuilding, setOptionsBuilding] = useState([]);
  const [valueBuilding, setValueBuilding] = useState(null);
  const [prevCampus, setPrevCampus] = useState(campus);
  const [disabled, setDisabled] = useState(false);

  const variablesBuilding = {
    query: buildingQuery,
    variables: {
      scenarioId: scenario,
      filter: {
        fields: {
          campusId: {
            eq: campus,
          },
        },
      },
    },
  };

  const setBuildingOptions = async (data: any) => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optBuildings = [];
      if (dataQuery.buildings.items.length > 0) {
        dataQuery.buildings.items.forEach((building: Building) => {
          optBuildings.push({ label: building.name, value: building.id });
        });
      }
      setOptionsBuilding(optBuildings);
    }
  };

  const getBuildingOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesBuilding);
      await setBuildingOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [client, variablesBuilding]);

  const getCacheBuildingOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesBuilding);
      await setBuildingOptions(data);
    } catch (error) {
      getBuildingOptions();
    }
  }, [client, getBuildingOptions, variablesBuilding]);

  useEffect(() => {
    if (campus && prevCampus !== campus && disabled) {
      getCacheBuildingOptions();
      setPrevCampus(campus);
      setDisabled(false);
    }
    if (!campus) {
      setPrevCampus(null);
      setValueBuilding("");
      setDisabled(true);
    }
  }, [campus, prevCampus, disabled, getCacheBuildingOptions]);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Edificio</label>}
      <Select
        options={optionsBuilding}
        value={valueBuilding}
        placeholder={placeholder ? placeholder : label ? "Seleccionar" : "Edificio"}
        isClearable
        isDisabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValueBuilding(value);
            onCallback(value);
          } else {
            setValueBuilding("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default BuildingSelect;
