import React, { useState, useContext, useEffect } from "react";
import { useApolloClient } from "react-apollo";
import { useParams } from "react-router-dom";
import { Table, Pager, Button, Loading } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "../../../../../../models/IParams";
import { ClassroomPage, PageInfo } from "../../../../../../models/ISchema";
import { ContextEdit, IContextEdit } from "../../ContextEdit/ContextEdit";
import { classroomSearchQuery } from "../graphql/classroom.queries";
import css from "../search.module.scss";

interface ITableResultsClassRoomProps {
  dataId: string;
  onDisabled: (value: boolean, notResult: boolean) => void;
}
const TableResultsClassRoom: React.FC<ITableResultsClassRoomProps> = (
  props: ITableResultsClassRoomProps,
) => {
  const { dataId, onDisabled } = props;
  const { scenario }: IParams = useParams();
  const client = useApolloClient();
  const context: IContextEdit = useContext(ContextEdit);
  const [pageCount, setPageCount] = useState(1);
  const [dataCheck, setDataCheck] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [dataPager, setDataPager] = useState<PageInfo>(null);
  const [totalCount, setTotalCount] = useState(null);
  const [prevDataId, setPrevDataId] = useState(null);
  const [loading, setLoading] = useState(false);

  const saveData = () => {
    const arrayDataCheck = [...context.classrooms];
    dataCheck.forEach((item: any) => {
      const findClassroom = context.classrooms.find(value => value.id === item.id);
      if (!findClassroom) {
        const objData = {
          code: item.code,
          id: item.id,
          name: item.name,
          building: item.building.name,
        };
        arrayDataCheck.push(objData);
      }
    });
    context.setClassrooms(arrayDataCheck);
  };

  const renderCheck = (row: any) => {
    return (
      <Table.Checkbox
        {...row.row.getToggleRowSelectedProps()}
        checked={dataCheck.find(value => value.id === row.value)}
        onChange={() => {
          const find = dataCheck.find(value => value.id === row.value);
          const checked = [...dataCheck];
          if (!find) {
            checked.push(row.row.original);
            setDataCheck(checked);
          } else {
            const removeChecked = checked.filter(check => check.id !== row.value);
            setDataCheck(removeChecked);
          }
        }}
      />
    );
  };

  const columns = [
    {
      Header: "",
      accessor: "id",
      minWidth: 30,
      maxWidth: 50,
      Cell: (row: any) => renderCheck(row),
    },
    {
      Header: "Código",
      accessor: "code",
      maxWidth: 90,
    },
    {
      Header: "Nombre",
      accessor: "name",
      minWidth: 80,
    },
    {
      Header: "Edificio",
      accessor: "building.name",
      minWidth: 80,
    },
    {
      Header: "Sede",
      accessor: "building.campus.name",
      minWidth: 80,
    },
    {
      Header: "Piso",
      accessor: "floor",
      maxWidth: 90,
    },
    {
      Header: "Tipo",
      accessor: "classroomType.name",
      minWidth: 140,
    },
    {
      Header: "Capacidad",
      accessor: "capacity",
      maxWidth: 90,
    },
    {
      Header: "Utilización (%)",
      accessor: "assignment.usagePercentage",
      maxWidth: 100,
    },
    {
      Header: "Ocupación (%)",
      accessor: "assignment.chairsOccupationPercentage",
      maxWidth: 100,
    },
  ];

  const getDataTable = async (page = pageCount) => {
    setLoading(true);
    onDisabled(true, false);
    try {
      const { data } = await client.query({
        query: classroomSearchQuery,
        variables: {
          scenarioId: scenario,
          filterId: dataId,
          pagination: {
            page,
            size: 20,
          },
        },
      });
      const classroom: ClassroomPage = data?.filter?.classrooms;
      const dataItems = classroom.items;
      const totalItems = classroom.pageInfo.total;
      setDataTable(dataItems);
      setDataPager(classroom.pageInfo);
      setTotalCount(totalItems);
      if (totalItems === 0) {
        onDisabled(false, true);
      } else {
        onDisabled(false, false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      onDisabled(false, false);
    }
  };

  const onPageChange = (pageIndex: number) => {
    getDataTable(pageIndex);
    setPageCount(pageIndex);
  };

  useEffect(() => {
    if (dataId && dataId !== prevDataId && !loading) {
      getDataTable();
      setPrevDataId(dataId);
    }
  }, [dataId]);

  return (
    <>
      {!dataTable && loading && <Loading />}
      {dataTable && dataTable.length > 0 && dataPager && (
        <>
          <h3 className={css.cntSearch_title}>{`${totalCount} resultados`}</h3>
          <Table.Container
            data={dataTable}
            columns={columns}
            pagerTop={true}
            pagerBottom={true}
            loading={loading}
          >
            <Pager
              {...dataPager}
              total={dataPager?.total ?? 20}
              size={20}
              page={pageCount}
              onPageChange={pageIndex => onPageChange(pageIndex)}
            />
          </Table.Container>
          <div className={cx(css.cntBtn, "container-row")}>
            <Button
              className={cx(css.cntBtn_item)}
              typeButton="transparent"
              onClick={() => context.setCurrentView("form")}
            >
              Cancelar
            </Button>
            <Button
              className={cx(css.cntBtn_item)}
              onClick={() => {
                saveData();
                context.setCurrentView("form");
              }}
              disabled={dataCheck.length === 0}
            >
              Agregar
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default TableResultsClassRoom;
