import { pipe, set, lensProp, head, reject, propOr } from "ramda";
import { Group } from "@models/ISchema";
import { subjectAdapter } from "./subject";
import { courseAdapter } from "./course";
import { AdaptedGroup } from "../models";

export const groupAdapter = (group: Group): AdaptedGroup => {
  const isNumeric = (value: string) => /^-?\d+$/.test(value);

  return pipe(
    set(lensProp<AdaptedGroup>("subject"), subjectAdapter(group?.subject)),
    set(lensProp<AdaptedGroup>("course"), courseAdapter(group?.course)),
    set(lensProp<AdaptedGroup>("link"), head(group?.links) ?? {}),
    set(
      lensProp<AdaptedGroup>("labels"),
      reject(pipe(propOr("69420", "code"), isNumeric), group?.labels ?? []),
    ),
  )(group ?? ({} as any));
};
