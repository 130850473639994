import gql from "graphql-tag";

export const groupSection = gql`
  query groupSection(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on GroupInfo {
            group {
              id
              code
              label
              name
              capacity
              type
              text
              course {
                code
                name
                curriculum {
                  id
                  name
                  program {
                    id
                    code
                    campus {
                      id
                      code
                      name
                      __typename
                    }
                  }
                }
              }
              labels {
                id
                code
                name
                category {
                  id
                  code
                  name
                  labelSource {
                    id
                    code
                    name
                  }
                }
              }
            }
            __typename
          }
        }
      }
    }
  }
`;
