import { EditorValidationError } from "../ISchema";
import ErrorWarning, { IErrorWarning } from "./ErrorWarning";

export const sessionEditStrings = {
  ExternalBlock:
    "No se permite hacer cambios de horario debido a que el\
      grupo tiene alumnos inscritos, se encuentra con cupo > 0 en el \
      sistema de inscripciones, o bien, se está intentando cambiar a un salón de menor capacidad.",
};

class SectionEdit extends ErrorWarning {
  private session: EditorValidationError;
  constructor(error: EditorValidationError) {
    super();
    this.session = error;
  }

  private getMessageExternalValidation = (): IErrorWarning => {
    let invalidInput = null;
    if (this.session.__typename === "ExternalGroupValidation") {
      invalidInput = this.setError(sessionEditStrings.ExternalBlock);
    }

    return invalidInput;
  };

  public getMessage = () => {
    return this.getMessageExternalValidation();
  };
}

export default SectionEdit;
